import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SportService } from '../services/sport.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { SocketServiceService } from '../services/socket-service.service';
import { ReportService } from '../services/report.service';
import { environment } from '../../environments/environment';
import { DataTableDirective } from 'angular-datatables';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import * as moment from 'moment';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
@Component({
  selector: 'app-pl-market',
  templateUrl: './pl-market.component.html',
  styleUrls: ['./pl-market.component.scss']
})
export class PlMarketComponent implements AfterViewInit, OnDestroy, OnInit {
  match_id: any;
  match_name: any;
  moment: any = moment;
  public Base_Url = environment['adminServerUrl'];
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  pageSize: number = 50;
  statementsTotalItems: any;
  statementsPageIndex: number;
  userDetails: any;
  fromDate: any;
  toDate: any;
  search: any;
  searchMarket: any;
  profitData: any;
  type_id: any;
  public token = localStorage.getItem('userAccessToken');
  reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ` + this.token
  });
  sport_id: any;
  column: any;
  constructor(private http: HttpClient, private route: ActivatedRoute, private sport: SportService, private reportService: ReportService,
    private cookie: CookieService, private toastr: ToastrService, private socketService: SocketServiceService, private router: Router) {
    this.route.params.subscribe(params => {
      this.match_id = params.matchId;
      this.match_name = params.matchName;
      this.type_id = params.typeId;
      this.sport_id = params.sportId
    })
  }

  ngOnInit(): void {
    this.column = [
      {
        title: 'Sport Name',
        data: 'sport_name'
      },
      {
        title: 'Event Name',
        data: 'match_name'
      },
      {
        title: 'Market Name',
        data: 'event_name',
        "render": function (data, type, row) {
          var content = '';
          content = content + '<span style="color: #14805e !important;cursor: pointer;">' + row.event_name + '</span>';
          return content;
        }
      },
      {
        title: 'Result',
        data: 'winner_name'
      },
      {
        title: 'Profit/Loss',
        data: 'user_pl',
        "render": function (data, type, row) {
          var content = ''
          if (row.user_pl != null && row.user_pl < 0) {
            content = content + '<span class="text-center text-danger">' + (row.user_pl).toFixed(2) + '</span>';
          } else if (row.user_pl != null && row.user_pl >= 0) {
            content = content + '<span class="text-center text-success">' + (row.user_pl).toFixed(2) + '</span>';
          } else {
            content = '';
          }
          return content;
        }
      },
      {
        title: 'Commission',
        data: 'user_commission_pl',
        "render": function (data, type, row) {
          var content = ''
          if (row.user_commission_pl != null && row.user_commission_pl < 0) {
            content = content + '<span class="text-center text-danger">' + (row.user_commission_pl).toFixed(2) + '</span>';
          } else if (row.user_commission_pl != null && row.user_commission_pl >= 0) {
            content = content + '<span class="text-center text-success">' + (row.user_commission_pl).toFixed(2) + '</span>';
          } else {
            content = '';
          }
          return content;
        }
      },
      {
        title: 'Settle Time',
        data: 'updateDateTime'
      },
    ];
    if (this.sport_id < 0) {
      let b = {
        title: 'Round-Id',
        data: 'roundId'
      }
      this.column[3] = b
    }
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      searching: false,
      paging: false,
      serverSide: true,
      processing: true,
      ordering:false,
      destroy:true,
      ajax: (dataTablesParameters1: any, callback) => {
        let dataTablesParameters = {
          limit: this.pageSize,
          page: this.statementsPageIndex,
          "search": {
            "match_name": this.match_name,
            "match_id": this.match_id,
            type: this.type_id
          }
        }
        dataTablesParameters1.search.value != "" ? dataTablesParameters["search"] = (dataTablesParameters1.search.value) : ""
        that.http.post<DataTablesResponse>(this.Base_Url + 'report/eventsProfitLoss', dataTablesParameters, { headers: this.reqHeader })
          .subscribe(resp => {
            if (resp["status"]) {
              this.profitData = resp.data['data'];
              this.profitData = this.profitData.sort((a, b) => <any>new Date(b.result_date) - <any>new Date(a.result_date));
              for (let i = 0; i < this.profitData.length; i++) {
                this.profitData[i].updateDateTime = moment(this.profitData[i].result_date).utcOffset("+5:30").format('DD-MM-YYYY HH:mm:ss');
                if (this.sport_id < 0) {
                  let a = (this.profitData[i]).event_id.split('.');
                  this.profitData[i].roundId = a[3]
                }
              }
              if (resp.data['metadata'][0]) {
                this.statementsTotalItems = resp.data['metadata'][0].total
                this.statementsPageIndex = resp.data['metadata'][0].page
              }
              callback({
                recordsTotal: resp.data['metadata'][0].total,
                recordsFiltered: resp.data['metadata'][0].total,
                data: this.profitData
              });

            } else {
              this.toastr.error(resp["msg"]);
              this.profitData = []
              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: this.profitData
              });
            }


          });
      },
      columns: this.column,
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        $('td', row).off('click');
        $('td', row).on('click', () => {
          console.log("190", data);

          this.userBet(data);
        });
        $(row).addClass('text-center');
        return row;
      }
    };
  }

  
  pageChange(event?: any) {
    if (event) {
      this.statementsPageIndex = event
    } else {
      this.statementsPageIndex = 1
    }
  }
  marketWisePl() {
    let data = {
      limit: this.pageSize,
      page: this.statementsPageIndex,
      "search": {
        "match_name": this.match_name,
        "match_id": this.match_id,
        type: this.type_id
      }
    }
    this.reportService.profitLoss(data).subscribe((res) => {
      if (res.status) {
        this.profitData = res.data.data;
        this.profitData = this.profitData.sort((a, b) => <any>new Date(b.result_date) - <any>new Date(a.result_date));
        for (let i = 0; i < this.profitData.length; i++) {
          this.profitData[i].updateDateTime = moment(this.profitData[i].result_date).utcOffset("+5:30").format('DD-MM-YYYY HH:mm:ss');
        }
        if (res.data.metadata[0]) {
          this.statementsTotalItems = res.data.metadata[0].total
          this.statementsPageIndex = res.data.metadata[0].page
        }
      } else {
        this.toastr.error(res.msg)
      }
    })
  }
  searchFilter() {
    this.search = this.searchMarket;
    this.marketWisePl();
  }
  setSelectedOption(selectedOption) {
    this.pageSize = parseInt(selectedOption);
    this.statementsPageIndex = 1;
    this.marketWisePl();
  }

  userBet(data) {
    // let a = this.Base_Url.split('api/v1/');
    // let b = 'http://192.168.0.9:4200/'+ 'plUserBet/' + data.match_id + '/'+data.event_id + '/'+ data.type;
    // let b = a[0] + 'plUserBet/' + data.match_id + '/'+data.event_id + '/'+ data.type;
    // window.open(b, '_blank', 'location=yes,height=800,width=1200,scrollbars=yes,status=yes');
    if (data.sport_id > 0) {
      this.router.navigate(['plUserBet/' + data.match_id + '/' + data.event_id + '/' + data.type])
    } else {
      if (data.sport_id == -102) {
        this.router.navigate(['plSnUserBet/' + data.event_id + '/' + data.sport_name + '/' + data.match_name + '/' + data.event_name])
      }
    }

  }
  ngAfterViewInit(): void {
    this.dtTrigger.next("");
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next("");
    });
  }
}
