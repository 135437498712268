<div class="main-body">
  <app-open-header></app-open-header>
  <section class="container-fluid">
    <div id="HomeSlider" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button data-bs-target="#HomeSlider" data-bs-slide-to="0" class="active" aria-current="true"
          aria-label="Slide 1"></button>
        <button data-bs-target="#HomeSlider" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button data-bs-target="#HomeSlider" data-bs-slide-to="2" aria-label="Slide 3"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="assets/images/banner1.png" alt="" class="d-block w-100">
        </div>
        <div class="carousel-item">
          <img src="assets/images/banner1.png" alt="" class="d-block w-100">
        </div>
        <div class="carousel-item">
          <img src="assets/images/banner1.png" alt="" class="d-block w-100">
        </div>
      </div>
      <a class="carousel-control-prev" href="#HomeSlider" role="button" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      </a>
      <a class="carousel-control-next" href="#HomeSlider" role="button" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
      </a>
    </div>
  </section>
  <div class="slider_games_section" data-aos="zoom-in">
    <img class="img-fluid" style="cursor:pointer;" (click)="dashboard(4)" src="assets/images/game-casino.png">
    <img class="img-fluid" style="cursor:pointer;" (click)="dashboard(1)" src="assets/images/game-sports.png">
  </div>
  <main id="main">
    <section id="clients" class="my-4">
      <div class="container">
        <div class="swiper slider1" data-aos="fade-out">
          <div class="swiper-wrapper">
            <div class="swiperMain-slide" style="margin-right: 10px; margin-bottom: 10px;"><img src="assets/images/poster1.png"
                class="img-fluid" alt=""></div>
            <div class="swiperMain-slide"><img src="assets/images/poster2.png" class="img-fluid" alt=""></div>
          </div>
        </div>
        <div class="sports_area mt-2" data-aos="fade-in">
          <div class="sa_col">
            <img class="img-fluid" src="assets/images/icon-vollyball.png">
            <span>vollyball</span>
          </div>
          <div class="sa_col">
            <img class="img-fluid" src="assets/images/icon-rugby.png">
            <span>rugby</span>
          </div>
          <div class="sa_col">
            <img class="img-fluid" src="assets/images/icon-handball.png">
            <span>handball</span>
          </div>
          <div class="sa_col">
            <img class="img-fluid" src="assets/images/icon-baseball.png">
            <span>baseball</span>
          </div>
          <div class="sa_col">
            <img class="img-fluid" src="assets/images/icon-golf.png">
            <span>golf</span>
          </div>
          <div class="sa_col">
            <img class="img-fluid" src="assets/images/icon-formula.png">
            <span>formulla</span>
          </div>
          <div class="sa_col">
            <img class="img-fluid" src="assets/images/icon-boxing.png">
            <span>boxing</span>
          </div>
          <div class="sa_col">
            <img class="img-fluid" src="assets/images/icon-cycling.png">
            <span>cycling</span>
          </div>
          <div class="sa_col">
            <img class="img-fluid" src="assets/images/icon-basketball.png">
            <span>basketball</span>
          </div>
        </div>
      </div>
    </section>
    <section class="hts">
      <div class="section-header">
        <h2>how to start</h2>
        <p>Join us for a better experience, With some easy effort, you can create your account and fill the flight of your dreams..</p>
      </div>
      <div class="timeline">
        <div class="container">
          <div class="row">
            <div class="timeline1" data-aos="fade-down">
              <img class="img-fluid" src="assets/images/icon-register.png">
              <h4>register</h4>
              <p>Create your username of your choice to register
                For example, Words and numbers you can match with it.</p>
              <span>1</span>
            </div>
            <div class="timeline2" data-aos="fade-up">
              <img class="img-fluid" src="assets/images/icon-verify.png">
              <h4>verify</h4>
              <p>There is no need to enter anything for verification, just register with your active whatsapp number so that it is easy to contact you.</p>
              <span>2</span>
            </div>
            <div class="timeline3" data-aos="fade-down">
              <img class="img-fluid" src="assets/images/icon-deposit.png">
              <h4>deposit</h4>
              <p>To deposit the payment, transfer the payment to the account provided on the site and upload your screenshot with UTR number.</p>
              <span>3</span>
            </div>
            <div class="timeline4" data-aos="fade-left">
              <img class="img-fluid" src="assets/images/icon-win.png">
              <h4>win</h4>
              <p>Choose your favorite game and enjoy with a chance to win lots of money</p>
              <span>4</span>
            </div>
          </div>
        </div>
      </div>
      <img class="img-fluid full-bg-img" src="assets/images/hts-img.png">
    </section>
    <section class="winner_section">
      <div class="winner container">
        <div class="row align-items-center">
          <div class="col-md-6 order-xs-last">
            <div class="winner_titile" data-aos="flip-down">
              <h2>play <span>anytime, <em>anywhere!</em></span></h2>
            </div>
            <!-- <p class="text-light mb-1">Meet Our Recent Winner</p>
            <div class="swiper slider2 client-slider" data-aos="fade-in">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="winner_client">
                    <img class="img-fluid" src="assets/images/winner-client1.png">
                    <h5>rs 25,000</h5>
                    <h6>rachi reddy</h6>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="winner_client">
                    <img class="img-fluid" src="assets/images/winner-client2.png">
                    <h5>rs 30,000</h5>
                    <h6>rachi reddy</h6>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="winner_client">
                    <img class="img-fluid" src="assets/images/winner-client1.png">
                    <h5>rs 35,000</h5>
                    <h6>rachi reddy</h6>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="winner_client">
                    <img class="img-fluid" src="assets/images/winner-client2.png">
                    <h5>rs 45,000</h5>
                    <h6>rachi reddy</h6>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="winner_client">
                    <img class="img-fluid" src="assets/images/winner-client1.png">
                    <h5>rs 55,000</h5>
                    <h6>rachi reddy</h6>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="winner_client">
                    <img class="img-fluid" src="assets/images/winner-client2.png">
                    <h5>rs 65,000</h5>
                    <h6>rachi reddy</h6>
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div> -->
          </div>
          <div class="col-md-6 order-xs-first">
            <img class="img-fluid" src="assets/images/winner-img.png" data-aos="fade-left">
          </div>
        </div>
      </div>
    </section>

    <section class="spca">
      <div class="container">
        <div class="row align-items-center mb-5">
          <div class="col-md-6">
            <img class="img-fluid ctm-img" src="assets/images/spca-img1.png" data-aos="fade-right">
          </div>
          <div class="col-md-6">
            <div class="spca_div" data-aos="fade-in">
              <h2>sports</h2>
              <p>Our website.in! Our team continues to work hard to make something special for all sports. we are very
                confident that we can exceed our players' expectations for a perfect gaming experience which encompasses
                a product with a wide variety of markets for punters to choose from.</p>
              <p>We offering many different types of sports to bet on. Some of the most popular ones are definitely
                cricket, football, tennis, basketball, horse racing, and many more.</p>
              <p>Our website.in also provide live Streaming and real time score board on screen for ongoing matches
                during betting.</p>
              <img class="img-fluid" src="assets/images/sport-bg-img.png">
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="spca2">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 order-xs-last">
            <div class="spca_div" data-aos="fade-in">
              <h2>sports</h2>
              <p>We’ve all grown to love the typical brick and mortar casino but there’s just something about online
                casino that triumphs over the rest. At Our website, we believe that playing casino online should be an
                experience of its own. With thousands of titles available at the click of a button, it’s become possible
                to play casino online from the comfort of your home. All the while, sit back and immerse yourself in a
                world of mythical creatures and vigorous jokers.</p>
              <p>If you’re looking to play online casino, Our website.in is the place for you! Let us introduce you to
                the world of online casino with our selection of live dealer games like Andar Bahar, Teen Patti, Dragon
                Tiger, Black Jack, pocker and many more.</p>
              <p>So what are you waiting for? Join us to discover the ultimate casino experience and snatch a portion of
                the winnings!</p>
              <img class="img-fluid" src="assets/images/casino-bg-img.png">
            </div>
          </div>
          <div class="col-md-6 order-xs-first">
            <img class="img-fluid" src="assets/images/spca-img2.png" data-aos="fade-left">
          </div>
        </div>
      </div>
    </section>

    <section class="clientsay container">
      <div class="mb-5">
        <img class="img-fluid" src="assets/images/jackpot.png" data-aos="slide-up">
      </div>
      <div class="section-header">
        <h2>what client say</h2>
        <p>It is our pleasure to share with you the experiences of many of our past clients.</p>
      </div>

      <!-- <div class="swiper slider3 testimonial-slider" data-aos="fade-down">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="client_say">
              <div>
                <img class="img-fluid" src="assets/images/client1.png">
                <h5>rakesh singh <img class="img-fluid" src="assets/images/star.png"></h5>
              </div>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever when an unknown. Lorem Ipsum is simply dummy text of the printing
                and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever when an unknown.
              </p>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="client_say">
              <div>
                <img class="img-fluid" src="assets/images/client1.png">
                <h5>rakesh singh <img class="img-fluid" src="assets/images/star.png"></h5>
              </div>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever when an unknown. Lorem Ipsum is simply dummy text of the printing
                and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever when an unknown.
              </p>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="client_say">
              <div>
                <img class="img-fluid" src="assets/images/client1.png">
                <h5>rakesh singh <img class="img-fluid" src="assets/images/star.png"></h5>
              </div>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever when an unknown. Lorem Ipsum is simply dummy text of the printing
                and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever when an unknown.
              </p>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="client_say">
              <div>
                <img class="img-fluid" src="assets/images/client1.png">
                <h5>rakesh singh <img class="img-fluid" src="assets/images/star.png"></h5>
              </div>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever when an unknown. Lorem Ipsum is simply dummy text of the printing
                and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever when an unknown.
              </p>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div> -->
    </section>

  </main><!-- End #main -->

  <!-- ======= Footer ======= -->
  <app-open-footer></app-open-footer>
</div>

<ng-template #loginModal>
  <div class="cross_btn">
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modalRef.hide();this.createFrom();submitted= false;"></button>
  </div>
  <div class="modal-header">
    <img src="{{logo}}" class="modal_image" alt="">
  </div>
  <div class="modal-body">
    <div class="mobile_bg">
      <img src="assets/images/mobile_bg.png" alt="">
      <div class="widraw">
        100%
        <strong>FAST</strong>
        withdraw
        <div class="group">
          <img src="assets/images/group.png" alt="">
        </div>
      </div>
    </div>

    <div class="form" [formGroup]='Form'>
      <div class="input_field">
        <input type="text" class="form_control" formControlName="user_name" required>
        <label for="" class="input_label">Enter your Username</label>
        <i class="fa fa-user"></i>
      </div>
      <div class="alert_base" *ngIf="submitted && f.user_name.status == 'INVALID'">
        <span>* Please enter username</span>
      </div>
      <div class="input_field pass_field">
        <input formControlName="password" required="" [type]="showPassword ? 'text' : 'password'" class="form_control">
        <label for="" class="input_label">Enter your Password</label>
        <i alt="show" class="fa fa-eye eye-show" (click)="showPassword = !showPassword" [class.hide]="showPassword"></i>
        <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showPassword = !showPassword"
          [class.hide]="!showPassword"></i>

      </div>
      <div class="alert_base" *ngIf="submitted && f.password.status == 'INVALID'">
        <span>* Please enter password</span>
      </div>
      <div class="input_btn mt-10" (click)="onSubmitLogin('login')">
        <button class="sinup_btn">Sign In</button>
      </div>
      <div class="member">
        Don't have an account?
        <a class="ragister_link" (click)="modalRef.hide();openModalSignupModal(signupModal)">Register</a>
      </div>
      <div class="social_media">
        <ul>
          <li>
            <a target="_blank" href="{{insta}}"><img src="assets/images/instagram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{wa}}"><img src="assets/images/whatsapp1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{telegram}}"><img src="assets/images/telegram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{twitter}}"><img src="assets/images/twitter1.png" alt=""></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #signupModal>
  <div class="cross_btn">
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modalRef.hide()"></button>
  </div>
  <div class="modal-header">
    <img src="{{logo}}" class="modal_image" alt="">
  </div>
  <div class="modal-body">
    <div class="mobile_bg">
      <img src="assets/images/mobile_bg.png" alt="">
      <div class="widraw">
        100%
        <strong>FAST</strong>
        withdraw
        <div class="group">
          <img src="assets/images/group.png" alt="">
        </div>
      </div>
    </div>
    <div class="form" [formGroup]='addUserFrom'>
      <div class="input_field">
        <input type="text" class="form_control" formControlName="user_name" required>
        <label for="" class="input_label">Enter your Username</label>
        <i class="fa fa-user"></i>
      </div>
      <div class="alert_base" *ngIf="isControlIsValid('user_name')">
        <span *ngIf="isControlHasErros('user_name')">* {{ isControlHasErros('user_name')}}</span>
      </div>
      <div class="input_field">
        <input type="text" formControlName="mobile" class="form_control" maxlength="10" required>
        <label for="" class="input_label">Enter your Whatsapp Number</label>
        <i class="fa fa-phone"></i>
      </div>
      <div class="alert_base" *ngIf="isControlIsValid('mobile')">
        <span *ngIf="isControlHasErros('mobile')">* {{ isControlHasErros('mobile')}}</span>
      </div>
      <div class="input_field pass_field">
        <input [type]="showPassword ? 'text' : 'password'" class="form_control" formControlName="password" required>
        <label for="" class="input_label">Enter your Password</label>
        <i alt="show" class="fa fa-eye eye-show" (click)="showPassword = !showPassword" [class.hide]="showPassword"></i>
        <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showPassword = !showPassword"
          [class.hide]="!showPassword"></i>
      </div>
      <div class="alert_base" *ngIf="isControlIsValid('password')">
        <span *ngIf="isControlHasErros('password')">* {{ isControlHasErros('password')}}</span>
      </div>
      <div class="input_field" *ngIf="referCode != undefined">
        <input type="text" class="form_control" formControlName="refer_code" readonly>
        <label for="" class="input_label">Enter your Refer Code</label>
      </div>
      <div class="input_field" *ngIf="referCode == undefined">
        <input type="text" class="form_control" formControlName="refer_code">
        <label for="" class="input_label">Enter your Refer Code</label>
      </div>
      <div class="input_btn" (click)="addUserData()">
        <button class="sinup_btn">Register</button>
      </div>
      <div class="input_btn" (click)="goToRoute()">
        <button class="sinup_btn">Get Your ID</button>
      </div>
      <div class="member">
        Already have an account?
        <a class="ragister_link" (click)="modalRef.hide();openModalLoginModal(loginModal)">SignIn</a>
      </div>
      <div class="social_media">
        <ul>
          <li>
            <a target="_blank" href="{{insta}}"><img src="assets/images/instagram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{wa}}"><img src="assets/images/whatsapp1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{telegram}}"><img src="assets/images/telegram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{twitter}}"><img src="assets/images/twitter1.png" alt=""></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>