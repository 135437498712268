import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UsersService } from '../services/users.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HeaderComponent } from '../header/header.component';
import { ReportService } from '../services/report.service';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [HeaderComponent],
})
export class ProfileComponent implements OnInit {
  @ViewChild('changePwd', { static: false }) public privacyPopup: TemplateRef<any>;
  userDetails: any;
  modalRef: BsModalRef;
  changePasswordForm: FormGroup;
  submitted = false;
  public showOldPassword: boolean = false;
  public showNewPassword: boolean = false;
  public showConfirmPassword: boolean = false;
  reportData: any;

  constructor(private head: HeaderComponent, private usersService: UsersService, private modalService: BsModalService, private fb: FormBuilder, private toastr: ToastrService,
    private report: ReportService) { }

  ngOnInit(): void {
    this.createChangePasswordFrom();
    this.profile();
    this.pl();
  }
  profile() {
    this.usersService.profile().subscribe((res) => {
      if (res.status) {
        this.userDetails = res.data
      }
    })
  }

  openModalEditPhone(editPhone: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      editPhone,
      Object.assign({}, { class: 'manual-deposit', ignoreBackdropClick: false }),
    );
  }
  openModalChangePass(changePass: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      changePass,
      Object.assign({}, { class: 'modal-fixed-footer change-password', ignoreBackdropClick: false }),
    );
  }
  createChangePasswordFrom() {
    this.changePasswordForm = this.fb.group({
      old_password: ['', [Validators.required]],
      new_password: ['', [Validators.required]],
      confirm_password: ['', [Validators.required]]
    })
  }



  get f() { return this.changePasswordForm.controls; }

  onSubmitChangePassword() {
    this.submitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }
    let data = {
      "newPassword": this.changePasswordForm.value.new_password
    }
    if (this.changePasswordForm.controls['new_password'].value == this.changePasswordForm.controls['confirm_password'].value) {
      this.usersService.changePassword(data).subscribe((result) => {
        if (result.status == true) {
          this.userDetails.is_change_password = '1';
          localStorage.setItem('userDetails', JSON.stringify(this.userDetails));
          this.submitted = false;
          this.toastr.success(result.msg, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          });
          this.modalRef.hide();
          this.head.logoutUser();
        } else {
          this.toastr.error(result.msg, '', {
            timeOut: 10000,
          });
        }
      }, (err) => {
        console.log(err);
      });
    } else {
      this.toastr.error("Password and confirm password did not match", '', {
        timeOut: 10000,
      });
    }
  }
  pl() {
    let data = {};
    this.report.totalPl(data).subscribe((res) => {
      if (res.status) {
        this.reportData = res.data.betsAndPlData;
      } else {
        this.toastr.error(res.msg)
      }
    })
  }
}
