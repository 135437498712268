<app-header></app-header>
<div class="content_area">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 left_bar">
        <app-left-side-bar></app-left-side-bar>
      </div>

      <div class="col-md-9 conetnt_col">
        <div class="promoting_tabs reporting_tabs">
          <app-report-header></app-report-header>
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="account" role="tabpanel" aria-labelledby="pills-profile-tab">
              <div class="account_title">
                <h4 class="card-title">Account Statement</h4>
                <button class="filter-btn btn"><img src="assets/images/icon-filter.png"> Filter</button>
              </div>
              <div class="date_picker">
                <div class="datepicker_input fromDate_input">
                  <div class="kyc-dropdown">
                    <div class="select-wrapper">
                      <input type="text" class="form-control" [popover]="popTemplateStatementStart" placement="bottom"
                        [outsideClick]="true" #popoverRef="bs-popover"
                        value="{{statementStartDate | date:'yyyy-MM-dd '}}" (click)="showDate();"
                        placeholder="From Date">
                    </div>
                  </div>
                </div>
                <div class="datepicker_input toDate_input">
                  <div class="kyc-dropdown">
                    <div class="select-wrapper">
                      <input type="text" class="form-control" [popover]="popTemplateStatementEnd" placement="bottom"
                        [outsideClick]="true" #popoverRef="bs-popover" value="{{statementEndDate | date:'yyyy-MM-dd '}}"
                        (click)="showDate();" placeholder="To Date">
                    </div>
                  </div>
                </div>
                <div class="datepicker_input">
                  <div class="open_bet_row">
                    <select class="form-select select_form" aria-label="Default select example"
                      [(ngModel)]="statementType" (ngModelChange)="rerender()">
                      <option value="0" selected>All</option>
                      <option value="1">Free Chips</option>
                      <option value="2">Profit Loss</option>
                      <option value="3">Commission</option>
                      <option value="4">Settlement</option>
                    </select>
                  </div>
                </div>
                <div class="datepicker_input">
                  <a class="search" (click)="rerender()">Search</a>
                </div>
              </div>
              <div class="data_table">
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>

<ng-template #popTemplateStatementStart>
  <datepicker [hidden]="!isDateVisible" [(ngModel)]="dateStatement" [ngModelOptions]="{standalone: true}"
    [minDate]="minDate" [maxDate]="todayDateTime" [showWeeks]="false" (ngModelChange)="updateDateStatement()"
    (selectionDone)="dateSelectionDone()">
  </datepicker>
  <timepicker [hidden]="!isDateVisible" [(ngModel)]="timeStatement" [ngModelOptions]="{standalone: true}"
    [min]="minTime" [max]="maxTime" (ngModelChange)="updateTimeStatement()" [showMeridian]="isMeridian"
    showSeconds="true">
  </timepicker>
</ng-template>
<ng-template #popTemplateStatementEnd>
  <datepicker [hidden]="!isDateVisible" [(ngModel)]="endDateStatement" [ngModelOptions]="{standalone: true}"
    [minDate]="dateStatement" [maxDate]="todayDateTime" [showWeeks]="false" (ngModelChange)="updateEndDateStatement()"
    (selectionDone)="dateSelectionDone()">
  </datepicker>
  <timepicker [hidden]="!isDateVisible" [(ngModel)]="endTimeStatement" [ngModelOptions]="{standalone: true}"
    [min]="minTime" [max]="maxTime" (ngModelChange)="updateEndTimeStatement()" [showMeridian]="isMeridian"
    showSeconds="true">
  </timepicker>
</ng-template>