import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {ActivatedRoute} from "@angular/router";
@Component({
  selector: 'app-casino-detail',
  templateUrl: './casino-detail.component.html',
  styleUrls: ['./casino-detail.component.scss']
})
export class CasinoDetailComponent implements OnInit {
  url: string;
  graphicTvUrl: SafeResourceUrl;
  games:any;
  iframe_height:any;
  deskop:any;
  url_id:any;
  constructor(public sanitizer: DomSanitizer,public route: ActivatedRoute) { }

  ngOnInit(): void {

    //this.url = localStorage.getItem('casino'); 
    let token = localStorage.getItem('userAccessToken');
    this.url_id = this.route.snapshot.paramMap.get('id'); 
    
    if(window.innerWidth > 768 ) {
      this.iframe_height=80;
      this.games = "https://d2.fawk.app/#/splash-screen/" + token + "|rubyexch/9366"  + "?opentable="+this.url_id;
      this.deskop=true;
    }
    else
    {
      this.iframe_height=100;
      this.deskop=false;
      this.games = "https://m2.fawk.app/#/splash-screen/" + token + "|rubyexch/9366"  + "?opentable="+this.url_id;
    }
    
    this.graphicTvUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.games);
  }

}
