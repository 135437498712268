<app-header></app-header>
<div class="content_area">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 left_bar">
        <app-left-side-bar></app-left-side-bar>
      </div>
      <div class="col-md-9 conetnt_col">
        <div class="promo-code-link" *ngIf="userDetails">
            Promo Code:&nbsp;  <span #textinput>{{this.domain}}/start/{{userDetails?._id}}</span>  &nbsp;&nbsp;&nbsp;&nbsp;<img (click)="copyInputMessage(textinput)" src="assets/clipboard.jpg" width="20px">  
        </div>
        <div class="slider">
          <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item item " *ngFor="let image of banner;let i = index;"
                [ngClass]="(i === 0) ? 'active' : ''">
                <img src="{{Base_Url}}wallets/{{image.banner}}" alt="">
                <div class="term-condition">
                  {{image?.term_conditions}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>