import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OpenHomeComponent } from './open-home/open-home.component';
import { OpenDashboardComponent } from './open-dashboard/open-dashboard.component';
import { OpenMatchDetailComponent } from './open-match-detail/open-match-detail.component';
import { PromotionComponent } from './promotion/promotion.component';
import { AuthCheckGuard } from './services/auth-check.guard';
import {  DashboardComponent } from './dashboard/dashboard.component';
import {  MatchDetailComponent } from './match-detail/match-detail.component';
import { ProfileComponent } from './profile/profile.component';
import { WithdrawComponent } from './withdraw/withdraw.component';
import { MyBetsComponent } from './my-bets/my-bets.component';
import { StatementComponent } from './statement/statement.component';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { OpenPromotionComponent } from './open-promotion/open-promotion.component';
import { CasinoComponent } from './casino/casino.component';
import { OpenCasinoComponent } from './open-casino/open-casino.component';
import { OpenNewsComponent } from './open-news/open-news.component';
import { ViewBetComponent } from './view-bet/view-bet.component';
import { SettleBetComponent } from './settle-bet/settle-bet.component';
import { CasinoDetailComponent } from './casino-detail/casino-detail.component';
import { PlSportsComponent } from './pl-sports/pl-sports.component';
import { PlMatchComponent } from './pl-match/pl-match.component';
import { PlMarketComponent } from './pl-market/pl-market.component';
import { PlUserBetComponent } from './pl-user-bet/pl-user-bet.component';
import { PlUserBetSnComponent } from './pl-user-bet-sn/pl-user-bet-sn.component';
const routes: Routes = [
  {path: '', redirectTo: 'start', pathMatch: 'full'},
  {path: 'start', component: OpenHomeComponent},
  {path: 'start/:referCode', component: OpenHomeComponent},
  {path: 'open-dashboard', component: OpenDashboardComponent},
  {path: 'open-dashboard/:sportName', component: OpenDashboardComponent},
  {path: 'openDetail', component: OpenMatchDetailComponent},
  {path: 'openDetail/:match_id', component: OpenMatchDetailComponent},
  {path: 'open-promo', component: OpenPromotionComponent},
  {path: 'promo', component: PromotionComponent},
  {path: 'dashboard', component: DashboardComponent , canActivate: [AuthCheckGuard]},
  {path: 'dashboard/:sportName', component: DashboardComponent , canActivate: [AuthCheckGuard]},
  {path: 'match-detail', component: MatchDetailComponent , canActivate: [AuthCheckGuard]},
  {path: 'match-detail/:match_id', component: MatchDetailComponent , canActivate: [AuthCheckGuard]},
  {path: 'profile', component: ProfileComponent , canActivate: [AuthCheckGuard]},
  {path: 'statement', component: StatementComponent , canActivate: [AuthCheckGuard]},
  {path: 'open-bet', component: MyBetsComponent , canActivate: [AuthCheckGuard]},
  {path: 'settle-bet', component: SettleBetComponent , canActivate: [AuthCheckGuard]},
  {path: 'withdraw', component: WithdrawComponent , canActivate: [AuthCheckGuard]},
  {path: 'transaction', component: TransactionHistoryComponent , canActivate: [AuthCheckGuard]},
  {path: 'qtech', component: CasinoComponent , canActivate: [AuthCheckGuard]},
  {path: 'casino-detail', component: CasinoDetailComponent , canActivate: [AuthCheckGuard]},
  {path: 'casino-detail/:id', component: CasinoDetailComponent , canActivate: [AuthCheckGuard]},
  {path: 'qtech/:sportName', component: CasinoComponent , canActivate: [AuthCheckGuard]},
  {path: 'openQtech', component: OpenCasinoComponent},
  { path: 'openNews',component:OpenNewsComponent },
  { path: 'viewBet/:matchId/:marketId/:type/:sportName/:seriesName/:matchName', component: ViewBetComponent, canActivate: [AuthCheckGuard] },
  { path: 'viewBet/:matchId/:marketId/:type/:sportName/:seriesName/:matchName/:marketName/:id', component: ViewBetComponent, canActivate: [AuthCheckGuard] },
  { path: 'viewBet/:matchId/:marketId/:type/:matchName/:id', component: ViewBetComponent, canActivate: [AuthCheckGuard] },
  { path: 'eventpl', component: PlSportsComponent, canActivate: [AuthCheckGuard] },
  { path: 'plMatchwise/:sportId/:sportName/:fromDate/:toDate/:typeId', component: PlMatchComponent, canActivate: [AuthCheckGuard] },
  { path: 'plMarketwise/:matchId/:matchName/:typeId/:sportId', component: PlMarketComponent, canActivate: [AuthCheckGuard] },
  { path: 'plUserBet/:matchId/:marketId/:type', component: PlUserBetComponent, canActivate: [AuthCheckGuard] },
  { path: 'plSnUserBet/:marketId/:sportName/:matchName/:marketName', component: PlUserBetSnComponent, canActivate: [AuthCheckGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
