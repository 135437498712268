import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-open-news',
  templateUrl: './open-news.component.html',
  styleUrls: ['./open-news.component.scss']
})
export class OpenNewsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
