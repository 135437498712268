import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppValidationService } from '../app-validation/app-validation.service';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-open-home',
  templateUrl: './open-home.component.html',
  styleUrls: ['./open-home.component.scss']
})
export class OpenHomeComponent implements OnInit {
  logo: string;
  referCode: any;
  facebook: any;
  insta: any;
  telegram: any;
  wa: any;
  mail: any;
  twitter: any;
  Form: FormGroup;
  addUserFrom: FormGroup;
  isUserNameExist: boolean;
  isAddUserFromSubmitted: boolean;
  modalRef: BsModalRef;
  public showPassword: boolean = false;
  public Base_Url = environment['adminServerUrl'];
  domainName: string;
  private clickTimeout = null;
  loginButtonDisable = false;
  a: any;
  path: string;
  sportsName: any;
  submitted = false;
  showResponseMessage;
  constructor(private router: Router, private fb: FormBuilder, private route: ActivatedRoute, private loginService: LoginService, private appValidationService: AppValidationService, private toastr: ToastrService, private modalService: BsModalService,) {
    this.route.queryParams.subscribe(params => {
      this.referCode = params['referCode'];
      if (this.referCode != undefined) {
        localStorage.setItem('referCode', params['referCode'])
      }
      this.sportsName = params.sportName;
      let a = this.router.url;
      let b = a.split('/')
      if (b.length <= 2) {
        this.path = b[1]
      } else {
        this.path = b[2]
      }
    });
  }

  ngOnInit(): void {
    this.logo = localStorage.getItem('logo')
    let a = localStorage.getItem('userAccessToken');
    if (a != undefined) {
      this.router.navigate(['dashboard'])
    }
    let domain = window.location.hostname
    let domain1 = domain.split('.')
    if (domain1[0] == '3rdempire') {
      this.facebook = '';
      this.insta = 'https://www.instagram.com/3rdempiree/';
      this.telegram = 'https://telegram.me/bigdream07';
      this.wa = 'https://api.whatsapp.com/send/?phone=919024696202&text=Hi&type=phone_number&app_absent=0';
      this.mail = '';
      this.twitter = 'https://twitter.com/3rdempiree'
    } else if (domain1[0] == 'motabook365') {
      this.facebook = 'https://www.facebook.com/profile.php?id=100086163486306';
      this.insta = 'https://www.instagram.com/motabook247/';
      this.telegram = 'https://t.me/Motabook247';
      this.wa = 'https://api.whatsapp.com/send?phone=917439876249';
      this.mail = '';
      this.twitter = ''
    }
    this.domainName = window.location.origin;
    this.createFrom();
    this.createSignFrom();
  }
  DownloadApk() {
    let c = (this.domainName).split('//')
    let a = btoa(c[1]);
    let b = this.Base_Url + 'content/download-mobile-app?key=' + a;
    window.open(b, '_blank')
  }
  createFrom() {
    this.Form = this.fb.group({
      user_name: ['', Validators.required],
      password: ['', [Validators.required]],
      grant_type: ['password'],
      isRememberMe: [false]
    })
    if (JSON.parse(localStorage.getItem('RememberMe'))) {
      this.Form.patchValue({
        user_name: localStorage.getItem('user_name'),
        password: localStorage.getItem('password'),
        isRememberMe: localStorage.getItem('RememberMe')
      })
    }
  }
  createSignFrom() {
    this.addUserFrom = this.fb.group({
      name: ['', Validators.required],
      user_name: ['', Validators.required],
      password: ['', [Validators.required]],
      mobile: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]],
      email: [''],
      refer_code: ['']
    });
    this.applyValidationToFormGroup(this.addUserFrom, "AddUser")
  }
  get f() { return this.Form.controls; }
  applyValidationToFormGroup(formGroupName, jsonArrayName) {
    this.appValidationService.applyValidationRulesToFromGroup(formGroupName, jsonArrayName).then((validators) => {
    }).catch(() => { })
  }

  openModalSignupModal(signupModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      signupModal,
      Object.assign({}, { class: 'login_page', ignoreBackdropClick: false }),
    );
    this.createSignFrom();
    if (this.referCode != undefined || this.referCode != '') {
      this.addUserFrom.controls['refer_code'].setValue(this.referCode);
    }
  }

  openModalLoginModal(loginModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginModal,
      Object.assign({}, { class: 'login_page', ignoreBackdropClick: false }),
    );
    this.createFrom();
  }


  addUserData() {
    this.isAddUserFromSubmitted = true;
    this.addUserFrom.controls['name'].setValue(this.addUserFrom.controls['user_name'].value)
    this.appValidationService.markFormGroupTouched(this.addUserFrom)
    if (this.addUserFrom.controls.email.value == '') {
      delete this.addUserFrom.value['email'];
    }
    if (this.addUserFrom.controls.refer_code.value == '') {
      delete this.addUserFrom.value['refer_code'];
    }
    this.loginService.register(this.addUserFrom.value).subscribe((result) => {
      debugger
      this.isAddUserFromSubmitted = false;
      if (result.status == true) {
        this.toastr.success(result.msg, '', {
          timeOut: 1000
        });
        this.Form.controls['user_name'].setValue(this.addUserFrom.controls['user_name'].value)
        this.Form.controls['password'].setValue(this.addUserFrom.controls['password'].value)
        this.headerSingleLoginClick();
        this.modalRef.hide();
      } else {
        this.toastr.error(result.msg, '', {
          timeOut: 10000,
        });
      }
    }, (err) => {
      console.log(err);
    });
  }
  async onSubmitLogin(from?: string) {
    if (this.Form.valid) {
      this.loginButtonDisable = true
      if (this.clickTimeout) {
        this.setClickTimeout(() => { });
      } else {
        this.setClickTimeout(() => {
          if (from == 'login') {
            this.headerSingleLoginClick()
          }
        })
      }
    } else {
      this.submitted = true;
    }
  }
  public headerSingleLoginClick() {
    this.submitted = true;
    this.a = (this.Form.value.user_name);
    let b = (this.a).toLowerCase()
    this.Form.value.user_name = b;
    if (this.Form.invalid) {
      this.loginButtonDisable = false
      return;
    }
    this.loginService.submitlogin(this.Form.value).subscribe((result) => {
      if (result.status == true) {
        localStorage.setItem('userAccessToken', result.token.accessToken);
        this.toastr.success(result.msg, '', {
          timeOut: 1000
        })
        this.setCokkies(result);
        localStorage.setItem('userDetails', JSON.stringify(result.data));
        localStorage.setItem('operator', JSON.stringify(result.operatorId));
        this.loginButtonDisable = false;
        this.modalRef.hide();
      } else {
        this.showResponseMessage = result.msg;
        this.toastr.error(this.showResponseMessage)
        this.loginButtonDisable = false;
      }
    }, (err) => {
      console.log(err);
      this.loginButtonDisable = false;
    });
  }
  public setClickTimeout(callback) {
    clearTimeout(this.clickTimeout);
    this.clickTimeout = setTimeout(() => {
      this.clickTimeout = null;
      callback();
    }, 400);
  }
  async redirectToDashboard() {
    var acctoken = localStorage.getItem('userAccessToken');
    if (acctoken != null && acctoken != undefined && acctoken != '') {
      this.router.navigate(['dashboard'])
    }
  }
  async setCokkies(result) {
    localStorage.setItem('userAccessToken', result.token.accessToken);
    localStorage.setItem('userId', result.data._id);
    localStorage.setItem('userRefreshToken', result.token.refreshToken);
    this.router.navigate(['dashboard'])
  }
  dashboard(data) {
    if (data == 1) {
      this.router.navigate(['open-dashboard'])
    } else if (data == 2) {
      this.router.navigate(['open-dashboard/inplay'])
    } else if (data == 3) {
      this.router.navigate(['open-promo'])
    } else if (data == 4) {
      this.router.navigate(['open-dashboard/Casino'])
    } else if (data == 5) {
      this.toastr.error('Coming Soon ...')
    }
  }
  isControlIsValid(controlName: string): boolean {
    if (this.addUserFrom.controls[controlName].invalid && (this.isAddUserFromSubmitted || this.addUserFrom.controls[controlName].dirty || this.addUserFrom.controls[controlName].touched)) {
      return true;
    }
    return false;
  }
  isControlHasErros(controlName: string) {
    if (this.addUserFrom.controls[controlName].errors) {
      let value = ''
      let object = this.addUserFrom.controls[controlName].errors
      for (var propName in object) {
        if (object.hasOwnProperty(propName) && propName != "required") {
          value = object[propName];
        }
      }
      return value;
    }
    return null;
  }
  goToRoute() {
    debugger
    let domain = window.location.hostname
    let domain1 = domain.split('.')
    if (domain1[0] == 'billionexchwallet') {
      this.wa = 'https://api.whatsapp.com/send?phone=13124935676&text=Hi&type=phone_number&app_absent=0';
      window.open(this.wa, "_blank")
    } else if (domain1[0] == 'motabook365') {
      this.wa = 'https://api.whatsapp.com/send?phone=13124935676';
      window.open(this.wa, "_blank")
    } else if (domain1[0] == '3rdempire') {
      this.wa = 'https://api.whatsapp.com/send/?phone=919024696202&text=Hi&type=phone_number&app_absent=0';
      window.open(this.wa, "_blank")
    }
  }
}
