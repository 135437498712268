<div class="main-section">
  <app-header></app-header>
  <section class="game_page">
    <div class="gamepage_wrapper">
      <div class="mobile-nav_slide">
        <div class="casion_game">
          <div class="portfolio_row" id="item-1">
            <iframe allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" width="100%"
              height="100%" scrolling="auto" style="border: none;" [src]="graphicTvUrl"></iframe>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </section>
</div>