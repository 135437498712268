import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../services/login.service';
import { OpenApiService } from '../services/open-api.service';
import { environment } from '../../environments/environment';
import { AppValidationService } from '../app-validation/app-validation.service';
@Component({
  selector: 'app-open-footer',
  templateUrl: './open-footer.component.html',
  styleUrls: ['./open-footer.component.scss']
})
export class OpenFooterComponent implements OnInit {
  logo: string;
  modalRef: BsModalRef;
  support_no:any;
  Form: FormGroup;
  addUserFrom: FormGroup;
  showResponseMessage;
  public showPassword: boolean = false;
  isUserNameExist: boolean;
  isAddUserFromSubmitted: boolean;
  private clickTimeout = null;
  loginButtonDisable = false;
  a: any;
  submitted = false;
  referCode: any;
  facebook: any;
  insta: any;
  telegram: any;
  wa: any;
  mail: any;
  twitter: any;
  public Base_Url = environment['adminServerUrl'];
  domainName: string;
  constructor(private router: Router,private openApiService: OpenApiService,
    private fb: FormBuilder, private toastr: ToastrService, private modalService: BsModalService, private loginService: LoginService,
    private appValidationService: AppValidationService) { }

  ngOnInit(): void {
    this.logo = localStorage.getItem('logo')
    this.getSocialMedia()
    this.domainName = window.location.origin;
    let domain = window.location.hostname
    let domain1 = domain.split('.')
    if (domain1[0] == '3rdempire') {
      //this.facebook = '';
      //this.insta = 'https://www.instagram.com/3rdempiree/';
      ////this.telegram = 'https://telegram.me/bigdream07';
     // this.wa = 'https://api.whatsapp.com/send/?phone=919024696202&text=Hi&type=phone_number&app_absent=0';
      //this.mail = '';
      //this.twitter = 'https://twitter.com/3rdempiree'
    } else if (domain1[0] == 'motabook365') {
      //this.facebook = 'https://www.facebook.com/profile.php?id=100086163486306';
      //this.insta = 'https://www.instagram.com/motabook247/';
      //this.telegram = 'https://t.me/Motabook247';
      //this.wa = 'https://api.whatsapp.com/send?phone=917439876249';
      //this.mail = '';
      //this.twitter = ''
    }
    this.referCode = localStorage.getItem('referCode') != undefined ? localStorage.getItem('referCode') : '';
  }
  openModalLoginModal(loginModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginModal,
      Object.assign({}, { class: 'login_page', ignoreBackdropClick: false }),
    );
    this.createFrom();
  }
  openModalSignupModal(signupModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      signupModal,
      Object.assign({}, { class: 'login_page', ignoreBackdropClick: false }),
    );
    this.createSignFrom();
    if (this.referCode != undefined || this.referCode != '') {
      this.addUserFrom.controls['refer_code'].setValue(this.referCode);
    }
  }
  createFrom() {
    this.Form = this.fb.group({
      user_name: ['', Validators.required],
      password: ['', [Validators.required]],
      grant_type: ['password'],
      isRememberMe: [false]
    })
    if (JSON.parse(localStorage.getItem('RememberMe'))) {
      this.Form.patchValue({
        user_name: localStorage.getItem('user_name'),
        password: localStorage.getItem('password'),
        isRememberMe: localStorage.getItem('RememberMe')
      })
    }
  }
  createSignFrom() {
    this.addUserFrom = this.fb.group({
      name: ['', Validators.required],
      user_name: ['', Validators.required],
      password: ['', [Validators.required]],
      mobile: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]],
      email: [''],
      refer_code: ['']
    });
    this.applyValidationToFormGroup(this.addUserFrom, "AddUser")
  }
  get f() { return this.Form.controls; }
  applyValidationToFormGroup(formGroupName, jsonArrayName) {
    this.appValidationService.applyValidationRulesToFromGroup(formGroupName, jsonArrayName).then((validators) => {
    }).catch(() => { })
  }

  isControlIsValid(controlName: string): boolean {
    if (this.addUserFrom.controls[controlName].invalid && (this.isAddUserFromSubmitted || this.addUserFrom.controls[controlName].dirty || this.addUserFrom.controls[controlName].touched)) {
      return true;
    }
    return false;
  }
  isControlHasErros(controlName: string) {
    if (this.addUserFrom.controls[controlName].errors) {
      let value = ''
      let object = this.addUserFrom.controls[controlName].errors
      for (var propName in object) {
        if (object.hasOwnProperty(propName) && propName != "required") {
          value = object[propName];
        }
      }
      return value;
    }
    return null;
  }
  addUserData() {
    this.isAddUserFromSubmitted = true;
    this.addUserFrom.controls['name'].setValue(this.addUserFrom.controls['user_name'].value)
    this.appValidationService.markFormGroupTouched(this.addUserFrom)
    if (this.addUserFrom.controls.email.value == '') {
      delete this.addUserFrom.value['email'];
    }
    if (this.addUserFrom.controls.refer_code.value == '') {
      delete this.addUserFrom.value['refer_code'];
    }
    if (this.addUserFrom.invalid) {
      return;
    }
    this.loginService.register(this.addUserFrom.value).subscribe((result) => {
      this.isAddUserFromSubmitted = false;
      if (result.status == true) {
        this.toastr.success(result.msg, '', {
          timeOut: 1000
        });
        this.Form.controls['user_name'].setValue(this.addUserFrom.controls['user_name'].value)
        this.Form.controls['password'].setValue(this.addUserFrom.controls['password'].value)
        this.headerSingleLoginClick();
        this.modalRef.hide();
      } else {
        this.toastr.error(result.msg, '', {
          timeOut: 10000,
        });
      }
    }, (err) => {
      console.log(err);
    });
  }
  async onSubmitLogin(from?: string) {
    if (this.Form.valid) {
      this.loginButtonDisable = true
      if (this.clickTimeout) {
        this.setClickTimeout(() => { });
      } else {
        this.setClickTimeout(() => {
          if (from == 'login') {
            this.headerSingleLoginClick()
          }
        })
      }
    } else {
      this.submitted = true;
    }
  }
  public headerSingleLoginClick() {
    this.submitted = true;
    this.a = (this.Form.value.user_name);
    let b = (this.a).toLowerCase()
    this.Form.value.user_name = b;
    if (this.Form.invalid) {
      this.loginButtonDisable = false
      return;
    }
    this.loginService.submitlogin(this.Form.value).subscribe((result) => {
      if (result.status == true) {
        localStorage.setItem('userAccessToken', result.token.accessToken);
        this.toastr.success(result.msg, '', {
          timeOut: 1000
        })
        this.setCokkies(result);
        localStorage.setItem('userDetails', JSON.stringify(result.data));
        localStorage.setItem('operator', JSON.stringify(result.operatorId));
        this.loginButtonDisable = false;
        this.modalRef.hide();
      } else {
        this.showResponseMessage = result.msg;
        this.toastr.error(this.showResponseMessage)
        this.loginButtonDisable = false;
      }
    }, (err) => {
      console.log(err);
      this.loginButtonDisable = false;
    });
  }
  public setClickTimeout(callback) {
    clearTimeout(this.clickTimeout);
    this.clickTimeout = setTimeout(() => {
      this.clickTimeout = null;
      callback();
    }, 400);
  }
  async redirectToDashboard() {
    var acctoken = localStorage.getItem('userAccessToken');
    if (acctoken != null && acctoken != undefined && acctoken != '') {
      this.router.navigate(['dashboard'])
    }
  }
  async setCokkies(result) {
    localStorage.setItem('userAccessToken', result.token.accessToken);
    localStorage.setItem('userId', result.data._id);
    localStorage.setItem('userRefreshToken', result.token.refreshToken);
    this.router.navigate(['dashboard'])
  }
  sports(data) {
    if (data == 1) {
      this.router.navigate(['open-dashboard'])
    } else if (data == 2) {
      this.router.navigate(['open-dashboard/inplay'])
    } else if (data == 3) {
      this.router.navigate(['open-promo'])
    } else if (data == 4) {
      this.router.navigate(['open-dashboard/Casino'])
    } else if (data == 5) {
      this.toastr.error('Coming Soon ...')
    }
  }
  openModalSearchBox(searchModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      searchModal,
      Object.assign({}, { class: 'search-modal', ignoreBackdropClick: false }),
    );
  }
  toggleBigTabStatus: boolean = false;
  toggleBigTab() {
    this.toggleBigTabStatus = !this.toggleBigTabStatus;
  }
  DownloadApk(data) {
    let c = (this.domainName).split('//')
    debugger
    let a = btoa(c[1] + data);
    let b = this.Base_Url + 'content/download-mobile-app?key=' + a;
    window.open(b, '_blank')
  }
  goToRoute() {
    
    let domain = window.location.hostname
    let domain1 = domain.split('.');
    alert(this.support_no)
      if(this.support_no){
        this.wa = 'https://api.whatsapp.com/send/?phone='+this.support_no+'&text=Hi&type=phone_number&app_absent=0';
        window.open(this.wa, "_blank")
      }
     
    
  }
  getSocialMedia() {
    let domain = window.location.hostname
    let params={host_name:domain}
    this.openApiService.getSocialMedia(params).subscribe(response => {
      if (response.status == true) {
        if(!response?.data)return;
      this.facebook = '';
      this.insta = 'https://www.instagram.com/'+response?.data?.instagram;
      this.telegram = 'https://telegram.me/'+response?.data?.telegram;
      this.wa = 'https://api.whatsapp.com/send/?phone='+response?.data?.whatsapp+'&text=Hi&type=phone_number&app_absent=0';
      this.support_no = 'https://api.whatsapp.com/send/?phone='+response?.data?.support_no+'&text=Hi&type=phone_number&app_absent=0';
    
      this.mail = '';
      this.twitter = 'https://twitter.com/'+response?.data?.twitter;
      } else {
        this.toastr.error(response.msg);
      }
    })
  }
  changeThemeMode(){
    const bodyElement = document.body;
    if (bodyElement) {
      bodyElement.classList.toggle('light_mode_on');
    }
  }
}
