<app-header></app-header>
<div class="content_area">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 left_bar">
        <app-left-side-bar></app-left-side-bar>
      </div>

      <div class="col-md-9 conetnt_col">
        <div class="promoting_tabs reporting_tabs">
          <app-report-header></app-report-header>
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="Transaction" role="tabpanel" aria-labelledby="pills-contact-tab">
              <div class="account_title">
                <h4 class="card-title">Transaction History</h4>
                <button class="filter-btn btn"><img src="assets/images/icon-filter.png"> Filter</button>
              </div>
              <div class="date_picker">
                <div class="datepicker_input fromDate_input">
                  <div class="kyc-dropdown">
                    <div class="select-wrapper">
                      <input type="text" placeholder="From Date" class="form-control" [popover]="popTemplate"
                        placement="bottom" [outsideClick]="true" #startpopoverRef="bs-popover"
                        value="{{startdateTime | date:'yyyy-MM-dd'}}" (click)="showDate()">
                    </div>
                  </div>
                </div>
                <div class="datepicker_input toDate_input">
                  <div class="kyc-dropdown">
                    <div class="select-wrapper">
                      <input type="text" placeholder="To Date" class="form-control" [popover]="popTemplate1"
                        placement="bottom" [outsideClick]="true" #endpopoverRef="bs-popover"
                        value="{{enddateTime | date:'yyyy-MM-dd'}}" (click)="showDate()">
                    </div>
                  </div>
                </div>
                <div class="datepicker_input">
                  <div class="open_bet_row">
                    <select class="form-select" aria-label="Default select example" [(ngModel)]="searchType">
                      <option selected>Select transaction type</option>
                      <option value="all">All</option>
                      <option value="0">Withdrawl</option>
                      <option value="1">Deposit</option>
                    </select>
                  </div>
                </div>
                <div class="datepicker_input">
                  <a (click)="rerender()" class="search">Search</a>
                </div>
              </div>

              <div class="data_table">
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                 
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>

<!-- Date Time Picker -->
<ng-template #popTemplate>
  <datepicker [hidden]="!isDateVisible" [(ngModel)]="date" [minDate]="minDate" [maxDate]="todayDateTime"
    [showWeeks]="false" (ngModelChange)="updateDate()" (selectionDone)="dateSelectionDone()">
  </datepicker>
  <timepicker style="color:black" [showSeconds]="seconds" [hidden]="!isDateVisible" [(ngModel)]="time"
    (ngModelChange)="updateTime()" [showMeridian]="isMeridian">
  </timepicker>
</ng-template>
<ng-template #popTemplate1>
  <datepicker [hidden]="!isDateVisible" [(ngModel)]="endDate" [minDate]="date" [maxDate]="todayDateTime"
    [showWeeks]="false" (ngModelChange)="updateEndDate()" (selectionDone)="dateSelectionDone()">
  </datepicker>
  <timepicker [hidden]="!isDateVisible" [(ngModel)]="endTime" (ngModelChange)="updateEndTime()" show-seconds="true"
    [showMeridian]="isMeridian" [showSeconds]="seconds">
  </timepicker>
</ng-template>


<ng-template #detailModal>
  <a class="modal-close right close-icon" data-bs-dismiss="modal" aria-label="Close" (click)="modalRef.hide()"><i
      aria-hidden="true" class="fa fa-times"></i></a>
  <h5><span class="card-title">
      
      <span class="title"> Transaction Details </span></span>
  </h5>
  <div class="modal-body">
    <dt>
      Approval Status:  {{previewData?.approval_status}}
    </dt>
    

    <dt>
      Payment Details:
    </dt>
    <dl class="stake">
     
    </dl>
    <div *ngFor="let p of previewData?.payment_arr">
      <div *ngFor="let pv of p?.payment_details">
        <dt *ngIf="previewData?.type==1">
                  {{pv.key}}- {{pv.value}}
        </dt>

        <dt *ngIf="previewData?.type==0 && pv?.bank_holder_name">
        Bank Holder Name  : {{pv.bank_holder_name}}
</dt>

<dt *ngIf="previewData?.type==0 && pv?.bank_name">
  Bank  Name  : {{pv.bank_name}}
</dt>

<dt *ngIf="previewData?.type==0 && pv?.mobile_no">
  Mobile No  : {{pv.mobile_no}}
</dt>

<dt *ngIf="previewData?.type==0 && pv?.ifsc_code">
  Ifsc Code  : {{pv.ifsc_code}}
</dt>

<dt *ngIf="previewData?.type==0 && pv?.account_no">
  Account No  : {{pv.account_no}}
</dt>
      </div>

    </div>

    <dt>
      Screenshot:
    </dt>
    <dl>
      <img src="{{Base_Url}}wallets/{{previewData?.image}}" onerror="this.src='assets/images/noimage.jpg';"  width="400px" height="100px">
    </dl>
  </div>

  <div class="modal-footer">
  
  </div>
</ng-template>