<app-header></app-header>
<div class="content_area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3 left_bar">
                <app-left-side-bar></app-left-side-bar>
            </div>
            <div class="col-md-9 conetnt_col">
                <div class="promoting_tabs reporting_tabs">
                    <app-report-header></app-report-header>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="open" role="tabpanel"
                            aria-labelledby="pills-contact-tab">
                            <div class="account_title">
                                <h4 class="card-title">Settled Bets</h4>
                                <button class="filter-btn btn"><img src="assets/images/icon-filter.png"> Filter</button>
                            </div>
                            <div class="date_picker">
                                <div class="datepicker_input">
                                    <div class="open_bet_row kyc-dropdown">
                                        <ng-select [ngModelOptions]="{standalone: true}" [(ngModel)]="searchSport"
                                            class="form-select"
                                            (change)="getOpenBetFilterData(0,searchSport.sport_id,searchSport.sport_name,'1','filterBtnClick') "
                                            (blur)="getOpenBetFilterData(0,searchSport.sport_id,searchSport.sport_name,'1','filterBtnClick')"
                                            placeholder="Search Sport Name"
                                            (clear)="getOpenBetFilterData(0,undefined,undefined,'1','filterBtnClick')">
                                            <ng-option *ngFor="let sport of SportList"
                                                [value]="sport">{{sport.sport_name}}</ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="datepicker_input">
                                    <div class="open_bet_row kyc-dropdown">
                                        <ng-select [ngModelOptions]="{standalone: true}" [(ngModel)]="searchSeries"
                                            class="form-select"
                                            (change)="getOpenBetFilterData(1,searchSeries.series_id,searchSeries.series_name,'1','filterBtnClick') "
                                            (blur)="getOpenBetFilterData(1,searchSeries.series_id,searchSeries.series_name,'1','filterBtnClick')"
                                            placeholder="Search Series Name"
                                            (clear)="getOpenBetFilterData(1,undefined,undefined,'1','filterBtnClick')">
                                            <ng-option *ngFor="let series of SeriesList"
                                                [value]="series">{{series.series_name}}</ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="datepicker_input">
                                    <div class="open_bet_row kyc-dropdown">
                                        <ng-select [ngModelOptions]="{standalone: true}" [(ngModel)]="searchMatch"
                                            class="form-select"
                                            (change)="getOpenBetFilterData(2,searchMatch.match_id,searchMatch.match_name,'1','filterBtnClick') "
                                            (blur)="getOpenBetFilterData(2,searchMatch.match_id,searchMatch.match_name,'1','filterBtnClick')"
                                            placeholder="Search Match Name"
                                            (clear)="getOpenBetFilterData(2,undefined,undefined,'1','filterBtnClick')">
                                            <ng-option *ngFor="let match of MatchList"
                                                [value]="match">{{match.match_name}}</ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="datepicker_input">
                                    <div class="open_bet_row kyc-dropdown">
                                        <ng-select [ngModelOptions]="{standalone: true}" [(ngModel)]="searchMarket"
                                            class="form-select"
                                            (change)="(searchMarket.type == '1') ? getOpenBetFilterData(3,searchMarket.event_id,searchMarket.event_name,'1' ,'filterBtnClick') : getOpenBetFilterData(4,searchMarket.event_id,searchMarket.event_name,'1','filterBtnClick') "
                                            (blur)="(searchMarket.type == '1') ? getOpenBetFilterData(3,searchMarket.event_id,searchMarket.event_name,'1','filterBtnClick') : getOpenBetFilterData(4,searchMarket.event_id,searchMarket.event_name,'1','filterBtnClick')"
                                            placeholder="Search Market Name"
                                            (clear)="getOpenBetFilterData(3,undefined,undefined,'1','filterBtnClick')">
                                            <ng-option *ngFor="let event of MarketList"
                                                [value]="event">{{event.event_name}}</ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="datepicker_input fromDate_input">
                                    <div class="kyc-dropdown">
                                        <div class="select-wrapper">
                                            <input type="text" class="form-control"
                                                [popover]="popTemplateStatementStart" placement="bottom"
                                                [outsideClick]="true" #popoverRef="bs-popover"
                                                value="{{statementStartDate | date:'yyyy-MM-dd '}}"
                                                (click)="showDate();" placeholder="From Date">
                                        </div>
                                    </div>
                                </div>
                                <div class="datepicker_input toDate_input">
                                    <div class="kyc-dropdown">
                                        <div class="select-wrapper">
                                            <input type="text" class="form-control" [popover]="popTemplateStatementEnd"
                                                placement="bottom" [outsideClick]="true" #popoverRef="bs-popover"
                                                value="{{statementEndDate | date:'yyyy-MM-dd '}}" (click)="showDate();"
                                                placeholder="To Date">
                                        </div>
                                    </div>
                                </div>
                                <div class="datepicker_input">
                                    <div class="open_bet_row">
                                        <select class="form-select select_form" aria-label="Default select example"
                                            [(ngModel)]="betType"
                                            (ngModelChange)="getOpenBetFilterData('betType','','filterBtnClick')">
                                            <option selected>Bet Type</option>
                                            <option value="2">All</option>
                                            <option value="1">Back</option>
                                            <option value="0">Lay</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="datepicker_input">
                                    <a href="javascript:void(0)" class="search" (click)="rerender()">Search</a>
                                </div>
                            </div>

                            <div class="data_table">
                                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>

<ng-template #popTemplateStatementStart>
    <datepicker [hidden]="!isDateVisible" [(ngModel)]="dateStatement" [ngModelOptions]="{standalone: true}"
        [minDate]="minDate" [maxDate]="todayDateTime" [showWeeks]="false" (ngModelChange)="updateDateStatement()"
        (selectionDone)="dateSelectionDone()">
    </datepicker>
    <timepicker [hidden]="!isDateVisible" [(ngModel)]="timeStatement" [ngModelOptions]="{standalone: true}"
        [min]="minTime" [max]="maxTime" (ngModelChange)="updateTimeStatement()" [showMeridian]="isMeridian"
        showSeconds="true">
    </timepicker>
</ng-template>
<ng-template #popTemplateStatementEnd>
    <datepicker [hidden]="!isDateVisible" [(ngModel)]="endDateStatement" [ngModelOptions]="{standalone: true}"
        [minDate]="dateStatement" [maxDate]="todayDateTime" [showWeeks]="false"
        (ngModelChange)="updateEndDateStatement()" (selectionDone)="dateSelectionDone()">
    </datepicker>
    <timepicker [hidden]="!isDateVisible" [(ngModel)]="endTimeStatement" [ngModelOptions]="{standalone: true}"
        [min]="minTime" [max]="maxTime" (ngModelChange)="updateEndTimeStatement()" [showMeridian]="isMeridian"
        showSeconds="true">
    </timepicker>
</ng-template>