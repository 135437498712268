import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { SportService } from '../services/sport.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss']
})
export class PromotionComponent implements OnInit {
  banner: any;
  userDetails:any;
  domain:any;
  public Base_Url = environment['adminServerUrl'];
  constructor(@Inject(DOCUMENT) private document,private sport: SportService,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
   this.domain = this.document.location.hostname;
   // this.banner = JSON.parse(localStorage.getItem('Banner'));
  
    if(this.userDetails)
    {
      this.getPromotionList();
    }
  }
  copyInputMessage(inputElement:any)
  {
    let val=this.domain+"/start/"+this.userDetails?._id;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.info("Copy..")
  }

  getPromotionList()
  {
    let params={user_id:this.userDetails._id,parent_id:this.userDetails.parent_id}
    this.sport.getPermotionList(params).subscribe((res) => {
      if (res.status == true) {
        this.banner=res.data;
      } else {
        this.toastr.error(res.msg)
      }
    })
  }

}
