import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import { Router } from "@angular/router";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DateTime } from '../../dateTime';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-statement',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.scss']
})
export class StatementComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('popoverRef') private _popoverRef: PopoverDirective;
  @ViewChild('startpopoverRef') private _startpopoverRef: PopoverDirective;
  @ViewChild('endpopoverRef') private _endpopoverRef: PopoverDirective;
  moment: any = moment;
  time: Date;
  date: Date;
  isDateVisible: boolean = true;
  isMeridian: boolean = false;
  dateTime = new Date();
  statementStartDate = new Date()
  statementEndDate = new Date();
  timeStatement: Date;
  dateStatement: Date;
  endDateStatement: Date;
  endTimeStatement: Date;
  endTime: any;
  public Base_Url = environment['adminServerUrl'];
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  public token = localStorage.getItem('userAccessToken');
  reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ` + this.token
  });
  userDetails: any;
  statementsLength: any;
  statementsDataSource: any;
  data: any;
  statementData: any;
  statementType: any = '0';
  constructor(private http: HttpClient, private router: Router, private toastr: ToastrService) { }
  ngOnInit(): void {
    this.statementStartDate.setHours(0, 0, 0, 0);
    this.statementEndDate.setHours(23, 59, 59);
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      serverSide: true,
      searching: true,
      processing: false,
      ordering: false,
      destroy: true,
      ajax: (dataTablesParameters1: any, callback) => {
        let dataTablesParameters = {
          limit: dataTablesParameters1.length,
          page: (dataTablesParameters1.start / dataTablesParameters1.length) + 1,
          "statement_type": this.statementType,
          "user_id": this.userDetails._id,
          "from_date": this.statementStartDate.toISOString(),
          "to_date": this.statementEndDate.toISOString()
        }
        that.http.post<DataTablesResponse>(this.Base_Url + 'account/statements', dataTablesParameters, { headers: this.reqHeader })
          .subscribe(resp => {
            if (resp["status"]) {
              this.statementsLength = resp.data[0].data.length
              this.statementsDataSource = resp.data[0].data;
              for (let i = 0; i < this.statementsDataSource.length; i++) {
                this.statementsDataSource[i].index = (dataTablesParameters1.start) + 1 + i;
                this.statementsDataSource[i].updateDateTime = moment(this.statementsDataSource[i].date).utcOffset("+5:30").format('DD-MM-YYYY HH:mm:ss');
                if (this.statementsDataSource[i].credit_debit >= 0) {
                  this.statementsDataSource[i].credit = this.statementsDataSource[i].credit_debit
                  this.statementsDataSource[i].debit = (0)
                } else {
                  this.statementsDataSource[i].credit = (0)
                  this.statementsDataSource[i].debit = -(this.statementsDataSource[i].credit_debit)
                }
              }
              this.statementsDataSource=this.statementsDataSource.filter((val)=>{return val.credit_debit!=0})
              if (resp.data[0].metadata.length != 0) {
                callback({
                  recordsTotal: resp.data[0].metadata[0].total,
                  recordsFiltered: resp.data[0].metadata[0].total,
                  data: this.statementsDataSource
                });
              } else {
                this.statementsDataSource = []
                callback({
                  recordsTotal: 0,
                  recordsFiltered: 0,
                  data: this.statementsDataSource
                });
              }
            } else {
              this.toastr.error(resp["msg"]);
              this.statementsDataSource = []
              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: this.statementsDataSource
              });
            }
          });
      },
      columns: [
        {
          title: 'S. No.',
          data: 'index'
        },
        {
          title: 'Date & Time',
          data: 'updateDateTime'
        },
        {
          title: 'Type',
          data: 'type',
          "render": function (data, type, row) {
            var content = ''
            content = content + '<span >' + ((row.type == 0) ? 'D/W' : 'P/L') + '</span>';
            return content;
          }
        },
        {
          title: 'Description',
          data: 'description',
          "render": function (data, type, row) {
            var content = ''
            content = content + '<span (click)="(row.statement_type == 4 || row.statement_type == 2) ? routePath(list) :  ">' + (row.description) + '</span>';
            return content;
          }
        },
        {
          title: 'Dr',
          data: 'credit_debit',
          "render": function (data, type, row) {
            var content = ''
            content = content + '<span style="color: red;">' + (row.credit_debit > 0 ? '-' : (row.credit_debit).toFixed(2)) + '</span>';
            return content;
          }
        },
        {
          title: 'Cr',
          data: 'credit_debit',
          "render": function (data, type, row) {
            var content = ''
            content = content + '<span style="color: green;">' + (row.credit <= 0 ? '-' : (row.credit).toFixed(2)) + '</span>';
            return content;
          }
        },
        {
          title: 'Balance',
          data: 'balance',
          "render": function (data, type, row) {
            var content = ''
            content = content + '<span >' + (row.balance).toFixed(2) + '</span>';
            return content;
          }
        }],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        $('td', row).off('click');
        $('td', row).on('click', () => {
          this.routePath(data);
        });
        $(row).addClass('text-center');
        return row;
      }
    };
  }

  dateSelectionDone() {
    this.isDateVisible = false;
  }
  updateDateStatement() {
    if (this.dateStatement) {
      this.statementStartDate = DateTime.getDateTime(this.dateStatement, this.timeStatement);
    }
    if (!this.timeStatement) {
      this.timeStatement = this.dateStatement;
    }
  }
  updateEndDateStatement() {
    if (this.endDateStatement) {
      this.statementEndDate = DateTime.getDateTime(this.endDateStatement, this.endTimeStatement);
    }
    if (!this.endTimeStatement) {
      this.endTimeStatement = this.endDateStatement;
    }
  }
  updateTimeStatement() {
    if (this.timeStatement) {
      this.statementStartDate = DateTime.getDateTime(this.dateStatement, this.timeStatement);
    }
  }
  updateEndTimeStatement() {
    if (this.endTime) {
      this.statementEndDate = DateTime.getDateTime(this.endDateStatement, this.endTimeStatement);
    }
  }
  updateDate() {
    if (this.date) {
      this.dateTime = DateTime.getDateTime(this.date, this.time);
    }
    if (!this.time) {
      this.time = this.date;
    }
  }

  updateTime() {
    if (this.time) {
      this.dateTime = DateTime.getDateTime(this.date, this.time);
    }
  }

  showDate() {
    this.isDateVisible = true;
  }

  showTime() {
    this.isDateVisible = false;
  }

  close() {
    this._popoverRef.hide();
  }

  now() {
    this.dateTime = DateTime.now(this.date);
    this.time = this.dateTime;
  }

  today() {
    this.statementStartDate = DateTime.now(this.date);
    this.statementEndDate = DateTime.now(this.date);
  }
  clear() {
    this.time = void 0;
    this.date = void 0;
    this.dateTime = void 0;
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next("");
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next("");
    });
  }
  routePath(list) {
    if (list.statement_type == 4 || list.statement_type == 2) {
      this.goToViewBets(list);
    }
  }
  goToViewBets(data) {
    this.router.navigate(['viewBet/' + data.match_id + '/' + data.event_id + '/' + data.type + '/' + data.description + '/' + data.user_id])
  }
}
