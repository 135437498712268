import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SportService } from '../services/sport.service';
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-left-side-bar',
  templateUrl: './left-side-bar.component.html',
  styleUrls: ['./left-side-bar.component.scss']
})
export class LeftSideBarComponent implements OnInit {
  allSportsList: any;
  allSeriesList: any;
  matchList: any[] = [];
  sportData: any;
  iUrl: any;
  showProviders: boolean = true;
  casino: any;
  exchangeGameList: any;
  seriesData: any;
  constructor(public toastr: ToastrService, private router: Router, private sport: SportService, private cdRef: ChangeDetectorRef) { }
  ngOnInit(): void {
    let a = window.screen.width;
    this.getAllSportsListData()
    if (a > 767) {
      this.exchangeGame();
      this.providers();
    }
  }
  getAllSportsListData() {
    let userData = {};
    this.sport.getAllSportsList(userData).subscribe(res => {
      if (res.status == true) {
        this.allSportsList = res.data;
        this.allSportsList = (this.allSportsList).sort(function (a, b) { return b.sport_id - a.sport_id });
        this.seriesData = undefined
        this.cdRef.detectChanges();
      } else {
        this.toastr.error(res.msg);
      }
    })
  }
  getSeriesList(id) {
    this.allSeriesList = []
    this.cdRef.detectChanges();
    this.sportData = id;
    let data = {
      sport_id: id
    };
    this.sport.getSeriesList(data).subscribe(res => {
      this.allSeriesList = res.data
    })
  }
  getMatchList(data, index, param) {
    this.matchList = []
    this.cdRef.detectChanges();
    this.seriesData = data.series_id;
    let userData = {
      series_id: data.series_id
    };
    this.sport.getHomeMatchesList(userData).subscribe(res => {
      if (res.status) {
        this.matchList[index] = res.data;
      } else {
        this.toastr.error(res.msg, '', {
          timeOut: 10000,
        });
      }
    }, (err) => {
      console.log(err);
    })
  }
  matchDetail(matchData) {
    localStorage.setItem('matchData', JSON.stringify(matchData));
    this.router.navigate(['match-detail/' + matchData.match_id]);
  }
  aviator() {
    let data = {
      gameId: 'SPB-aviator',
      providerCode: "SPB"
    }
    this.sport.qtechLaunch(data).subscribe((res) => {
      if (res.status) {
        this.iUrl = res.data.url;
        window.open(this.iUrl, "_blank")
      } else {
        this.toastr.error(res.msg)
      }
    })
  }
  qtech(data) {
    this.router.navigate(['qtech/' + data]);
  }
  dashboardCasino() {
    this.router.navigate(['dashboard/Casino']);
  }
  providers() {
    this.showProviders = true;
    this.cdRef.detectChanges();
  }
  liveCasino() {
    this.casino = (this.exchangeGameList).filter(a => a.is_live == 1)
    this.showProviders = false;
    this.cdRef.detectChanges();
  }
  virtual() {
    this.casino = (this.exchangeGameList).filter(a => a.is_live == 0)
    this.showProviders = false;
    this.cdRef.detectChanges();
  }
  exchangeGame() {
    let data = {
      sport_id: "-100"
    };
    this.sport.getHomeMatchesList(data).subscribe((res) => {
      if (res.status == true) {
        this.exchangeGameList = res.data;
        for (let i = 0; i < (this.exchangeGameList).length; i++) {
          if ((this.exchangeGameList[i].match_name).indexOf('(Virtual)') > -1) {
            this.exchangeGameList[i].is_live = 0;
          } else {
            this.exchangeGameList[i].is_live = 1;
          }
        }
        this.casino = (this.exchangeGameList).filter(a => a.is_live == 1)
        this.showProviders = false;
      }
    })
  }
  gotoexchangeGame(cardData) {
    let data = {
      game_id: cardData.match_id
    }
   /* this.sport.lotusLaunch(data).subscribe((res) => {
      if (res.status == true) {
        window.open(res.launchUrl, '_blank')
      }
    }) */
    localStorage.setItem('selectedMatch', JSON.stringify(cardData.match_id));
    this.router.navigate(['casino-detail/'+cardData.match_id]);
  }

  close() {
    this.seriesData = undefined;
    this.cdRef.detectChanges();
  }
  closeSportList() {
    this.seriesData = undefined;
    this.sportData = undefined;
    this.cdRef.detectChanges();
  }
  openSeries(data) {
    this.router.navigate(['dashboard/' + data.series_id + 'series'])
  }
}
