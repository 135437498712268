import { Component, OnInit, ElementRef, TemplateRef, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { RightSideBarComponent } from '../right-side-bar/right-side-bar.component';
import { LeftSideBarComponent } from '../left-side-bar/left-side-bar.component';
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SportService } from '../services/sport.service';
import * as moment from 'moment';
import { resolve } from 'q';
import { ToastrService } from 'ngx-toastr';
import { SocketServiceService } from '../services/socket-service.service';
import { OpenApiService } from '../services/open-api.service';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [HeaderComponent, RightSideBarComponent, LeftSideBarComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;
  public Base_Url = environment['adminServerUrl'];
  moment: any = moment;
  homeData: any;
  public timer: any;
  allSportsList: any;
  modalRef: BsModalRef;
  userData: any;
  firstData: any;
  parameter: any;
  a: any = [];
  runnerData: any;
  combineData: any;
  Data: any;
  finalData: any;
  isSocket;
  runnerDataLength: any;
  firstDataLength: any;
  updateRunnerData: any = [];
  dataLength: any;
  marketId: any = [];
  callType = 1;
  marketData: any = [];
  socketData: any;
  callingType = 1;
  updateFirstData: any = [];
  games: string;
  mobileAllSportsList: any;
  sportName: any;
  banner: any;
  userDetail: any;
  logo: string;
  sportView: boolean;
  casinoList: any;
  totalMatches: any;
  totalInplay: any;
  exchangeGameList: any;
  totalMatchLength: any;
  iUrl: any;
  casinoId:any = 'All';
  constructor(private open: OpenApiService, private modalService: BsModalService, private cdRef: ChangeDetectorRef, private socketService: SocketServiceService, private route: ActivatedRoute,
    private head: HeaderComponent, private router: Router, private sport: SportService, private toastr: ToastrService) {
    this.socketStatus();
  }
  socketStatus() {
    this.sport.getSocketStatus().subscribe((res) => {
      this.isSocket = res.is_socket;
      this.socketService.setUpSocketConnection();
      this.socketListenersUser();
      this.socketSuccess()
      this.socketError();
      this.route.params.subscribe(params => {
        let a = params.sportName != undefined ? (params.sportName.split('series')) : '';
        if (a[1] != '' || params.sportName == undefined) {
          if (params.sportName == undefined) {
            this.sportView = true;
            this.userData = {};
            this.parameter = 'All';
            this.sportName = 'All';
            this.homematches();
            this.cdRef.detectChanges();
          } else {
            if (params.sportName == 'Casino') {
              this.sportView = false;
              this.exchangeGame();
            } else {
              this.sportView = true;
              this.parameter = params.sportName;
              this.sportName = params.sportName;
            }
            this.homematches();
            this.cdRef.detectChanges();
          }
        } else {
          this.homematchesSeries(a[0])
        }
      })
    })
  }
  async ngOnInit() {
    this.logo = localStorage.getItem('logo')
    this.getBanner()
    this.userDetail = JSON.parse(localStorage.getItem('userDetails'))
    window.scrollTo(0, 0)
  }

  socketOnEvent(eventName, callback) {
    this.socketService.socket.on(eventName, data => callback(data));
  }

  socketEmitEvent(eventName, data) {
    this.socketService.socket.emit(eventName, data);
  }
  socketError() {
    this.socketService.socket.on(`error`, (res) => {
      if (res.status == false) {
      
        //this.toastr.error(res.msg)
      }
    })
  }
  socketSuccess() {
    this.socketService.socket.on('success', (res) => {
      if (res.status == true) {
        if (res.event_code == "subscribe_event") {
          this.marketData = [];
          for (let i = 0; i < this.marketId.length; i++) {
            this.socketOnEvent(this.marketId[i], result => {
              if (result.status == true) {
                this.marketData = result.data;
                this.runnerDataLength = this.marketData.length;
                this.runnerData = this.marketData;
                for (let i = 0; i < this.firstData.length; i++) {
                  if (this.firstData[i].marketId == this.runnerData.marketId) {
                    this.firstData[i].runners = this.runnerData.runners;
                    this.firstData[i].status = this.runnerData.status;
                    this.firstData[i].inplay = this.runnerData.inplay;
                    this.socketData = this.firstData[i]
                    break
                  }
                }
                for (let i = 0; i < Object.keys(this.homeData).length; i++) {
                  if (Object.values(this.homeData)[i]["marketId"] == this.socketData.marketId) {
                    Object.values(this.homeData)[i]["runners"] = this.socketData.runners;
                    Object.values(this.homeData)[i]["status"] = this.socketData.status;
                    Object.values(this.homeData)[i]["inplay"] = this.socketData.inplay;
                  }
                }
                this.cdRef.detectChanges();
              }
            })
          }
        } else {
        }
      }
    })
  }
  getRunnerEmit() {
    this.marketId = [];
    for (let i = 0; i < Object.keys(this.homeData).length; i++) {
      let a = [];
      a.push(Object.values(this.homeData)[i])
      for (let j = 0; j < a[0].length; j++) {
        this.marketId.push(a[0][j].marketId)
      }
    }
    let event = {
      "eventIds": this.marketId
    }
    this.socketEmitEvent('subscribe_event', event);
  }

  marketAdded() {
    this.userData = {};
    this.socketEmitEvent('new_market_added', this.userData);
  }
  ngOnDestroy() {
    this.socketService.socket.close();
  }
  socketListenersUser() {
    this.socketOnEvent(`homeMatches`, res => {
      this.firstData = res.data;
      this.firstDataLength = this.firstData.length
      for (let i = 0; i < this.firstData.length; i++) {
        this.marketId.push(this.firstData[i].marketId)
      }
      this.getRunnerEmit();
    });

    this.socketOnEvent(`subscribe_event`, res => {
    });
    this.socketOnEvent(`unsubscribe_event`, res => {
    });
    this.socketOnEvent(`new_market_added`, res => {
      if (res.status == true) {
        this.toastr.success(res.msg, '', {
          positionClass: 'toast-bottom-right',
          timeOut: 1000
        })
        this.homematches();
      }
    });
    this.socketOnEvent(`socket_state_changed`, res => {
      this.isSocket = res.is_socket
      this.route.params.subscribe(params => {
        if (params.sportName == undefined) {
          this.userData = {};
          this.homematches();
        } else {
          this.parameter = params.sportName;
        
          this.homematches();
        }
      })
      if (this.isSocket == 0) {
        this.marketId = [];
        for (let i = 0; i < this.firstData.length; i++) {
          this.marketId.push(this.firstData[i].marketId)
        }
        let event = {
          "eventIds": this.marketId
        }
        this.socketEmitEvent('unsubscribe_event', event);
      } else {
        clearTimeout(this.timer);
      }
    });
  }

  getHomeMatchCallEvent(param) {
    this.userData = {};
    this.socketEmitEvent('homeMatches', this.userData);
  }
  homematches() {
    this.sport.getHomeMatchesList(this.userData).subscribe(res => {
      if (res.status) {
        this.firstData = res.data;
        this.firstDataLength = this.firstData.length;
        this.getAllSportsListData();
        if (this.firstData.length != 0) {
          for (let i = 0; i < this.firstDataLength; i++) {
            this.firstData[i].manualInplay = this.firstData[i].inplay;
            if ((this.firstData[i].my_favorites).length != 0) {
              for (let j = 0; j < (this.firstData[i].my_favorites).length; j++) {
                let isFounded = (this.firstData[i].my_favorites).some(ai => ai === this.userDetail._id);
                if (isFounded == true) {
                  this.firstData[i].fav = true;
                } else {
                  this.firstData[i].fav = false
                }
              }
            } else {
              this.firstData[i].fav = false
            }
            this.updateRunnerData[i] = {
              "marketId": this.firstData[i].marketId,
              "status": "SUSPENDED",
              "runners": [
                {
                  "ex": {
                    "availableToBack": [
                      {
                        "price": "--",
                        "size": "--"
                      }
                    ],
                    "availableToLay": [
                      {
                        "price": "--",
                        "size": "--"
                      }
                    ],
                    "tradedVolume": []
                  }
                },
                {
                  "ex": {
                    "availableToBack": [
                      {
                        "price": "--",
                        "size": "--"
                      }
                    ],
                    "availableToLay": [
                      {
                        "price": "--",
                        "size": "--"
                      }
                    ],
                    "tradedVolume": []
                  }
                }
              ]
            }
          }
          let a3 = this.firstData.map(t1 => ({ ...t1, ...this.updateRunnerData.find(t2 => t2.marketId === t1.marketId) }));
          let matches;
          if (this.parameter == undefined || this.parameter == 'All') {
            matches = a3;
            this.dataLength = matches.length;
          } else if (this.parameter == 'inplay') {
            matches = a3.filter(t => t.inplay == true || t.manualInplay == true);
            this.dataLength = matches.length;
          } else {
            matches = a3.filter(t => t.sport_name == this.parameter);
            this.dataLength = matches.length;
          }
          matches = matches.sort((a: any, b: any) =>
            new Date(a.match_date).getTime() - new Date(b.match_date).getTime()
          );
          matches = matches.reduce((groupSport, sports) => {
            const { sport_name } = sports;
            groupSport[sport_name] = [...groupSport[sport_name] || [], sports];
            return groupSport;
          }, {});
          this.homeData = matches;
          this.runners();
          this.cdRef.detectChanges();
        } else {
          this.toastr.error('No Match Found.')
        }
      } else {
        if (res.logout == true) {
          this.head.logoutUser();
        } else {
         
          this.toastr.error(res.msg, '', {
            timeOut: 10000,
          });
        }
      }
    }, (err) => {
      console.log(err);
    },
      () => {
        if (this.router.url.split('?')[0] == '/dashboard') {
          if (this.isSocket != 1) {
            this.timer = setTimeout(() => resolve(this.homematches()), 10000);
          } else {
            clearTimeout(this.timer);
          }
        }
      })
  }
  homematchesSeries(param) {
    let data = {
      series_id: param
    }
    this.sport.getHomeMatchesList(data).subscribe(res => {
      if (res.status) {
        this.firstData = res.data;
        this.firstDataLength = this.firstData.length;
        this.getAllSportsListData();
        if (this.firstData.length != 0) {
          for (let i = 0; i < this.firstDataLength; i++) {
            this.firstData[i].manualInplay = this.firstData[i].inplay;
            if ((this.firstData[i].my_favorites).length != 0) {
              for (let j = 0; j < (this.firstData[i].my_favorites).length; j++) {
                let isFounded = (this.firstData[i].my_favorites).some(ai => ai === this.userDetail._id);
                if (isFounded == true) {
                  this.firstData[i].fav = true;
                } else {
                  this.firstData[i].fav = false
                }
              }
            } else {
              this.firstData[i].fav = false
            }
            this.updateRunnerData[i] = {
              "marketId": this.firstData[i].marketId,
              "status": "SUSPENDED",
              "runners": [
                {
                  "ex": {
                    "availableToBack": [
                      {
                        "price": "--",
                        "size": "--"
                      }
                    ],
                    "availableToLay": [
                      {
                        "price": "--",
                        "size": "--"
                      }
                    ],
                    "tradedVolume": []
                  }
                },
                {
                  "ex": {
                    "availableToBack": [
                      {
                        "price": "--",
                        "size": "--"
                      }
                    ],
                    "availableToLay": [
                      {
                        "price": "--",
                        "size": "--"
                      }
                    ],
                    "tradedVolume": []
                  }
                }
              ]
            }
          }
          let a3 = this.firstData.map(t1 => ({ ...t1, ...this.updateRunnerData.find(t2 => t2.marketId === t1.marketId) }));
          let matches = a3;
          matches = matches.sort((a: any, b: any) =>
            new Date(a.match_date).getTime() - new Date(b.match_date).getTime()
          );
          matches = matches.reduce((groupSport, sports) => {
            const { sport_name } = sports;
            groupSport[sport_name] = [...groupSport[sport_name] || [], sports];
            return groupSport;
          }, {});
          this.homeData = matches;
          this.sportView = true
          this.runnersSeries();
          this.cdRef.detectChanges();
        } else {
          this.toastr.error('No Match Found.')
        }
      } else {
        if (res.logout == true) {
          this.head.logoutUser();
        } else {
        
          this.toastr.error(res.msg, '', {
            timeOut: 10000,
          });
        }
      }
    }, (err) => {
      console.log(err);
    },
      () => {
        if (this.router.url.split('?')[0] == '/dashboard') {
          if (this.isSocket != 1) {
            this.timer = setTimeout(() => resolve(this.homematchesSeries(param)), 10000);
          } else {
            clearTimeout(this.timer);
          }
        }
      })
  }
  runners() {
    this.userData = {};
    this.sport.runnerList(this.userData).subscribe((res) => {
      if (res.status) {
        this.runnerDataLength = res.data.length;
        this.runnerData = res.data;
        for (let i = 0; i < (this.firstData).length; i++) {
          for (let j = 0; j < this.runnerDataLength; j++) {
            if (this.firstData[i].marketId == this.runnerData[j].marketId) {
              this.updateRunnerData[i] = []
              this.updateRunnerData[i] = (this.runnerData[j]);
              break
            } else {
              this.updateRunnerData[i] = {
                "marketId": this.firstData[i].marketId,
                "status": "SUSPENDED",
                "inplay": false,
                "runners": [
                  {
                    "ex": {
                      "availableToBack": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "availableToLay": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "tradedVolume": []
                    }
                  },
                  {
                    "ex": {
                      "availableToBack": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "availableToLay": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "tradedVolume": []
                    }
                  }
                ]
              }
            }
          }
        }
        const a3 = this.firstData.map(t1 => ({ ...t1, ...this.updateRunnerData.find(t2 => t2.marketId === t1.marketId) }))
        this.a = a3;
        let matches;
        if (this.parameter == undefined || this.parameter == 'All') {
          matches = this.a;
          this.dataLength = matches.length;
        } else if (this.parameter == 'inplay') {
          matches = this.a.filter(t => t.inplay == true || t.manualInplay == true);
          this.dataLength = matches.length;
        } else {
          matches = this.a.filter(t => t.sport_name == this.parameter);
          this.dataLength = matches.length;
        }
        matches = matches.sort((a: any, b: any) =>
          new Date(a.match_date).getTime() - new Date(b.match_date).getTime()
        );

        this.totalMatchLength = matches;
        matches = matches.reduce((groupSport, sports) => {
          const { sport_name } = sports;
          groupSport[sport_name] = [...groupSport[sport_name] || [], sports];
          return groupSport;
        }, {});
        this.homeData = matches;
        this.a = [];
        this.cdRef.detectChanges();
        this.getRunnerEmit();
      } else {
        for (let i = 0; i < this.firstDataLength; i++) {
          this.updateRunnerData[i] = {
            "marketId": this.firstData[i].marketId,
            "status": "SUSPENDED",
            "inplay": false,
            "runners": [
              {
                "ex": {
                  "availableToBack": [
                    {
                      "price": "--",
                      "size": "--"
                    }
                  ],
                  "availableToLay": [
                    {
                      "price": "--",
                      "size": "--"
                    }
                  ],
                  "tradedVolume": []
                }
              },
              {
                "ex": {
                  "availableToBack": [
                    {
                      "price": "--",
                      "size": "--"
                    }
                  ],
                  "availableToLay": [
                    {
                      "price": "--",
                      "size": "--"
                    }
                  ],
                  "tradedVolume": []
                }
              }
            ]
          }
        }
        const a3 = this.firstData.map(t1 => ({ ...t1, ...this.updateRunnerData.find(t2 => t2.marketId === t1.marketId) }));
        this.a = a3;
        let matches;
        if (this.parameter == undefined || this.parameter == 'All') {
          matches = this.a;
          this.dataLength = matches.length;
        } else if (this.parameter == 'inplay') {
          matches = this.a.filter(t => t.inplay == true || t.manualInplay == true);
          this.dataLength = matches.length;
        } else {
          matches = this.a.filter(t => t.sport_name == this.parameter);
          this.dataLength = matches.length;
        }
        matches = matches.sort((a: any, b: any) =>
          new Date(a.match_date).getTime() - new Date(b.match_date).getTime()
        );

        matches = matches.reduce((groupSport, sports) => {
          const { sport_name } = sports;
          groupSport[sport_name] = [...groupSport[sport_name] || [], sports];
          return groupSport;
        }, {});
        this.homeData = matches;
        this.a = [];
        this.cdRef.detectChanges();
        this.getRunnerEmit();
      }
    }, (err) => {
      console.log(err);
    })
  }
  runnersSeries() {
    this.userData = {};
    this.sport.runnerList(this.userData).subscribe((res) => {
      if (res.status) {
        this.runnerDataLength = res.data.length;
        this.runnerData = res.data;
        for (let i = 0; i < (this.firstData).length; i++) {
          for (let j = 0; j < this.runnerDataLength; j++) {
            if (this.firstData[i].marketId == this.runnerData[j].marketId) {
              this.updateRunnerData[i] = []
              this.updateRunnerData[i] = (this.runnerData[j]);
              break
            } else {
              this.updateRunnerData[i] = {
                "marketId": this.firstData[i].marketId,
                "status": "SUSPENDED",
                "inplay": false,
                "runners": [
                  {
                    "ex": {
                      "availableToBack": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "availableToLay": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "tradedVolume": []
                    }
                  },
                  {
                    "ex": {
                      "availableToBack": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "availableToLay": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "tradedVolume": []
                    }
                  }
                ]
              }
            }
          }
        }
        const a3 = this.firstData.map(t1 => ({ ...t1, ...this.updateRunnerData.find(t2 => t2.marketId === t1.marketId) }))
        this.a = a3;
        let matches = a3;
        matches = matches.sort((a: any, b: any) =>
          new Date(a.match_date).getTime() - new Date(b.match_date).getTime()
        );

        this.totalMatchLength = matches;
        matches = matches.reduce((groupSport, sports) => {
          const { sport_name } = sports;
          groupSport[sport_name] = [...groupSport[sport_name] || [], sports];
          return groupSport;
        }, {});
        this.homeData = matches;
        this.a = [];
        this.cdRef.detectChanges();
        this.getRunnerEmit();
      } else {
        for (let i = 0; i < this.firstDataLength; i++) {
          this.updateRunnerData[i] = {
            "marketId": this.firstData[i].marketId,
            "status": "SUSPENDED",
            "inplay": false,
            "runners": [
              {
                "ex": {
                  "availableToBack": [
                    {
                      "price": "--",
                      "size": "--"
                    }
                  ],
                  "availableToLay": [
                    {
                      "price": "--",
                      "size": "--"
                    }
                  ],
                  "tradedVolume": []
                }
              },
              {
                "ex": {
                  "availableToBack": [
                    {
                      "price": "--",
                      "size": "--"
                    }
                  ],
                  "availableToLay": [
                    {
                      "price": "--",
                      "size": "--"
                    }
                  ],
                  "tradedVolume": []
                }
              }
            ]
          }
        }
        const a3 = this.firstData.map(t1 => ({ ...t1, ...this.updateRunnerData.find(t2 => t2.marketId === t1.marketId) }));
        this.a = a3;
        let matches = a3;
        matches = matches.sort((a: any, b: any) =>
          new Date(a.match_date).getTime() - new Date(b.match_date).getTime()
        );

        matches = matches.reduce((groupSport, sports) => {
          const { sport_name } = sports;
          groupSport[sport_name] = [...groupSport[sport_name] || [], sports];
          return groupSport;
        }, {});
        this.homeData = matches;
        this.a = [];
        this.cdRef.detectChanges();
        this.getRunnerEmit();
      }
    }, (err) => {
      console.log(err);
    })
  }
  scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 250), behavior: 'smooth' });
  }

  scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 250), behavior: 'smooth' });
  }
  getAllSportsListData() {
    let userData = {};
    this.sport.getAllSportsList(userData).subscribe(res => {
      this.allSportsList = res.data;
      for (let i = 0; i < (this.allSportsList).length; i++) {
        let matches = (this.firstData).filter((a) => a.sport_id == this.allSportsList[i].sport_id);
        let inplayData = (matches).filter(a => a.inplay == true)
        this.allSportsList[i].totalMatch = matches.length;
        this.allSportsList[i].totalInplayMatch = inplayData.length;
      }
      this.totalMatches = this.firstDataLength;
      let inplay = (this.firstData).filter(a => a.inplay == true)
      this.totalInplay = inplay.length
      this.allSportsList = (this.allSportsList).sort(function (a, b) { return b.sport_id - a.sport_id });
      this.cdRef.detectChanges()
    })
  }

  goToDashboard(sportName, id, data) {
    if (id == -100) {
      this.router.navigate(['dashboard/' + sportName]);
    } else if (id < -100) {
      this.router.navigate(['snova/' + id + '/' + data.name + '/' + data.providerCode])
    } else if (id > -100) {
      this.router.navigate(['dashboard/' + sportName]);
    } else if (id == 'QT') {
      this.router.navigate(['qtech'])
    }
  }
  matchDetail(matchData) {
    localStorage.setItem('matchData', JSON.stringify(matchData));
    this.router.navigate(['match-detail']);
  }
  matchDetailDrop(matchData) {
    this.modalRef.hide();
    localStorage.setItem('matchData', JSON.stringify(matchData));
    this.router.navigate(['match-detail']);
  }
  gotoexchangeGame(cardData) {
    let data = {
      game_id: cardData.match_id
    }
    this.sport.lotusLaunch(data).subscribe((res) => {
      if (res.status == true) {
        window.open(res.launchUrl, "_blank")
      }
    })
  }


  favourite(id) {
    let data = {
      match_id: id
    };
    this.sport.favourite(data).subscribe((res) => {
      if (res.status == true) {
        this.toastr.success(res.msg, '', {
          positionClass: 'toast-bottom-right',
          timeOut: 1000
        });
        this.homematches();
      } else {
       
        this.toastr.error(res.msg)
      }
    })
  }
  openModalSearch(search: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      search,
      Object.assign({}, { class: 'searchModal', ignoreBackdropClick: false })
    );
  }
  exchangeGame() {
    let data = {
      sport_id: "-100"
    };
    this.sport.getHomeMatchesList(data).subscribe((res) => {
      if (res.status == true) {
        this.exchangeGameList = res.data;
        for (let i = 0; i < (this.exchangeGameList).length; i++) {
          if ((this.exchangeGameList[i].match_name).indexOf('(Virtual)') > -1) {
            this.exchangeGameList[i].is_live = 0;
          } else {
            this.exchangeGameList[i].is_live = 1;
          }
        }
        this.casinoList = [];
        this.casinoList = this.exchangeGameList;
      }
    })
  }
  liveCasino() {
    this.casinoId = 'Live'
    this.casinoList = (this.exchangeGameList).filter(a => a.is_live == 1)
  }
  virtual() {
    this.casinoId = 'Virtual'
    this.casinoList = (this.exchangeGameList).filter(a => a.is_live == 0)
  }
  all() {
    this.casinoId = 'All'
    this.casinoList = this.exchangeGameList;
  }
  inplay() {
    this.router.navigate(['dashboard/inplay']);
  }
  aviator() {
    let data = {
      gameId: 'SPB-aviator',
      providerCode: "SPB"
    }
    this.sport.qtechLaunch(data).subscribe((res) => {
      if (res.status) {
        this.iUrl = res.data.url;
        window.open(this.iUrl, "_blank")
      } else {
       
        this.toastr.error(res.msg)
      }
    })
  }
  qtech(data) {
    this.router.navigate(['qtech/' + data]);
  }
  dashboardCasino() {
    this.router.navigate(['dashboard/Casino']);
  }

  getBanner() {
    this.open.getBannerImage().subscribe((res) => {
      if (res.status == true) {
        let a = res.data;
        if (a.length != 0) {
          for (let i = 0; i < a.length; i++) {
            if ((a[i]).hasOwnProperty('self_host')) {
              if ((a[i].self_host == true)) {
                a[i].bannerUrl = this.Base_Url + a[i].description;
              } else {
                a[i].bannerUrl = a[i].description;
              }
            } else {
              a[i].bannerUrl = this.Base_Url + a[i].description;
            }
          }
          this.banner = a;
          localStorage.setItem('Banner', JSON.stringify(this.banner))
        }
      }
    })
  }
}
