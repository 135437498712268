<header id="header" class="header">
  <div class="container-fluid d-flex align-items-center justify-content-between main_navbar">
    <div class="burger_menu" (click)="openMobileSidebar()">
      <a href="javascript:void(0)" id="button"><img src="assets/images/bars.png" alt=""></a>
    </div>
    <a (click)="dashboard(1)" class="logo d-flex align-items-center me-auto me-lg-0">
      <img src="assets/logo/{{domain}}.png" width="172px" alt="">
    </a>
    <div class="left-mobile-sidebar" [ngClass]="openMobileSidebarStatus ? 'showSidebar' : 'hideSidebar'">
      <div class="sidebar-div">
        <div class="sidebarBackdrop" (click)="openMobileSidebar()"></div>
        <div class="left_sidebar">
          <div class="main_left_sidebar">
            <app-left-side-bar></app-left-side-bar>
          </div>
        </div>
      </div>
    </div>

    <nav id="navbar" class="navbar">
      <ul>
        <div class="input-group searchbar_input d-inline-flex d-sm-none w-100">
          <div ngbDropdown class="search-top  d-lg-block dropdown" style="cursor:pointer">
            <div class="input-group">
              <form class="d-flex position-relative" (click)="homematches()">
                <span class="float-icon search-icon" style="color:#000 !important"><i class="bi bi-search"></i></span>
                <input ngbDropdownToggle class="serch_input dropdown-toggle" placeholder="Events, Markets, and more"
                  id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" type="text"
                  autocomplete="off" [(ngModel)]="searchSport" [ngModelOptions]="{standalone: true}">
                <span class="float-icon clearall-icon" (click)="searchSport = ''"><i class="bi bi-x-lg"></i></span>
                <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <a ngbDropdownItem class="dropdown-item"
                    *ngFor="let sport of homeData |search: searchSport :'match_name'">
                    <div class="event-info" (click)="matchDetail(sport)">
                      <div class="event-team">
                        <div class="team-name">{{sport?.match_name}}</div>
                      </div>
                    </div>
                  </a>
                </div>
              </form>
            </div>
          </div>
          <span class="input-group-text">
            <img class="img-fluid" src="assets/images/icon-search.png">
          </span>
        </div>

        <li><a (click)="dashboard(1)" [class.active]='"dashboard" == path'><span>Sports</span></a></li>
        <li><a (click)="dashboard(4)" [class.active]='"Casino" == path'><span>Casino</span></a></li>
        <li><a (click)="dashboard(2)" [class.active]='"inplay" == path'><span>InPlay</span></a></li>
        <li><a (click)="dashboard(3)" [class.active]='"promo" == path'><span>Promotions</span></a></li>
        <li><a (click)="dashboard(5)"><span><img src="assets/images/aviator.png"></span></a></li>

        <li class="d-block d-sm-none"><a class="btn-log-reg" data-bs-toggle="modal"><img
              src="assets/images/icon-balance2.png"> {{walletBalance?.balance}}</a></li>
        <li class="d-block d-sm-none"><a class="btn-log-reg" data-bs-toggle="modal"><img
              src="assets/images/bank-icon.png"></a></li>
              
      </ul>
      <button class="mobile-nav-toggle d-none">
        <img class="openNav" src="assets/images/icon-navbar.png">
        <img class="closeNav" src="assets/images/icon-close.png">
      </button>
    </nav>
    <div class="input-group hide-on-mobile searchbar_input">
      <div ngbDropdown class="search-top d-lg-block dropdown" style="cursor:pointer">
        <div class="input-group">
          <form class="d-flex position-relative" (click)="homematches()">
            <span class="float-icon search-icon" style="color:#000 !important"><i class="bi bi-search"></i></span>
            <input ngbDropdownToggle class="serch_input dropdown-toggle" placeholder="Events, Markets, and more"
              id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" type="text"
              autocomplete="off" [(ngModel)]="searchSport" [ngModelOptions]="{standalone: true}">
            <span class="float-icon clearall-icon" (click)="searchSport = ''"><i class="bi bi-x-lg"></i></span>
            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <a ngbDropdownItem class="dropdown-item"
                *ngFor="let sport of homeData |search: searchSport :'match_name'">
                <div class="event-info" (click)="matchDetail(sport)">
                  <div class="event-team">
                    <div class="team-name">{{sport?.match_name}}</div>
                  </div>
                </div>
              </a>
            </div>
          </form>
        </div>
      </div>
      <span class="input-group-text">
        <img class="img-fluid" src="assets/images/icon-search.png">
      </span>
    </div>
    <a class="btn-getstarted hide-on-mobile" (click)="openModalexposureModal(exposureModal)">
      Exp: <span style="color: red;">{{walletBalance?.liability}}</span>
    </a>
    <a class="btn-getstarted hide-on-mobile"><img src="assets/images/icon-balance2.png">
      {{walletBalance?.balance}}
    </a>
    <a class="btn-getstarted balExp-btn hide-on-desktop">
      <span>Bal: {{walletBalance?.balance}}</span><br>
      <span (click)="openModalexposureModal(exposureModal)">Exp: <span style="color: red;text-decoration: underline;">{{walletBalance?.liability}}</span> </span>
    </a>
    <a  class="btn-getstarted hide-on-mobile" data-bs-toggle="modal" (click)="openModalDeposit(depositModal);getTodayStatement()"><img
        src="assets/images/icon-deposit2.png"> Deposit</a>
        <a  class="btn-getstarted hide-on-mobile" data-bs-toggle="modal" routerLink="/withdraw" ><img
          src="assets/images/icon-menu-withdraw.png" routerLink="/withdraw"> Withdraw</a>
    <a class="btn-getstarted hide-on-desktop" data-bs-toggle="modal" (click)="openModalDeposit(depositModal)"><img
        src="assets/images/bank-icon.png"></a>
        <a class="btn-getstarted hide-on-desktop" data-bs-toggle="modal" routerLink="/withdraw"><img
          src="assets/images/icon-menu-withdraw.png"></a>
    <div class="dropdown userMenus hide-on-mobile">
      <a class="dropdown-toggle" type="button" id="userMenus" data-bs-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false">
        <small>welcome</small>
        {{userDetails?.user_name}}
      </a>
      <div class="dropdown-menu" aria-labelledby="userMenus">
        <div class="user_header">
          <div>
            <img src="assets/images/icon-user.png">
            <span>
              username
              <small>{{userDetails?.user_name}}</small>
            </span>
            <a (click)="profile()">view</a>
          </div>

          <button class="btn_pl" (click)="profitLoss()">profit loss</button>
          <button class="btn_statement" (click)="statement()">statements</button>
        </div>
        <a class="dropdown-item" *ngIf="userDetails?.wallet_status && walletBalance?.is_wallet_allow" (click)="withdraw()"><img src="assets/images/icon-menu-withdraw.png"> withdraw</a>
        <a class="dropdown-item" (click)="bet()"><img src="assets/images/icon-menu-openbets.png"> open bets</a>
        <a class="dropdown-item" (click)="sbet()"><img src="assets/images/icon-menu-settled.png"> settled bets</a>
        <a class="dropdown-item" *ngIf="userDetails?.wallet_status && walletBalance?.is_wallet_allow" (click)="transaction()"><img src="assets/images/icon-menu-settled.png"> Transaction
          History</a>
        <a class="dropdown-item" (click)="openModalChangePass(changePwd)"><img
            src="assets/images/icon-menu-changepassword.png"> change password</a>
        <a class="dropdown-item" (click)="getUserMatchStackDetails();openModalStackModal(stackModal)"><img
            src="assets/images/icon-menu-editstake.png"> edit stakes</a>
        <a class="dropdown-item" (click)="logoutUser()"><img src="assets/images/icon-menu-signout.png"> signout</a>
      </div>
    </div>

    <!-- <a class="dark-mode" id="darkmode" (click)="changeThemeMode()">
      <img class="img-fluid darkmodeimg" src="assets/images/icon-mode-dark.png">
      <img class="img-fluid lightmodeimg" src="assets/images/icon-mode-light.png">
    </a> -->

  </div>
</header><!-- End Header -->

<div class="bottom-header" *ngIf="adminData?.length != 0">
  <div class="news_feed">
    <span><i class="fas fa-bell"></i></span>
    <span class="title"> News : </span>
    <marquee behavior="scroll" direction="left" onmouseout="this.start();" onmouseover="this.stop();" scrolldelay="100"
      class="marquee-style">
      <div class="marquee-news"><i class="fas fa-circle"></i><span class="marquee-news-text"> {{adminHeading}} </span>
        <span *ngIf="agentData?.length != 0" class="marquee-news-text">| {{agentHeading}} |</span>
      </div>
      <div style="clear: both;"></div>
    </marquee>
  </div>
</div>


<!-- deposit modal -->
<ng-template #depositModal>
  <a class="modal-close right close-icon" data-bs-dismiss="modal" aria-label="Close" (click)="modalRef.hide()"><i
      aria-hidden="true" class="fa fa-times"></i></a>
  <h5><span class="card-title">
      <img alt=""
        src="assets/images/payment.png">
      <span class="title"> Make a Deposit </span></span>
  </h5>

  <div class="modal-body">
    <div class="body-inner">
      <div class="deposit-modal"></div>
      <div class="payment-method">
        <form novalidate="" class="ng-untouched ng-pristine ng-invalid">
          <div class="bank-transfer">
            <div class="select-transfer">
              <a *ngFor="let list of payMethodList;let i = index;" (click)="detail(list)"
                [class.active]='list?.name == selectData' [ngClass]="list?.name == selectData ? 'active_c' : ''">
                <img alt="" class="responsive-img" src="assets/images/{{list?.payment_type}}.png">
              </a>
            </div>
            <div class="pay-details">
              <div class="title"> Make your payment on the details below </div>
              <div class="phone-pay-details" *ngIf="payMethodList?.length>0">
                <table *ngIf="payLength != 0">
                  <tr>
                    <td *ngIf="imageQr">
                      <img alt="" class="responsive-img" width="100%" src="{{Base_Url}}wallets/{{imageQr}}">
                    </td>
                  </tr>
                  <tbody *ngFor="let detail of payDetails; let i = index;">
                    <ng-container >
                    <tr id="payment0" >
                      <td class="title">{{detail?.key}}</td>
                      <td class="sub-title right-align" >
                       <div ngxClipboard
                       [cbContent]="'Content to be copied.'" (cbOnSuccess)="contentCopied($event)"
                       (click)="copyContent(detail.value)"> <img src="assets/images/copy.png"> {{detail?.value}}
                      
                      </div>
                      </td>
                    </tr>
                    
                   </ng-container>
                  </tbody>
                </table>
                <table *ngIf="payLength == 0">
                  <div style="color: #000;">No Data Found.</div>
                </table>
              </div>
            </div>
            <div class="utr-amount-details" *ngIf="payMethodList?.length>0">
              <div class="left-content">
                <div class="amount-bg">
                  <div class="amount input-field">
                    <input type="text" [(ngModel)]="reference_no" [ngModelOptions]="{standalone: true}" minlength="10"
                      class="validate" placeholder="Enter Reference No" min="10">
                  </div>
                  <div class="center-align add-amount"> reference_no
                    <strong></strong> minimum <strong>10</strong> required
                  </div>
                </div>


                <div class="amount-bg">
                  <div class="amount input-field">
                    <div class="center-align add-amount" *ngIf="message" style="color:red">
                     {{message}}
                    </div>
                    <input type="number" [(ngModel)]="amount" [ngModelOptions]="{standalone: true}" (change)="validateMinMax($event)" maxlength="8"
                      class="validate" placeholder="Enter Amount" min="0">
                  </div>
                  <div class="center-align add-amount" *ngIf="paymentData"> you can add upto
                    <strong>{{paymentData?.max_amount}},</strong> minimum <strong>{{paymentData?.min_amount}}</strong> required
                  </div>
                </div>
              </div>
              <div class="right-content pay-details">
                <div class="upload_ss">
                  <form novalidate="" class="choose-file mb-15 ng-untouched ng-pristine ng-valid">
                    <div class="file-field upload-id">
                      <div class="btn">
                        <i aria-hidden="true" class="fa fa-times-circle-o closeIcon" style="font-size: 30px;"
                          *ngIf="file != undefined" (click)="close()"></i>
                        <span *ngIf="file == undefined"><a><i class="fas fa-plus"></i></a></span>
                        <span class="upload-title" *ngIf="file == undefined">Click here to upload Payment
                          Screenshot</span>
                        <input type="file" class="form-control selection-option" accept="image/*" #imageInput
                          name="image" (change)="processFile(imageInput)" *ngIf="file == undefined">
                        <img id="blah" [src]="imageSrc || ''" alt="your image" *ngIf="file != undefined">
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="user-warning-note">
              <div class="title" *ngIf="payMethodList?.length==0"><i class="fas fa-exclamation-triangle" ></i> No deposit allowed contact upline.. </div>
              <div class="title"><i class="fas fa-exclamation-triangle"></i> note: </div>
              <div class="note-content"> Our bank accounts or payment details keep changing check the payment details
                before depositing. If you deposited in other account witch is not currently listed on site then we are
                not responsible for your loss. </div>
            </div>
          </div>
        </form>
      </div>
      <div class="submit-div center-align" *ngIf="payMethodList?.length>0">
        <a class="btn-success btn" (click)="paymentDeposit()">
          Submit
        </a>
      </div>
      <div class="data_table">
        <table class="dataTable no-footer">
          <tr>
            <th>S No.</th>
            <th>Date</th>
            <th>Status</th>
            <th>Amount</th>
          </tr>

          <tr *ngFor="let p of statement_data;let i=index;">
            <td>{{i+1}}.</td>
            <td>{{p?.createdAt|date:'medium'}}</td>
            <td>{{p?.approval_status}}</td>
            <td>{{p?.amount}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #exposureModal>
  <div class="modal-body">
    <a class="modal-close right close-icon" data-bs-dismiss="modal" aria-label="Close" (click)="modalRef.hide()"><i
        aria-hidden="true" class="fa fa-times"></i></a>
    <div class="change-password-content">
      <h5>Exposure List</h5>
      <div class="bet-list table-responsive" *ngIf="expoLength != 0">
        <table class="dataTable">
          <thead>
            <tr>
              <th>S.No.</th>
              <th>MARKET</th>
              <th>EXPOSURE</th>
            </tr>
          </thead>
          <tbody *ngFor="let data of exposureData;let i = index;">
            <tr *ngIf="i < (expoLength-1)">
              <td>
                <b>{{i+1}}</b>
              </td>
              <td>{{data?.sport_name}} - {{data?.series_name}} - {{data?.match_name}} - {{data?.event_name}}</td>
              <td [ngClass]="{'loss':data.liability < 0,'profit':data.liability >= 0}">{{data?.liability}}</td>
            </tr>
            <tr *ngIf="i == (expoLength-1)">
              <td></td>
              <td>Total</td>
              <td [ngClass]="{'loss':data.liabilitySum < 0,'profit':data.liabilitySum >= 0}">{{data?.liabilitySum}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #changePwd>
  <div class="modal-body">
    <a class="modal-close right close-icon" data-bs-dismiss="modal" aria-label="Close" (click)="modalRef.hide()"><i
        aria-hidden="true" class="fa fa-times"></i></a>
    <div class="change-password-content">
      <h5>Change Password</h5>
      <form class="col s12 p-0" [formGroup]='changePasswordForm'>
        <div class="row ml-0 mr-0">
          <div class="col s12">
            <div class="input_field pass_field">
              <input [type]="showOldPassword ? 'text' : 'password'" class="form_control" formControlName='old_password'>
              <label for="myId" class="input_label">Old Password</label>
              <i alt="show" class="fa fa-eye eye-show" (click)="showOldPassword = !showOldPassword"
                [class.hide]="showPassword"></i>
              <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showOldPassword = !showOldPassword"
                [class.hide]="!showPassword"></i>
            </div>
            <div class="alert_base" *ngIf="submitted && f.old_password.status == 'INVALID'">
              <span>* Please enter old password</span>
            </div>
          </div>
        </div>
        <div class="row ml-0 mr-0">
          <div class="col s12">
            <div class="input_field pass_field">
              <input [type]="showNewPassword ? 'text' : 'password'" class="form_control" formControlName='new_password'>
              <label for="myId1" class="input_label">New Password</label>
              <i alt="show" class="fa fa-eye eye-show" (click)="showNewPassword = !showNewPassword"
                [class.hide]="showPassword"></i>
              <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showNewPassword = !showNewPassword"
                [class.hide]="!showPassword"></i>
            </div>
            <div class="alert_base" *ngIf="submitted && f.new_password.status == 'INVALID'">
              <span>* Please enter new password</span>
            </div>
          </div>
        </div>
        <div class="row ml-0 mr-0">
          <div class="col s12">
            <div class="input_field pass_field">
              <input [type]="showConfirmPassword ? 'text' : 'password'" class="form_control"
                formControlName='confirm_password'>
              <label for="myId2" class="input_label">Confirm Password</label>
              <i alt="show" class="fa fa-eye eye-show" (click)="showConfirmPassword = !showConfirmPassword"
                [class.hide]="showPassword"></i>
              <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showConfirmPassword = !showConfirmPassword"
                [class.hide]="!showPassword"></i>
            </div>
            <div class="alert_base" *ngIf="submitted && f.confirm_password.status == 'INVALID'">
              <span>* Please enter confirm password</span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="modal-footer">
    <div class="row submit-btn">
      <a (click)="onSubmitChangePassword()" class="btn-success btn mr-10">
        <span class="animate-btn">Change</span>
      </a>
      <a class="btn-danger btn" (click)="modalRef.hide()">
        <span class="animate-btn">Cancel</span>
      </a>
    </div>
  </div>
</ng-template>
<ng-template #stackModal>
  <a class="modal-close right close-icon" data-bs-dismiss="modal" aria-label="Close" (click)="modalRef.hide()"><i
      aria-hidden="true" class="fa fa-times"></i></a>
  <h5><span class="card-title">
      <img alt="" style="display: none;"
        src="assets/images/background.jpeg">
      <span class="title"> Edit Stake </span></span>
  </h5>
  <div class="modal-body">
    <dl class="stake">
      <dd *ngFor="let stack of userMatchStack?.match_stack;let k=index;trackBy:trackByFn" class="input_field">
        <input type="text" [value]="stack" class="form_control" [(ngModel)]="match_stack[k]" name="stack_value"
          [ngModelOptions]="{standalone: true}">
      </dd>
    </dl>
  </div>

  <div class="modal-footer">
    <div class="row submit-btn">
      <a (click)="updateUserMatchStack()" class="btn-success btn mr-10">
        <span class="animate-btn">Save</span>
      </a>
      <a class="btn-danger btn" (click)="modalRef.hide()">
        <span class="animate-btn">Cancel</span>
      </a>
    </div>
  </div>
</ng-template>