<aside class="left_side_bar">
  <ul class="main_menu_div">
    <li>
      <a (click)="getAllSportsListData()">
        All sports <img src="assets/images/sidebar-icon.png">
      </a>
      <div class="sport_list">
        <div class="sport-name">
          <ng-container *ngFor="let sports of allSportsList;let i= index">
            <div class="list_menu" *ngIf="sports?.sport_id > 0">
              <a (click)="getSeriesList(sports.sport_id)">
                {{sports.name}}
              </a>
              <i class="fa fa-angle-down" (click)="getSeriesList(sports.sport_id)"
                *ngIf="sportData != sports?.sport_id"></i>
              <i class="fa fa-angle-up" (click)="closeSportList()" *ngIf="sportData == sports?.sport_id"></i>
              <ng-container *ngIf="sportData == sports?.sport_id">
                <div class="eventleague" *ngFor="let series of allSeriesList;let j= index">
                  <a (click)="getMatchList(series, j);openSeries(series)">{{series.name}}
                  </a>
                  <i class="fa fa-angle-down" (click)="getMatchList(series, j)"
                    *ngIf="seriesData != series.series_id"></i>
                  <i class="fa fa-angle-up" (click)="close()" *ngIf="seriesData == series.series_id"></i>
                  <ng-container *ngIf="seriesData == series.series_id">
                    <div class="match-name" *ngFor="let match of matchList[j];let k= index">
                      <a (click)="matchDetail(match)">
                        {{match.match_name}}
                      </a>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </li>
  </ul>

  <div class="left-tab">
    <ul class="nav nav-tabs nav-justified" id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" id="provider-tab" data-bs-toggle="tab" (click)="providers()" role="tab"
          aria-controls="provider" aria-selected="true">provider</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="provider-tab" data-bs-toggle="tab" (click)="liveCasino()" role="tab"
          aria-controls="live" aria-selected="false">live</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="provider-tab" data-bs-toggle="tab" (click)="virtual()" role="tab"
          aria-controls="virtual" aria-selected="false">virtual</a>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="provider" role="tabpanel" *ngIf="showProviders == false">
        <div class="sidebar_thumb" *ngFor="let list of casino;let i = index;" (click)="gotoexchangeGame(list)">
          <img src="assets/Casino/{{list?.match_id}}.jpg" style="height: 90px;">
        </div>
      </div>
      <div class="tab-pane fade active" id="provider" role="tabpanel" *ngIf="showProviders == true">
        <div class="sidebar_thumb" (click)="aviator()">
          <img src="assets/img/SPB-aviator.png" style="height: 90px;">
        </div>
      </div>
    </div>

    <ul class="nav nav-tabs nav-justified promotion-tab" id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link" id="promotions-tab" data-bs-toggle="tab" href="#promotions" role="tab"
          aria-controls="promotions" aria-selected="false">promotions</a>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="betslip" role="tabpanel">
        <div class="sidebar_thumb">
          <img src="assets/images/thumb/p1.png">
        </div>
      </div>
      <div class="tab-pane fade" id="activity" role="tabpanel">
        <div class="sidebar_thumb">
          <img src="assets/images/thumb/p1.png">
        </div>
      </div>
      <div class="tab-pane fade" id="promotions" role="tabpanel">
        <div class="sidebar_thumb">
          <img src="assets/images/thumb/p1.png">
        </div>
      </div>
    </div>
  </div>
</aside>
<div class="sidebarBackdrop"></div>