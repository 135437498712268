<app-header></app-header>
<div class="content_area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3 left_bar">
                <app-left-side-bar></app-left-side-bar>
            </div>

            <div class="col-md-9 conetnt_col">
                <div class="promoting_tabs reporting_tabs">
                    <app-report-header></app-report-header>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="open" role="tabpanel"
                            aria-labelledby="pills-contact-tab">
                            <div class="account_title">
                                <h4 class="card-title">Market Profit-Loss</h4>
                            </div>
                            <div class="data_table">
                                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>