import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { HeaderComponent } from '../header/header.component';
import { SportService } from '../services/sport.service';
import { OpenApiService } from '../services/open-api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { UsersService } from '../services/users.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [HeaderComponent]
})
export class FooterComponent implements OnInit {
  logo: string;
  modalRef: BsModalRef;
  userDetails: any;
  homeData: any;
  userMatchStack: any;
  match_stack: any[] = [];
  public showOldPassword: boolean = false;
  public showNewPassword: boolean = false;
  public showConfirmPassword: boolean = false;
  changePasswordForm: FormGroup;
  submitted = false;
  facebook:any;
  insta:any;
  wa:any;
  telegram:any;
  support_no:any;
  twitter:any;
  public Base_Url = environment['adminServerUrl'];
  domainName: string;
  constructor(private openApiService:OpenApiService,private sportService: SportService, private modalService: BsModalService, private cookie: CookieService,
    private usersService: UsersService, private toastr: ToastrService, private router: Router, private fb: FormBuilder, private head: HeaderComponent) { }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.logo = localStorage.getItem('logo')
    this.createChangePasswordFrom();
    this.getSocialMedia()
  }
  createChangePasswordFrom() {
    this.changePasswordForm = this.fb.group({
      old_password: ['', [Validators.required]],
      new_password: ['', [Validators.required]],
      confirm_password: ['', [Validators.required]]
    })
  }
  get f() { return this.changePasswordForm.controls; }

  onSubmitChangePassword() {
    this.submitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }
    let data = {
      "newPassword": this.changePasswordForm.value.new_password
    }
    if (this.changePasswordForm.controls['new_password'].value == this.changePasswordForm.controls['confirm_password'].value) {
      this.usersService.changePassword(data).subscribe((result) => {
        if (result.status == true) {
          localStorage.setItem('userDetails', JSON.stringify(this.userDetails));
          this.submitted = false;
          this.toastr.success(result.msg, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          });
          this.modalRef.hide();
          this.logoutUser();
        } else {
          if (result.logout == true) {
            this.cookie.delete('userId');
            this.cookie.delete('accessToken');
            this.cookie.delete('refreshToken');
            localStorage.clear();
            this.router.navigate(['open-dashboard']);
            window.location.reload();
            window.location.replace('open-dashboard');
          } else {
            this.toastr.error(result.msg, '', {
              timeOut: 10000,
            });
          }
        }
      }, (err) => {
        console.log(err);
      });
    }
    else {
      this.toastr.error("Password and confirm password did not match", '', {
        timeOut: 10000,
      });
    }
  }
  statement() {
    this.router.navigate(['statement'])
  }
  profile() {
    this.router.navigate(['profile'])
  }
  bet() {
    this.router.navigate(['open-bet'])
  }
  sbet() {
    this.router.navigate(['settle-bet'])
  }
  withdraw() {
    this.router.navigate(['withdraw'])
  }
  transaction() {
    this.router.navigate(['transaction'])
  }
  openModalSearchBox(searchModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      searchModal,
      Object.assign({}, { class: 'search-modal', ignoreBackdropClick: false }),
    );
  }
  dashboard(data) {
    if (data == 1) {
      this.router.navigate(['dashboard'])
    } else if (data == 2) {
      this.router.navigate(['dashboard/inplay'])
    } else if (data == 3) {
      this.router.navigate(['promo'])
    } else if (data == 4) {
      this.router.navigate(['qtech'])
    } else if (data == 5) {
      this.toastr.error('Coming Soon ...')
    }
  }
  logoutUser() {
    this.head.logoutUser();
  }
  toggleBigTabStatus: boolean = false;
  toggleBigTab() {
    this.toggleBigTabStatus = !this.toggleBigTabStatus;
  }
  homematches() {
    let data = {}
    this.sportService.getHomeMatchesList(data).subscribe(res => {
      if (res.status) {
        this.homeData = res.data;
      } else {
        this.toastr.error(res.msg, '', {
          timeOut: 10000,
        });
      }
    }, (err) => {
      console.log(err);
    })
  }
  openModalChangePass(changePwd: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      changePwd,
      Object.assign({}, { class: 'modal-fixed-footer change-password', ignoreBackdropClick: false }),
    );
  }
  openModalStackModal(stackModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      stackModal,
      Object.assign({}, { class: 'login_page stack-edit', ignoreBackdropClick: false }),
    );
  }
  getUserMatchStackDetails() {
    this.sportService.getUserMatchStack(this.userDetails._id).subscribe(response => {
      if (response.status == true) {
        this.userMatchStack = response.data;
        this.match_stack = response.data.match_stack
      } else {
        this.toastr.error(response.msg);
      }
    })
  }
 
  getSocialMedia() {
    let domain = window.location.hostname
    let params={host_name:domain}
    this.openApiService.getSocialMedia(params).subscribe(response => {
      if (response.status == true) {
        if(!response?.data)return;
      this.facebook = '';
      this.insta = 'https://www.instagram.com/'+response?.data?.instagram;
      this.telegram = 'https://telegram.me/'+response?.data?.telegram;
      this.wa = 'https://api.whatsapp.com/send/?phone='+response?.data?.whatsapp+'&text=Hi&type=phone_number&app_absent=0';
      this.support_no = 'https://api.whatsapp.com/send/?phone='+response?.data?.support_no+'&text=Hi&type=phone_number&app_absent=0';
    
      
      this.twitter = 'https://twitter.com/'+response?.data?.twitter;
      } else {
        this.toastr.error(response.msg);
      }
    })
  }


  updateUserMatchStack() {
    let stackRequest = {
      "match_stack": this.match_stack
    }
    this.sportService.updateUserMatchStack(stackRequest).subscribe(result => {
      if (result.status == true) {
        this.toastr.success(result.msg, '', {
          positionClass: 'toast-bottom-right',
          timeOut: 1000
        });
        this.modalRef.hide();
      } else {
        this.toastr.error(result.msg, '', {
          timeOut: 10000,
        });
      }
    })
  }
  trackByFn(index: any, item: any) {
    return index;
  }
  DownloadApk(data) {
    let c = (this.domainName).split('//')
    debugger
    let a = btoa(c[1] + data);
    let b = this.Base_Url + 'content/download-mobile-app?key=' + a;
    window.open(b, '_blank')
  }
}
