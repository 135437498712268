import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DataTablesModule } from 'angular-datatables';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { SearchPipe } from './pipes/search.pipe';
import { OrderByPipe } from './order-by.pipe';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DashboardComponent } from './dashboard/dashboard.component'
import { HeaderComponent } from './header/header.component';
import { RightSideBarComponent } from './right-side-bar/right-side-bar.component';
import { LeftSideBarComponent } from './left-side-bar/left-side-bar.component';
import { MatchDetailComponent } from './match-detail/match-detail.component';
import { CasinoDetailComponent } from './casino-detail/casino-detail.component';
import { CasinoComponent } from './casino/casino.component';
import { StatementComponent } from './statement/statement.component';
import { MyBetsComponent } from './my-bets/my-bets.component';
import { OpenHeaderComponent } from './open-header/open-header.component';
import { OpenLeftSidebarComponent } from './open-left-sidebar/open-left-sidebar.component';
import { OpenRightSidebarComponent } from './open-right-sidebar/open-right-sidebar.component';
import { PromotionComponent } from './promotion/promotion.component';
import { OpenHomeComponent } from './open-home/open-home.component';
import { OpenDashboardComponent } from './open-dashboard/open-dashboard.component';
import { OpenMatchDetailComponent } from './open-match-detail/open-match-detail.component';
import { Match } from '../app/model/match';
import { ProfileComponent } from './profile/profile.component';
import { WithdrawComponent } from './withdraw/withdraw.component';
import { ReportHeaderComponent } from './report-header/report-header.component';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { FooterComponent } from './footer/footer.component';
import { OpenPromotionComponent } from './open-promotion/open-promotion.component';
import { OpenFooterComponent } from './open-footer/open-footer.component';
import { OpenCasinoComponent } from './open-casino/open-casino.component';
import { OpenNewsComponent } from './open-news/open-news.component';
import { ViewBetComponent } from './view-bet/view-bet.component';
import { SettleBetComponent } from './settle-bet/settle-bet.component';
import { PlSportsComponent } from './pl-sports/pl-sports.component';
import { PlMatchComponent } from './pl-match/pl-match.component';
import { PlMarketComponent } from './pl-market/pl-market.component';
import { PlUserBetComponent } from './pl-user-bet/pl-user-bet.component';
import { PlUserBetSnComponent } from './pl-user-bet-sn/pl-user-bet-sn.component';
export const MY_CUSTOM_FORMATS = {
  fullPickerInput: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' },
  datePickerInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
  timePickerInput: { hour: 'numeric', minute: 'numeric' },
  monthYearLabel: { year: 'numeric', month: 'short' },
  dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
  monthYearA11yLabel: { year: 'numeric', month: 'long' },
};
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HeaderComponent,
    RightSideBarComponent,
    LeftSideBarComponent,
    MatchDetailComponent,
    CasinoDetailComponent,
    CasinoComponent,
    StatementComponent,
    MyBetsComponent,
    OpenHeaderComponent,
    OpenLeftSidebarComponent,
    OpenRightSidebarComponent,
    PromotionComponent,
    OpenHomeComponent,
    OpenDashboardComponent,
    OpenMatchDetailComponent,
    ProfileComponent,
    WithdrawComponent,
    ReportHeaderComponent,
    TransactionHistoryComponent,
    FooterComponent,
    OrderByPipe,
    SearchPipe,
    OpenPromotionComponent,
    OpenFooterComponent,
    OpenCasinoComponent,
    OpenNewsComponent,
    ViewBetComponent,
    SettleBetComponent,
    PlSportsComponent,
    PlMatchComponent,
    PlMarketComponent,
    PlUserBetComponent,
    PlUserBetSnComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    FormsModule, NgbModule,
    DataTablesModule,
    DatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    // DatetimePopupModule,
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot(),
    HttpClientModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    NgBootstrapFormValidationModule.forRoot(),
    NgBootstrapFormValidationModule,
    NgHttpLoaderModule.forRoot(),
    MatTableModule,
    MatExpansionModule,
    MatMenuModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatIconModule,
    MatPaginatorModule,
    NgSelectModule,
  ],
  providers: [CookieService, Match],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
