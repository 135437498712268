<div class="sidebar-div">
  <div class="right_sidebar">
    <div class="main_right_sidebar">
      <div class="promotion-tab">
        <ul class="nav tabs" id="myTab" role="tablist">
          <li class="tab" role="presentation" (click)="openBets()">
            <a id="home-tab" data-bs-toggle="tab" data-bs-target="#home" role="tab" aria-controls="home"
              aria-selected="true">Betlist</a>
          </li>
          <li class="tab" role="presentation">
            <a id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" role="tab" aria-controls="profile"
              aria-selected="false">Recent Activity</a>
          </li>
          <li class="tab" role="presentation">
            <a class="active" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" role="tab"
              aria-controls="contact" aria-selected="false">Promotions</a>
          </li>
        </ul>
        <div class="tab_content">
          <div class="tab-pane fade " id="home" role="tabpanel" aria-labelledby="home-tab">
            <div class="empty-betlist" *ngIf="dataSourceLength == 0">
              <img src="assets/images/no-betlist.png" alt="">
              <div class="title">Your Betlist is empty</div>
              <div class="title2">Click on odds to add a bet to the betlist</div>
            </div>

            <div class="bet-list" *ngIf="dataSourceLength != 0">
              <ul class="top-list">
                <li class="bet-type">Matched Bet</li>
                <li class="avg-odd">Average Odds</li>
              </ul>
              <ng-container *ngFor="let betList of betDataList | keyvalue;let i = index;">
                <div class="selection-text">{{betList?.key}}</div>
                <ul>
                  <li>{{matchName}}</li>
                </ul>
                <table class="table">
                  <thead>
                    <tr>
                      <th>Selection</th>
                      <th>Odds</th>
                      <th>Stake</th>
                      <th>P/L</th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let list of betList.value;let j = index;">
                    <tr class="lay" [ngClass]="{'lay':list.is_back == 0,'back':list.is_back == 1}">
                      <td>
                        <b>{{list?.selection_name}}</b>
                        <span>{{list?.createdAt | date :'dd-MM-yyyy HH:mm:ss'}}</span>
                      </td>
                      <td>{{list?.odds}}<span *ngIf="list?.is_fancy ==1">/{{list?.size}}</span></td>
                      <td>{{list?.stack}}</td>
                      <td [ngClass]="{'loss':list.p_l < 0,'profit':list.p_l >= 0}">{{list?.p_l | number : '0.0-2'}}</td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
            </div>
          </div>
          <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
            <div class="empty-betlist">
              <img src="assets/images/no-betlist.png" alt="">
              <div class="title">There is no recent activity</div>
              <div class="title2">&nbsp;</div>
            </div>
          </div>
          <div class="tab-pane fade show active" id="contact" role="tabpanel" aria-labelledby="contact-tab"
            (click)="aviator()">
            <a><img src="assets/images/refer.png" alt=""></a>
          </div>
        </div>
      </div>
      <div class="vartual_tab">
        <div class="vartual_tab_link promotion-tab">
          <ul class="nav tabs">
            <li class="tab show" (click)="providers()">
              <a class="no-border">Providers</a>
            </li>
            <li class="tab" (click)="liveCasino()">
              <a class=" active">Live</a>
            </li>
            <li class="tab" (click)="virtual()">
              <a class="no-border">Virtual</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="game_slider tab-content">
        <div class="gameitem_wrapper" *ngIf="showProviders == false">
          <div class="game_item" *ngFor="let list of casino;let i = index;" (click)="gotoexchangeGame(list)">
            <div class="game_thumb">
              <img src="assets/Casino/{{list?.match_id}}.jpg" alt="">
            </div>
            <div class="play_btn">
              <a>{{list?.name}}</a>
            </div>
          </div>
        </div>
        <div class="gameitem_wrapper" *ngIf="showProviders == true">
          <div class="game_item" (click)="aviator()">
            <div class="game_thumb">
              <img src="assets/img/SPB-aviator.png" alt="">
            </div>
            <div class="play_btn">
              <a>Aviator</a>
            </div>
          </div>
          <div class="game_item" (click)="dashboardCasino()">
            <div class="game_thumb">
              <img src="assets/img/casino.png" alt="">
            </div>
            <div class="play_btn">
              <a>Casino</a>
            </div>
          </div>
          <div class="game_item" (click)="qtech('EZU')">
            <div class="game_thumb" style="background-color: cornsilk;">
              <img src="assets/img/ezugi.png" alt="">
            </div>
            <div class="play_btn">
              <a>Ezugi</a>
            </div>
          </div>
          <div class="game_item" (click)="qtech('EVO')">
            <div class="game_thumb">
              <img src="assets/img/evolution.png" alt="">
            </div>
            <div class="play_btn">
              <a>Evolution</a>
            </div>
          </div>
          <div class="game_item" (click)="qtech('SAG')">
            <div class="game_thumb" style="background-color: cornsilk;">
              <img src="assets/img/sexyCasino.png" alt="">
            </div>
            <div class="play_btn">
              <a>SexyCasino</a>
            </div>
          </div>
          <div class="game_item" (click)="qtech('EBT')">
            <div class="game_thumb">
              <img src="assets/img/ebet.png" alt="">
            </div>
            <div class="play_btn">
              <a>Ebet</a>
            </div>
          </div>
          <div class="game_item" (click)="qtech('BTL')">
            <div class="game_thumb">
              <img src="assets/img/beterlive.png" alt="">
            </div>
            <div class="play_btn">
              <a>Beterlive</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>