import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpHeaderResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
//import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public Base_Url = environment['adminServerUrl'];
  public token = localStorage.getItem('userAccessToken');

  logInHeader = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': 'Basic YXBwbGljYXRpb246c2VjcmV0'
  });
  constructor(private http: HttpClient, private cookie: CookieService) { }

  submitlogin(loginInfo) {
    localStorage.removeItem('user_name');
    localStorage.removeItem('password');
    localStorage.removeItem('RememberMe');
    if (loginInfo.isRememberMe) {
      localStorage.setItem('user_name', loginInfo.user_name);
      localStorage.setItem('password', loginInfo.password);
      localStorage.setItem('RememberMe', JSON.stringify(loginInfo.isRememberMe));
    }
    let body = new HttpParams()
      .set('user_name', loginInfo.user_name.toLowerCase())
      .set('password', loginInfo.password)
      .set('verify_domain', loginInfo.verify_domain)
      .set('grant_type', loginInfo.grant_type);

    return this.http.post<any>(this.Base_Url + 'user/userLogin', body, { headers: this.logInHeader });
  }


  logoutUser(param) {
    this.token = localStorage.getItem('userAccessToken');
    let reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.token
    });
    return this.http.post<any>(this.Base_Url + 'user/logout', param, { headers: reqHeader });
  }
  getLogo(data) {
    return this.http.post<any>(this.Base_Url + 'content/getLogo', data);
  }
  loginDemo(data) {
    return this.http.post<any>(this.Base_Url + 'user/demoUserLogin', data);
  }
  register(data) {
    return this.http.post<any>(this.Base_Url + 'user/register', data);
  }
  clearLocalStorage() {
    let exclude = ["user_name", "password", "RememberMe"]
    for (var i = 0; i < localStorage.length; i++) {
      var key = localStorage.key(i);
      if (exclude.indexOf(key) === -1) {
        localStorage.removeItem(key);
      }
    }
  }

}
