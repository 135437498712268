import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-open-promotion',
  templateUrl: './open-promotion.component.html',
  styleUrls: ['./open-promotion.component.scss']
})
export class OpenPromotionComponent implements OnInit {
  banner: any;
  constructor() { }

  ngOnInit(): void {
    this.banner = JSON.parse(localStorage.getItem('Banner'))
  }


}
