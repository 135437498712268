<app-header></app-header>
<div class="content_area">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 left_bar">
        <app-left-side-bar></app-left-side-bar>
      </div>
      <div class="col-md-9 conetnt_col">
        <div class="matchDetail-header">
          <div class="row">
            <div class="col-md-6">
              <div class="event_lists">
                <p class="event-name">{{matchData?.match_name}}</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="inner-right">
                <div class="event-timer">
                  <ng-container *ngIf="matchData?.inplay == false && timerInplayStatus == false">
                    <div class="coundown">
                      <div class="coundown_number">
                        <span>{{countdown?.days}}</span>
                        <h6>Days</h6>
                      </div>
                      <div class="coundown_number">
                        <span>{{countdown.hours}}</span>
                        <h6> Hours </h6>
                      </div>
                      <div class="coundown_number">
                        <span>{{countdown.minutes}}</span>
                        <h6> Minutes </h6>
                      </div>
                      <div class="coundown_number">
                        <span>{{countdown.seconds}}</span>
                        <h6> Seconds </h6>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="matchData?.inplay == true || timerInplayStatus == true">
                    <div class="coundown live-match-title">
                      <div class="live-match">
                        <i class="fa fa-circle allow"></i> Live
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div class="event-date" *ngIf="matchData?.inplay  == true">
                  <div class="date-details">
                    <span class="date-title"> Today </span>
                    <span><i _ngcontent-vpn-c83="" class="far fa-clock"></i></span>
                    <span> {{matchData?.match_date | date :'HH:mm'}} </span>
                  </div>
                </div>
                <div class="header-icon">
                  <ul>
                    <li>
                      <a (click)="toggleLiveTV()"><img src="assets/images/icon-streaming.png" alt=""></a>
                    </li>
                    <li>
                      <a (click)="toggleLiveScore()"><img src="assets/images/icon-scoreboard.png" alt=""></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- scoarecard -->
        <div class="scoarecard" *ngIf="graph == true">
          <div class="row">
            <div class="col-md-12">
              <div [ngClass]="(toggleLiveScoreStatus || inplayStatus) ? 'showScore' : 'hideScore'"
                *ngIf="graph == true">
                <iframe allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" width="100%"
                  height="200" scrolling="auto" style="border: none;" [src]="graphicTvUrl"></iframe>
              </div>
            </div>
          </div>
        </div>
        <!-- scoarecard -->
        <!-- live TV Mobile -->
        <div class="mobile-liveTv" [ngClass]="toggleLiveTVStatus ? 'showTv' : 'hideTv'">
          <iframe id="tvIframe" [src]="liveUrl" style=" height: 240px !important;width: 100% !important;" (load)="onIframeLoad($event)"></iframe>
        </div>
        <!-- Live Tv Mobile -->
        <div class="detail_data">
          <ul class="nav nav-pills my-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <a class="nav-link active" id="pills-all-tab" data-bs-toggle="pill" data-bs-target="#pills-all"
                type="button" role="tab" aria-controls="pills-all" aria-selected="true">all</a>
            </li>
            <li class="nav-item hide-on-desktop" role="presentation">
              <a class="nav-link" id="pills-betList-tab" data-bs-toggle="pill" data-bs-target="#pills-betList"
                type="button" role="tab" aria-controls="pills-betList" aria-selected="false">My Bet</a>
            </li>
          </ul>
        </div>
        <div class="row mx-0">
          <div class="col-md-9 px-0 detail_data">
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab">

                <div class="market">
                  <div class="card" *ngFor="let matchOddsData of matchDetail;let mDTIndex=index;">
                    <div class="card-header ">
                      <img src="assets/images/icon-dot-circle.png"> {{matchOddsData?.market_name}}

                      
                      <span>
                       <!--
                      <em> Matched : ({{(matchOddsData?.matched) != undefined ? (matchOddsData?.matched|number : '1.2-2') : '0'}})
                        </em>
                       -->
                     
                       <label  (click)="CashOut(matchOddsData)">
                        <label class="cashout"> Cashout
                        </label>
                       
                      </label> 
                        <img src="assets/images/icon-info.png" (click)="openModalCondition(Terms)">
                      </span>
                      
                    
                    </div>
                    <div class="match-class">&nbsp;&nbsp;&nbsp; Matched : ({{(matchOddsData?.matched) != undefined ? (matchOddsData?.matched) : '0'}})
                      &nbsp; &nbsp;</div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-5 col-7">
                          <div class="minMax-limit">
                            <p>Stake Limit: <span>{{matchOddsData?.min_stake}}-{{matchOddsData?.max_stake}}</span></p>
                            <p>Max Profit: <span>{{matchOddsData?.max_profit}}</span></p>
                          </div>
                        </div>
                        <div class="col-md-7 col-5">
                          <div class="odds_section odds_head">
                            <button class="d-sm-inline-block d-none"></button>
                            <button class="d-sm-inline-block d-none"></button>
                            <button class="back">back</button>
                            <button class="lay">lay</button>
                            <button class="d-sm-inline-block d-none"></button>
                            <button class="d-sm-inline-block d-none"></button>
                          </div>
                        </div>
                      </div>
                      <div class="row" *ngFor="let runner of matchOddsData.runners; let i = index;trackBy: trackHero">
                        <div class="col-md-5 col-7">
                          <div class="event_name">
                            <p>{{runner?.selection_name}}</p>
                            <span
                              [ngClass]="{'loss':(runner.win_loss)<0,'profit':(runner.win_loss)>=0}">({{(runner.win_loss)
                              != 0 ? ((runner.win_loss)| number : '0.0-2' ) : '0'}}) &nbsp;</span>
                            <span *ngIf="betslipStatus == true && market == matchOddsData.market_id"
                              [ngClass]="{'loss':(runner.win_loss+ this.matchModel.calculateProfitLossSelection(runner.selectionId,matchOddsData.market_id))<0,'profit':(runner.win_loss+ this.matchModel.calculateProfitLossSelection(runner.selectionId,matchOddsData.market_id))>0}">->
                              ({{(runner.win_loss+this.matchModel.calculateProfitLossSelection(runner.selectionId,matchOddsData.market_id))|
                              number : '0.0-2'}})</span>
                          </div>
                        </div>
                        <div class="col-md-7 col-5" style="position: relative;">
                          <div class="odds_section" [ngClass]="{'suspended':matchOddsData?.status == 'SUSPENDED'}">
                            <button class="back1" *ngIf="runner?.ex?.availableToBack?.length == 3"
                              [ngClass]="runner?.ex?.availableToBack[2]
                            !=undefined ? addClassWhenOddChange(runner?.ex?.availableToBack[2]?.price,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToBack[2].price,i,'back') : ''">
                              {{(runner?.ex?.availableToBack[2]
                              !=undefined?runner?.ex?.availableToBack[2]?.price
                              :'')}}
                              <span>{{(runner?.ex?.availableToBack[2]
                                !=undefined?runner?.ex?.availableToBack[2]?.size
                                :'')}}</span>
                            </button>
                            <button class="back1" *ngIf="runner?.ex?.availableToBack?.length != 3">
                              --
                              <span>--</span>
                            </button>
                            <button class="back1" *ngIf="runner?.ex?.availableToBack?.length != 1"
                              [ngClass]="runner?.ex?.availableToBack[1]
                            !=undefined ? addClassWhenOddChange(runner?.ex?.availableToBack[1]?.price,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToBack[1].price,i,'back') : ''">
                              {{(runner?.ex?.availableToBack[1]
                              !=undefined?runner?.ex?.availableToBack[1]?.price
                              :'')}}
                              <span>{{(runner?.ex?.availableToBack[1]
                                !=undefined?runner?.ex?.availableToBack[1]?.size
                                :'')}}</span>
                            </button>
                            <button class="back1" *ngIf="runner?.ex?.availableToBack?.length == 1">
                              --
                              <span>--</span>
                            </button>
                            <button class="back" *ngIf="runner?.ex?.availableToBack?.length > 0"
                              (click)="getOddsValue((runner.ex.availableToBack[0].price),runner.ex.availableToBack[0].size,1,runner.name,runner.selectionId,matchOddsData.market_id,i,matchOddsData,0)"
                              [ngClass]="runner?.ex?.availableToBack[0]
                            !=undefined ? addClassWhenOddChange(runner?.ex?.availableToBack[0]?.price,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToBack[0].price,i,'back') : ''">
                              {{(runner?.ex?.availableToBack[0]
                              !=undefined?runner?.ex?.availableToBack[0]?.price
                              :'')}}
                              <span>{{(runner?.ex?.availableToBack[0]
                                !=undefined?runner?.ex?.availableToBack[0]?.size
                                :'')}}</span>
                            </button>
                            <button class="back"
                              *ngIf="runner?.ex?.availableToBack?.length == undefined ||runner?.ex?.availableToBack?.length == 0">
                              --
                              <span>--</span>
                            </button>
                            <button class="lay" *ngIf="runner?.ex?.availableToLay?.length > 0"
                              (click)="getOddsValue((runner.ex.availableToLay[0]?.price),runner.ex.availableToBack[0].size,0,runner.name,runner.selectionId,matchOddsData.market_id,i,matchOddsData,0)"
                              [ngClass]="runner?.ex?.availableToLay[0]
                            !=undefined ? addClassWhenOddChange(runner?.ex?.availableToLay[0]?.price,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToLay[0]?.price,i,'lay') : ''">
                              {{(runner?.ex?.availableToLay[0]
                              !=undefined?runner?.ex?.availableToLay[0]?.price
                              :'')}}
                              <span>{{(runner?.ex?.availableToLay[0]
                                !=undefined?runner?.ex?.availableToLay[0]?.size
                                :'')}}</span>
                            </button>
                            <button class="lay"
                              *ngIf="runner?.ex?.availableToLay?.length == undefined || runner?.ex?.availableToLay?.length == 0">
                              --
                              <span>--</span>
                            </button>
                            <button class="lay1" *ngIf="runner?.ex?.availableToLay?.length != 1"
                              [ngClass]="runner?.ex?.availableToLay[1] !=undefined ? addClassWhenOddChange(runner?.ex?.availableToLay[1].price,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToLay[1].price,i,'lay') : ''">
                              {{(runner?.ex?.availableToLay[1]
                              !=undefined?runner?.ex?.availableToLay[1]?.price
                              :'')}}
                              <span>{{(runner?.ex?.availableToLay[1]
                                !=undefined?runner?.ex?.availableToLay[1]?.size
                                :'')}}</span>
                            </button>
                            <button class="lay1" *ngIf="runner?.ex?.availableToLay?.length == 1 ">
                              --
                              <span>--</span>
                            </button>
                            <button class="lay1" *ngIf="runner?.ex?.availableToLay?.length == 3"
                              [ngClass]="runner?.ex?.availableToLay[2]
                            !=undefined ? addClassWhenOddChange(runner?.ex?.availableToLay[2]?.price,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToLay[2]?.price,i,'lay') : ''"
                              (click)="getOddsValue((runner.ex.availableToLay[2]?.price),runner.ex.availableToBack[0].size,runner.ex.availableToLay[2].size,0,runner.name,runner.selectionId,matchOddsData.market_id,i,matchOddsData,0)">
                              {{(runner?.ex?.availableToLay[2]
                              !=undefined?runner?.ex?.availableToLay[2]?.price
                              :'')}}
                              <span>{{(runner?.ex?.availableToLay[2]
                                !=undefined?runner?.ex?.availableToLay[2]?.size
                                :'')}}</span>
                            </button>
                            <button class="lay1" *ngIf="runner?.ex?.availableToLay?.length != 3">
                              --
                              <span>--</span>
                            </button>
                          </div>
                          <div class="suspended suspend-runner"
                            *ngIf="runner?.status == 'SUSPENDED' && (matchOddsData.status != 'SUSPENDED')">
                            <div class="suspend-cont">Suspended</div>
                          </div>
                        </div>
                        <!-- placebet -->
                        <div class="col-md-12"
                          *ngIf="matchOddsData.market_id == market && runner.selectionId == selection">
                          <div class="row" id="loki"
                            [ngClass]="{'back-bg': arrayObj.is_back == 1 , 'lay-bg': arrayObj.is_back == 0, 'placebet' : arrayObj.is_back == 1 || arrayObj.is_back == 0}">
                            <ng-container *ngFor="let back of this.matchModel.tempArray;let index=index">
                              <div class="col-md-7 col-12">
                                <p> {{runner?.selection_name}}</p>
                              </div>
                              <div class="col-md-5">
                                <form class="d-flex">
                                  <div class="form-group">
                                    <label>{{arrayObj.is_back == 1 ? 'Back' : 'Lay'}} <img
                                        src="assets/images/icon-arrow-right.png"></label>
                                    <input type="text" value="{{back?.odds}}">
                                  </div>
                                  <div class="form-group">
                                    <label>Stack <img src="assets/images/icon-arrow-right.png"></label>
                                    <div class="stake-group">
                                      <span class="input-group-text"
                                        (click)="back.stake = back.stake - 100; calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);">-</span>
                                      <input type="number" class="oddinput" type="number" class="form-control" min="0"
                                        id="stackVal" (focus)="setbtn(index)" (click)="updateMarketTimer()"
                                        (input)="calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);"
                                        pattern="[0-9]*" step="1" id="stakeValue" formcontrolname="stake"
                                        placeholder="0" class="oddinput" [(ngModel)]="(back.stake < 0) ? 0 : back.stake"
                                        [ngModelOptions]="{standalone: true}">
                                      <span class="input-group-text"
                                        (click)="back.stake = back.stake + 100; calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);">+</span>
                                    </div>
                                  </div>
                                  <div class="form-group">
                                    <label>{{arrayObj.is_back == 1 ? 'Profit' : 'Exp'}}</label>
                                    <span *ngIf="arrayObj.is_back == 1 " style="color: green;">{{
                                      back.is_back == 0 ?
                                      back.stake
                                      :((back.odds*back.stake)-back.stake)=='NaN'?0:
                                      ((back.odds*back.stake)-back.stake)|
                                      number :
                                      '0.0-2'}}</span>
                                    <span *ngIf="arrayObj.is_back == 0" style="color: red;">-{{back.is_back
                                      == 0 ?
                                      ((back.odds*back.stake)-back.stake)=='NaN'?0:
                                      (((back.odds*back.stake)-back.stake)|
                                      number : '0.0-2') :
                                      back.stake}}</span>
                                  </div>
                                </form>
                              </div>

                              <div class="col-md-12 stakes">
                                <button *ngFor="let stake of userMatchStack | keyvalue;let k = index;"
                                  (click)="calculateP_lOnStack(((back.odds*back.stake)-back.stake),stake.value,back.is_back,back);">{{stake.value}}</button>
                              </div>
                              <div class="col-md-12 ccp_btns">
                                <button class="btn-cancel"
                                  (click)="this.matchModel.tempArray=[];clearAll();updateMarketTimer();matchRunner()">cancel</button>
                                <button class="btn-clear" (click)="back.stake=0;stake.value=0;">clear</button>
                                <button class="btn-placebet"
                                  (click)="saveBet(this.matchModel.tempArray,inplayStatus)">place bet</button>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                        <!-- placebet -->
                      </div>
                      <div class="row" *ngIf="matchOddsData?.news != undefined">
                        <div class="col-md-12 col-12 p-0">
                          <div class="news_feed">
                            <span><i class="fas fa-bell"></i></span>
                            <span class="title"> News : </span>
                            <marquee behavior="scroll" direction="left" onmouseout="this.start();"
                              onmouseover="this.stop();" scrolldelay="100" class="marquee-style">
                              <div class="marquee-news"><i class="fas fa-circle"></i><span class="marquee-news-text">
                                  {{matchOddsData?.news}} </span>
                              </div>
                              <div style="clear: both;"></div>
                            </marquee>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card fancy_market"
                    *ngIf="matchData?.sport_id == 4">
                    <div class="card-header">
                      <img src="assets/images/icon-dot-circle.png"> Fancy
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-9 col-7">
                        </div>
                        <div class="col-md-2 col-4">
                          <div class="odds_section odds_head">
                            <button class="lay">No</button>
                            <button class="back">Yes</button>
                          </div>
                        </div>
                        <div class="col-md-1">
                        </div>
                      </div>
                      <div class="row" *ngFor="let fancyData of fancyDataArray;let i = index;trackBy:trackByFn;">
                        <div class="col-md-7 col-5">
                          <div class="event_name">
                            {{fancyData?.name}}
                            <span class="loss" *ngIf="fancyData?.liability != undefined">{{fancyData?.liability !=
                              undefined ?
                              fancyData?.liability : '0.00'}}</span>
                          </div>
                        </div>
                        <div class="col-md-2 col-2 px-0">
                          <button class="bet" data-bs-toggle="modal" (click)="fancyPositionData(fancyData,fancyBook)"
                            *ngIf="fancyData?.liability != undefined">book</button>
                        </div>
                        <div class="col-md-2 col-4">
                          <div class="odds_section " [ngClass]="{'suspended':fancyData.display_message != ''}">
                            <button class="lay"
                              (click)="setSessionValue(fancyData,0,fancyData.session_value_no,fancyData.session_size_no)">
                              {{fancyData?.session_value_no == 'Ball' ||
                              fancyData?.session_value_no == '-' ||
                              fancyData?.session_value_no == '0' ? '0' :
                              fancyData?.session_value_no}}
                              <span>{{fancyData?.session_size_no == 'SUSPENDED'
                                || fancyData?.session_size_no == 'Running' ||
                                fancyData?.session_size_no == '0'? '0' :
                                fancyData?.session_size_no}}</span>
                            </button>
                            <button class="back"
                              (click)="setSessionValue(fancyData,1,fancyData.session_value_yes,fancyData.session_size_yes)">
                              {{fancyData?.session_value_yes == 'Ball' ||
                              fancyData?.session_value_yes == '-' ||
                              fancyData?.session_value_yes == '0' ? '0' :
                              fancyData?.session_value_yes}}
                              <span>{{fancyData?.session_size_yes == 'SUSPENDED'
                                ||
                                fancyData?.session_size_yes == 'Running'
                                ||fancyData?.session_size_yes == '0' ? '0' :
                                fancyData?.session_size_yes}}</span>
                            </button>
                          </div>
                        </div>
                        <div class="col-md-1 col-1">
                          <div class="drop_up text-right toggle_icon">
                            <a (click)="toggleLimitInfo(fancyData.fancy_id)"><i class="fa fa-info-circle"></i></a>&nbsp;
                            <div class="fancy_info-popup" [ngClass]="toggleInfo ? 'show-info' : 'hide-info'"
                              *ngIf="fancyData.fancy_id == selectedMarket_id">
                              <div class="arrow"></div>
                              <p>Stake Limit:
                                <span>{{fancyData?.session_min_stack}}-{{fancyData?.session_max_stack}}</span></p>
                              <p>Max Profit: <span>{{fancyData?.session_max_profit}}</span></p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12" *ngIf="fancyData.fancy_id == fancySelection">
                          <div class="row" id="loki"
                            [ngClass]="{'back-bg': arrayObj.is_back == 1 , 'lay-bg': arrayObj.is_back == 0, 'placebet' : arrayObj.is_back == 1 || arrayObj.is_back == 0}">
                            <ng-container *ngFor="let back of this.matchModel.tempArray;let index=index">
                              <div class="col-md-7 col-12">
                                <p>{{fancyData?.name}}</p>
                              </div>

                              <div class="col-md-5">
                                <form class="d-flex">
                                  <div class="form-group">
                                    <label>{{arrayObj.is_back == 1 ? 'Yes' : 'No'}} <img
                                        src="assets/images/icon-arrow-right.png"></label>
                                    <input type="text" [value]="back?.odds" placeholder="{{back?.odds}}" readonly>
                                  </div>
                                  <div class="form-group">
                                    <label>Stack <img src="assets/images/icon-arrow-right.png"></label>
                                    <div class="stake-group">
                                      <span class="input-group-text"
                                        (click)="back.stake = back.stake - 100; calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);">-</span>
                                      <input type="number" class="oddinput" type="number" class="form-control" min="0"
                                        id="stackVal" (focus)="setbtn(index)" (click)="updateFancyTimer()"
                                        (input)="calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);"
                                        pattern="[0-9]*" step="1" id="stakeValue" formcontrolname="stake"
                                        placeholder="0" class="oddinput" [(ngModel)]="(back.stake < 0) ? 0 :back.stake"
                                        [ngModelOptions]="{standalone: true}">
                                      <span class="input-group-text"
                                        (click)="back.stake = back.stake + 100; calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);">+</span>
                                    </div>
                                  </div>
                                  <div class="form-group">
                                    <label>{{arrayObj.is_back == 1 ? 'Profit' : 'Exp'}}</label>
                                    <span *ngIf="arrayObj.is_back == 1" style="color: green;"> {{back.is_back
                                      == 0 ? back.stake :
                                      (((back.size *
                                      back.stake)/100)|
                                      number :
                                      '0.0-2')}}</span>
                                    <span *ngIf="arrayObj.is_back == 0" style="color: red;">{{back.is_back
                                      == 0 ? (((back.size
                                      * back.stake)/100)|
                                      number : '0.0-2') :
                                      back.stake}}</span>
                                  </div>
                                </form>
                              </div>

                              <div class="col-md-12 stakes">
                                <button *ngFor="let stake of userMatchStack | keyvalue;let k = index;"
                                  (click)="calculateP_lOnStack(((back.odds*back.stake)-back.stake),stake.value,back.is_back,back);">
                                  {{stake.value}}</button>
                              </div>

                              <div class="col-md-12 ccp_btns">
                                <button class="btn-cancel"
                                  (click)="this.matchModel.tempArray=[];clearAll();updateFancyTimer();fancyRunner()">cancel</button>
                                <button class="btn-clear" (click)="back.stake = 0;">clear</button>
                                <button class="btn-placebet"
                                  (click)="saveFancyBet(fancyData,this.matchModel.tempArray);"
                                  [disabled]="back.stake <= 0 || loading == true">place bet</button>
                              </div>
                            </ng-container>
                          </div>
                        </div>

                        <div class="col-md-12 col-12 p-0" *ngIf="fancyData?.news != undefined">
                          <div class="news_feed">
                            <span><i class="fas fa-bell"></i></span>
                            <span class="title"> News : </span>
                            <marquee behavior="scroll" direction="left" onmouseout="this.start();"
                              onmouseover="this.stop();" scrolldelay="100" class="marquee-style">
                              <div class="marquee-news"><i class="fas fa-circle"></i><span class="marquee-news-text">
                                  {{fancyData?.news}} </span>
                              </div>
                              <div style="clear: both;"></div>
                            </marquee>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card fancy_market"
                    *ngIf="matchData?.sport_id == 4  && sessionMarketFancyData?.length != 0">
                    <div class="card-header">
                      <img src="assets/images/icon-dot-circle.png"> Fancy1 Market
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-9 col-7">
                        </div>
                        <div class="col-md-2 col-4">
                          <div class="odds_section odds_head">
                            <button class="lay">No</button>
                            <button class="back">Yes</button>
                          </div>
                        </div>
                        <div class="col-md-1">
                        </div>
                      </div>
                      <div class="row"
                        *ngFor="let fancyData of sessionMarketFancyData;let i = index;trackBy:trackByFn;">
                        <div class="col-md-7 col-5">
                          <div class="event_name">
                            {{fancyData?.name}}
                            <span class="loss" *ngIf="fancyData?.liability != undefined">{{fancyData?.liability !=
                              undefined ?
                              fancyData?.liability : '0.00'}}</span>
                          </div>
                        </div>
                        <div class="col-md-2 col-2 px-0">
                          <button class="bet" data-bs-toggle="modal" (click)="fancyPositionData(fancyData,fancyBook)"
                            *ngIf="fancyData?.liability != undefined">book</button>
                        </div>
                        <div class="col-md-2 col-4">
                          <div class="odds_section " [ngClass]="{'suspended':fancyData.display_message != ''}">
                            <button class="lay"
                              (click)="setSessionValue(fancyData,0,fancyData.session_value_no,fancyData.session_size_no)">
                              {{fancyData?.session_value_no == 'Ball' ||
                              fancyData?.session_value_no == '-' ||
                              fancyData?.session_value_no == '0' ? '0' :
                              fancyData?.session_value_no}}
                              <span>{{fancyData?.session_size_no == 'SUSPENDED'
                                || fancyData?.session_size_no == 'Running' ||
                                fancyData?.session_size_no == '0'? '0' :
                                fancyData?.session_size_no}}</span>
                            </button>
                            <button class="back"
                              (click)="setSessionValue(fancyData,1,fancyData.session_value_yes,fancyData.session_size_yes)">
                              {{fancyData?.session_value_yes == 'Ball' ||
                              fancyData?.session_value_yes == '-' ||
                              fancyData?.session_value_yes == '0' ? '0' :
                              fancyData?.session_value_yes}}
                              <span>{{fancyData?.session_size_yes == 'SUSPENDED'
                                ||
                                fancyData?.session_size_yes == 'Running'
                                ||fancyData?.session_size_yes == '0' ? '0' :
                                fancyData?.session_size_yes}}</span>
                            </button>
                          </div>
                        </div>
                        <div class="col-md-1 col-1">
                          <div class="drop_up text-right toggle_icon">
                            <a (click)="toggleLimitInfo(fancyData.fancy_id)"><i class="fa fa-info-circle"></i></a>&nbsp;
                            <div class="fancy_info-popup" [ngClass]="toggleInfo ? 'show-info' : 'hide-info'"
                              *ngIf="fancyData.fancy_id == selectedMarket_id">
                              <div class="arrow"></div>
                              <p>Stake Limit:
                                <span>{{fancyData?.session_min_stack}}-{{fancyData?.session_max_stack}}</span></p>
                              <p>Max Profit: <span>{{fancyData?.session_max_profit}}</span></p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12" *ngIf="fancyData.fancy_id == fancySelection">
                          <div class="row" id="loki"
                            [ngClass]="{'back-bg': arrayObj.is_back == 1 , 'lay-bg': arrayObj.is_back == 0, 'placebet' : arrayObj.is_back == 1 || arrayObj.is_back == 0}">
                            <ng-container *ngFor="let back of this.matchModel.tempArray;let index=index">
                              <div class="col-md-7 col-12">
                                <p>{{fancyData?.name}}</p>
                              </div>
                              <div class="col-md-5">
                                <form class="d-flex">
                                  <div class="form-group">
                                    <label>{{arrayObj.is_back == 1 ? 'Yes' : 'No'}} <img
                                        src="assets/images/icon-arrow-right.png"></label>
                                    <input type="text" [value]="back?.odds" placeholder="{{back?.odds}}" readonly>
                                  </div>
                                  <div class="form-group">
                                    <label>Stack <img src="assets/images/icon-arrow-right.png"></label>
                                    <div class="stake-group">
                                      <span class="input-group-text"
                                        (click)="back.stake = back.stake - 100; calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);">-</span>
                                      <input type="number" class="oddinput" type="number" class="form-control" min="0"
                                        id="stackVal" (focus)="setbtn(index)" (click)="updateFancyTimer()"
                                        (input)="calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);"
                                        pattern="[0-9]*" step="1" id="stakeValue" formcontrolname="stake"
                                        placeholder="0" class="oddinput" [(ngModel)]="(back.stake < 0) ? 0 :back.stake"
                                        [ngModelOptions]="{standalone: true}">
                                      <span class="input-group-text"
                                        (click)="back.stake = back.stake + 100; calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);">+</span>
                                    </div>
                                  </div>
                                  <div class="form-group">
                                    <label>{{arrayObj.is_back == 1 ? 'Profit' : 'Exp'}}</label>
                                    <span *ngIf="arrayObj.is_back == 1" style="color: green;"> {{back.is_back
                                      == 0 ? back.stake :
                                      (((back.size *
                                      back.stake)/100)|
                                      number :
                                      '0.0-2')}}</span>
                                    <span *ngIf="arrayObj.is_back == 0" style="color: red;">{{back.is_back
                                      == 0 ? (((back.size
                                      * back.stake)/100)|
                                      number : '0.0-2') :
                                      back.stake}}</span>
                                  </div>
                                </form>
                              </div>
                              <div class="col-md-12 stakes">
                                <button *ngFor="let stake of userMatchStack | keyvalue;let k = index;"
                                  (click)="calculateP_lOnStack(((back.odds*back.stake)-back.stake),stake.value,back.is_back,back);">
                                  {{stake.value}}</button>
                              </div>
                              <div class="col-md-12 ccp_btns">
                                <button class="btn-cancel"
                                  (click)="this.matchModel.tempArray=[];clearAll();updateFancyTimer();fancyRunner()">cancel</button>
                                <button class="btn-clear" (click)="back.stake = 0;">clear</button>
                                <button class="btn-placebet"
                                  (click)="saveFancyBet(fancyData,this.matchModel.tempArray);"
                                  [disabled]="back.stake <= 0 || loading == true">place bet</button>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                        <div class="col-md-12 col-12 p-0" *ngIf="fancyData?.news != undefined">
                          <div class="news_feed">
                            <span><i class="fas fa-bell"></i></span>
                            <span class="title"> News : </span>
                            <marquee behavior="scroll" direction="left" onmouseout="this.start();"
                              onmouseover="this.stop();" scrolldelay="100" class="marquee-style">
                              <div class="marquee-news"><i class="fas fa-circle"></i><span class="marquee-news-text">
                                  {{fancyData?.news}} </span>
                              </div>
                              <div style="clear: both;"></div>
                            </marquee>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card fancy_market"
                    *ngIf="matchData?.sport_id == 4  && overByOverFancyData?.length != 0">
                    <div class="card-header">
                      <img src="assets/images/icon-dot-circle.png"> Over By Over Session Market
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-9 col-7">
                        </div>
                        <div class="col-md-2 col-4">
                          <div class="odds_section odds_head">
                            <button class="lay">No</button>
                            <button class="back">Yes</button>
                          </div>
                        </div>
                        <div class="col-md-1">
                        </div>
                      </div>
                      <div class="row" *ngFor="let fancyData of overByOverFancyData;let i = index;trackBy:trackByFn;">
                        <div class="col-md-7 col-5">
                          <div class="event_name">
                            {{fancyData?.name}}
                            <span class="loss" *ngIf="fancyData?.liability != undefined">{{fancyData?.liability !=
                              undefined ?
                              fancyData?.liability : '0.00'}}</span>
                          </div>
                        </div>
                        <div class="col-md-2 col-2 px-0">
                          <button class="bet" data-bs-toggle="modal" (click)="fancyPositionData(fancyData,fancyBook)"
                            *ngIf="fancyData?.liability != undefined">book</button>
                        </div>
                        <div class="col-md-2 col-4">
                          <div class="odds_section " [ngClass]="{'suspended':fancyData.display_message != ''}">
                            <button class="lay"
                              (click)="setSessionValue(fancyData,0,fancyData.session_value_no,fancyData.session_size_no)">
                              {{fancyData?.session_value_no == 'Ball' ||
                              fancyData?.session_value_no == '-' ||
                              fancyData?.session_value_no == '0' ? '0' :
                              fancyData?.session_value_no}}
                              <span>{{fancyData?.session_size_no == 'SUSPENDED'
                                || fancyData?.session_size_no == 'Running' ||
                                fancyData?.session_size_no == '0'? '0' :
                                fancyData?.session_size_no}}</span>
                            </button>
                            <button class="back"
                              (click)="setSessionValue(fancyData,1,fancyData.session_value_yes,fancyData.session_size_yes)">
                              {{fancyData?.session_value_yes == 'Ball' ||
                              fancyData?.session_value_yes == '-' ||
                              fancyData?.session_value_yes == '0' ? '0' :
                              fancyData?.session_value_yes}}
                              <span>{{fancyData?.session_size_yes == 'SUSPENDED'
                                ||
                                fancyData?.session_size_yes == 'Running'
                                ||fancyData?.session_size_yes == '0' ? '0' :
                                fancyData?.session_size_yes}}</span>
                            </button>
                          </div>
                        </div>
                        <div class="col-md-1 col-1">
                          <div class="drop_up text-right toggle_icon">
                            <a (click)="toggleLimitInfo(fancyData.fancy_id)"><i class="fa fa-info-circle"></i></a>&nbsp;
                            <div class="fancy_info-popup" [ngClass]="toggleInfo ? 'show-info' : 'hide-info'"
                              *ngIf="fancyData.fancy_id == selectedMarket_id">
                              <div class="arrow"></div>
                              <p>Stake Limit:
                                <span>{{fancyData?.session_min_stack}}-{{fancyData?.session_max_stack}}</span></p>
                              <p>Max Profit: <span>{{fancyData?.session_max_profit}}</span></p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12" *ngIf="fancyData.fancy_id == fancySelection">
                          <div class="row" id="loki"
                            [ngClass]="{'back-bg': arrayObj.is_back == 1 , 'lay-bg': arrayObj.is_back == 0, 'placebet' : arrayObj.is_back == 1 || arrayObj.is_back == 0}">
                            <ng-container *ngFor="let back of this.matchModel.tempArray;let index=index">
                              <div class="col-md-7 col-12">
                                <p>{{fancyData?.name}}</p>
                              </div>
                              <div class="col-md-5">
                                <form class="d-flex">
                                  <div class="form-group">
                                    <label>{{arrayObj.is_back == 1 ? 'Yes' : 'No'}} <img
                                        src="assets/images/icon-arrow-right.png"></label>
                                    <input type="text" [value]="back?.odds" placeholder="{{back?.odds}}" readonly>
                                  </div>
                                  <div class="form-group">
                                    <label>Stack <img src="assets/images/icon-arrow-right.png"></label>
                                    <div class="stake-group">
                                      <span class="input-group-text"
                                        (click)="back.stake = back.stake - 100; calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);">-</span>
                                      <input type="number" class="oddinput" type="number" class="form-control" min="0"
                                        id="stackVal" (focus)="setbtn(index)" (click)="updateFancyTimer()"
                                        (input)="calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);"
                                        pattern="[0-9]*" step="1" id="stakeValue" formcontrolname="stake"
                                        placeholder="0" class="oddinput" [(ngModel)]="(back.stake < 0) ? 0 :back.stake"
                                        [ngModelOptions]="{standalone: true}">
                                      <span class="input-group-text"
                                        (click)="back.stake = back.stake + 100; calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);">+</span>
                                    </div>
                                  </div>
                                  <div class="form-group">
                                    <label>{{arrayObj.is_back == 1 ? 'Profit' : 'Exp'}}</label>
                                    <span *ngIf="arrayObj.is_back == 1" style="color: green;"> {{back.is_back
                                      == 0 ? back.stake :
                                      (((back.size *
                                      back.stake)/100)|
                                      number :
                                      '0.0-2')}}</span>
                                    <span *ngIf="arrayObj.is_back == 0" style="color: red;">{{back.is_back
                                      == 0 ? (((back.size
                                      * back.stake)/100)|
                                      number : '0.0-2') :
                                      back.stake}}</span>
                                  </div>
                                </form>
                              </div>
                              <div class="col-md-12 stakes">
                                <button *ngFor="let stake of userMatchStack | keyvalue;let k = index;"
                                  (click)="calculateP_lOnStack(((back.odds*back.stake)-back.stake),stake.value,back.is_back,back);">
                                  {{stake.value}}</button>
                              </div>
                              <div class="col-md-12 ccp_btns">
                                <button class="btn-cancel"
                                  (click)="this.matchModel.tempArray=[];clearAll();updateFancyTimer();fancyRunner()">cancel</button>
                                <button class="btn-clear" (click)="back.stake = 0;">clear</button>
                                <button class="btn-placebet"
                                  (click)="saveFancyBet(fancyData,this.matchModel.tempArray);"
                                  [disabled]="back.stake <= 0 || loading == true">place bet</button>
                              </div>
                            </ng-container>
                          </div>
                        </div>

                        <div class="col-md-12 col-12 p-0" *ngIf="fancyData?.news != undefined">
                          <div class="news_feed">
                            <span><i class="fas fa-bell"></i></span>
                            <span class="title"> News : </span>
                            <marquee behavior="scroll" direction="left" onmouseout="this.start();"
                              onmouseover="this.stop();" scrolldelay="100" class="marquee-style">
                              <div class="marquee-news"><i class="fas fa-circle"></i><span class="marquee-news-text">
                                  {{fancyData?.news}} </span>
                              </div>
                              <div style="clear: both;"></div>
                            </marquee>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card fancy_market"
                    *ngIf="matchData?.sport_id == 4  && ballByballFancyData?.length != 0">
                    <div class="card-header">
                      <img src="assets/images/icon-dot-circle.png"> Ball By Ball Session Market
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-9 col-7">
                        </div>
                        <div class="col-md-2 col-4">
                          <div class="odds_section odds_head">
                            <button class="lay">No</button>
                            <button class="back">Yes</button>
                          </div>
                        </div>
                        <div class="col-md-1">
                        </div>
                      </div>
                      <div class="row" *ngFor="let fancyData of ballByballFancyData;let i = index;trackBy:trackByFn;">
                        <div class="col-md-7 col-5">
                          <div class="event_name">
                            {{fancyData?.name}}
                            <span class="loss" *ngIf="fancyData?.liability != undefined">{{fancyData?.liability !=
                              undefined ?
                              fancyData?.liability : '0.00'}}</span>
                          </div>
                        </div>
                        <div class="col-md-2 col-2 px-0">
                          <button class="bet" data-bs-toggle="modal" (click)="fancyPositionData(fancyData,fancyBook)"
                            *ngIf="fancyData?.liability != undefined">book</button>
                        </div>
                        <div class="col-md-2 col-4">
                          <div class="odds_section " [ngClass]="{'suspended':fancyData.display_message != ''}">
                            <button class="lay"
                              (click)="setSessionValue(fancyData,0,fancyData.session_value_no,fancyData.session_size_no)">
                              {{fancyData?.session_value_no == 'Ball' ||
                              fancyData?.session_value_no == '-' ||
                              fancyData?.session_value_no == '0' ? '0' :
                              fancyData?.session_value_no}}
                              <span>{{fancyData?.session_size_no == 'SUSPENDED'
                                || fancyData?.session_size_no == 'Running' ||
                                fancyData?.session_size_no == '0'? '0' :
                                fancyData?.session_size_no}}</span>
                            </button>
                            <button class="back"
                              (click)="setSessionValue(fancyData,1,fancyData.session_value_yes,fancyData.session_size_yes)">
                              {{fancyData?.session_value_yes == 'Ball' ||
                              fancyData?.session_value_yes == '-' ||
                              fancyData?.session_value_yes == '0' ? '0' :
                              fancyData?.session_value_yes}}
                              <span>{{fancyData?.session_size_yes == 'SUSPENDED'
                                ||
                                fancyData?.session_size_yes == 'Running'
                                ||fancyData?.session_size_yes == '0' ? '0' :
                                fancyData?.session_size_yes}}</span>
                            </button>
                          </div>
                        </div>
                        <div class="col-md-1 col-1">
                          <div class="drop_up text-right toggle_icon">
                            <a (click)="toggleLimitInfo(fancyData.fancy_id)"><i class="fa fa-info-circle"></i></a>&nbsp;
                            <div class="fancy_info-popup" [ngClass]="toggleInfo ? 'show-info' : 'hide-info'"
                              *ngIf="fancyData.fancy_id == selectedMarket_id">
                              <div class="arrow"></div>
                              <p>Stake Limit:
                                <span>{{fancyData?.session_min_stack}}-{{fancyData?.session_max_stack}}</span></p>
                              <p>Max Profit: <span>{{fancyData?.session_max_profit}}</span></p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12" *ngIf="fancyData.fancy_id == fancySelection">
                          <div class="row" id="loki"
                            [ngClass]="{'back-bg': arrayObj.is_back == 1 , 'lay-bg': arrayObj.is_back == 0, 'placebet' : arrayObj.is_back == 1 || arrayObj.is_back == 0}">
                            <ng-container *ngFor="let back of this.matchModel.tempArray;let index=index">
                              <div class="col-md-7 col-12">
                                <p>{{fancyData?.name}}</p>
                              </div>
                              <div class="col-md-5">
                                <form class="d-flex">
                                  <div class="form-group">
                                    <label>{{arrayObj.is_back == 1 ? 'Yes' : 'No'}} <img
                                        src="assets/images/icon-arrow-right.png"></label>
                                    <input type="text" [value]="back?.odds" placeholder="{{back?.odds}}" readonly>
                                  </div>
                                  <div class="form-group">
                                    <label>Stack <img src="assets/images/icon-arrow-right.png"></label>
                                    <div class="stake-group">
                                      <span class="input-group-text"
                                        (click)="back.stake = back.stake - 100; calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);">-</span>
                                      <input type="number" class="oddinput" type="number" class="form-control" min="0"
                                        id="stackVal" (focus)="setbtn(index)" (click)="updateFancyTimer()"
                                        (input)="calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);"
                                        pattern="[0-9]*" step="1" id="stakeValue" formcontrolname="stake"
                                        placeholder="0" class="oddinput" [(ngModel)]="(back.stake < 0) ? 0 :back.stake"
                                        [ngModelOptions]="{standalone: true}">
                                      <span class="input-group-text"
                                        (click)="back.stake = back.stake + 100; calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);">+</span>
                                    </div>
                                  </div>
                                  <div class="form-group">
                                    <label>{{arrayObj.is_back == 1 ? 'Profit' : 'Exp'}}</label>
                                    <span *ngIf="arrayObj.is_back == 1" style="color: green;"> {{back.is_back
                                      == 0 ? back.stake :
                                      (((back.size *
                                      back.stake)/100)|
                                      number :
                                      '0.0-2')}}</span>
                                    <span *ngIf="arrayObj.is_back == 0" style="color: red;">{{back.is_back
                                      == 0 ? (((back.size
                                      * back.stake)/100)|
                                      number : '0.0-2') :
                                      back.stake}}</span>
                                  </div>
                                </form>
                              </div>
                              <div class="col-md-12 stakes">
                                <button *ngFor="let stake of userMatchStack | keyvalue;let k = index;"
                                  (click)="calculateP_lOnStack(((back.odds*back.stake)-back.stake),stake.value,back.is_back,back);">
                                  {{stake.value}}</button>
                              </div>
                              <div class="col-md-12 ccp_btns">
                                <button class="btn-cancel"
                                  (click)="this.matchModel.tempArray=[];clearAll();updateFancyTimer();fancyRunner()">cancel</button>
                                <button class="btn-clear" (click)="back.stake = 0;">clear</button>
                                <button class="btn-placebet"
                                  (click)="saveFancyBet(fancyData,this.matchModel.tempArray);"
                                  [disabled]="back.stake <= 0 || loading == true">place bet</button>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                        <div class="col-md-12 col-12 p-0" *ngIf="fancyData?.news != undefined">
                          <div class="news_feed">
                            <span><i class="fas fa-bell"></i></span>
                            <span class="title"> News : </span>
                            <marquee behavior="scroll" direction="left" onmouseout="this.start();"
                              onmouseover="this.stop();" scrolldelay="100" class="marquee-style">
                              <div class="marquee-news"><i class="fas fa-circle"></i><span class="marquee-news-text">
                                  {{fancyData?.news}} </span>
                              </div>
                              <div style="clear: both;"></div>
                            </marquee>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade hide-on-desktop" id="pills-betList" role="tabpanel"
                aria-labelledby="pills-betList-tabMob">
                <div class="betting_sidebar">
                  <ul class="nav nav-pills nav-justified" id="pills-tabMob" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" id="pills-ab-tabMob" data-bs-toggle="pill" href="#pills-abMob" role="tab"
                        aria-controls="pills-home" aria-selected="true">All Bet ({{allBetDataLength == 0 ? '0' :
                        allBetDataLength}})</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="pills-mb-tabMob" data-bs-toggle="pill" href="#pills-mbMob" role="tab"
                        aria-controls="pills-mb" aria-selected="false">Match Bet ({{matchedBetsLength == 0 ? '0' :
                        matchedBetsLength}})</a>
                    </li>
                    <li class="nav-item" *ngIf="matchData.sport_id == 4">
                      <a class="nav-link" id="pills-fb-tabMob" data-bs-toggle="pill" href="#pills-fbMob" role="tab"
                        aria-controls="pills-fb" aria-selected="false">Fancy Bet ({{fancyBetsLength == 0 ? '0' :
                        fancyBetsLength}})</a>
                    </li>
                  </ul>
                  <div class="tab-content" id="pills-tabContentMob">
                    <div class="tab-pane fade show active" id="pills-abMob" role="tabpanel" aria-labelledby="pills-ab-tabMob">
                      <ng-container *ngIf="allBetDataLength != 0">
                        <div class="betTable" *ngFor="let bet of allBetData;let i= index;"
                          [ngClass]="{'lay':(bet.is_back)==0,'back':(bet.is_back)==1}">
                          <div class="row">
                            <div class="col-7">
                              {{bet.is_fancy == 0 ? bet.selection_name :
                              bet.selection_name}}
                            </div>
                            <div class="col-5 text-right">
                              odds : <b>{{bet?.odds}} </b><span *ngIf="bet.is_fancy == 1">/ {{bet?.size}}</span>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-7">
                              <span [ngClass]="{'pink':(bet.is_back)==0,'blue':(bet.is_back)==1}">{{(bet.is_back)==0 ?
                                'Lay' : 'Back'}}</span>
                            </div>
                            <div class="col-5 text-right">
                              stake : <b>{{bet?.stack}}</b>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-7">
                              {{(bet?.createdAt | date :'dd-MM-yyyy hh:mm:ss aa')}}
                            </div>
                            <div class="col-5 text-right">
                              Liability : <b
                                [ngClass]="{'loss':(bet?.liability)<0,'profit':(bet?.liability)>0}">{{(bet?.liability)|
                                number : '0.0-2'}}</b>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-7">
                              {{bet?.market_name}}
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="tab-pane fade" id="pills-mbMob" role="tabpanel" aria-labelledby="pills-mb-tabMob">
                      <ng-container *ngIf="matchedBetsLength != 0">
                        <div class="betTable" *ngFor="let bet of matchedBets;let i= index;"
                          [ngClass]="{'lay':(bet.is_back)==0,'back':(bet.is_back)==1}">
                          <div class="row">
                            <div class="col-7">
                              {{bet.is_fancy == 0 ? bet.selection_name :
                              bet.selection_name}}
                            </div>
                            <div class="col-5 text-right">
                              odds : <b>{{bet?.odds}} </b><span *ngIf="bet.is_fancy == 1">/ {{bet?.size}}</span>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-7">
                              <span [ngClass]="{'pink':(bet.is_back)==0,'blue':(bet.is_back)==1}">{{(bet.is_back)==0 ?
                                'Lay' : 'Back'}}</span>
                            </div>
                            <div class="col-5 text-right">
                              stake : <b>{{bet?.stack}}</b>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-7">
                              {{(bet?.createdAt | date :'dd-MM-yyyy hh:mm:ss aa')}}
                            </div>
                            <div class="col-5 text-right">
                              Liability : <b
                                [ngClass]="{'loss':(bet?.liability)<0,'profit':(bet?.liability)>0}">{{(bet?.liability)|
                                number : '0.0-2'}}</b>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-7">
                              {{bet?.market_name}}
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="tab-pane fade" id="pills-fbMob" role="tabpanel" aria-labelledby="pills-fb-tabMob">
                      <ng-container *ngIf="fancyBetsLength != 0">
                        <div class="betTable" *ngFor="let bet of fancyBets;let i= index;"
                          [ngClass]="{'lay':(bet.is_back)==0,'back':(bet.is_back)==1}">
                          <div class="row">
                            <div class="col-7">
                              {{bet.is_fancy == 0 ? bet.selection_name :
                              bet.selection_name}}
                            </div>
                            <div class="col-5 text-right">
                              odds : <b>{{bet?.odds}} </b><span *ngIf="bet.is_fancy == 1">/ {{bet?.size}}</span>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-7">
                              <span [ngClass]="{'pink':(bet.is_back)==0,'blue':(bet.is_back)==1}">{{(bet.is_back)==0 ?
                                'Lay' : 'Back'}}</span>
                            </div>
                            <div class="col-5 text-right">
                              stake : <b>{{bet?.stack}}</b>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-7">
                              {{(bet?.createdAt | date :'dd-MM-yyyy hh:mm:ss aa')}}
                            </div>
                            <div class="col-5 text-right">
                              Liability : <b
                                [ngClass]="{'loss':(bet?.liability)<0,'profit':(bet?.liability)>0}">{{(bet?.liability)|
                                number : '0.0-2'}}</b>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-7">
                              {{bet?.market_name}}
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 px-1 hide-on-mobile">
            <div class="live-tv hide-on-mobile" [ngClass]="toggleLiveTVStatus ? 'showTv' : 'hideTv'">
              <div class="betting_sidebar">
                <ul class="nav nav-pills nav-justified" id="liveTvTab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="liveTv-tab" data-bs-toggle="tab" data-bs-target="#liveTv" role="tab" aria-controls="liveTv" aria-selected="true">
                      Live TV
                    </a>
                  </li>
                </ul>
                <div class="tab_content">
                  <div class="tab-pane fade show active" id="liveTv" role="tabpanel" aria-labelledby="liveTv-tab">
                    <iframe [src]="liveUrl" style=" height: 200px !important;width: 100% !important;" (load)="onIframeLoad($event)"></iframe>
                  </div>
                </div>
              </div>
            </div>
            <div class="betting_sidebar">
              <ul class="nav nav-pills nav-justified" id="pills-tab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="pills-ab-tab" data-bs-toggle="pill" href="#pills-ab" role="tab"
                    aria-controls="pills-home" aria-selected="true">All Bet ({{allBetDataLength == 0 ? '0' :
                    allBetDataLength}})</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="pills-mb-tab" data-bs-toggle="pill" href="#pills-mb" role="tab"
                    aria-controls="pills-mb" aria-selected="false">Match Bet ({{matchedBetsLength == 0 ? '0' :
                    matchedBetsLength}})</a>
                </li>
                <li class="nav-item" *ngIf="matchData.sport_id == 4">
                  <a class="nav-link" id="pills-fb-tab" data-bs-toggle="pill" href="#pills-fb" role="tab"
                    aria-controls="pills-fb" aria-selected="false">Fancy Bet ({{fancyBetsLength == 0 ? '0' :
                    fancyBetsLength}})</a>
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade active" id="pills-ab" role="tabpanel" aria-labelledby="pills-ab-tab">
                  <ng-container *ngIf="allBetDataLength != 0">
                    <div class="betTable" *ngFor="let bet of allBetData;let i= index;"
                      [ngClass]="{'lay':(bet.is_back)==0,'back':(bet.is_back)==1}">
                      <div class="row">
                        <div class="col-7">
                          {{bet.is_fancy == 0 ? bet.selection_name :
                          bet.selection_name}}
                        </div>
                        <div class="col-5 text-right">
                          odds : <b>{{bet?.odds}} </b><span *ngIf="bet.is_fancy == 1">/ {{bet?.size}}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-7">
                          <span [ngClass]="{'pink':(bet.is_back)==0,'blue':(bet.is_back)==1}">{{(bet.is_back)==0 ? 'Lay'
                            : 'Back'}}</span>
                        </div>
                        <div class="col-5 text-right">
                          stake : <b>{{bet?.stack}}</b>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-7">
                          {{(bet?.createdAt | date :'dd-MM-yyyy hh:mm:ss aa')}}
                        </div>
                        <div class="col-5 text-right">
                          Liability : <b
                            [ngClass]="{'loss':(bet?.liability)<0,'profit':(bet?.liability)>0}">{{(bet?.liability)|
                            number : '0.0-2'}}</b>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-7">
                          {{bet?.market_name}}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div class="tab-pane fade" id="pills-mb" role="tabpanel" aria-labelledby="pills-mb-tab">
                  <ng-container *ngIf="matchedBetsLength != 0">
                    <div class="betTable" *ngFor="let bet of matchedBets;let i= index;"
                      [ngClass]="{'lay':(bet.is_back)==0,'back':(bet.is_back)==1}">
                      <div class="row">
                        <div class="col-7">
                          {{bet.is_fancy == 0 ? bet.selection_name :
                          bet.selection_name}}
                        </div>
                        <div class="col-5 text-right">
                          odds : <b>{{bet?.odds}} </b><span *ngIf="bet.is_fancy == 1">/ {{bet?.size}}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-7">
                          <span [ngClass]="{'pink':(bet.is_back)==0,'blue':(bet.is_back)==1}">{{(bet.is_back)==0 ? 'Lay'
                            : 'Back'}}</span>
                        </div>
                        <div class="col-5 text-right">
                          stake : <b>{{bet?.stack}}</b>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-7">
                          {{(bet?.createdAt | date :'dd-MM-yyyy hh:mm:ss aa')}}
                        </div>
                        <div class="col-5 text-right">
                          Liability : <b
                            [ngClass]="{'loss':(bet?.liability)<0,'profit':(bet?.liability)>0}">{{(bet?.liability)|
                            number : '0.0-2'}}</b>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-7">
                          {{bet?.market_name}}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div class="tab-pane fade" id="pills-fb" role="tabpanel" aria-labelledby="pills-fb-tab">
                  <ng-container *ngIf="fancyBetsLength != 0">
                    <div class="betTable" *ngFor="let bet of fancyBets;let i= index;"
                      [ngClass]="{'lay':(bet.is_back)==0,'back':(bet.is_back)==1}">
                      <div class="row">
                        <div class="col-7">
                          {{bet.is_fancy == 0 ? bet.selection_name :
                          bet.selection_name}}
                        </div>
                        <div class="col-5 text-right">
                          odds : <b>{{bet?.odds}} </b><span *ngIf="bet.is_fancy == 1">/ {{bet?.size}}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-7">
                          <span [ngClass]="{'pink':(bet.is_back)==0,'blue':(bet.is_back)==1}">{{(bet.is_back)==0 ? 'Lay'
                            : 'Back'}}</span>
                        </div>
                        <div class="col-5 text-right">
                          stake : <b>{{bet?.stack}}</b>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-7">
                          {{(bet?.createdAt | date :'dd-MM-yyyy hh:mm:ss aa')}}
                        </div>
                        <div class="col-5 text-right">
                          Liability : <b
                            [ngClass]="{'loss':(bet?.liability)<0,'profit':(bet?.liability)>0}">{{(bet?.liability)|
                            number : '0.0-2'}}</b>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-7">
                          {{bet?.market_name}}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>

<ng-template #mobileBetList>
  <div class="cross_btn">
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modalRef.hide()"></button>
  </div>
  <div class="modal-header">
    <h5>Bet List</h5>
  </div>
  <div class="modal-body">
    <div class="empty-betlist " *ngIf="dataSourceLength == 0">
      <img src="assets/images/no-betlist.png" alt="">
      <div class="title">Your Betlist is empty</div>
      <div class="title2">Click on odds to add a bet to the betlist</div>
    </div>

    <div class="bet-list" *ngIf="dataSourceLength != 0">
      <ul class="top-list">
        <li class="bet-type">Matched Bet</li>
        <li class="avg-odd">Average Odds</li>
      </ul>
      <ng-container *ngFor="let betList of betDataList | keyvalue;let i = index;">
        <div class="selection-text">{{betList?.key}}</div>
        <ul>
          <li>{{matchName}}</li>
        </ul>
        <table class="table">
          <thead>
            <tr>
              <th>Selection</th>
              <th>Type</th>
              <th>Odds</th>
              <th>Stake</th>
              <th>P/L</th>
            </tr>
          </thead>
          <tbody *ngFor="let list of betList.value;let j = index;">
            <tr class="lay" [ngClass]="{'lay':list.is_back == 0,'back':list.is_back == 1}">
              <td>
                <b>{{list?.selection_name}}</b>
                <span>{{list?.createdAt | date :'dd-MM-yyyy HH:mm:ss'}}</span>
              </td>
              <td *ngIf="list?.is_fancy == 0">{{list?.is_back == 1 ? 'Back' : 'Lay'}}</td>
              <td *ngIf="list?.is_fancy == 1">{{list?.is_back == 1 ? 'Yes' : 'No'}}</td>
              <td>{{list?.odds}}<span *ngIf="list?.is_fancy ==1">/{{list?.size}}</span></td>
              <td>{{list?.stack}}</td>
              <td [ngClass]="{'loss':list.p_l < 0,'profit':list.p_l >= 0}">{{list?.p_l | number : '0.0-2'}}</td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #fancyBook>
  <div class="modal-header">
    <h2>{{selectedFancyMarket?.name}}</h2>
    <div class="cross_btn">
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
        (click)="modalRef.hide()"></button>
    </div>
  </div>
  <div class="modal-body">
    <dl *ngFor="let fancyPos of fancyPosData;let i=index;"
      [ngClass]="{'lay-1':(fancyPos.value)<0,'back-1':(fancyPos.value)>0}">
      <dt>{{fancyPos.key}}</dt>
      <dd>:</dd>
      <dd class="profit" [ngClass]="{'loss':(fancyPos.value)<0,'profit':(fancyPos.value)>0}">{{fancyPos.value}}</dd>
    </dl>
  </div>
</ng-template>

<ng-template #Terms>
  <a class="modal-close right close-icon" data-bs-dismiss="modal" aria-label="Close" (click)="modalRef.hide()"><i
      aria-hidden="true" class="fa fa-times"></i></a>
  <h5> Terms & Conditions </h5>
  <div class="modal-body">
    <div class="rules-content">
      <div>
        <div>
          <p>1.&nbsp;Cricket General :- ➢ If a ball is not bowled during a competition, series or match then all bets
            will be void except for those on any market that has been unconditionally determined (e.g. in the
            'Completed Match' market).</p>
          <p>2.&nbsp;Cricket General :- If a match is shortened by weather, all bets will be settled according to the
            official result (including for limited overs matches, the result determined by the Duckworth Lewis
            method).</p>
          <p>3.&nbsp;Cricket General :- In the event of a match being decided by a bowl-off or toss of the coin, all
            bets will be void except for those on markets that have been unconditionally determined.</p>
          <p>4.&nbsp;Cricket Test matches :- If a match starts but is later abandoned for any reason other than
            weather (which may include but is not limited to: dangerous or unplayable wicket or outfield; pitch
            vandalism; strike or boycott; crowd protests/violence; stadium damage; acts of terrorism; and acts of
            God), Betfair reserves the right to void all bets, except for those on markets that have been
            unconditionally determined.</p>
          <p>5. In case anyone is found using 2 different IDs and logging in from same IP his winning in both accounts
            will be cancelled.</p>
          <p>6.&nbsp;Cricket Test matches :- If the match is not scheduled to be completed within five days after the
            original scheduled completion date, then all bets on markets for this event will be void, except for bets
            on any markets that have been unconditionally determined.</p>
          <p>7.&nbsp;Cricket Limited Over matches :- If a match is declared (No Result), bets will be void on all
            markets for the event except for those markets which have been unconditionally determined or where the
            minimum number of overs have been bowled as laid out in the market specific information.</p>
          <p>8.&nbsp;Cricket Limited Over matches :- In the event of a new toss taking place on a scheduled reserve
            day for a limited overs match all bets that were placed after 30 minutes before the original scheduled
            start of play on the first day will be made void. This rule relates to all markets except those that have
            been unconditionally determined (e.g. in the win the toss and toss combination markets).</p>
          <p>9.&nbsp;Multiple Bets :- Multiple Bets With Same Time And Same User Will Be Voided Immediately.</p>
          <p>10.&nbsp;If any user found doing trading bet example: multiple time khai lagai within a 1 minutes, there winning amount bet will be void and it is possible that user balance goes minus figure.</p>
        </div>
        <div>
          <div>
            <h3>Cricket Bookmaker</h3>
          </div>
          <div>&nbsp;</div>
          <div>
            <p>1. Due to any reason any team will be getting advantage or disadvantage we are not concerned.</p>
          </div>
          <div>
            <p>2. We will simply compare both teams 25 overs score higher score team will be declared winner in ODI
              (25 over comparison)</p>
          </div>
          <div>
            <p>3. We will simply compare both teams 10 overs higher score team will be declared winner in T20 matches
              (10 over comparison)</p>
          </div>
          <div>
            <p>4. Any query about the result or rates should be contacted within 7 days of the specific event, the
              same will not be considered valid post 7 days from the event.</p>
          </div>
          <div class="rules-content">
            <div>
              <div>
                <div>
                  <h3><strong>Rules Of Fancy Bets</strong></h3>
                </div>
              </div>
              <div>
                <div>
                  <p>1. Once all session/fancy bets are completed and settled there will be no reversal even if the
                    Match is Tied or is Abandoned.</p>
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <div>
                      <p>2. Advance Session or Player Runs and all Fancy Bets are only valid for 20/50 overs full
                        match each side. (Please Note this condition is applied only in case of Advance Fancy Bets
                        only).</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <div>
                      <p>3. All advance fancy bets market will be suspended 60 mins prior to match and will be
                        settled.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <p>4. Under the rules of Session/Fancy Bets if a market gets Suspended for any reason whatsoever and
                    does not resume then all previous Bets will remain Valid and become HAAR/JEET bets.</p>
                  <p>5. Incomplete Session Bet will be cancelled but Complete Session will be settled.</p>
                  <p>6.&nbsp;In the case of Running Match getting Cancelled/ No Result/ Abandoned but the session is
                    complete it will still be settled. Player runs / fall of wicket / Only Over will be also settled
                    at the figures where match gets stopped due to rain for the inning (D/L) , cancelled , abandoned ,
                    no result.</p>
                  <p>7. If a player gets Retired Hurt and one ball is completed after you place your bets then all the
                    betting till then is and will remain valid.</p>
                  <p>8.&nbsp;Should a Technical Glitch in Software occur, we will not be held responsible for any
                    losses.</p>
                  <p>9.&nbsp;Should there be a power failure or a problem with the Internet connection at our end and
                    session/fancy market does not get suspended then our decision on the outcome is final.</p>
                  <p>10. All decisions relating to settlement of wrong market being offered will be taken by
                    management. Management will consider all actual facts and decision taken will be full in final.
                  </p>
                  <p>11.Any bets which are deemed of being suspicious, including bets which have been placed from the
                    stadium or from a source at the stadium maybe voided at anytime. The decision of whether to void
                    the particular bet in question or to void the entire market will remain at the discretion of
                    Company. The final decision of whether bets are suspicious will be taken by Company and that
                    decision will be full and final.</p>
                  <p>12.&nbsp;Any sort of cheating bet , any sort of Matching (Passing of funds), Court Siding
                    (Ghaobaazi on commentary), Sharpening, Commission making is not allowed in Company, If any company
                    User is caught in any of such act then all the funds belonging that account would be seized and
                    confiscated. No argument or claim in that context would be entertained and the decision made by
                    company management will stand as final authority.</p>
                  <p>13.&nbsp;Fluke hunting/Seeking is prohibited in Company , All the fluke bets will be reversed.
                    Cricket commentary is just an additional feature and facility for company user but company is not
                    responsible for any delay or mistake in commentary.</p>
                  <p>14. In case anyone is found using 2 different IDs and logging in from same IP his winning in both
                    accounts will be cancelled.</p>
                  15.&nbsp;If any case wrong rate has been given in fancy ,that particular bets will be cancelled
                  (Wrong Commentary).
                  <p>&nbsp;</p>
                  <p>16.&nbsp;In case customer make bets in wrong fancy we are not liable to delete, no changes will
                    be made and bets will be considered as confirm bet.</p>
                  <p>17.&nbsp;Dot Ball Market Rules</p>
                  <ul>
                    <li>Wides Ball - Not Count</li>
                    <li>No Ball - Not Count</li>
                    <li>Leg Bye - Not Count as A Dot Ball</li>
                    <li>Bye Run - Not Count as A Dot Ball</li>
                    <li>Out - Any Type of WKT Not Count as A Dot Ball</li>
                  </ul>
                  <p>18.&nbsp;Penalty Runs - Any Penalty Runs in the Match Will be Counted While Settling in our
                    Exchange.</p>
                  <p>19. All Test In Future Also ...All Full Match Markets Me Minimum 300 Overs Play Or Match Result
                    Needed Otherwise All Full Match Fancy Voided</p>
                  <p>20. If match stoped by any reason running over in the match will count as complete over in Single
                    over market(only overs runs) . Atleast one ball to be bowled needed in the over</p>
                  <p>21. In Test/Odi/T20 partnership If one batsman Will injured Then partnership Will continued With
                    next batsman.</p>
                  <div>
                    <div>
                      <h3><strong>Rules Of Line Market</strong></h3>
                    </div>
                  </div>
                  <div>
                    <div>
                      <p>1.How many runs will be scored in the stated number of overs? Line market: This market
                        operate with a bet delay of 1 second; All bets are struck at 2.0 The 'price' at which your bet
                        is placed defines the number of runs bet upon. *YES Line* (buy) bets win when more runs are
                        scored in the specified number of overs than the 'price' at which the bet is matched. *NO
                        Line* (Sell) bets win when fewer runs are scored in the specified number of overs than the
                        'price' at which the bet is matched. This market will be settled if 5 overs have been
                        completed in the innings, the batting team is bowled out or has reached the target to win the
                        match.</p>
                      <p>2. How many runs will be scored in the stated number of overs? Line market:This market
                        operate with a bet delay of 1 second; All bets are struck at 2.0 . The 'price' at which your
                        bet is placed defines the number of runs bet upon. *YES Line* (Buy) bets win when more runs
                        are scored in the specified number of overs than the 'price' at which the bet is matched. *NO
                        Line* (Sell) bets win when fewer runs are scored in the specified number of overs than the
                        'price' at which the bet is matched. Special rules for Overs Runs Line markets: Bets will be
                        void - regardless of whether the outcome of the bet is already unconditionally determined -
                        when any of the following are the case: -if the scheduled number of overs for the innings is
                        reduced by rain interruption and, at settlement time, the actual number of overs bowled is
                        less than the number of overs stipulated at the head of these rules. - if the scheduled number
                        of overs for the innings is reduced for any other reason after the innings has begun and at
                        settlement time, that new number of scheduled overs is less than the number of overs
                        stipulated at the head of these rules. Please note if the batting side reach their target
                        within the 20 overs or have been bowled out and the innings hasn’t been reduced in overs to
                        less than the stipulated number at the head of the rules, the market will be settled.</p>
                      <div>
                        <div>
                          <h3><strong>Rules Of PSL 2022 Full Series Fancy</strong></h3>
                        </div>
                      </div>
                      <div>
                        <div>
                          <p>Total 34 Match Counted Start To Finals All Counted</p>
                          <p>(1) Agar Koi Bhi Match Rain Ke Wajah Se Ya Power Fail Se Total 40 Overs Se Kam Khela Gaya
                            To Fix Fancy Jo Hum Ne Tay Ki Hai Wo Add Kar Di Jayegi - 38.5 Over Counted As Under 40
                            Overs Match 39.1 Counted as Full 40 Over Match.</p>
                          <p>(2) Bina Rain Ke Under 40 Overs Result Aata Hai To Scoreboard Ke Hisaab Se All Fancy
                            Update Ki Jayegi.</p>
                          <p>(3) By Chance Covid Ya Kisi Bhi Act Of God Reason Se Tournament Under 25 Match Intrupted
                            Ho Jaata Hai To All Series Fancy Voided Plus Ho Or Minus.</p>
                          <p>(4) Jo Fancy Ka Result Aa Gaya Jo Settle Ho Gyee Wo Void Nahi Hogi Wo Valid Hai.</p>
                          <p>Over 25 Match All Fancy Settled <br>Non Playing Match Me Fix All Fancy Added </p>
                          <p>Please Read All Fix Fancy Before Play<br>( All Fix Fancy Added In Full Washout Or Under
                            39.1 Overs Played Rain Affected Match )</p>
                          <p>C And B Also Count In Series Catch Outs</p>
                          <p>12 Wickets Per Match Added<br>27 Fours Per Match Added<br>11 Sixes Per Match Added<br>2
                            Fifty Per Match Added<br>8 Wides Per Match Added<br>14 Extras Per Match Added<br>8 Catch
                            Per Match Added<br>2 Bowled Per Match Added<br>1 LBW Per Match Added<br>1 Runout Per match
                            Added</p>
                          <div>
                            <div>
                              <h3><strong>IPL 2022 Series Fancy Rules</strong></h3>
                            </div>
                          </div>
                          <div>
                            <div>
                              <p>Total 74 Match Counted Start To Finals All Counted</p>
                              <p>(1) Agar Koi Bhi Match Rain Ke Wajah Se Ya Power Fail Se Total 40 Overs Se Kam Khela
                                Gaya To Fix Fancy Jo Hum Ne Tay Ki Hai Wo Add Kar Di Jayegi - 38.5 Over Counted As
                                Under 40 Overs Match 39.1 Counted as Full 40 Over Match.</p>
                              <p>(2) Bina Rain Ke Under 40 Overs Result Aata Hai To Scoreboard Ke Hisaab Se All Fancy
                                Update Ki Jayegi.</p>
                              <p>(3) By Chance Covid Ya Kisi Bhi Act Of God Reason Se Tournament Under 40 Match
                                Intrupted Ho Jaata Hai Aur 30 Day Re Start Nahi Hota Hai To All Series Fancy Voided
                                Plus Ho Or Minus.</p>
                              <p>(4) Jo Fancy Ka Result Aa Gaya Jo Settle Ho Gyee Wo Void Nahi Hogi Wo Valid Hai.</p>
                              <p>Over 50 Match All Fancy Settled <br>Non Playing Match Me Fix All Fancy Added </p>
                              <p>Please Read All Fix Fancy Before Play<br>( All Fix Fancy Added In Full Washout Or
                                Under 39.1 Overs Played Rain Affected Match )</p>
                              <p>C And B Also Count In Series Catch Outs</p>
                              <p>12 Wickets Per Match Added<br>28 Fours Per Match Added<br>12 Sixes Per Match
                                Added<br>2 Fifty Per Match Added<br>8 Wides Per Match Added<br>16 Extras Per Match
                                Added<br>8 Catch Per Match Added<br>2 Bowled Per Match Added<br>1 LBW Per Match
                                Added<br>1 Runout Per match Added<br>1 No Ball Per Match Added<br>1 Duck Per Match
                                Added</p>
                              <p>Fab Four vs Poor Sixes Bookmaker Rules</p>
                              <p>70 Match Counted <br>In This Market <br>4 Team Set Vs 6 Team Set</p>
                              <p>You Bet On Which Group Wins More Matches</p>
                              <p>Example If Fab Four Teams Win 34 match And poor Six wins 37 match means poor six win
                                Market</p>
                              <p>If Both Group Win Same Matches Than Table Top Teams Group Win Market</p>
                            </div>
                          </div>
                          <div>
                            <div>
                              <h3><strong>TEST Match Rules</strong></h3>
                            </div>
                          </div>
                          <p>1. All Test In Future Also ...Minimum 300 overs has been played or the match has been won
                            by any team otherwise all these fancy will be deleted</p>
                          <p>2.1st innig Adv Runs is not completed for example: If You are Placing Advance Bets in 1st
                            innigs 80 over runs ADV in case team-A declares or all-out at 70 over next 10 over counted
                            in Team-B's 1st inning.</p>
                          <p>3. Test match both advance fancy batsman run, Fall Of Wkt Runs, is valid on both team's
                            1st innings.</p>
                          <p>4.In case due to weather situation match has been stopped all lambi trades will be
                            deleted.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-center">
    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="modalRef.hide()">Ok</button>
  </div>
</ng-template>