<app-header></app-header>
<div class="content_area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3 left_bar">
                <app-left-side-bar></app-left-side-bar>

            </div>
            <div class="col-md-9 conetnt_col">
                <div class="promoting_tabs reporting_tabs">
                    <app-report-header></app-report-header>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="withdraw" role="tabpanel"
                            aria-labelledby="pills-contact-tab">
                            <div class="kyc-staus-box status-box">
                                <span class="withdrawable-amt">
                                    Your withdrawable amount is ₹
                                    <span class="amount">{{updatedBalance < 0 ? '0' : updatedBalance}}</span>
                                    </span>
                            </div>
                            <form class="col s12" [ngClass]="hideShowBankStatus ? 'bankForm-show' : 'bankForm-hide'">
                                <div class="row m-0">
                                    <div class="bankList-table">
                                        <table class="dataTable">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Mobile No</th>
                                                    <th>Bank Name</th>
                                                    <th>Account Holder Name</th>
                                                    <th>Acc Number/UPI..</th>
                                                    <th>IFSC Code</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let list of payMethodList;let i = index;">
                                                    <td> {{list?.type}}  </td>
                                                    <td>   {{list?.payment_details[0]?.mobile_no}}</td>
                                                    <td>   {{list?.payment_details[0]?.bank_holder_name}}</td>
                                                  
                                                    <td> {{list?.payment_details[0]?.bank_name}} </td>
                                                    <td> {{list?.payment_details[0]?.account_no}} </td>
                                                    <td> {{list?.payment_details[0]?.ifsc_code}} </td>
                                                    <td>
                                                        <a (click)="delete(list)" class="btn"> Remove </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="row p-0 select-bank">
                                    <div class="input-field col s12 m6 m-0">
                                        <div class="bank-details">
                                            <div class="w-100">
                                                <div class="input-field bank-payment col s12">
                                                    <ng-select [ngModelOptions]="{standalone: true}"
                                                            [(ngModel)]="method_id" class="select-dropdown dropdown-trigger"
                                                            (change)="selectBank(method_id) " placeholder="Select Bank or UpI">
                                                            <ng-option *ngFor="let list of payMethodList;let i = index;"
                                                                [value]="list._id">{{list.name}}-{{list?.payment_details[0]?.account_no}}</ng-option></ng-select>
                                                    <label class="">Payment Selection <span
                                                            class="text-danger">*</span></label>
                                                </div> 
                                            </div>

                                            <div class="w-100">
                                                <div class="input-field bank-amount col s12">
                                                    <input placeholder="Enter amount *" maxlength="8"
                                                        class="form-control validate" [(ngModel)]="amount"
                                                        style="margin-top: 10px;" value="{{amount}}"
                                                        name="withdrawAmount" type="number" min="0">
                                                   
                                                </div>
                                            </div>
                                            <a class="add-bank" (click)="showBankForm()">
                                                <i class="fas fa-plus"></i>
                                                Add bank
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="row m-0 center-align"  *ngIf="min_MAXdATA">
                                    <div class="withdraw-amount" *ngIf="min_MAXdATA[0]">
                                        You can withdraw upto
                                        <strong>{{min_MAXdATA[0]?.max_amount}},</strong>
                                        minimum
                                        <strong>{{min_MAXdATA[0]?.min_amount}},</strong>
                                        is required
                                    </div>
                                </div>
                            </form>
                            <form class="col s12" [formGroup]='Form'
                                [ngClass]="showBankFormStatus ? 'addBank-show' : 'addBank-hide'">
                                <div class="row m-0 withdraw-bank">
                                    <div class="nav-item-box"  *ngFor="let p of allowPaymentType" (click)="paymentType=p;selectMethodType(p)" [ngClass]="paymentType?.type == p?.type ? '' : 'selected'">
                                          {{p?.name}}
                                    </div>
                                     <div class="alert-message" *ngIf="allowPaymentType?.length==0">Note: No Withdraw Allowed contact upline..</div>
                                    <div *ngIf="paymentType?.type=='Bank' && paymentType!=null" class="input-field col s12 m6 m-0 p-0 pr-10">
                                        <div class="bank-dropdown">
                                            <label class="active">Bank Details <span
                                                    class="text-danger">*</span>:</label>
                                            <div class="select-wrapper">
                                                <ng-select [ngModelOptions]="{standalone: true}"
                                                    [(ngModel)]="searchBank" class="select-dropdown dropdown-trigger"
                                                    (change)="selectBank(searchBank) " placeholder="Select Bank">
                                                    <ng-option *ngFor="let list of bankList;let i = index;"
                                                        [value]="list.bn">{{list.bn}}</ng-option></ng-select>
                                            </div>
                                        </div>
                                        <div class="alert_base" *ngIf="submitted && f.bank_name.status == 'INVALID'">
                                            <span>* Please enter Bank Name</span>
                                        </div>
                                        <div class="bank-ifsc">
                                            <div class="input-field col s12 p-0">
                                                <input placeholder="Enter branch IFSC" type="text"
                                                    formControlName="ifsc_code" class="validate">
                                                <label class="active">Branch IFSC <span class="text-danger">*</span>:
                                                </label>
                                            </div>
                                        </div>
                                        <div class="alert_base" *ngIf="submitted && f.ifsc_code.status == 'INVALID'">
                                            <span>* Please enter IFSC Code</span>
                                        </div>
                                    </div>
                                    <div *ngIf="paymentType?.type=='Bank' && paymentType!=null" class="input-field col s12 m6 m-0 p-0 pl-10">
                                        <div class="bank-account-no">
                                            <div class="input-field col s12 p-0">
                                                <input placeholder="Enter bank account no" type="text"
                                                    formControlName="account_no" class="validate">
                                                <label class="active">Bank Account No <span
                                                        class="text-danger">*</span>: </label>
                                            </div>
                                        </div>
                                        <div class="alert_base" *ngIf="submitted && f.account_no.status == 'INVALID'">
                                            <span>* Please enter Account Number</span>
                                        </div>
                                        <div class="bank-ac-name">
                                            <div class="input-field col s12 p-0">
                                                <input placeholder="Enter name" type="text"
                                                    formControlName="bank_holder_name" class="validate">
                                                <label class="active">Account Holder Name<span
                                                        class="text-danger">*</span>: </label>
                                            </div>
                                        </div>
                                        <div class="alert_base"
                                            *ngIf="submitted && f.bank_holder_name.status == 'INVALID'">
                                            <span>* Please enter Holder Name</span>
                                        </div>
                                    </div>
                                      
                                    <div *ngIf="paymentType?.type!='Bank' && paymentType!=null" class="input-field col s12 m6 m-0 p-0 pl-10">
                                        <div class="bank-account-no">
                                            <div class="input-field col s12 p-0">
                                                <input placeholder="Enter upi/etc" type="text"
                                                    formControlName="account_no" class="validate">
                                                <label class="active">UPI/ETC <span
                                                        class="text-danger">*</span>: </label>
                                            </div>
                                        </div>
                                        <div class="alert_base" *ngIf="submitted && f.account_no.status == 'INVALID'">
                                            <span>* Please enter UPI/ETC</span>
                                        </div>
                                        <div class="bank-ac-name">
                                            <div class="input-field col s12 p-0">
                                                <input placeholder="Enter mobile no" type="text"
                                                    formControlName="mobile_no" class="validate">
                                                <label class="active">Mobile No<span
                                                        class="text-danger">*</span>: </label>
                                            </div>
                                        </div>
                                        <div class="alert_base"
                                            *ngIf="submitted && f.mobile_no.status == 'INVALID'">
                                            <span>* Please enter Mobile No</span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="col s12 submit-btn mt-10"
                                [ngClass]="hideShowBankStatus ? 'bankForm-show' : 'bankForm-hide'">
                                <a class="btn mr-10 " [ngClass]="{'btn-disable': loading == true}"
                                    (click)=" validate()">
                                    <span class="animate-btn">Withdraw Request</span>
                                </a>
                            </div>
                            <div class="col s12 submit-btn mt-10"
                                [ngClass]="showBankFormStatus ? 'addBank-show' : 'addBank-hide'">
                                <a class="btn mr-10 right" (click)="addPaymentMethod()"><span class="animate-btn">Add
                                        Bank</span></a>
                                <a class="mr-10 can-btn" (click)="hideShowBankdetail()"> Back </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="data_table">
                    <table class="dataTable no-footer">
                      <tr>
                        <th>S No.</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Amount</th>
                      </tr>
            
                      <tr *ngFor="let p of statement_data;let i=index;">
                        <td>{{i+1}} .</td>
                        <td>{{p?.createdAt|date:'medium'}}</td>
                        <td>{{p?.approval_status}}</td>
                        <td>{{p?.amount}}</td>
                      </tr>
                    </table>
                  </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>