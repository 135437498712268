<aside class="left_side_bar">
  <ul class="main_menu_div">
    <li>
      <a (click)="getAllSportsListData()">
        All sports <img src="assets/images/sidebar-icon.png">
      </a>
      <div class="sport_list">
        <div class="sport-name">
          <ng-container *ngFor="let sports of allSportsList;let i= index">
            <div class="list_menu" *ngIf="sports?.sport_id > 0">
              <a (click)="getSeriesList(sports?.sport_id)">
                {{sports.name}}
              </a>
              <i class="fa fa-angle-down" (click)="getSeriesList(sports?.sport_id)"
                *ngIf="sportData != sports?.sport_id"></i>
              <i class="fa fa-angle-up" (click)="closeSportList()" *ngIf="sportData == sports?.sport_id"></i>
              <ng-container *ngIf="sportData == sports?.sport_id">
                <div class="eventleague" *ngFor="let series of allSeriesList;let j= index">
                  <a (click)="getMatchList(series, j);openSeries(series)">{{series.series_name}}
                  </a>
                  <i class="fa fa-angle-down" (click)="getMatchList(series, j)"
                    *ngIf="seriesData != series.series_id"></i>
                  <i class="fa fa-angle-up" (click)="close()" *ngIf="seriesData == series.series_id"></i>
                  <ng-container *ngIf="seriesData == series.series_id">
                    <div class="match-name" *ngFor="let match of matchList[j];let k= index">
                      <a (click)="matchDetail(match)">
                        {{match.match_name}}
                      </a>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </li>
  </ul>

  <div class="left-tab">
    <ul class="nav nav-tabs nav-justified" id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" id="provider-tab" data-bs-toggle="tab" (click)="providers()" role="tab"
          aria-controls="provider" aria-selected="true">provider</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="provider-tab" data-bs-toggle="tab" (click)="liveCasino()" role="tab"
          aria-controls="live" aria-selected="false">live</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="provider-tab" data-bs-toggle="tab" (click)="virtual()" role="tab"
          aria-controls="virtual" aria-selected="false">virtual</a>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="provider" role="tabpanel" *ngIf="showProviders == false">
        <div class="sidebar_thumb" *ngFor="let list of casino;let i = index;" (click)="openModalLoginModal(loginModal)">
          <img src="assets/Casino/{{list?.match_id}}.jpg" style="height: 90px;">
        </div>
      </div>
      <div class="tab-pane fade active" id="provider" role="tabpanel" *ngIf="showProviders == true">
        <div class="sidebar_thumb" (click)="openModalLoginModal(loginModal)">
          <img src="assets/img/SPB-aviator.png" style="height: 90px;">
        </div>
      </div>
    </div>

    <ul class="nav nav-tabs nav-justified promotion-tab" id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link" id="promotions-tab" data-bs-toggle="tab" href="#promotions" role="tab"
          aria-controls="promotions" aria-selected="false">promotions</a>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="betslip" role="tabpanel">
        <div class="sidebar_thumb">
          <img src="assets/images/thumb/p1.png" (click)="openModalLoginModal(loginModal)">
        </div>
      </div>
      <div class="tab-pane fade" id="activity" role="tabpanel">
        <div class="sidebar_thumb">
          <img src="assets/images/thumb/p1.png" (click)="openModalLoginModal(loginModal)">
        </div>
      </div>
      <div class="tab-pane fade" id="promotions" role="tabpanel">
        <div class="sidebar_thumb">
          <img src="assets/images/thumb/p1.png" (click)="openModalLoginModal(loginModal)">
        </div>
      </div>
    </div>
  </div>
</aside>

<ng-template #loginModal>
  <div class="cross_btn">
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modalRef.hide();this.createFrom();submitted= false;"></button>
  </div>
  <div class="modal-header">
    <img src="{{logo}}" class="modal_image" alt="">
  </div>
  <div class="modal-body">
    <div class="mobile_bg">
      <img src="assets/images/mobile_bg.png" alt="">
      <div class="widraw">
        100%
        <strong>FAST</strong>
        withdraw
        <div class="group">
          <img src="assets/images/group.png" alt="">
        </div>
      </div>
    </div>

    <div class="form" [formGroup]='Form'>
      <div class="input_field">
        <input type="text" class="form_control" formControlName="user_name" required>
        <label for="" class="input_label">Enter your Username</label>
        <i class="fa fa-user"></i>
      </div>
      <div class="alert_base" *ngIf="submitted && f.user_name.status == 'INVALID'">
        <span>* Please enter username</span>
      </div>
      <div class="input_field pass_field">
        <input formControlName="password" required="" [type]="showPassword ? 'text' : 'password'" class="form_control">
        <label for="" class="input_label">Enter your Password</label>
        <i alt="show" class="fa fa-eye eye-show" (click)="showPassword = !showPassword" [class.hide]="showPassword"></i>
        <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showPassword = !showPassword"
          [class.hide]="!showPassword"></i>
      </div>
      <div class="alert_base" *ngIf="submitted && f.password.status == 'INVALID'">
        <span>* Please enter password</span>
      </div>
      <div class="input_btn mt-10" (click)="onSubmitLogin('login')">
        <button class="sinup_btn">Sign In</button>
      </div>
      <div class="member">
        Don't have an account?
        <a class="ragister_link" (click)="modalRef.hide();openModalSignupModal(signupModal)">Register</a>
      </div>
      <div class="social_media">
        <ul>
          <li>
            <a target="_blank" href="{{insta}}"><img src="assets/images/instagram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{wa}}"><img src="assets/images/whatsapp1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{telegram}}"><img src="assets/images/telegram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{twitter}}"><img src="assets/images/twitter1.png" alt=""></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #signupModal>
  <div class="cross_btn">
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modalRef.hide()"></button>
  </div>
  <div class="modal-header">
    <img src="{{logo}}" class="modal_image" alt="">
  </div>
  <div class="modal-body">
    <div class="mobile_bg">
      <img src="assets/images/mobile_bg.png" alt="">
      <div class="widraw">
        100%
        <strong>FAST</strong>
        widraw
        <div class="group">
          <img src="assets/images/group.png" alt="">
        </div>
      </div>
    </div>

    <div class="form" [formGroup]='addUserFrom'>
      <div class="input_field">
        <input type="text" class="form_control" formControlName="user_name" required>
        <label for="" class="input_label">Enter your Username</label>
      </div>
      <div class="alert_base" *ngIf="isControlIsValid('user_name')">
        <span *ngIf="isControlHasErros('user_name')"> {{ isControlHasErros('user_name')}}</span>
      </div>
      <div class="input_field">
        <input type="text" formControlName="mobile" class="form_control" maxlength="10" required>
        <label for="" class="input_label">Enter your Whatsapp Number</label>
      </div>
      <div class="alert_base" *ngIf="isControlIsValid('mobile')">
        <span *ngIf="isControlHasErros('mobile')"> {{ isControlHasErros('mobile')}}</span>
      </div>
      <div class="input_field">
        <input [type]="showPassword ? 'text' : 'password'" class="form_control" formControlName="password" required>
        <label for="" class="input_label">Enter your Password</label>
        <i alt="show" class="fa fa-eye eye-show" (click)="showPassword = !showPassword" [class.hide]="showPassword"></i>
        <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showPassword = !showPassword"
          [class.hide]="!showPassword"></i>
      </div>
      <div class="alert_base" *ngIf="isControlIsValid('password')">
        <span *ngIf="isControlHasErros('password')"> {{ isControlHasErros('password')}}</span>
      </div>
      <div class="input_field" *ngIf="referCode != undefined">
        <input type="text" class="form_control" formControlName="refer_code" readonly>
        <label for="" class="input_label">Enter your Refer Code</label>
      </div>
      <div class="input_field" *ngIf="referCode == undefined">
        <input type="text" class="form_control" formControlName="refer_code">
        <label for="" class="input_label">Enter your Refer Code</label>
      </div>
      <div class="input_btn" (click)="addUserData()">
        <button class="sinup_btn">Register</button>
      </div>
      <div class="input_btn" (click)="goToRoute()">
        <button class="sinup_btn">Get Your ID</button>
      </div>
      <div class="member">
        Already have an account?
        <a class="ragister_link" (click)="modalRef.hide();openModalLoginModal(loginModal)">SignIn</a>
      </div>
      <div class="social_media">
        <ul>
          <li>
            <a target="_blank" href="{{insta}}"><img src="assets/images/instagram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{wa}}"><img src="assets/images/whatsapp1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{telegram}}"><img src="assets/images/telegram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{twitter}}"><img src="assets/images/twitter1.png" alt=""></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>