import { Component, OnInit, TemplateRef, OnDestroy, ChangeDetectorRef, HostListener } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { resolve } from 'q';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { Match } from '../model/match';
import { SportService } from '../services/sport.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SocketServiceService } from '../services/socket-service.service';
import { interval } from 'rxjs';
import { HeaderComponent } from '../header/header.component';
import { UsersService } from '../services/users.service';
import { ReportService } from '../services/report.service';

const _ = require("lodash");
@Component({
  selector: 'app-match-detail',
  templateUrl: './match-detail.component.html',
  styleUrls: ['./match-detail.component.scss'],
  providers: [HeaderComponent]
})
export class MatchDetailComponent implements OnInit, OnDestroy {
  modalRef: BsModalRef;
  
  activeBetTab: string = 'all';
  activeScoreTab: string = 'scoreBoard';
  activeScoreDiv: string = 'scoreBoardDiv';
  isSocket: number;
  timer: any;
  fancytimer: any;
  public matchDetail: any;
  fancy: any = [];
  moment: any = moment;
  matchDetailLength: number;
  betslipinfo: boolean;
  betPrice: any;
  arrayObj: any = [];
  public IsToggle = false;
  private oddBetSlipValArray: any = [];
  public oneClickSelectedBet;
  stakeIds: any;
  stackval: any;
  isActive: boolean;
  total_liability: any;
  config_max_odd_limit: any;
  setRef: any;
  userDetails: any;
  userMatchStack: any;
  user_id: any;
  betData: any;
  matchedBets: any;
  fancyBets: any;
  liveTv: string;
  graphicTv: string;
  liveUrl: SafeResourceUrl;
  graphicTvUrl: SafeResourceUrl;
  match_id: any;
  matchData: any;
  matchName: any;
  matchDate: any;
  tv: boolean = false;
  graph: boolean = false;
  toggleInfo: boolean = false;
  scoreData: any;
  scoreBoard: any;
  scoreLength: any;
  score: boolean;
  perball: any;
  callingType = 1;
  callingFancyType = 1
  selection: any;
  market: any;
  matchLength: any;
  inplayStatus: boolean;
  teamPositionData: any;
  allBetData: any
  graphicScore: boolean;
  allPopBetData: any;
  selectedMarket: any;
  showFancyList: boolean;
  userData: {};
  firstData: any;
  homeData: any;
  matchedBetsLength: any;
  fancyBetsLength: any;
  allBetDataLength: any;
  disablePlaceBet: boolean = false;
  disableFancyPlaceBet: boolean = false;
  loading: boolean;
  fancyLoading: boolean;
  fancyPosData: any;
  displayfancyPositionBox: Array<boolean> = []
  selectedFancyMarket: any;
  applyUserValidation: boolean;
  fancyLiability: any;
  twenty: boolean;
  param: any;
  betSize: any;
  marketIds: any = [];
  marketRunnerData: any;
  matchDetailFirst: any;
  runnerObjectData: any = [];
  objectData: any;
  marketObjectData: any;
  sampleObjectData: any;
  sportsSettingValues: any;
  showSetting: boolean;
  sessionSetting: any;
  fancySetting: boolean;
  check_event_limit: any;
  fancyIdValidation: any;
  fancyLength: any;
  betDataPopup: boolean;
  message: string;
  fancyDataArray = [];
  fancyArray: any;
  fancyRunnerData: any;
  primaryFancy: any;
  ring: boolean = false;
  ringOn: boolean = false;
  walletBalance: any;
  currentExpo: number;
  currentBalance: number;
  detailCalling = 1;
  marketId: any = [];
  fancyId: any = [];
  marketData: any;
  socketData: any;
  fancyDta: any;
  createFancyType: number = 1;
  runnerData: any;
  fancyTimer: any;
  pageSize: number;
  dataSource: any;
  openBetsLength: any = 0;
  MatchList: any;
  searchMatch: any;
  layData: any = [];
  backData: any = [];
  cuurency: string;
  popFancyId: any;
  popShow: boolean;
  showBet: any = 0;
  tvId: any = 0;
  exposuerLimit: any;
  marketLiability: any;
  fancyExpoLiability: any;
  marketCurrentData: any;
  marketPL: number;
  marketLiabilityCondition: boolean;
  currentFancyLiability: any;
  fancySource: any;
  totalMaxLoss: number;
  volumeLimit: boolean = false;
  minVolLimit: boolean = false;
  targetDate: Date;
  countdown: {
    days: number,
    hours: number,
    minutes: number,
    seconds: number
  };

  betDataList: any;
  dataSourceLength: any;
  selectedMarket_id: any;
  token: string;
  operator_id: string;
  games: string;
  enableFancy: any;
  timerInplayStatus: boolean = false;
  marketView: boolean = true;
  fancyView: boolean = true;
  callbet: any = 1;
  betType: number;
  fancyRunnerTimer: any;
  Runnertimer: any;
  fancySelection: any;
  fancySessionData: any[];
  sessionMarketFancyData: any[];
  overByOverFancyData: any[];
  ballByballFancyData: any[];
  @HostListener("document:visibilitychange", ['$event'])
  visibilitychange(event: any) {
    if (!document.hidden) {
      this.socketService.socket.close();
      this.socketService.socket.disconnect();
      this.refresh();
    }
  }
  refresh(): void {
    // this.router.navigateByUrl("/openNews", { skipLocationChange: true }).then(() => {
    //   this.router.navigate([decodeURI(this._location.path())]);
    // });
    if (this.isSocket == 0) {
      clearTimeout(this.timer);
      clearTimeout(this.fancyTimer);
    }
    this.getMatchDetail(0);
    this.socketStatus();
    this.myTeamPosition();
    this.matchData = JSON.parse(localStorage.getItem('matchData'));
    this.enableFancy = this.matchData.enable_fancy;
    if (this.enableFancy == 1) {
      this.showFancy();
    }
  }
  constructor(private ref: ChangeDetectorRef, private route: ActivatedRoute, public sanitizer: DomSanitizer, private router: Router, public toastr: ToastrService,
    public matchModel: Match, private sportService: SportService, private modalService: BsModalService, private usersService: UsersService, private reportService: ReportService,
    private socketService: SocketServiceService, private head: HeaderComponent) {

    this.matchData = JSON.parse(localStorage.getItem('matchData'));
    this.route.params.subscribe(params => {
      if (params.match_id == undefined) {
        this.match_id = this.matchData.match_id;
        this.socketStatus()
      } else {
        this.match_id = params.match_id
        this.socketStatus()
      }
    })
  }
  toggleLimitInfo(id) {
    this.selectedMarket_id = id;
    this.toggleInfo = !this.toggleInfo;
  }
  toggleLiveTVStatus: boolean = false;
  toggleLiveTV() {
    this.toggleLiveTVStatus = !this.toggleLiveTVStatus;
    if (this.matchData.match_tv_url == '' || this.matchData.match_tv_url == null) {
      this.tv = false
    } else {
      this.tv = true;
      this.liveTv = this.matchData.match_tv_url;
      this.liveUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.liveTv);
    }
  }
  onIframeLoad(event: Event) {
    const iframe = event.target as HTMLIFrameElement;
    if (iframe.contentDocument?.readyState === 'complete') {
      // iframe loaded successfully
    } else {
      // an error occurred while loading the iframe
      console.error('Error loading iframe');
    }
  }
  toggleLiveScoreStatus: boolean = false;
  toggleLiveScore() {
    this.toggleLiveScoreStatus = !this.toggleLiveScoreStatus;
    if (this.matchData.match_scoreboard_url == '' || this.matchData.match_scoreboard_url == null) {
      this.graph = false
    } else {
      this.graph = true;
      this.graphicTv = this.matchData.match_scoreboard_url;
      this.graphicTvUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.graphicTv);
    }
  }
  socketStatus() {
    this.sportService.getSocketStatus().subscribe((res) => {
      this.isSocket = res.is_socket;
      this.isSocket = 0;
      this.matchData = JSON.parse(localStorage.getItem('matchData'));
      if (this.isSocket != 0) {
        this.socketListeners();
        this.socketService.setUpSocketConnection();
        this.socketError();
        this.socketFancySuccess();
        this.socketSuccess();
      }
    })
  }
  async ngOnInit() {
    this.matchData = JSON.parse(localStorage.getItem('matchData'));
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.teamPositionData = {};
    this.applyValidation();
    this.marketSettingApi(this.matchData.sport_id, this.matchData.match_id)
    if (this.matchData.sport_id == '4') {
      this.showFancy();
    }
    this.getMatchDetail(0);
    this.openBets();
    this.myTeamPosition();
    this.getFancyLiability(this.matchData.match_id, 1);
    this.match_id = this.matchData.match_id;
    this.matchName = this.matchData.match_name;
    this.matchDate = this.matchData.match_date;
    this.targetDate = new Date(this.matchData?.match_date);
    const source = interval(1000);
    const now = new Date().getTime();
    const difference = this.targetDate.getTime() - now;
    this.countdown = this.calculateCountdown(difference);
    const subscription = source.subscribe(val => {
      const now = new Date().getTime();
      const difference = this.targetDate.getTime() - now;
      this.countdown = this.calculateCountdown(difference);
      if (this.countdown.seconds <= 0 && this.countdown.minutes <= 0 && this.countdown.hours <= 0 && this.countdown.days <= 0) {
        // Countdown is complete
        this.timerInplayStatus = true;
        subscription.unsubscribe();
      }
    });
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.getUserMatchStackDetails();
  }

  CashOut(market:any){
  
    let greenAmount=0;
    let redAmount=0;
    let currentbackredodds=0;
    let currentlaygrrenodds=0;
    let runners;
    let runners_point=[];
    let runners_v={};
    let diff_arr=[];
    for(var i=0;i<market.runners.length;i++){
      diff_arr.push(market?.runners[i].win_loss); 
      if(market?.runners[i].win_loss>0){
        greenAmount=market?.runners[i].win_loss;
        currentlaygrrenodds=market.runners[i]?.ex?.availableToLay[0]?.price;
        runners=market.runners[i];
        runners_point.push(greenAmount)
      }
      else
      {
        redAmount=market?.runners[i].win_loss;
        runners_point.push(redAmount);
        runners_v[i]=market.runners[i];
        currentbackredodds=market.runners[i]?.ex?.availableToBack[0]?.price;
       
      }

    }
   
    let backStake=(greenAmount-redAmount)/currentbackredodds;
    let layStake=(greenAmount-redAmount)/currentlaygrrenodds;
    if(!runners){
      if(Math.trunc(runners_point[0])==Math.trunc(runners_point[1])){
       
        
        return;
      }
      
      if(runners_point[0]>runners_point[1]){
        runners=market.runners[0];
        greenAmount=market?.runners[0].win_loss;
        currentlaygrrenodds=market.runners[0]?.ex?.availableToLay[0]?.price;
        redAmount=market?.runners[1].win_loss;
      }
      else
      {
        runners=market.marketBook.runners[1];
        greenAmount=market?.runners[1].win_loss;
        currentlaygrrenodds=market.runners[1]?.ex?.availableToLay[0]?.price;
        redAmount=market?.runners[0].win_loss;
      }

    }
    
    
    if(layStake===Infinity){
       layStake=(greenAmount-redAmount)/currentlaygrrenodds;
      }
    
      if(diff_arr[0]>0 && diff_arr[1]>0){
        let diff=diff_arr[0]-1*diff_arr[1];
        if(diff<0){
          diff=-1*diff;
        }
        if(diff<4){
          return;
        }
      }
     
      if(diff_arr[0]<0 && diff_arr[1]>0){
        let diff=diff_arr[1]+1*diff_arr[1];
        if(diff<0){
          diff=-1*diff;
        }
      if(diff<4){
        return;
      }
    }
    
    if(diff_arr[0]>0 && diff_arr[1]<0){
      let diff=diff_arr[0]+1*diff_arr[1];
      if(diff<0){
        diff=-1*diff;
      }
      if(diff< 4 ){
        return;
      }
    }
  
      if(diff_arr[0]<0 && diff_arr[1]<0){
        let diff=-1*diff_arr[0]+diff_arr[1];
        if(diff<0){
          diff=-1*diff;
        }
        if(diff<4){
          return;
        }
        
      }
      
      this.getOddsValue((currentlaygrrenodds),currentlaygrrenodds,0,runners.name,runners.selectionId,market.market_id,0,market,layStake);
    
    }

  calculateCountdown(difference: number) {
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    return {
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds
    };
  }
  applyValidation() {
    let data = {};
    this.sportService.applyValidation(data).subscribe((res) => {
      if (res.status == true) {
        this.applyUserValidation = res.applyValidation;
      } else {
        this.applyUserValidation = false;
      }
    })
  }
  marketSettingApi(sport_id, match_id) {
    let data = {
      "sport_id": sport_id,
      "match_id": match_id,
    };
    this.sportService.marketSetting(data).subscribe((res) => {
      if (res.status == true) {
        this.exposuerLimit = res.exposure_limit;
        this.check_event_limit = res.check_event_limit
        if (this.check_event_limit == true) {
          this.sportsSettingValues = res.data;
          this.sessionSetting = res.data.session;
          this.fancySetting = true;
        } else {
          this.sportsSettingValues = res.data.market;
          this.sessionSetting = res.data.session;
          this.fancySetting = false;
        }
        this.showSetting = true;
        this.ref.detectChanges();
      } else {
        this.applyUserValidation = false;
        this.showSetting = false;
        this.ref.detectChanges();
      }
    },
      () => {
        if (this.router.url.split('?')[0] == '/match-detail') {
          if (this.isSocket == 0) {
            this.timer = setTimeout(() => resolve(this.marketSettingApi(this.matchData.sport_id, this.matchData.match_id)), 10000);
          } else {
            clearTimeout(this.timer);
          }
        }
      })
  }
  getMatchDetail(id) {
    clearTimeout(this.timer);
    this.matchData = JSON.parse(localStorage.getItem('matchData'));
    let data = {
      "match_id": this.matchData.match_id
    }
    this.sportService.matchDetails(data).subscribe((res) => {
      this.matchDetailFirst = res.data;
      this.matchDetailLength = res.data.length;
      if (this.check_event_limit == true) {
        for (let i = 0; i < this.matchDetailFirst.length; i++) {
          if (this.sportsSettingValues != undefined) {
            for (let j = 0; j < Object.keys(this.sportsSettingValues).length; j++) {
              if (this.matchDetailFirst[i].market_id == Object.keys(this.sportsSettingValues)[j]) {
                let key = Object.values(this.sportsSettingValues)[j]
                this.matchDetailFirst[i].min_stake = Object.values(this.sportsSettingValues)[j]['market_min_stack']
                this.matchDetailFirst[i].max_stake = Object.values(this.sportsSettingValues)[j]['market_max_stack']
                this.matchDetailFirst[i].max_profit = Object.values(this.sportsSettingValues)[j]['market_max_profit']
              }
            }
          }

        }
      } else {
        if (this.sportsSettingValues != undefined) {
          this.matchDetailFirst.forEach(matDta => {
            matDta.min_stake = this.sportsSettingValues.market_min_stack;
            matDta.max_stake = this.sportsSettingValues.market_max_stack;
            matDta.max_profit = this.sportsSettingValues.market_max_profit;
          });
        }
      }
      for (let i = 0; i < this.matchDetailFirst.length; i++) {
        this.matchDetailFirst[i].updateNews = this.matchDetailFirst[i].news == '' ? undefined : this.matchDetailFirst[i].news;
      }
      this.matchDetailFirst.forEach(matDta => {
        matDta["shadowRunner"] = [...matDta["runners"]];
      });
      if (id == 0) {
        this.matchDetailFirst = this.matchDetailFirst;
      } else {
        this.matchDetailFirst = this.matchDetailFirst.filter(t => t.market_name == "Match Odds")
      }
      // if (this.isSocket != 1) {
      //   this.matchRunner();
      // } else {
      //   this.matchRunner();
      // }
      if (this.callingType == 1) {
        this.matchRunner();
        // this.getRunnerEmit()
        this.callingType = 2;
      } else {
        if (this.isSocket != 0) {
          this.getRunnerEmit();
        } else {
          clearTimeout(this.Runnertimer);
          this.matchRunner();
        }
      }
      let matchOddds
      this.matchDetailFirst.forEach((matDta, index) => {
        if (matDta.name == 'Match Odds') {
          matchOddds = this.matchDetailFirst.splice(index, 1)
        }
      });
      this.matchDetailFirst.unshift(matchOddds[0])
      this.ref.detectChanges();
    }, (err) => {
      // this.scoreApi();
    },
      () => {
        if (this.router.url.split('?')[0] == '/match-detail') {
          if (this.isSocket == 0) {
            this.timer = setTimeout(() => resolve(this.getMatchDetail(0)), 5000);
          } else {
            clearTimeout(this.timer);
          }
        }
      })
  }

  matchRunner() {
    clearTimeout(this.Runnertimer);
    for (let i = 0; i < this.matchDetailFirst.length; i++) {
      let data = "ODDS_" + this.matchDetailFirst[i].market_id
      this.marketIds.push(data);
    }
    this.matchData = JSON.parse(localStorage.getItem('matchData'));
    let marketData = {
      "match_id": this.matchData.match_id,
      "marketIds": this.marketIds
    }
    this.sportService.matchDetailRunners(marketData).subscribe((res) => {
      if (res.status) {
        this.marketIds = [];
        this.marketRunnerData = res.data;
        for (let i = 0; i < this.matchDetailFirst.length; i++) {
          for (let j = 0; j < this.marketRunnerData.length; j++) {
            if (this.marketRunnerData[j].hasOwnProperty('totalMatched')) {
              if (this.marketRunnerData[j].totalMatched > 999 && this.marketRunnerData[j].totalMatched < 1000000) {
                this.cuurency = (this.marketRunnerData[j].totalMatched / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
              } else if (this.marketRunnerData[j].totalMatched > 1000000) {
                this.cuurency = (this.marketRunnerData[j].totalMatched / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
              } else if (this.marketRunnerData[j].totalMatched < 900) {
                this.cuurency = this.marketRunnerData[j].totalMatched; // if value < 1000, nothing to do
              }
            } else {
              this.cuurency = '0';
            }
            this.matchDetailFirst[i].matched = this.cuurency;
            this.matchDetailFirst[i].status = this.marketRunnerData[j].status;
            if (this.matchDetailFirst[i].market_id == this.marketRunnerData[j].marketId) {
              for (let k = 0; k < this.matchDetailFirst[i].runners.length; k++) {
                for (let l = 0; l < this.marketRunnerData[j].runners.length; l++) {
                  if (this.matchDetailFirst[i].runners[k].selectionId == this.marketRunnerData[j].runners[l].selectionId) {
                    (this.marketRunnerData[j].runners[l].name) = (this.matchDetailFirst[i].runners[k].name);
                    (this.marketRunnerData[j].runners[l].selection_name) = (this.matchDetailFirst[i].runners[k].name);
                    (this.marketRunnerData[j].runners[l].win_loss) = (this.matchDetailFirst[i].runners[k].win_loss);
                  }
                }
              }
              break
            } else {
              this.marketObjectData = [];
              this.objectData = [];
              this.runnerObjectData = []
              for (let k = 0; k < this.matchDetailFirst[i].runners.length; k++) {
                this.objectData = {
                  "selectionId": this.matchDetailFirst[i].runners[k].selectionId,
                  "name": this.matchDetailFirst[i].runners[k].name,
                  "selection_name": this.matchDetailFirst[i].runners[k].selection_name,
                  "win_loss": this.matchDetailFirst[i].runners[k].win_loss,
                  "status": "SUSPENDED",
                  "ex": {
                    "availableToBack": [
                      {
                        "price": '--',
                        "size": '--'
                      },
                      {
                        "price": '--',
                        "size": '--'
                      },
                      {
                        "price": '--',
                        "size": '--'
                      }
                    ],
                    "availableToLay": [
                      {
                        "price": '--',
                        "size": '--'
                      },
                      {
                        "price": '--',
                        "size": '--'
                      },
                      {
                        "price": '--',
                        "size": '--'
                      }
                    ]
                  }
                }
                this.runnerObjectData.push(this.objectData)
              }
              this.marketObjectData = {
                "inplay": false,
                "marketId": this.matchDetailFirst[i].market_id,
                "runners": this.runnerObjectData,
                "status": "SUSPENDED",
              }
              const found = this.marketRunnerData.some(el => el.marketId === this.matchDetailFirst[i].market_id);
              if (!found) {
                this.marketRunnerData.push(this.marketObjectData);
              }
            }
          }
        }
        const a3 = this.matchDetailFirst
        this.matchDetailFirst = a3
        if (this.matchDetailLength != 0) {
          this.inplayStatus = res.data[0].inplay;
          if (this.callingType == 1) {
            this.callingType = 2;
            this.matchDetailFirst.forEach(matDta => {
              matDta["shadowRunner"] = [...matDta["runners"]];
            });
            for (let i = 0; i < this.matchDetailFirst.length; i++) {
              if (this.teamPositionData != undefined || this.teamPositionData != null || this.teamPositionData != '') {
                for (let j = 0; j < Object.keys(this.teamPositionData).length; j++) {
                  if (this.matchDetailFirst[i].marketId == Object.keys(this.teamPositionData)[j]) {
                    for (let k = 0; k < this.matchDetailFirst[i].runners.length; k++) {
                      let key = Object.values(this.teamPositionData)[j]
                      for (let l = 0; l < Object.keys(key).length; l++) {
                        if (this.matchDetailFirst[i].runners[k].selectionId == key[l].selection_id) {
                          this.matchDetailFirst[i].runners[k].win_loss = key[l].win_loss;
                          this.matchDetailFirst[i].runners[k].win_loss_total_exposure = key[l].win_loss_total_exposure;
                        }
                      }
                    }
                  }
                }
              }
            }
          } else {
            for (let mDt = 0; mDt < this.matchDetailFirst.length; mDt++) {
              for (let runsIndex = 0; runsIndex < this.marketRunnerData.length; runsIndex++) {
                if (this.matchDetailFirst[mDt].market_id == this.marketRunnerData[runsIndex].marketId) {
                  this.matchDetailFirst[mDt]["shadowRunner"] = [];
                  for (let i = 0; i < this.matchDetailFirst[mDt].runners.length; i++) {
                    for (let j = 0; j < (this.marketRunnerData[runsIndex].runners).length; j++) {
                      if (this.matchDetailFirst[mDt].runners[i].selectionId == this.marketRunnerData[runsIndex].runners[j].selectionId) {
                        this.matchDetailFirst[mDt].shadowRunner[i] = {};
                        this.matchDetailFirst[mDt].shadowRunner[i] = JSON.parse(JSON.stringify(this.matchDetailFirst[mDt].runners[i]));
                        this.matchDetailFirst[mDt].runners[i].ex = JSON.parse(JSON.stringify(this.marketRunnerData[runsIndex].runners[j].ex));
                       //if(this.marketRunnerData[runsIndex]?.runners[j]?.status){
                        this.matchDetailFirst[mDt].runners[i].status = JSON.parse(JSON.stringify(this.marketRunnerData[runsIndex]?.runners[j]?.status));
                       //}
                       
                      }
                    }
                  }
                }
              }
            }
            for (let i = 0; i < this.matchDetailFirst.length; i++) {
              if (this.check_event_limit == true) {
                if (this.sportsSettingValues != undefined) {
                  for (let j = 0; j < Object.keys(this.sportsSettingValues).length; j++) {
                    if (this.matchDetailFirst[i].market_id == Object.keys(this.sportsSettingValues)[j]) {
                      let key = Object.values(this.sportsSettingValues)[j]
                      this.matchDetailFirst[i].min_stake = Object.values(this.sportsSettingValues)[j]['market_min_stack']
                      this.matchDetailFirst[i].max_stake = Object.values(this.sportsSettingValues)[j]['market_max_stack']
                      this.matchDetailFirst[i].max_profit = Object.values(this.sportsSettingValues)[j]['market_max_profit']
                    }
                  }
                }
              } else {
                if (this.sportsSettingValues != undefined) {
                  this.matchDetailFirst.forEach(matDta => {
                    matDta.min_stake = this.sportsSettingValues.market_min_stack;
                    matDta.max_stake = this.sportsSettingValues.market_max_stack;
                    matDta.max_profit = this.sportsSettingValues.market_max_profit;
                  });
                }
              }
              if (this.teamPositionData != undefined || this.teamPositionData != null || this.teamPositionData != '') {
                for (let j = 0; j < Object.keys(this.teamPositionData).length; j++) {
                  if (this.matchDetailFirst[i].market_id == Object.keys(this.teamPositionData)[j]) {
                    for (let k = 0; k < this.matchDetailFirst[i].runners.length; k++) {
                      let key = Object.values(this.teamPositionData)[j]
                      for (let l = 0; l < Object.keys(key).length; l++) {
                        if (this.matchDetailFirst[i].runners[k].selectionId == key[l].selection_id) {
                          this.matchDetailFirst[i].runners[k].win_loss = key[l].win_loss;
                          this.matchDetailFirst[i].runners[k].win_loss_total_exposure = key[l].win_loss_total_exposure;
                        }
                      }
                    }
                  }
                }
              }
              for (let o = 0; o < this.matchDetailFirst[i].runners.length; o++) {
                if ((this.matchDetailFirst[i].runners[o].ex.availableToBack).length != 0) {
                  for (let m = 0; m < (this.matchDetailFirst[i].runners[o].ex.availableToBack).length; m++) {
                    if (this.matchDetailFirst[i].runners[o].ex.availableToBack[m] != null) {
                      if (this.matchDetailFirst[i].runners[o].ex.availableToBack[m].size > 999 && this.matchDetailFirst[i].runners[o].ex.availableToBack[m].size < 1000000) {
                        this.matchDetailFirst[i].runners[o].ex.availableToBack[m].size = (this.matchDetailFirst[i].runners[o].ex.availableToBack[m].size / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
                      } else if (this.matchDetailFirst[i].runners[o].ex.availableToBack[m].size > 1000000) {
                        this.matchDetailFirst[i].runners[o].ex.availableToBack[m].size = (this.matchDetailFirst[i].runners[o].ex.availableToBack[m].size / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
                      } else if (this.matchDetailFirst[i].runners[o].ex.availableToBack[m].size < 900) {
                        this.matchDetailFirst[i].runners[o].ex.availableToBack[m].size = this.matchDetailFirst[i].runners[o].ex.availableToBack[m].size; // if value < 1000, nothing to do
                      }
                    }
                  }
                }
                if ((this.matchDetailFirst[i].runners[o].ex.availableToLay).length != 0) {
                  for (let n = 0; n < (this.matchDetailFirst[i].runners[o].ex.availableToLay).length; n++) {
                    if (this.matchDetailFirst[i].runners[o].ex.availableToLay[n] != null) {
                      if (this.matchDetailFirst[i].runners[o].ex.availableToLay[n].size > 999 && this.matchDetailFirst[i].runners[o].ex.availableToLay[n].size < 1000000) {
                        this.matchDetailFirst[i].runners[o].ex.availableToLay[n].size = (this.matchDetailFirst[i].runners[o].ex.availableToLay[n].size / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
                      } else if (this.matchDetailFirst[i].runners[o].ex.availableToLay[n].size > 1000000) {
                        this.matchDetailFirst[i].runners[o].ex.availableToLay[n].size = (this.matchDetailFirst[i].runners[o].ex.availableToLay[n].size / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
                      } else if (this.matchDetailFirst[i].runners[o].ex.availableToLay[n].size < 900) {
                        this.matchDetailFirst[i].runners[o].ex.availableToLay[n].size = this.matchDetailFirst[i].runners[o].ex.availableToLay[n].size; // if value < 1000, nothing to do
                      }
                    }
                  }
                }
              }
            }
          }
          let matchOddds
          this.matchDetailFirst.forEach((matDta, index) => {
            if (matDta.name == 'Match Odds') {
              matchOddds = this.matchDetailFirst.splice(index, 1)
            }
          });
          this.matchDetailFirst.unshift(matchOddds[0])
          this.matchDetail = this.matchDetailFirst;
          this.ref.detectChanges()
          if (this.isSocket != 0) {
            this.getRunnerEmit();
          }
        } else {
          console.log("No record found");
        }
      } else {
        this.marketIds = [];
        for (let i = 0; i < this.matchDetailFirst.length; i++) {
          for (let j = 0; j < this.matchDetailFirst[i].runners.length; j++) {
            this.sampleObjectData =
            {
              "availableToBack": [
                {
                  "price": "--",
                  "size": "--"
                },
                {
                  "price": "--",
                  "size": "--"
                },
                {
                  "price": "--",
                  "size": "--"
                }
              ],
              "availableToLay": [
                {
                  "price": "--",
                  "size": "--"
                },
                {
                  "price": "--",
                  "size": "--"
                },
                {
                  "price": "--",
                  "size": "--"
                }
              ]
            }
              ;
            this.matchDetailFirst[i].runners[j].ex = this.sampleObjectData;
          }
        }
        for (let i = 0; i < this.matchDetailFirst.length; i++) {
          if (this.teamPositionData != undefined || this.teamPositionData != null || this.teamPositionData != '') {
            for (let j = 0; j < Object.keys(this.teamPositionData).length; j++) {
              if (this.matchDetailFirst[i].market_id == Object.keys(this.teamPositionData)[j]) {
                for (let k = 0; k < this.matchDetailFirst[i].runners.length; k++) {
                  let key = Object.values(this.teamPositionData)[j]
                  for (let l = 0; l < Object.keys(key).length; l++) {
                    if (this.matchDetailFirst[i].runners[k].selectionId == key[l].selection_id) {
                      this.matchDetailFirst[i].runners[k].win_loss = key[l].win_loss;
                      this.matchDetailFirst[i].runners[k].win_loss_total_exposure = key[l].win_loss_total_exposure;
                    }
                  }
                }
              }
            }
          }
        }
        this.matchDetailFirst.forEach(matDta => {
          matDta["shadowRunner"] = [...matDta["runners"]]
        });
        this.matchDetail = this.matchDetailFirst;
        if (this.isSocket != 0) {
          this.getRunnerEmit();
        }
      }
    }, (err) => {
      // this.scoreApi();
    },
      () => {
        if (this.router.url.split('?')[0] == '/match-detail') {
          if (this.isSocket != 1) {
            this.Runnertimer = setTimeout(() => resolve(this.matchRunner()), 500);
          } else {
            clearTimeout(this.Runnertimer);
          }
        }
      })
  }
  addClassWhenOddChange(newPrice, oldPrice, i, type) {
    if ((newPrice != oldPrice) && type == 'back') {
      return 'backColorChange' + i;
    }
    else if ((newPrice != oldPrice) && type == 'lay') {
      return 'layColorChange' + i;
    }
  }
  trackHero(index, runner) {
  }
  betslipStatus: boolean = false;
  hideClose() {
    this.betslipStatus = false;
  }
  activeBetslip() {
    this.betslipStatus = true;
  }
  getOddsValue(price, size, isback, name, id, marketid, index, marketitem,stake) {
    
    this.marketCurrentData = marketitem;
    this.ring = false;
    this.betslipinfo = true;
    this.betslipStatus = true;
    this.betPrice = price;
    this.selection = id;
    this.market = marketid;
    this.betType = 0;
    this.arrayObj = {
      selection_id: id,
      market_id: marketid,
      size: size,
      odds: price,
      stake: parseInt(stake),
      is_back: isback,
      is_fancy: 0,
      MarketName: marketitem.name,
      placeName: name,
      isManual: 0,
      is_session_fancy: 'N'
    };
    localStorage.setItem('betList', JSON.stringify(this.arrayObj));
    this.matchModel.setUser(this.arrayObj);
    this.getBetSlipDataForOdds();
  }

  setOddsDec(index) {
    const tempValue = this.matchModel.tempArray[index].odds;
    this.matchModel.tempArray[index].odds = +(tempValue - 0.01).toFixed(2);
  }

  setOddsInc(index) {
    const tempValue = this.matchModel.tempArray[index].odds;
    this.matchModel.tempArray[index].odds = +(tempValue + 0.01).toFixed(2);
  }
  setFancyOddsDec(index) {
    const tempValue = this.matchModel.tempArray[index].odds;
    this.matchModel.tempArray[index].odds = +(tempValue - 1);
  }

  setFancyOddsInc(index) {
    const tempValue = this.matchModel.tempArray[index].odds;
    this.matchModel.tempArray[index].odds = +(tempValue + 1);
  }

  setSessionValue(FancyObject, is_back, value, size, liability) {
    this.currentFancyLiability = liability == undefined ? 0 : liability
    this.ring = false;
    this.betslipinfo = true;
    this.betslipStatus = true;
    this.betPrice = value;
    this.betSize = size;
    this.market = FancyObject.fancy_id
    this.fancySelection = FancyObject.fancy_id;
    this.fancyIdValidation = FancyObject.fancy_id;
    this.arrayObj = {
      fancy_id: FancyObject.fancy_id,
      market_id: FancyObject.market_id,
      odds: value,
      stake: 0,
      is_fancy: 1,
      is_back: is_back,
      MatchName: FancyObject.name,
      placeName: FancyObject.name,
      isManual: 0,
      size: size,
      is_session_fancy: 'Y'
    };
    this.betType = 1;
    this.matchModel.isbetslipshow = true;
    localStorage.setItem('betList', JSON.stringify(this.arrayObj));
    this.matchModel.setUser(this.arrayObj);
    this.getBetSlipDataForOdds();
  }
  getBetSlipDataForOdds() {
    this.matchModel.tempArray = [];
    let oddBetSlipVal = JSON.parse(localStorage.getItem('betList'));
    this.oddBetSlipValArray.push(oddBetSlipVal);
    this.matchModel.tempArray.push(oddBetSlipVal);
    if (this.IsToggle) {
      this.matchModel.tempArray[0].stake = this.oneClickSelectedBet;
      this.matchModel.tempArray[0].p_l = ((this.matchModel.tempArray[0].odds * this.matchModel.tempArray[0].stake) - this.matchModel.tempArray[0].stake);
    }
  }

  calculateP_lOnStackOnInput(stake, stkbtn, isback, back) {
    if (stake <= 0) {
      back.stake = 0;
    } else {
      this.stakeIds = this.matchModel.stakeIds;
      this.stackval = (back.stake == '' || back.stake == null) ? 0 : back.stake;
      back.stake = parseFloat(stkbtn);
      if (parseFloat(back.stake) <= parseFloat(back.max_bet_liability) || true) {
        this.isActive = true;
        back.p_l = ((back.odds * back.stake) - back.stake);
        if (isback == 0) {
          this.total_liability = back.p_l;
        } else {
          this.total_liability = back.stake;
        }
        this.matchModel.calculateProfitLoss(back);
        if (back.priceVal <= this.config_max_odd_limit || back.is_session_fancy == 'Y' || true) {
          if (back.priceVal > 0) {
            const tempback = back;
            this.isActive = true;
            this.total_liability = 0;
            if (back.isManual) {
              const pval = back.pricefinal + 1;
              back.p_l = ((pval * back.stake) - back.stake);
            } else {
              back.p_l = ((back.priceVal * back.stake) - back.stake);
            }
            this.matchModel.ProfitLoss = back.p_l;
          }
        }
      } else {
        let msg = '';
        if (back.is_session_fancy == 'Y') {
          msg = 'Max session bet liability is ' + parseFloat(back.max_bet_liability);
        } else {
          msg = 'Max market bet liability is ' + parseFloat(back.max_bet_liability);
        }
        back.stake = parseFloat(back.max_bet_liability);
        this.isActive = false;
      }
    }
  }
  calculateP_lOnStack(stake, stkbtn, isback, back) {

    this.stakeIds = this.matchModel.stakeIds;
    this.stackval = (back.stake == '' || back.stake == null) ? 0 : back.stake;
    back.stake = parseFloat(stkbtn);
    if (parseFloat(back.stake) <= parseFloat(back.max_bet_liability) || true) {
      this.isActive = true;
      back.p_l = ((back.odds * back.stake) - back.stake);
      if (isback == 0) {
        this.total_liability = back.p_l;
      } else {
        this.total_liability = back.stake;
      }
      this.matchModel.calculateProfitLoss(back);
      if (back.odds <= this.config_max_odd_limit || back.is_session_fancy == 'Y' || true) {
        if (back.odds > 0) {
          const tempback = back;
          this.isActive = true;
          this.total_liability = 0;
          if (back.isManual) {
            const pval = back.pricefinal + 1;
            back.p_l = ((pval * back.stake) - back.stake);
          } else {
            back.p_l = ((back.odds * back.stake) - back.stake);
          }
          this.matchModel.ProfitLoss = back.p_l;


        }
      }
    } else {
      let msg = '';
      if (back.is_session_fancy == 'Y') {
        msg = 'Max session bet liability is ' + parseFloat(back.max_bet_liability);
      } else {
        msg = 'Max market bet liability is ' + parseFloat(back.max_bet_liability);
      }
      back.stake = parseFloat(back.max_bet_liability);
      this.isActive = false;
    }
  }
  setbtn(indx) {
    this.setRef = indx;
  }
  getUserMatchStackDetails() {
    this.sportService.getUserMatchStack(this.userDetails._id).subscribe(response => {
      if (response.status == true) {
        this.userMatchStack = response.data.match_stack;
      } else {
        this.toastr.error(response.msg);
      }
    })
  }
  clearAll = function () {
    this.selection = undefined
    this.betslipinfo = false;
    this.betslipStatus = false;
    this.IsShowBetInfo = false;
    this.total_liability = 0;
    this.isActive = false;
    for (let i = 0; i < this.matchModel.stakeIds.length; i++) {
      this.matchModel.stake2['field_' + this.matchModel.stakeIds[i].UId] = 0;
    }
    this.ref.detectChanges();
  };

  showFancy() {
    clearTimeout(this.fancyTimer);
    this.matchData = JSON.parse(localStorage.getItem('matchData'));
    // if (this.callingFancyType == 1) {
    //   let param = {
    //     "user_id": this.userDetails._id,
    //     "match_id": this.matchData.match_id,
    //   }
    //   this.sportService.showFancyList(param).subscribe((res) => {
    //     if (res.status == true) {
    //       if (res.data.length == 0) {
    //         // this.callingFancyType = 1;
    //         this.fancy = [];
    //         this.showFancyList = false;
    //         this.ref.detectChanges();
    //       } else {
    //         this.showFancyList = true;
    //         // if(this.isSocket != 0){
    //         // this.callingFancyType = 2;
    //         // }
    //         let fancyData = res.data;

    //         for (let i = 0; i < fancyData.length; i++) {
    //           if (this.sessionSetting != undefined) {
    //             for (let j = 0; j < Object.keys(this.sessionSetting).length; j++) {
    //               if (fancyData[i].fancy_id == Object.keys(this.sessionSetting)[j]) {
    //                 let key = Object.values(this.sessionSetting)[j]
    //                 fancyData[i].min_stake = Object.values(this.sessionSetting)[j]['session_min_stack']
    //                 fancyData[i].max_stake = Object.values(this.sessionSetting)[j]['session_max_stack']
    //                 fancyData[i].max_profit = Object.values(this.sessionSetting)[j]['session_max_profit']
    //               }
    //             }
    //           }

    //         }
    //         this.primaryFancy = fancyData;
    //         this.fancyDataArray = [];
    //         for (let i = 0; i < this.primaryFancy.length; i++) {
    //           this.fancyArray = {
    //             news: this.primaryFancy[i].news == '' ? undefined : this.primaryFancy[i].news,
    //             display_message: 'Loading...',
    //             fancy_id: this.primaryFancy[i].fancy_id,
    //             is_active: this.primaryFancy[i].is_active,
    //             is_created: this.primaryFancy[i].is_created,
    //             is_lock: this.primaryFancy[i].is_lock,
    //             max_profit: this.primaryFancy[i].max_profit,
    //             max_stake: this.primaryFancy[i].max_stake,
    //             min_stake: this.primaryFancy[i].min_stake,
    //             name: this.primaryFancy[i].name,
    //             selection_id: this.primaryFancy[i].selection_id,
    //             session_size_no: '0',
    //             session_size_yes: '0',
    //             session_value_no: '0',
    //             session_value_yes: '0',
    //           }
    //           this.fancyDataArray.push(this.fancyArray)
    //         }
    //         this.fancy = this.fancyDataArray;
    //         // this.fancyRunner();
    //         if(this.isSocket != 0){
    //         if (this.callingFancyType == 1) {
    //           this.callingFancyType = 2;
    //           this.fancyRunner();
    //           this.fancyId = [];
    //           for (let i = 0; i < this.primaryFancy.length; i++) {
    //             let data = this.primaryFancy[i].fancy_id
    //             this.fancyId.push(data);
    //           }
    //           let event = {
    //             "eventIds": this.fancyId
    //           }
    //           this.socketEmitEvent('subscribe_event', event);
    //         }
    //         } 
    //         else {
    //           clearTimeout(this.fancyTimer);
    //           // this.showFancy();
    //         }
    //         // this.marketSettingApi(this.matchData.sport_id, this.matchData.match_id);
    //         this.ref.detectChanges();
    //       }

    //     } else {
    //       if (res.logout == true) {
    //         this.router.navigate(['login']);
    //         window.location.reload();
    //         window.location.replace('login');
    //       }
    //     }
    //   }, (err) => {
    //     console.log(err);
    //   }, () => {
    //     if (this.router.url.split('?')[0] == '/match-detail') {
    //       if (this.isSocket != 1) {
    //         this.fancyTimer = setTimeout(() => resolve(this.showFancy()), 10000);
    //       } else {
    //         clearTimeout(this.fancyTimer);
    //       }
    //     }
    //   })

    // }
    // else {

      let param = {
        "user_id": this.userDetails._id,
        "match_id": this.matchData.match_id,
        // "page": 1,
        // "limit": 100
      }
      this.fancyId = [];
      this.sportService.showFancyList(param).subscribe((res) => {
        this.showFancyList = true;
        if (res.status == true) {
          if (res.data.length == 0) {
            this.fancy = [];
            this.fancySessionData = [];
            this.sessionMarketFancyData = [];
            this.overByOverFancyData = [];
            this.ballByballFancyData = [];
            this.ref.detectChanges();
          } else {
            let fancyData = res.data;
            for (let i = 0; i < fancyData.length; i++) {
              if (this.sessionSetting != undefined) {
                for (let j = 0; j < Object.keys(this.sessionSetting).length; j++) {
                  if (fancyData[i].fancy_id == Object.keys(this.sessionSetting)[j]) {
                    let key = Object.values(this.sessionSetting)[j]
                    fancyData[i].min_stake = Object.values(this.sessionSetting)[j]['session_min_stack']
                    fancyData[i].max_stake = Object.values(this.sessionSetting)[j]['session_max_stack']
                    fancyData[i].max_profit = Object.values(this.sessionSetting)[j]['session_max_profit']
                  }
                }
              }

            }
            this.primaryFancy = fancyData;  
            this.fancyDataArray = [];
            if(this.isSocket != 0){
            for (let i = 0; i < this.primaryFancy.length; i++) {
              this.fancyArray = {
                category: this.primaryFancy[i].category,
                news: this.primaryFancy[i].news == '' ? undefined : this.primaryFancy[i].news,
                display_message: 'Loading...',
                fancy_id: this.primaryFancy[i].fancy_id,
                is_active: this.primaryFancy[i].is_active,
                is_created: this.primaryFancy[i].is_created,
                is_lock: this.primaryFancy[i].is_lock,
                max_profit: this.primaryFancy[i].max_profit,
                max_stake: this.primaryFancy[i].max_stake,
                min_stake: this.primaryFancy[i].min_stake,
                name: this.primaryFancy[i].name,
                selection_id: this.primaryFancy[i].SelectionId,
                session_size_no: '0',
                session_size_yes: '0',
                session_value_no: '0',
                session_value_yes: '0',
              }
            } 
          } else {
            for (let i = 0; i < this.primaryFancy.length; i++) {
              this.fancyArray = {
                category: this.primaryFancy[i].category,
                news: this.primaryFancy[i].news == '' ? undefined : this.primaryFancy[i].news,
                display_message: 'Loading...',
                fancy_id: this.primaryFancy[i].fancy_id,
                is_active: this.primaryFancy[i].is_active,
                is_created: this.primaryFancy[i].is_created,
                is_lock: this.primaryFancy[i].is_lock,
                max_profit: this.primaryFancy[i].max_profit,
                max_stake: this.primaryFancy[i].max_stake,
                min_stake: this.primaryFancy[i].min_stake,
                name: this.primaryFancy[i].name,
                selection_id: this.primaryFancy[i].SelectionId,
                // session_size_no: '0',
                // session_size_yes: '0',
                // session_value_no: '0',
                // session_value_yes: '0',
              }
              this.fancyDataArray.push(this.fancyArray)
            }
              
            }
            
            // this.fancySessionData = this.fancyDataArray.filter((object) => object.category === 0);
            // this.sessionMarketFancyData = this.fancyDataArray.filter((object) => object.category === 1);
            // this.overByOverFancyData = this.fancyDataArray.filter((object) => object.category === 2);
            // this.ballByballFancyData = this.fancyDataArray.filter((object) => object.category === 3);
            this.ref.detectChanges();
            if(this.isSocket != 0){
              this.fancyId = [];
              for (let i = 0; i < this.primaryFancy.length; i++) {
                let data = this.primaryFancy[i].fancy_id
                this.fancyId.push(data);
              }
              let event = {
                "eventIds": this.fancyId
              }
              this.socketEmitEvent('subscribe_event', event);
          } else {

            if (this.callingFancyType == 1) {
              this.callingFancyType = 2;
              this.fancyRunner()
            } else {
            }
          }
          }
          // this.cdref.detectChanges();
        } else {
          if (res.logout == true) {
            this.head.logoutUser();
          } else {
            this.toastr.error(res.msg,'',{
              positionClass: 'toast-top-center',
            })
          }
        }
      }, (err) => {
            console.log(err);
          }, () => {
            if (this.router.url.split('?')[0] == '/match-detail') {
              if (this.isSocket != 1) {
                this.fancyTimer = setTimeout(() => resolve(this.showFancy()), 15000);
              } else {
                clearTimeout(this.fancyTimer);
              }
            }
          })

    // }


  }

  fancyRunner() {
    clearTimeout(this.fancyRunnerTimer);
    this.matchData = JSON.parse(localStorage.getItem('matchData'));
    let data = {
      "match_id": this.matchData.match_id
    };
    this.sportService.showFancyListRunner(data).subscribe((res) => {
      if (res.status == true) {
        this.fancyRunnerData = res.data;
        let a3 = this.primaryFancy.map(t1 => ({ ...t1, ...this.fancyRunnerData.find(t2 => (t2.SelectionId == t1.selection_id)) }));
        this.fancyDataArray = [];
        for (let i = 0; i < a3.length; i++) {
          if (this.sessionSetting != undefined) {
            for (let j = 0; j < Object.keys(this.sessionSetting).length; j++) {
              if (a3[i].fancy_id == Object.keys(this.sessionSetting)[j]) {
                let key = Object.values(this.sessionSetting)[j]
                a3[i].min_stake = Object.values(this.sessionSetting)[j]['session_min_stack']
                a3[i].max_stake = Object.values(this.sessionSetting)[j]['session_max_stack']
                a3[i].max_profit = Object.values(this.sessionSetting)[j]['session_max_profit']
              }
            }
          }
          this.fancyArray = {
            category: a3[i].category,
            news: a3[i].news == '' ? undefined : a3[i].news,
            display_message: a3[i].GameStatus,
            fancy_id: a3[i].fancy_id,
            is_active: a3[i].is_active,
            is_created: a3[i].is_created,
            is_lock: a3[i].is_lock,
            max_profit: a3[i].max_profit,
            max_stake: a3[i].max_stake,
            min_stake: a3[i].min_stake,
            name: a3[i].name,
            selection_id: a3[i].selection_id,
            session_size_no: a3[i].LaySize1 == undefined ? '0' : a3[i].LaySize1,
            session_size_yes: a3[i].BackSize1 == undefined ? '0' : a3[i].BackSize1,
            session_value_no: a3[i].LayPrice1 == undefined ? '0' : a3[i].LayPrice1,
            session_value_yes: a3[i].BackPrice1 == undefined ? '0' : a3[i].BackPrice1,
          }
          this.fancyDataArray.push(this.fancyArray)
        }
        if (this.fancyLiability != undefined) {
          for (let i = 0; i < Object.keys(this.fancyLiability).length; i++) {
            for (let j = 0; j < (this.fancyDataArray).length; j++) {
              if (this.fancyDataArray[j].fancy_id == Object.keys(this.fancyLiability)[i]) {
                this.fancyDataArray[j].liability = Object.values(this.fancyLiability)[i]
              }
            }
          }
        }
            this.fancySessionData = this.fancyDataArray.filter((object) => object.category === 0);
            this.sessionMarketFancyData = this.fancyDataArray.filter((object) => object.category === 1);
            this.overByOverFancyData = this.fancyDataArray.filter((object) => object.category === 2);
            this.ballByballFancyData = this.fancyDataArray.filter((object) => object.category === 3);
        
        if(this.isSocket != 0){
        this.fancyId = [];
        for (let i = 0; i < this.primaryFancy.length; i++) {
          let data = this.primaryFancy[i].fancy_id
          this.fancyId.push(data);
        }
        let event = {
          "eventIds": this.fancyId
        }
        this.socketEmitEvent('subscribe_event', event);
        }
        this.ref.detectChanges();
      } else {
        if (res.logout == true) {
          this.head.logoutUser();
        } else {
          this.fancyDataArray = [];
          let a3 = this.primaryFancy;
          for (let i = 0; i < a3.length; i++) {
            this.fancyArray = {
              category: a3[i].category,
              news: a3[i].news == '' ? undefined : a3[i].news,
              display_message: 'SUSPENDED',
              fancy_id: a3[i].fancy_id,
              is_active: a3[i].is_active,
              is_created: a3[i].is_created,
              is_lock: a3[i].is_lock,
              max_profit: 0,
              max_stake: 0,
              min_stake: 0,
              name: a3[i].fancy_name,
              selection_id: a3[i].selection_id,
              session_size_no: '0',
              session_size_yes: '0',
              session_value_no: '0',
              session_value_yes: '0',
            }
            this.fancyDataArray.push(this.fancyArray)
          }
          // this.fancy = this.fancyDataArray;
            this.fancySessionData = this.fancyDataArray.filter((object) => object.category === 0);
            this.sessionMarketFancyData = this.fancyDataArray.filter((object) => object.category === 1);
            this.overByOverFancyData = this.fancyDataArray.filter((object) => object.category === 2);
            this.ballByballFancyData = this.fancyDataArray.filter((object) => object.category === 3);
          if(this.isSocket != 0){
          this.fancyId = [];
          for (let i = 0; i < this.primaryFancy.length; i++) {
            let data = this.primaryFancy[i].fancy_id
            this.fancyId.push(data);
          }
          let event = {
            "eventIds": this.fancyId
          }
          this.socketEmitEvent('subscribe_event', event);
          }
          this.ref.detectChanges();
        }
      }

    }, (err) => {
      console.log(err);
    }, () => {
      if (this.router.url.split('?')[0] == '/match-detail') {
        if (this.isSocket != 1) {
          this.fancyRunnerTimer = setTimeout(() => resolve(this.fancyRunner()), 700);
        } else {
          clearTimeout(this.fancyRunnerTimer);
        }
      }
    })
  }
  saveBet(betValue, status) {
    let a = this.marketCurrentData.runners;
    // if (betValue[0].is_back == 1) {
    //   this.marketLiability = betValue[0].stake;
    //   this.marketPL = (betValue[0].odds - 1) * betValue[0].stake
    //   for (let i = 0; i < a.length; i++) {
    //     if (a[i].selectionId == betValue[0].selection_id) {
    //       a[i].currentWinLoss = a[i].win_loss + this.marketPL
    //     } else {
    //       a[i].currentWinLoss = a[i].win_loss - this.marketLiability
    //     }
    //   }
    //   const min = (a).reduce(function (prev, current) {
    //     return (prev.currentWinLoss < current.currentWinLoss) ? prev : current
    //   })
    //   const previousMin = (a).reduce(function (prev, current) {
    //     return (prev.win_loss < current.win_loss) ? prev : current
    //   })
    //   if (previousMin.win_loss < 0) {
    //     this.marketLiability = min.currentWinLoss + (this.walletBalance.liability - previousMin.win_loss)
    //   } else {
    //     this.marketLiability = min.currentWinLoss + (this.walletBalance.liability - 0)
    //   }

    //   if (-(this.marketLiability) <= this.exposuerLimit || this.exposuerLimit == -1) {
    //     this.marketLiabilityCondition = true;
    //   } else {
    //     this.marketLiabilityCondition = false
    //   }
    // } else {
    //   this.marketPL = betValue[0].stake;
    //   this.marketLiability = (betValue[0].odds - 1) * betValue[0].stake
    //   for (let i = 0; i < a.length; i++) {
    //     if (a[i].selectionId != betValue[0].selection_id) {
    //       a[i].currentWinLoss = a[i].win_loss + this.marketPL
    //     } else {
    //       a[i].currentWinLoss = a[i].win_loss - this.marketLiability
    //     }
    //   }
    //   const min = (a).reduce(function (prev, current) {
    //     return (prev.currentWinLoss < current.currentWinLoss) ? prev : current
    //   })
    //   const previousMin = (a).reduce(function (prev, current) {
    //     return (prev.win_loss < current.win_loss) ? prev : current
    //   })
    //   if (previousMin.win_loss < 0) {
    //     this.marketLiability = min.currentWinLoss + (this.walletBalance.liability - previousMin.win_loss)
    //   } else {
    //     this.marketLiability = min.currentWinLoss + (this.walletBalance.liability - 0)
    //   }
    //   if (-(this.marketLiability) <= this.exposuerLimit || this.exposuerLimit == -1) {
    //     this.marketLiabilityCondition = true;
    //   } else {
    //     this.marketLiabilityCondition = false
    //   }
    // }
    this.marketLiabilityCondition = true
    if (this.marketLiabilityCondition == true) {
      this.loading = true
      if (this.applyUserValidation == true) {
        this.validateStakeValue(betValue[0].stake, betValue[0])
        this.validateStakeAmountwithOldBets(betValue[0].stake)
      } else {
        this.disablePlaceBet = false;
      }
      if (betValue[0].MarketName == "Match Odds") {
        this.volumeLimit = true;
        this.minVolLimit = true;
      } else {
        this.volumeLimit = true;
        this.minVolLimit = true;
      }
      if (!this.disablePlaceBet && this.volumeLimit && this.minVolLimit) {
        if (this.betPrice == betValue[0].odds) {
          let betData = {
            'market_id': betValue[0].market_id,
            'selection_id': betValue[0].selection_id,
            'odds': betValue[0].odds,
            'stack': betValue[0].stake,
            'is_back': JSON.parse(betValue[0].is_back),
          };
          this.sportService.saveBet(betData).subscribe((data) => {
            if (data.status == false) {
              this.loading = false
              this.matchModel.tempArray = [];
              this.toastr.error(data.msg, '', {
                timeOut: 10000,
              })
              this.betslipinfo = false;
              clearTimeout(this.Runnertimer);
              this.matchRunner();
              this.betslipStatus = false
            } else {
              this.toastr.success(data.msg, '', {
                positionClass: 'toast-bottom-right',
                timeOut: 1000
              });
              this.loading = false
              this.selection = undefined
              if (this.ringOn == true) {
                this.ring = true;
              }
              this.matchModel.tempArray = [];
              this.myTeamPosition();
              this.betslipStatus = false;
              clearTimeout(this.Runnertimer);
              this.matchRunner();
              this.getUserBalance();
              let params = JSON.parse(localStorage.getItem('matchData'))
              this.openBets()
            }
          }, (err) => {
          });
        } else {
          this.betslipStatus = false
          this.loading = false
          this.toastr.error('Bet Price is changed , Please try again', '', {
            timeOut: 10000,
          });
        }
      } else {
        this.disablePlaceBet = false;
        this.betslipinfo = false;
        this.betslipStatus = false;
        this.loading = false
      }
    } else {
      this.toastr.error("Exposure limit is over")
      this.disablePlaceBet = false;
      this.betslipinfo = false;
      this.betslipStatus = false;
      this.loading = false
    }
  }
  createFancyPosition = (dataObj) => {
    let DataOject = {
      "run": (dataObj[0].odds),
      "is_back": dataObj[0].is_back,
      "size": dataObj[0].size,
      "stack": dataObj[0].stake,
      "per": 100
    };
    let fancyList = this.fancySource.map(data => {
      return {
        "run": data.odds,
        "stack": data.stack,
        "size": data.size,
        "is_back": data.is_back,
        "per": 100
      }
    });
    let fancyListData = [];
    if (fancyList.length != 0) {
      fancyListData = fancyList;
      fancyListData.push(DataOject);
    } else {
      fancyListData.push(DataOject);
    }
    let run = [], resultValues = [], orgRun = [];
    let lastPosition = 0, max_exposure = 0, max_profit = 0;
    fancyListData = _.orderBy(fancyListData, ['run'], ['asc']);
    for (let i in fancyListData) {
      let fancy = fancyListData[i];
      run.push(fancy.run - 1);
    }
    run.sort();
    run.push(fancyListData[fancyListData.length - 1].run);
    orgRun = run;
    run = [...new Set(run)];
    run.map(function (r, ind) {
      let tempTotal = 0;
      fancyListData.map(function (f) {
        let stack = (f.stack * f.per) / 100;
        if (f.is_back == 1) {
          if (f.run <= r)
            tempTotal -= stack * (f.size / 100);
          else
            tempTotal += stack;
        } else {
          if (f.run > r)
            tempTotal -= stack;
          else
            tempTotal += stack * (f.size / 100);
        }
      });
      if (tempTotal != 0)
        tempTotal = -(tempTotal);
      if ((orgRun.length) - 1 == ind) {
        resultValues.push({ "key": lastPosition + '+', "value": tempTotal.toFixed(2) });
      } else {
        if (lastPosition == r) {
          resultValues.push({ "key": lastPosition, "value": tempTotal.toFixed(2) });
        } else {
          resultValues.push({ "key": lastPosition + '-' + r, "value": tempTotal.toFixed(2) });
        }
      }
      lastPosition = r + 1;
      if (max_exposure > tempTotal)
        max_exposure = tempTotal;
      if (max_profit < tempTotal)
        max_profit = tempTotal;
    });
    this.totalMaxLoss = max_exposure;
    // this.saveFancyBet(dataObj);
    let data = { "fancy_position": resultValues, "liability": max_exposure, "profit": max_profit };
  }
  saveFancyBet(param, betValue) {
    if (this.applyUserValidation == true) {
      this.validateFancyStakeValue(betValue[0].stake, betValue[0])
    }
    this.loading = true
    if (this.betPrice == betValue[0].odds && this.betSize == betValue[0].size) {
      let betData = {
        'fancy_id': param.fancy_id,
        'size': betValue[0].size,
        'run': betValue[0].odds,
        'stack': betValue[0].stake,
        'is_back': JSON.parse(betValue[0].is_back),
      };
      this.sportService.saveFancyBet(betData).subscribe((data) => {
        if (data.status == false) {
          this.loading = false
          this.fancyLoading = false;
          this.matchModel.tempArray = [];
          this.toastr.error(data.msg, '', {
            timeOut: 10000,
          })

          document.getElementById("loki").classList.remove("placebet");
          this.betslipinfo = false;
          this.betslipStatus = false
          this.fancyRunner()
        } else {
          this.loading = false;
          this.selection = undefined
          this.toastr.success(data.msg, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          });
          if (this.ringOn == true) {
            this.ring = true;
          }
          this.matchModel.tempArray = [];
          this.betslipStatus = false;
          this.fancyRunner();
          document.getElementById("loki").classList.remove("placebet");
          this.ref.detectChanges()
          this.getFancyLiability(this.matchData.match_id, 1);
          this.openBets()
        }
      }, (err) => {
      });
    } else {
      this.loading = false
      this.toastr.error('Bet Price or Size is changed , Please try again', '', {
        timeOut: 10000,
      });

      document.getElementById("loki").classList.remove("placebet");
    }
  }

  validateStakeValue(stake, back) {
    this.toastr.clear();
    this.disablePlaceBet = false;
    if (this.sportsSettingValues != undefined) {
      if (this.check_event_limit == true) {
        if (!this.inplayStatus) {
          if (stake > this.sportsSettingValues[this.market].market_advance_bet_stake) {
            this.disablePlaceBet = true
            this.loading = false
            this.toastr.error('Before inplay stake amount limit is exceded ' + this.sportsSettingValues[this.market].market_advance_bet_stake, '', {
              timeOut: 10000,
            });
          }
        }
        if (this.sportsSettingValues[this.market].market_min_odds_rate > back.odds || back.odds > this.sportsSettingValues[this.market].market_max_odds_rate) {
          this.disablePlaceBet = true
          this.loading = false
          this.toastr.error('Odd Rate value is not in min and max range(' + this.sportsSettingValues[this.market].market_min_odds_rate + '-' + this.sportsSettingValues[this.market].market_max_odds_rate + ')', '', {
            timeOut: 10000,
          });
        }
        if (this.sportsSettingValues[this.market].market_min_stack > stake || stake > this.sportsSettingValues[this.market].market_max_stack) {
          this.disablePlaceBet = true
          this.loading = false
          this.toastr.error('Stake value is not in min and max range(' + this.sportsSettingValues[this.market].market_min_stack + '-' + this.sportsSettingValues[this.market].market_max_stack + '), please enter valid stake value', '', {
            timeOut: 10000,
          });
        }
      } else {
        if (!this.inplayStatus) {
          if (stake > this.sportsSettingValues.market_advance_bet_stake) {
            this.disablePlaceBet = true
            this.loading = false
            this.toastr.error('Before inplay stake amount limit is exceded ' + this.sportsSettingValues.market_advance_bet_stake, '', {
              timeOut: 10000,
            });
          }
        }
        if (this.sportsSettingValues.market_min_odds_rate > back.odds || back.odds > this.sportsSettingValues.market_max_odds_rate) {
          this.disablePlaceBet = true
          this.loading = false
          this.toastr.error('Odd Rate value is not in min and max range(' + this.sportsSettingValues.market_min_odds_rate + '-' + this.sportsSettingValues.market_max_odds_rate + ')', '', {
            timeOut: 10000,
          });
        }
        if (this.sportsSettingValues.market_min_stack > stake || stake > this.sportsSettingValues.market_max_stack) {
          this.disablePlaceBet = true
          this.loading = false
          this.toastr.error('Stake value is not in min and max range(' + this.sportsSettingValues.market_min_stack + '-' + this.sportsSettingValues.market_max_stack + '), please enter valid stake value', '', {
            timeOut: 10000,
          });
        }
      }
    } else {
      this.disablePlaceBet = false;
    }
  }

  validateFancyStakeValue(stake, back) {
    this.toastr.clear();
    this.disableFancyPlaceBet = false
    let profit = back.is_back == 0 ? back.stake : (((back.size * back.stake) / 100).toFixed(2))
    if (this.sessionSetting != undefined) {
      if (this.check_event_limit != true) {
        if (this.sessionSetting.session_min_stack > stake || stake > this.sessionSetting.session_max_stack) {
          this.disableFancyPlaceBet = true
          this.loading = false
          this.fancyLoading = false
          this.betslipStatus = false
          this.toastr.error('Stake value is not in min and max range(' + this.sessionSetting.session_min_stack + '-' + this.sessionSetting.session_max_stack + '), please enter valid stake value', '', {
            timeOut: 10000,
          });
        }
      } else {
        if (this.sessionSetting[this.fancyIdValidation].session_min_stack > stake || stake > this.sessionSetting[this.fancyIdValidation].session_max_stack) {
          this.disableFancyPlaceBet = true
          this.loading = false
          this.fancyLoading = false
          this.betslipStatus = false
          this.toastr.error('Stake value is not in min and max range(' + this.sessionSetting[this.fancyIdValidation].session_min_stack + '-' + this.sessionSetting[this.fancyIdValidation].session_max_stack + '), please enter valid stake value', '', {
            timeOut: 10000,
          });
        }
      }
    } else {
      this.disableFancyPlaceBet = false;
    }
  }

  validateStakeAmountwithOldBets(stakeAmount) {
    if (!this.matchData.inplay) {
      if (this.sportsSettingValues != undefined) {
        if (this.check_event_limit != true) {
          if (this.betData !== undefined) {
            let existingBets = this.betData.filter(t => t.market_id == this.market && t.delete_status != 2);
            let sumofStake = 0
            if (existingBets.length != 0) {
              existingBets.forEach(element => {
                sumofStake = element.stack + sumofStake
              });
            }
            sumofStake = sumofStake + stakeAmount
            if (sumofStake > this.sportsSettingValues.market_advance_bet_stake) {
              this.disablePlaceBet = true
              this.loading = false
              this.toastr.error('Market Advance bet stake amount limit is exceded ' + this.sportsSettingValues.market_advance_bet_stake, '', {
                timeOut: 10000,
              });
            }
          }
        } else {
          if (this.betData !== undefined) {
            let existingBets = this.betData.filter(t => t.market_id == this.market && t.delete_status != 2);
            let sumofStake = 0
            if (existingBets.length != 0) {
              existingBets.forEach(element => {
                sumofStake = element.stack + sumofStake
              });
            }
            sumofStake = sumofStake + stakeAmount
            if (sumofStake > this.sportsSettingValues[this.market].market_advance_bet_stake) {
              this.disablePlaceBet = true
              this.loading = false
              this.toastr.error('Market Advance bet stake amount limit is exceded ' + this.sportsSettingValues[this.market].market_advance_bet_stake, '', {
                timeOut: 10000,
              });
            }
          }
        }
      } else {
        this.disableFancyPlaceBet = false;
        this.disablePlaceBet = false;
      }
    } else {
      this.disablePlaceBet = false
    }
  }
  openModalCondition(Terms: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      Terms,
      Object.assign({}, { class: 'modal-dialog modal-dialog-scrollable modal-large' })
    );
  }
  openModalBetList(mobileBetList: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      mobileBetList,
      Object.assign({}, { class: 'bet-list login_page modal-dialog-scrollable' })
    );
  }
  myTeamPosition() {
    this.matchData = JSON.parse(localStorage.getItem('matchData'));
    let param = {
      "match_id": this.matchData.match_id
    };
    this.sportService.teamPosition(param).subscribe((res) => {
      if (res.status == true) {
        this.teamPositionData = res.data;
        this.ref.detectChanges();
      }
    })
  }

  getUserBalance() {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    let data = {};
    this.sportService.getUserBalance(data).subscribe(response => {
      this.usersService.updateUserBalanceSubject(response.data)
      this.walletBalance = response.data;
      this.currentExpo = ((this.walletBalance.liability)) * (this.userDetails.point);
      this.currentBalance = (this.walletBalance.balance) * (this.userDetails.point)
    }, (err) => {
    })
  }
  fancyPositionData(fancyData, fancyBook: TemplateRef<any>) {
    this.selectedFancyMarket = fancyData
    this.modalRef = this.modalService.show(
      fancyBook,
      Object.assign({}, { class: 'modal-dialog fancy-book' })
    );
    let data = {
      fancy_id: fancyData.fancy_id
    };
    this.sportService.getFancyPosition(data).subscribe((res) => {
      if (res.status == true) {
        this.fancyPosData = res.data.fancy_position;
      } else {
        this.toastr.error(res.msg, '', {
          timeOut: 10000,
        })
      }
    })
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  getFancyLiability(id, type) {
    let data = {};
    if (type == 1) {
      data['match_id'] = id
    }
    this.sportService.getFancyLiability(data).subscribe(res => {
      if (res.status == true) {
        this.fancyLiability = res.data
      } else {
        this.fancyLiability = []
        this.toastr.error(res.msg, '', {
          timeOut: 10000,
        });
      }
    }, error => {

    })
  }

  socketOnEvent(eventName, callback) {
    this.socketService.socket.on(eventName, data => callback(data));
  }

  socketEmitEvent(eventName, data) {
    this.socketService.socket.emit(eventName, data);
  }
  marketAdded() {
    this.userData = {};
    this.socketEmitEvent('new_market_added', this.userData);
  }
  fancyAdded() {
    this.userData = {};
    this.socketEmitEvent('fancy_added', this.userData);
  }
  socketError() {
    this.socketService.socket.on('error', (res) => {
      if (res.status == false) {
        this.toastr.error(res.msg)
      }
    })
  }
  socketSuccess() {
    this.socketService.socket.on('success', (res) => {
      if (res.status == true) {
        if (res.event_code == "subscribe_event") {
          for (let i = 0; i < this.marketId.length; i++) {
            this.socketOnEvent(this.marketId[i], result => {
              if (result.status == true) {
                if (result.is_fancy == false) {
                  this.marketData = result.data;
                  this.inplayStatus = this.marketData.inplay
                  this.runnerData = this.marketData;
                  if (this.check_event_limit == true) {
                    for (let i = 0; i < this.matchDetail.length; i++) {
                      if (this.sportsSettingValues != undefined) {
                        for (let j = 0; j < Object.keys(this.sportsSettingValues).length; j++) {
                          if (this.matchDetail[i].market_id == Object.keys(this.sportsSettingValues)[j]) {
                            let key = Object.values(this.sportsSettingValues)[j]
                            this.matchDetail[i].min_stake = Object.values(this.sportsSettingValues)[j]['market_min_stack']
                            this.matchDetail[i].max_stake = Object.values(this.sportsSettingValues)[j]['market_max_stack']
                            this.matchDetail[i].max_profit = Object.values(this.sportsSettingValues)[j]['market_max_profit']
                          }
                        }
                      }

                    }
                  } else {
                    if (this.sportsSettingValues != undefined) {
                      this.matchDetail.forEach(matDta => {
                        matDta.min_stake = this.sportsSettingValues.market_min_stack;
                        matDta.max_stake = this.sportsSettingValues.market_max_stack;
                        matDta.max_profit = this.sportsSettingValues.market_max_profit;
                      });
                    }
                  }
                  for (let i = 0; i < this.matchDetailFirst.length; i++) {
                    if (this.matchDetailFirst[i].market_id == this.runnerData.marketId) {
                      this.matchDetailFirst[i]["shadowRunner"] = [];
                      for (let j = 0; j < this.matchDetailFirst[i].runners.length; j++) {
                        for (let k = 0; k < this.runnerData.runners.length; k++) {
                          if (this.matchDetailFirst[i].runners[j].selectionId == this.runnerData.runners[k].selectionId) {
                            this.matchDetail[i].runners[j].selection_name = this.matchDetailFirst[i].runners[j].name
                            this.matchDetail[i].shadowRunner[j] = {};
                            this.matchDetail[i].shadowRunner[j] = JSON.parse(JSON.stringify(this.matchDetailFirst[i].runners[j]));
                          }
                        }
                      }
                    }
                  }
                  for (let i = 0; i < this.matchDetail.length; i++) {
                    if (this.matchDetail[i].market_id == this.runnerData.marketId) {
                      this.matchDetail[i].status = this.runnerData.status;
                      this.matchDetail[i].inplay = this.runnerData.inplay;
                      if (this.runnerData.hasOwnProperty('totalMatched')) {
                        if (this.runnerData.totalMatched > 999 && this.runnerData.totalMatched < 1000000) {
                          this.cuurency = (this.runnerData.totalMatched / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
                        } else if (this.runnerData.totalMatched > 1000000) {
                          this.cuurency = (this.runnerData.totalMatched / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
                        } else if (this.runnerData.totalMatched < 900) {
                          this.cuurency = this.runnerData.totalMatched; // if value < 1000, nothing to do
                        }
                      } else {
                        this.cuurency = '0';
                      }
                      this.matchDetail[i].matched = this.cuurency;
                      break
                    }
                  }
                  for (let i = 0; i < this.matchDetail.length; i++) {
                    if (this.matchDetail[i].market_id == this.runnerData.marketId) {
                      this.matchDetail[i].status = this.runnerData.status;
                      this.matchDetail[i].inplay = this.runnerData.inplay;
                      for (let j = 0; j < this.matchDetail[i].runners.length; j++) {
                        for (let k = 0; k < this.runnerData.runners.length; k++) {
                          if (this.matchDetail[i].runners[j].selectionId == this.runnerData.runners[k].selectionId) {
                            this.matchDetail[i].runners[j].ex = this.runnerData.runners[k].ex;
                            this.matchDetail[i].runners[j].status = this.runnerData.runners[k].status;
                          }
                        }

                      }
                      break
                    }
                  }
                  for (let i = 0; i < this.matchDetail.length; i++) {
                    if (this.teamPositionData != undefined || this.teamPositionData != null || this.teamPositionData != '') {
                      for (let j = 0; j < Object.keys(this.teamPositionData).length; j++) {
                        if (this.matchDetail[i].market_id == Object.keys(this.teamPositionData)[j]) {
                          for (let k = 0; k < this.matchDetail[i].runners.length; k++) {
                            let key = Object.values(this.teamPositionData)[j]
                            for (let l = 0; l < Object.keys(key).length; l++) {
                              if (this.matchDetail[i].runners[k].selectionId == key[l].selection_id) {
                                this.matchDetail[i].runners[k].win_loss = key[l].win_loss;
                                this.matchDetail[i].runners[k].win_loss_total_exposure = key[l].win_loss_total_exposure;
                              }
                            }
                          }
                        }
                      }
                    }
                    for (let o = 0; o < this.matchDetail[i].runners.length; o++) {
                      if ((this.matchDetail[i].runners[o].ex.availableToBack).length != 0) {
                        for (let m = 0; m < (this.matchDetail[i].runners[o].ex.availableToBack).length; m++) {
                          if (this.matchDetail[i].runners[o].ex.availableToBack[m] != null) {
                            if (this.matchDetail[i].runners[o].ex.availableToBack[m].size > 999 && this.matchDetail[i].runners[o].ex.availableToBack[m].size < 1000000) {
                              this.matchDetail[i].runners[o].ex.availableToBack[m].size = (this.matchDetail[i].runners[o].ex.availableToBack[m].size / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
                            } else if (this.matchDetail[i].runners[o].ex.availableToBack[m].size > 1000000) {
                              this.matchDetail[i].runners[o].ex.availableToBack[m].size = (this.matchDetail[i].runners[o].ex.availableToBack[m].size / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
                            } else if (this.matchDetail[i].runners[o].ex.availableToBack[m].size < 900) {
                              this.matchDetail[i].runners[o].ex.availableToBack[m].size = this.matchDetail[i].runners[o].ex.availableToBack[m].size; // if value < 1000, nothing to do
                            }
                          }
                        }
                      }
                      if ((this.matchDetail[i].runners[o].ex.availableToLay).length != 0) {
                        for (let n = 0; n < (this.matchDetail[i].runners[o].ex.availableToLay).length; n++) {
                          if (this.matchDetail[i].runners[o].ex.availableToLay[n] != null) {
                            if (this.matchDetail[i].runners[o].ex.availableToLay[n].size > 999 && this.matchDetail[i].runners[o].ex.availableToLay[n].size < 1000000) {
                              this.matchDetail[i].runners[o].ex.availableToLay[n].size = (this.matchDetail[i].runners[o].ex.availableToLay[n].size / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
                            } else if (this.matchDetail[i].runners[o].ex.availableToLay[n].size > 1000000) {
                              this.matchDetail[i].runners[o].ex.availableToLay[n].size = (this.matchDetail[i].runners[o].ex.availableToLay[n].size / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
                            } else if (this.matchDetail[i].runners[o].ex.availableToLay[n].size < 900) {
                              this.matchDetail[i].runners[o].ex.availableToLay[n].size = this.matchDetail[i].runners[o].ex.availableToLay[n].size; // if value < 1000, nothing to do
                            }
                          }
                        }
                      }
                    }
                  }

                } else {
                  // console.log("948",result);

                  this.fancyDta = result.data;
                  // console.log("951",this.fancyDta);
                  for (let i = 0; i < this.fancy.length; i++) {
                    if (this.fancy[i].fancy_id == this.fancyDta.fancy_id) {
                      this.fancy[i].session_value_yes = this.fancyDta.BackPrice1;
                      this.fancy[i].session_size_yes = this.fancyDta.BackSize1;
                      this.fancy[i].session_value_no = this.fancyDta.LayPrice1;
                      this.fancy[i].session_size_no = this.fancyDta.LaySize1;
                      this.fancy[i].display_message = this.fancyDta.GameStatus;
                      // console.log("959",this.fancy[i]);
                      break
                    }
                  }

                  this.ref.detectChanges();
                }
              }
            })
          }

        } else {
          this.toastr.success(res.msg, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          })
        }
      }
    })
  }

  socketFancySuccess() {
    this.socketService.socket.on('success', (res) => {
      if (res.status == true) {
        if (res.event_code == "subscribe_event") {
          for (let i = 0; i < this.fancyId.length; i++) {
            this.socketOnEvent(this.fancyId[i], result => {
              if (result.status == true) {
                this.fancyDta = result.data;
                if (this.sessionSetting != undefined) {
                  for (let j = 0; j < Object.keys(this.sessionSetting).length; j++) {
                    if (this.fancyDta.fancy_id == Object.keys(this.sessionSetting)[j]) {
                      let key = Object.values(this.sessionSetting)[j]
                      this.fancyDta.min_stake = Object.values(this.sessionSetting)[j]['session_min_stack']
                      this.fancyDta.max_stake = Object.values(this.sessionSetting)[j]['session_max_stack']
                      this.fancyDta.max_profit = Object.values(this.sessionSetting)[j]['session_max_profit']
                    }
                  }
                }
                if (this.fancyLiability != undefined) {
                  for (let i = 0; i < Object.keys(this.fancyLiability).length; i++) {
                    for (let j = 0; j < (this.fancy).length; j++) {
                      if (this.fancy[j].fancy_id == Object.keys(this.fancyLiability)[i]) {
                        this.fancy[j].liability = Object.values(this.fancyLiability)[i]
                      }
                    }
                  }
                }
                for (let i = 0; i < this.fancy.length; i++) {
                  if (this.fancy[i].fancy_id == this.fancyDta.fancy_id) {
                    this.fancy[i].session_value_yes = this.fancyDta.BackPrice1;
                    this.fancy[i].session_size_yes = this.fancyDta.BackSize1;
                    this.fancy[i].session_value_no = this.fancyDta.LayPrice1;
                    this.fancy[i].session_size_no = this.fancyDta.LaySize1;
                    this.fancy[i].display_message = this.fancyDta.GameStatus;
                    this.fancy[i].min_stake = this.fancyDta.min_stake
                    this.fancy[i].max_stake = this.fancyDta.max_stake
                    this.fancy[i].max_profit = this.fancyDta.max_profit
                    break
                  }
                }

                this.ref.detectChanges();
              }
            })
          }
        } else {
          this.toastr.success(res.msg, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          })
        }
      }
    })
  }
  getRunnerEmit() {
    this.marketId = [];
    for (let i = 0; i < this.matchDetailFirst.length; i++) {
      let data = this.matchDetailFirst[i].market_id
      this.marketId.push(data);
    }
    let event = {
      "eventIds": this.marketId
    }
    this.socketEmitEvent('subscribe_event', event);
  }

  socketListeners() {
    this.socketOnEvent(this.match_id + `_new_market_added`, res => {
      if (res.status == true) {
        this.marketSettingApi(this.matchData.sport_id, this.matchData.match_id);
        this.getMatchDetail(0);
      }
    });

    this.socketOnEvent(this.match_id + `_fancy_added`, res => {
      if (res.status == true) {
        if (res.hasData == true) {
          let fancyData = res.data;
          if (this.sessionSetting != undefined) {
            for (let j = 0; j < Object.keys(this.sessionSetting).length; j++) {
              if (fancyData.fancy_id == Object.keys(this.sessionSetting)[j]) {
                let key = Object.values(this.sessionSetting)[j]
                fancyData.min_stake = Object.values(this.sessionSetting)[j]['session_min_stack']
                fancyData.max_stake = Object.values(this.sessionSetting)[j]['session_max_stack']
                fancyData.max_profit = Object.values(this.sessionSetting)[j]['session_max_profit']
              }
            }
          }
          let a3 = fancyData;
          this.fancyArray = {
            display_message: 'SUSPENDED',
            fancy_id: a3.fancy_id,
            is_active: a3.is_active,
            is_created: 1,
            is_lock: a3.is_lock,
            max_profit: a3.max_profit,
            max_stake: a3.max_stake,
            min_stake: a3.min_stake,
            name: a3.fancy_name,
            selection_id: a3.selection_id,
            session_size_no: '0',
            session_size_yes: '0',
            session_value_no: '0',
            session_value_yes: '0',
          }
          let isFounded = (this.fancy).some(ai => ai.fancy_id === fancyData.fancy_id);
          if (isFounded == false) {
            this.fancy.push(this.fancyArray);
            this.fancyId = [];
            this.fancyId.push(a3.fancy_id);
            let event = {
              "eventIds": this.fancyId
            }
            this.socketEmitEvent('subscribe_event', event);
            this.ref.detectChanges();
          }
        } else {
          this.showFancy();
        }
      }
    });
    this.socketOnEvent(`socket_state_changed`, res => {
      this.isSocket = res.is_socket;
      this.getMatchDetail(0);
      this.showFancy();
      if (this.isSocket == 0) {
        this.marketId = []
        for (let i = 0; i < this.matchDetailFirst.length; i++) {
          let data = this.matchDetailFirst[i].market_id
          this.marketId.push(data);
        }
        if (this.primaryFancy != undefined) {
          for (let i = 0; i < this.primaryFancy.length; i++) {
            let data = this.primaryFancy[i].fancy_id
            this.marketId.push(data);
          }
        }
        let event = {
          "eventIds": this.marketId
        }
        this.socketEmitEvent('unsubscribe_event', event);
      } else {
        clearTimeout(this.fancyTimer);
        clearTimeout(this.timer)
      }
    });
    this.socketOnEvent(`events_limit_updated`, res => {
      this.marketSettingApi(this.matchData.sport_id, this.matchData.match_id)
    });
  }

  openBets() {
    this.matchData = JSON.parse(localStorage.getItem('matchData'));
    let data = {
      "match_id": this.matchData.match_id
    }
    this.sportService.getBet(data).subscribe((res) => {
      if (res.status == true) {
        this.betData = res.data;
        this.allBetData = this.betData.filter(t => t.delete_status == '0' || t.delete_status == '2')
        this.allBetDataLength = this.allBetData.length;
        this.matchedBets = this.betData.filter(t => t.is_fancy == '0' && t.delete_status == '0');
        this.matchedBetsLength = this.matchedBets.length;
        this.fancyBets = this.betData.filter(t => t.is_fancy == '1' && t.delete_status == '0');
        this.fancyBetsLength = this.fancyBets.length;
      } else {
        this.allBetDataLength = 0;
        this.matchedBetsLength = 0;
        this.fancyBetsLength = 0;
      }
    })
  }
  getSportList() {
    let data = {
      type: 'openBets',
      search: {}
    };
    this.reportService.eventList(data).subscribe((res) => {
      if (res.status == true) {
        this.MatchList = res.data[0].matches;
        this.ref.detectChanges();
      } else {
        this.toastr.error(res.msg);
      }
    })
  }
  popOver(data) {
    this.popFancyId = data.fancy_id;
    this.popShow = true;
    setTimeout(() => (this.closePopOver()), 5000)
  }
  closePopOver() {
    this.popShow = false;
  }
  showBetHistory(data) {
    if (data == 1) {
      this.showBet = 1;
      this.ref.detectChanges();
    } else {
      this.showBet = 0;
      this.ref.detectChanges();
    }
  }
  TvBlock(id) {
    if (id == 0) {
      this.tvId = 1;
      this.ref.detectChanges();
    } else {
      this.tvId = 0;
      this.ref.detectChanges();
    }
  }
  tvOpen(data) {
    if (data == false) {
      this.tv = true;
    } else {
      this.tv = false;
    }
    this.ref.detectChanges();
  }
  openFancyBets(params, posData) {
    this.pageSize = 30
    let data = {
      page: 1,
      limit: this.pageSize,
      search: {
        "fancy_id": params.fancy_id,
        "delete_status": 0
      }
    }
    this.reportService.openBets(data).subscribe((res) => {
      if (res.status) {
        if (res.data[0].data) {
          this.fancySource = res.data[0].data;
        } else {
          this.fancySource = []
        }
        this.createFancyPosition(posData)
      } else {
        this.toastr.error(res.msg);
        this.openBetsLength = 0;
      }
    })
  }
  ngOnDestroy() {
    this.socketService.socket.close();
    clearTimeout(this.timer)
    clearTimeout(this.Runnertimer)
    clearTimeout(this.fancyTimer)
    clearTimeout(this.fancyRunnerTimer)
  }
  all() {
    this.marketView = true;
    this.fancyView = true;
    this.ref.detectChanges()
  }
  mView() {
    this.marketView = true;
    this.fancyView = false;
    this.ref.detectChanges()
  }
  fView() {
    this.marketView = false;
    this.fancyView = true;
    this.ref.detectChanges()
  }
  updateMarketTimer() {
    clearTimeout(this.Runnertimer);
  }
  updateFancyTimer() {
    clearTimeout(this.fancyRunnerTimer);
  }
  betClose(data) {
    if (data != '') {
      this.fancySelection = undefined;
      this.ref.detectChanges()
    }
  }
}
