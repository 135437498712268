import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SportService } from '../services/sport.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from "@angular/router";
@Component({
  selector: 'app-casino',
  templateUrl: './casino.component.html',
  styleUrls: ['./casino.component.scss']
})
export class CasinoComponent implements OnInit {
  banner: any;
  casinoList: any;
  providerGames: any;
  iUrl: any;

  constructor(private sport: SportService, private router: Router, private route: ActivatedRoute,
    private toastr: ToastrService) {
    this.route.params.subscribe(params => {
      if (params.sportName == undefined) {
        this.getProviderGame('EZU')
      } else {
        this.getProviderGame(params.sportName)
      }
    })
  }

  ngOnInit(): void {
    this.banner = JSON.parse(localStorage.getItem('Banner'));
    this.getProvider();
  }
  getProvider() {
    this.sport.getQtechProvider().subscribe((res) => {
      if (res.status) {
        this.casinoList = res.data;
      } else {
        this.toastr.error(res.msg)
      }
    })
  }

  getProviderGame(id) {
    this.sport.getProviderGameList(id).subscribe((res) => {
      if (res.status) {
        let a = res.data.items;
        for (let i = 0; i < a.length; i++) {
          if (a[i].hasOwnProperty('images')) {
            if ((a[i].images[1]).hasOwnProperty('url')) {

            } else {
              a[i].images[1].url = 'assets/Casino/67660.jpg';
            }
          } else {
            let c = {
              images: [
                {},
                {
                  url: 'assets/images/thumb/casino.png'
                }
              ]
            }
            a[i] = (c);
          }
        }
        this.providerGames = a;
      }
    })
  }

  gotoexchangeGame(data) {
    let param = {
      providerCode: data.provider['id'],
      gameId: data.id
    }
    this.sport.qtechLaunch(param).subscribe((res) => {
      if (res.status) {
        this.iUrl = res.data.url;
        window.open(this.iUrl, "_blank")
      } else {
        this.toastr.error(res.msg)
      }
    })
  }
  aviator() {
    let data = {
      gameId: 'SPB-aviator',
      providerCode: "SPB"
    }
    this.sport.qtechLaunch(data).subscribe((res) => {
      if (res.status) {
        this.iUrl = res.data.url;
        window.open(this.iUrl, "_blank")
      } else {
        this.toastr.error(res.msg)
      }
    })
  }
  qtech(data) {
    this.router.navigate(['qtech/' + data]);
  }
  dashboardCasino() {
    this.router.navigate(['dashboard/Casino']);
  }
}
