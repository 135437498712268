<header id="header" class="header">
  <div class="container-fluid d-flex align-items-center justify-content-between main_navbar">
    <div class="burger_menu" (click)="openMobileSidebar()">
      <a href="javascript:void(0)" id="button"><img src="assets/images/bars.png" alt=""></a>
    </div>
    <a (click)="dashboard(1)" class="logo d-flex align-items-center me-auto me-lg-0">
      <img src="assets/logo/{{domain_name}}" alt="">
    </a>
    <div class="left-mobile-sidebar" [ngClass]="openMobileSidebarStatus ? 'showSidebar' : 'hideSidebar'">
      <div class="sidebar-div">
        <div class="sidebarBackdrop" (click)="openMobileSidebar()"></div>
        <div class="left_sidebar">
          <div class="main_left_sidebar">
            <app-open-left-sidebar></app-open-left-sidebar>
          </div>
        </div>
      </div>
    </div>
    <nav id="navbar" class="navbar">
      <ul>
        <div class="input-group searchbar_input d-inline-flex d-sm-none w-100">
          <input type="search" class="form-control rounded" placeholder="Event Market More....">
          <span class="input-group-text">
            <img class="img-fluid" src="assets/images/icon-search.png">
          </span>
        </div>
        <li><a (click)="dashboard(1)" [class.active]='"start" == path'><span>Sports</span></a></li>
        <li><a (click)="dashboard(4)" [class.active]='"Casino" == path'><span>Casino</span></a></li>
        <li><a (click)="dashboard(2)" [class.active]='"inplay" == path'><span>InPlay</span></a></li>
        <li><a (click)="dashboard(3)" [class.active]='"open-promo" == path'><span>Promotions</span></a></li>
        <li><a (click)="openModalLoginModal(loginModal)"><span><img src="assets/images/aviator.png"></span></a></li>
        <li class="d-block d-sm-none" (click)="openModalLoginModal(loginModal)"><a class="btn-log-reg"><img
              src="assets/images/icon-login.png"> Login</a></li>
        <li class="d-block d-sm-none" (click)="openModalSignupModal(signupModal)"><a class="btn-log-reg"><img
              src="assets/images/icon-signup.png"> Signup</a></li>
      </ul>
      <button class="mobile-nav-toggle d-none">
        <img class="openNav" src="assets/images/icon-navbar.png">
        <img class="closeNav" src="assets/images/icon-close.png">
      </button>
    </nav>
    <div class="input-group hide-on-mobile searchbar_input">
      <div ngbDropdown class="search-top dropdown" style="cursor:pointer">
        <div class="input-group">
          <form class="d-flex position-relative" (click)="homematches()">
            <span class="float-icon search-icon" style="color:#000 !important"><i class="bi bi-search"></i></span>
            <input ngbDropdownToggle class="serch_input dropdown-toggle" placeholder="Events, Markets, and more"
              id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" type="text"
              autocomplete="off" [(ngModel)]="searchSport" [ngModelOptions]="{standalone: true}">
            <span class="float-icon clearall-icon" (click)="searchSport = ''"><i class="bi bi-x-lg"></i></span>
            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <a ngbDropdownItem class="dropdown-item"
                *ngFor="let sport of homeData |search: searchSport :'match_name'">
                <div class="event-info" (click)="matchDetail(sport)">
                  <div class="event-team">
                    <div class="team-name">{{sport?.match_name}}</div>
                  </div>
                </div>
              </a>
            </div>
          </form>
        </div>
      </div>
      <span class="input-group-text">
        <img class="img-fluid" src="assets/images/icon-search.png">
      </span>
    </div>
    <a class="btn-getstarted login-btn" (click)="openModalLoginModal(loginModal)"><img
        src="assets/images/icon-login.png" class="hide-on-mobile"> Login</a>
    <a class="btn-getstarted login-btn" (click)="openModalSignupModal(signupModal)"><img
        src="assets/images/icon-signup.png" class="hide-on-mobile"> Signup</a>
    <a class="dark-mode hide-on-mobile" id="darkmode">
      <img class="img-fluid darkmodeimg" src="assets/images/icon-mode-dark.png">
      <img class="img-fluid lightmodeimg" src="assets/images/icon-mode-light.png">
    </a>

  </div>
</header><!-- End Header -->

<div class="bottom-header">
  <div class="news_feed">
    <span><i class="fas fa-bell"></i></span>
    <span class="title"> News : </span>
    <marquee behavior="scroll" direction="left" onmouseout="this.start();" onmouseover="this.stop();" scrolldelay="100"
      class="marquee-style">
      <div class="marquee-news"><i class="fas fa-circle"></i><span class="marquee-news-text"> Welcome to {{domain_name}}
        </span>
      </div>
      <div style="clear: both;"></div>
    </marquee>
  </div>
</div>

<ng-template #loginModal>
  <div class="cross_btn">
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modalRef.hide();this.createFrom();submitted= false;"></button>
  </div>
  <div class="modal-header">
    <img src="assets/logo/{{domain_name}}" class="modal_image" alt="">
  </div>
  <div class="modal-body">
    <div class="mobile_bg">
      <img src="assets/images/mobile_bg.png" alt="">
      <div class="widraw">
        100%
        <strong>FAST</strong>
        withdraw
        <div class="group">
          <img src="assets/images/group.png" alt="">
        </div>
      </div>
    </div>

    <div class="form" [formGroup]='Form'>
      <div class="input_field">
        <input type="text" class="form_control" formControlName="user_name" required>
        <label for="" class="input_label">Enter your Username</label>
        <i class="fa fa-user"></i>
      </div>
      <div class="alert_base" *ngIf="submitted && f.user_name.status == 'INVALID'">
        <span>* Please enter username</span>
      </div>
      <div class="input_field pass_field">
        <input formControlName="password" required="" [type]="showPassword ? 'text' : 'password'" class="form_control">
        <label for="" class="input_label">Enter your Password</label>
        <i alt="show" class="fa fa-eye eye-show" (click)="showPassword = !showPassword" [class.hide]="showPassword"></i>
        <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showPassword = !showPassword"
          [class.hide]="!showPassword"></i>
      </div>
      <div class="alert_base" *ngIf="submitted && f.password.status == 'INVALID'">
        <span>* Please enter password</span>
      </div>
      <div class="input_btn mt-10" (click)="onSubmitLogin('login')">
        <button class="sinup_btn">Sign In</button>
      </div>
      <div class="member">
        Don't have an account?
        <a class="ragister_link" (click)="modalRef.hide();openModalSignupModal(signupModal)">Register</a>
      </div>
      <div class="social_media">
        <ul>
          <li>
            <a target="_blank" href="{{insta}}"><img src="assets/images/instagram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{wa}}"><img src="assets/images/whatsapp1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{telegram}}"><img src="assets/images/telegram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{twitter}}"><img src="assets/images/twitter1.png" alt=""></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #signupModal>
  <div class="cross_btn">
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modalRefsign.hide()"></button>
  </div>
  <div class="modal-header">
    <img src="assets/logo/{{domain_name}}" class="modal_image" alt="">
  </div>
  <div class="modal-body">
    <div class="mobile_bg">
      <img src="assets/images/mobile_bg.png" alt="">
      <div class="widraw">
        100%
        <strong>FAST</strong>
        withdraw
        <div class="group">
          <img src="assets/images/group.png" alt="">
        </div>
      </div>
    </div>
    <div class="form" [formGroup]='addUserFrom'>
      <div class="input_field">
        <input type="text" class="form_control" formControlName="user_name" required>
        <label for="" class="input_label">Enter your Username</label>
        <i class="fa fa-user"></i>
      </div>
      <div class="alert_base" *ngIf="isControlIsValid('user_name')">
        <span *ngIf="isControlHasErros('user_name')">* {{ isControlHasErros('user_name')}}</span>
      </div>
      <div class="input_field">
        <input type="text" formControlName="mobile" class="form_control" maxlength="10" required>
        <label for="" class="input_label">Enter your Whatsapp Number</label>
        <i class="fa fa-phone"></i>
      </div>
      <div class="alert_base" *ngIf="isControlIsValid('mobile')">
        <span *ngIf="isControlHasErros('mobile')">* {{ isControlHasErros('mobile')}}</span>
      </div>
      <div class="input_field pass_field">
        <input [type]="showPassword ? 'text' : 'password'" class="form_control" formControlName="password" required>
        <label for="" class="input_label">Enter your Password</label>
        <i alt="show" class="fa fa-eye eye-show" (click)="showPassword = !showPassword" [class.hide]="showPassword"></i>
        <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showPassword = !showPassword"
          [class.hide]="!showPassword"></i>
      </div>
      <div class="alert_base" *ngIf="isControlIsValid('password')">
        <span *ngIf="isControlHasErros('password')">* {{ isControlHasErros('password')}}</span>
      </div>
      <div class="input_field" *ngIf="referCode != undefined">
        <input type="text" class="form_control" formControlName="refer_code" readonly >
        <label for="" class="input_label">Enter your Refer Code</label>
      </div>
      <div class="input_field" *ngIf="referCode == undefined">
        <input type="text" class="form_control" formControlName="refer_code" readonly>
        <label for="" class="input_label">Enter your Refer Code</label>
      </div>
      <div class="input_btn" (click)="addUserData()">
        <button class="sinup_btn">Register</button>
      </div>
      <div class="input_btn" (click)="goToRoute()">
        <button class="sinup_btn">Get Your ID</button>
      </div>
      <div class="member">
        Already have an account?
        <a class="ragister_link" (click)="modalRefsign.hide();openModalLoginModal(loginModal)">SignIn</a>
      </div>
      <div class="social_media">
        <ul>
          <li>
            <a target="_blank" href="{{insta}}"><img src="assets/images/instagram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{wa}}"><img src="assets/images/whatsapp1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{telegram}}"><img src="assets/images/telegram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{twitter}}"><img src="assets/images/twitter1.png" alt=""></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>