<div class="sidebar-div">
  <div class="right_sidebar" style="top: 70px;">
    <div class="main_right_sidebar">
      <div class="promotion-tab">
        <ul class="tabs">
          <li>
            <a class="active"> Promotions </a>
          </li>
        </ul>
        <div class="tab_content" (click)="openModalLoginModal(loginModal)">
          <a><img src="assets/images/refer.png" alt=""></a>
        </div>
      </div>
      <div class="vartual_tab">
        <div class="vartual_tab_link promotion-tab">
          <ul>
            <li class="tab show" (click)="providers()">
              <a class="casino_title no-border">Providers</a>
            </li>
            <li (click)="liveCasino()">
              <a class="casino_title active">Live</a>
            </li>
            <li (click)="virtual()">
              <a class="casino_title no-border">Virtual</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="game_slider">
        <div class="gameitem_wrapper" *ngIf="showProviders == false">
          <div class="game_item" data-bs-toggle="modal" data-bs-target="#exampleModal"
            *ngFor="let list of casino;let i = index;" (click)="openModalLoginModal(loginModal)">
            <div class="game_thumb">
              <img src="assets/Casino/{{list?.match_id}}.jpg" alt="">
            </div>
            <div class="play_btn">
              <a>{{list?.name}}</a>
            </div>
          </div>
        </div>
        <div class="gameitem_wrapper" *ngIf="showProviders == true">
          <div class="game_item" (click)="openModalLoginModal(loginModal)">
            <div class="game_thumb">
              <img src="assets/img/SPB-aviator.png" alt="">
            </div>
            <div class="play_btn">
              <a>Aviator</a>
            </div>
          </div>
          <div class="game_item" (click)="openModalLoginModal(loginModal)">
            <div class="game_thumb">
              <img src="assets/img/casino.png" alt="">
            </div>
            <div class="play_btn">
              <a>Casino</a>
            </div>
          </div>
          <div class="game_item" (click)="openModalLoginModal(loginModal)">
            <div class="game_thumb" style="background-color: cornsilk;">
              <img src="assets/img/ezugi.png" alt="">
            </div>
            <div class="play_btn">
              <a>Ezugi</a>
            </div>
          </div>
          <div class="game_item" (click)="openModalLoginModal(loginModal)">
            <div class="game_thumb">
              <img src="assets/img/evolution.png" alt="">
            </div>
            <div class="play_btn">
              <a>Evolution</a>
            </div>
          </div>
          <div class="game_item" (click)="openModalLoginModal(loginModal)">
            <div class="game_thumb" style="background-color: cornsilk;">
              <img src="assets/img/sexyCasino.png" alt="">
            </div>
            <div class="play_btn">
              <a>SexyCasino</a>
            </div>
          </div>
          <div class="game_item" (click)="openModalLoginModal(loginModal)">
            <div class="game_thumb">
              <img src="assets/img/ebet.png" alt="">
            </div>
            <div class="play_btn">
              <a>Ebet</a>
            </div>
          </div>
          <div class="game_item" (click)="openModalLoginModal(loginModal)">
            <div class="game_thumb">
              <img src="assets/img/beterlive.png" alt="">
            </div>
            <div class="play_btn">
              <a>Beterlive</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loginModal>
  <div class="cross_btn">
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modalRef.hide();this.createFrom();submitted= false;"></button>
  </div>
  <div class="modal-header">
    <img src="{{logo}}" class="modal_image" alt="">
  </div>
  <div class="modal-body">
    <div class="mobile_bg">
      <img src="assets/images/mobile_bg.png" alt="">
      <div class="widraw">
        100%
        <strong>FAST</strong>
        withdraw
        <div class="group">
          <img src="assets/images/group.png" alt="">
        </div>
      </div>
    </div>

    <div class="form" [formGroup]='Form'>
      <div class="input_field">
        <input type="text" class="form_control" formControlName="user_name" required>
        <label for="" class="input_label">Enter your Username</label>
        <i class="fa fa-user"></i>
      </div>
      <div class="alert_base" *ngIf="submitted && f.user_name.status == 'INVALID'">
        <span>* Please enter username</span>
      </div>
      <div class="input_field pass_field">
        <input formControlName="password" required="" [type]="showPassword ? 'text' : 'password'" class="form_control">
        <label for="" class="input_label">Enter your Password</label>
        <i alt="show" class="fa fa-eye eye-show" (click)="showPassword = !showPassword" [class.hide]="showPassword"></i>
        <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showPassword = !showPassword"
          [class.hide]="!showPassword"></i>

      </div>
      <div class="alert_base" *ngIf="submitted && f.password.status == 'INVALID'">
        <span>* Please enter password</span>
      </div>
      <div class="input_btn mt-10" (click)="onSubmitLogin('login')">
        <button class="sinup_btn">Sign In</button>
      </div>
      <div class="member">
        Don't have an account?
        <a class="ragister_link" (click)="modalRef.hide();openModalSignupModal(signupModal)">Register</a>
      </div>
      <div class="social_media">
        <ul>
          <li>
            <a target="_blank" href="{{insta}}"><img src="assets/images/instagram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{wa}}"><img src="assets/images/whatsapp1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{telegram}}"><img src="assets/images/telegram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{twitter}}"><img src="assets/images/twitter1.png" alt=""></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #signupModal>
  <div class="cross_btn">
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modalRef.hide()"></button>
  </div>
  <div class="modal-header">
    <img src="{{logo}}" class="modal_image" alt="">
  </div>
  <div class="modal-body">
    <div class="mobile_bg">
      <img src="assets/images/mobile_bg.png" alt="">
      <div class="widraw">
        100%
        <strong>FAST</strong>
        widraw
        <div class="group">
          <img src="assets/images/group.png" alt="">
        </div>
      </div>
    </div>

    <div class="form" [formGroup]='addUserFrom'>
      <div class="input_field">
        <input type="text" class="form_control" formControlName="user_name" required>
        <label for="" class="input_label">Enter your Username</label>
      </div>
      <div class="alert_base" *ngIf="isControlIsValid('user_name')">
        <span *ngIf="isControlHasErros('user_name')"> {{ isControlHasErros('user_name')}}</span>

      </div>
      <div class="input_field">
        <input type="text" formControlName="mobile" class="form_control" maxlength="10" required>
        <label for="" class="input_label">Enter your Whatsapp Number</label>
      </div>
      <div class="alert_base" *ngIf="isControlIsValid('mobile')">
        <span *ngIf="isControlHasErros('mobile')"> {{ isControlHasErros('mobile')}}</span>
      </div>
      <div class="input_field">
        <input [type]="showPassword ? 'text' : 'password'" class="form_control" formControlName="password" required>
        <label for="" class="input_label">Enter your Password</label>
        <i alt="show" class="fa fa-eye eye-show" (click)="showPassword = !showPassword" [class.hide]="showPassword"></i>
        <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showPassword = !showPassword"
          [class.hide]="!showPassword"></i>
      </div>
      <div class="alert_base" *ngIf="isControlIsValid('password')">
        <span *ngIf="isControlHasErros('password')"> {{ isControlHasErros('password')}}</span>
      </div>
      <div class="input_field" *ngIf="referCode != undefined">
        <input type="text" class="form_control" formControlName="refer_code" readonly>
        <label for="" class="input_label">Enter your Refer Code</label>
      </div>
      <div class="input_field" *ngIf="referCode == undefined">
        <input type="text" class="form_control" formControlName="refer_code">
        <label for="" class="input_label">Enter your Refer Code</label>
      </div>
      <div class="input_btn" (click)="addUserData()">
        <button class="sinup_btn">Register</button>
      </div>

      <div class="input_btn" (click)="goToRoute()">
        <button class="sinup_btn">Get Your ID</button>
      </div>
      <div class="member">
        Already have an account?
        <a class="ragister_link" (click)="modalRef.hide();openModalLoginModal(loginModal)">SignIn</a>
      </div>
      <div class="social_media">
        <ul>
          <li>
            <a target="_blank" href="{{insta}}"><img src="assets/images/instagram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{wa}}"><img src="assets/images/whatsapp1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{telegram}}"><img src="assets/images/telegram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{twitter}}"><img src="assets/images/twitter1.png" alt=""></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>