<footer id="footer" class="footer">
  <div class="container">
    <div class="row">

      <div class="footer_column first_foot">
        <img class="img-fluid" src="{{logo}}">
        <div class="right-align">
          <button class="btn_download" (click)="DownloadApk('-apk')"><img class="img-fluid"
              src="assets/images/icon-download.png"><i class="fa fa-android" aria-hidden="true"></i></button>
        </div>
        <p>Our website is Asia’s one of the biggest gaming platforms with over 350 games and sports available. <br />
          Popular sports such as Cricket, Tennis, Football, Basketball, Volleyball and many more including Indian card
          games like Teen Patti and Andar Bahar are also available from various worldwide gaming providers. <br /> With
          unlimited markets and odds, Our website gives players the best gaming experience in the market.</p>
        <div class="social_cions">
          <a  target="_blank" href="{{insta}}"><img class="img-fluid" src="assets/images/social-insta.png"></a>
          <a target="_blank" href="{{wa}}"><img class="img-fluid" src="assets/images/social-whatsapp.png"></a>
          <a target="_blank" href="{{telegram}}"><img class="img-fluid" src="assets/images/social-tweet.png"></a>
          <a target="_blank" href="{{twitter}}"><img class="img-fluid" src="assets/images/social-tele.png"></a>
        </div>
      </div>
      <div class="footer_column second_foot">
        <img class="img-fluid" src="assets/images/responsible.png">
        <p>Visit Our website for tools and resources to help you stay in control of the way you play.</p>
        <p>100% Safe Instant Payments You can make payments and receive earnings instantly via your UPI ID - so you can
          be sure that your money is safe and secure. All UPI platforms are accepted.</p>
        <img class="img-fluid" src="assets/images/secure-logo.png">
      </div>

      <div class="footer_column third_foot">
        <!-- <button class="btn_download" (click)="DownloadApk('-apk')"><img class="img-fluid"
            src="assets/images/icon-download.png"><i class="fa fa-android" aria-hidden="true"></i></button> -->
        <p class="pay-title">Accepted Modes of Payment</p>
        <img class="img-fluid" src="assets/images/payment-footer-logo1.png">
        <img class="img-fluid" src="assets/images/payment-footer-logo2.png">
        <!-- <div class="paymentMode">
          <ul>
            <li><a href="javascript:void(0)"><img src="assets/images/upi.jpg"> </a></li>
            <li><a href="javascript:void(0)"><img src="assets/images/phonepe.png"> </a></li>
            <li><a href="javascript:void(0)"><img src="assets/images/google-pay.png"> </a></li>
          </ul>
          <ul>
            <li><a href="javascript:void(0)"><img src="assets/images/bank-transfer.png"> </a></li>
            <li><a href="javascript:void(0)"><img src="assets/images/paytm.png"> </a></li>
            <li><a href="javascript:void(0)"><img src="assets/images/visa.png"> </a></li>
            <li><a href="javascript:void(0)"><img src="assets/images/masterCard.png"> </a></li>
          </ul>
        </div> -->
        <em>&#169; Copyright 2010 - 2023</em>
      </div>
    </div>
  </div>
</footer><!-- End Footer -->
<footer class="footer-bottom">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-2">
        <img class="img-fluid" src="assets/images/gc.png">
      </div>
      <div class="col-md-10">
        <h4>Disclaimer</h4>
        <p>Please Note that gaming involves a financial risk and could be addictive over time if not practised within
          limits. Only 18+ people should use the services and should use it responsibly. Players should be aware of any
          financial risk and govern themselves accordingly.</p>
      </div>
    </div>
  </div>
</footer>


<div class="bottom_tabs">
  <ul>
    <li (click)="sports(2)">
      <a>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="11.25" stroke="#C4C4C4" stroke-width="1.5"></circle>
          <path
            d="M8 17.9475V6.05261C8 5.90049 8.16309 5.80406 8.29638 5.87737L19.11 11.8248C19.2481 11.9008 19.2481 12.0993 19.11 12.1753L8.29638 18.1228C8.16309 18.1961 8 18.0997 8 17.9475Z"
            fill="#C4C4C4"></path>
        </svg>
      </a>
      <div class="title_name">
        InPlay
      </div>
    </li>
    <li (click)="sports(4)">
      <a href="javascript:void(0)">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.4">
            <path
              d="M10.334 14.4893C10.334 15.4304 11.0673 16.2004 11.9595 16.2004H13.7929C14.5751 16.2004 15.2107 15.5282 15.2107 14.7093C15.2107 13.8171 14.8195 13.4993 14.2451 13.2915L11.3118 12.2648C10.7251 12.0571 10.334 11.7393 10.334 10.8471C10.334 10.0282 10.9695 9.35596 11.7518 9.35596H13.5851C14.4895 9.36818 15.2229 10.126 15.2229 11.0671"
              stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M12.7793 16.2607V17.1652" stroke="#292D32" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round"></path>
            <path d="M12.7793 8.39014V9.34347" stroke="#292D32" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round"></path>
          </g>
          <path
            d="M12.7675 22.5311C18.1609 22.5311 22.5331 18.1589 22.5331 12.7656C22.5331 7.37219 18.1609 3 12.7675 3C7.37414 3 3.00195 7.37219 3.00195 12.7656C3.00195 18.1589 7.37414 22.5311 12.7675 22.5311Z"
            stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
          <path opacity="0.4"
            d="M14.002 22.4089C15.102 23.9611 16.8986 24.9756 18.952 24.9756C22.2886 24.9756 25.002 22.2622 25.002 18.9256C25.002 16.8967 23.9997 15.1 22.472 14"
            stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
        <div class="title_name">
        </div>
      </a>
      <div class="title_name">
        Casino
      </div>
    </li>
    <li class="all-sports home-icon" (click)="sports(1)">
      <a>
        <i class="fa fa-home" style="font-size: 24px;"></i>
      </a>
      <div class="title_name">
        Home
      </div>
    </li>
    <li id="darkModeFooter" (click)="changeThemeMode()">
      <a class="dark-mode">
        <img class="img-fluid darkmodeimg" src="assets/images/day-mode.png">

        <img class="img-fluid lightmodeimg" src="assets/images/night-mode.png">
      </a>
      <div class="title_name">
        Theme
      </div>
    </li>
    <li (click)="openModalLoginModal(loginModal)">
      <a>
        <img src="assets/images/avatar3.png" alt="">
      </a>
      <div class="title_name">
        Sign In
      </div>
    </li>
  </ul>
</div>


<ng-template #searchModal>
  <a class="modal-close right" data-bs-dismiss="modal" aria-label="Close" (click)="modalRef.hide()"><i
      aria-hidden="true" class="fa fa-times"></i></a>
  <div class="modal-body">
    <div ngbDropdown class="search-top dropdown" style="cursor:pointer">
      <div class="input-group">
        <form class="d-flex position-relative" (click)="homematches()">
          <input ngbDropdownToggle id="dropdownMenuLink" placeholder="Search.." type="text" name="search"
            class="search_input validate z-depth-0 dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false" type="text" autocomplete="off" [(ngModel)]="searchSport"
            [ngModelOptions]="{standalone: true}">
          <span class="float-icon search-icon"><i class="fa fa-search"></i></span>
          <span class="float-icon clearall-icon" (click)="searchSport = ''"><i class="bi bi-x-lg"></i></span>
          <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <a ngbDropdownItem class="dropdown-item" *ngFor="let sport of homeData |search: searchSport :'match_name'">
              <div class="event-info" (click)="matchDetail(sport)">
                <div class="event-team">
                  <div class="team-name">{{sport?.match_name}}</div>
                </div>
              </div>
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loginModal>
  <div class="cross_btn">
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modalRef.hide();this.createFrom();submitted= false;"></button>
  </div>
  <div class="modal-header">
    <img src="{{logo}}" class="modal_image" alt="">
  </div>
  <div class="modal-body">
    <div class="mobile_bg">
      <img src="assets/images/mobile_bg.png" alt="">
      <div class="widraw">
        100%
        <strong>FAST</strong>
        withdraw
        <div class="group">
          <img src="assets/images/group.png" alt="">
        </div>
      </div>
    </div>

    <div class="form" [formGroup]='Form'>
      <div class="input_field">
        <input type="text" class="form_control" formControlName="user_name" required>
        <label for="" class="input_label">Enter your Username</label>
        <i class="fa fa-user"></i>
      </div>
      <div class="alert_base" *ngIf="submitted && f.user_name.status == 'INVALID'">
        <span>* Please enter username</span>
      </div>
      <div class="input_field pass_field">
        <input formControlName="password" required="" [type]="showPassword ? 'text' : 'password'" class="form_control">
        <label for="" class="input_label">Enter your Password</label>
        <i alt="show" class="fa fa-eye eye-show" (click)="showPassword = !showPassword" [class.hide]="showPassword"></i>
        <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showPassword = !showPassword"
          [class.hide]="!showPassword"></i>

      </div>
      <div class="alert_base" *ngIf="submitted && f.password.status == 'INVALID'">
        <span>* Please enter password</span>
      </div>
      <div class="input_btn mt-10" (click)="onSubmitLogin('login')">
        <button class="sinup_btn">Sign In</button>
      </div>
      <div class="member">
        Don't have an account?
        <a class="ragister_link" (click)="modalRef.hide();openModalSignupModal(signupModal)">Register</a>
      </div>
      <div class="social_media">
        <ul>
          <li>
            <a target="_blank" href="{{insta}}"><img src="assets/images/instagram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{wa}}"><img src="assets/images/whatsapp1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{telegram}}"><img src="assets/images/telegram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{twitter}}"><img src="assets/images/twitter1.png" alt=""></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #signupModal>
  <div class="cross_btn">
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modalRef.hide()"></button>
  </div>
  <div class="modal-header">
    <img src="{{logo}}" class="modal_image" alt="">
  </div>
  <div class="modal-body">
    <div class="mobile_bg">
      <img src="assets/images/mobile_bg.png" alt="">
      <div class="widraw">
        100%
        <strong>FAST</strong>
        widraw
        <div class="group">
          <img src="assets/images/group.png" alt="">
        </div>
      </div>
    </div>

    <div class="form" [formGroup]='addUserFrom'>
      <div class="input_field">
        <input type="text" class="form_control" formControlName="user_name" required>
        <label for="" class="input_label">Enter your Username</label>
      </div>
      <div class="alert_base" *ngIf="isControlIsValid('user_name')">
        <span *ngIf="isControlHasErros('user_name')"> {{ isControlHasErros('user_name')}}</span>

      </div>
      <div class="input_field">
        <input type="text" formControlName="mobile" class="form_control" maxlength="10" required>
        <label for="" class="input_label">Enter your Whatsapp Number</label>
      </div>
      <div class="alert_base" *ngIf="isControlIsValid('mobile')">
        <span *ngIf="isControlHasErros('mobile')"> {{ isControlHasErros('mobile')}}</span>
      </div>
      <div class="input_field">
        <input [type]="showPassword ? 'text' : 'password'" class="form_control" formControlName="password" required>
        <label for="" class="input_label">Enter your Password</label>
        <i alt="show" class="fa fa-eye eye-show" (click)="showPassword = !showPassword" [class.hide]="showPassword"></i>
        <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showPassword = !showPassword"
          [class.hide]="!showPassword"></i>
      </div>
      <div class="alert_base" *ngIf="isControlIsValid('password')">
        <span *ngIf="isControlHasErros('password')"> {{ isControlHasErros('password')}}</span>
      </div>
      <div class="input_field" *ngIf="referCode != undefined">
        <input type="text" class="form_control" formControlName="refer_code" readonly>
        <label for="" class="input_label">Enter your Refer Code</label>
      </div>
      <div class="input_field" *ngIf="referCode == undefined">
        <input type="text" class="form_control" formControlName="refer_code">
        <label for="" class="input_label">Enter your Refer Code</label>
      </div>
      <div class="input_btn" (click)="addUserData()">
        <button class="sinup_btn">Register</button>
      </div>

      <div class="input_btn" (click)="goToRoute()">
        <button class="sinup_btn">Get Your ID</button>
      </div>
      <div class="member">
        Already have an account?
        <a class="ragister_link" (click)="modalRef.hide();openModalLoginModal(loginModal)">SignIn</a>
      </div>
      <div class="social_media">
        <ul>
          <li>
            <a target="_blank" href="{{insta}}"><img src="assets/images/instagram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{wa}}"><img src="assets/images/whatsapp1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{telegram}}"><img src="assets/images/telegram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{twitter}}"><img src="assets/images/twitter1.png" alt=""></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>