import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
@Component({
  selector: 'app-report-header',
  templateUrl: './report-header.component.html',
  styleUrls: ['./report-header.component.scss']
})
export class ReportHeaderComponent implements OnInit {
  a: string;
  constructor(private router: Router, private route: ActivatedRoute,) { }
  ngOnInit(): void {
    this.a = this.router.url;
  }
  statement() {
    this.router.navigate(['statement'])
  }
  profile() {
    this.router.navigate(['profile'])
  }
  bet() {
    this.router.navigate(['open-bet'])
  }
  sbet() {
    this.router.navigate(['settle-bet'])
  }
  withdraw() {
    this.router.navigate(['withdraw'])
  }
  transaction() {
    this.router.navigate(['transaction'])
  }
  profitLoss() {
    this.router.navigate(['eventpl'])
  }
}
