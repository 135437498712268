import { Component, AfterViewInit, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DateTime } from '../../dateTime';
import { ActivatedRoute } from "@angular/router";
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-view-bet',
  templateUrl: './view-bet.component.html',
  styleUrls: ['./view-bet.component.scss'],
})
export class ViewBetComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('popoverRef') private _popoverRef: PopoverDirective;
  @ViewChild('startpopoverRef') private _startpopoverRef: PopoverDirective;
  @ViewChild('endpopoverRef') private _endpopoverRef: PopoverDirective;
  moment: any = moment;
  time: Date;
  date: Date;
  isDateVisible: boolean = true;
  isMeridian: boolean = false;
  dateTime = new Date();
  statementStartDate = new Date()
  statementEndDate = new Date();
  timeStatement: Date;
  dateStatement: Date;
  endDateStatement: Date;
  endTimeStatement: Date;
  endTime: any;
  public Base_Url = environment['adminServerUrl'];
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  public token = localStorage.getItem('userAccessToken');
  reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ` + this.token
  });
  betList: any[];
  type: any;
  filterParam: any = [];
  openBetFilter: boolean = false;
  MarketList: any;
  itemsPerPage: number = 10;
  currentPage: number = 1;
  totalItems: number = 0;
  betType: any = "2";
  param: any;
  SportList: any;
  SeriesList: any;
  MatchList: any;
  initialSportList: any;
  searchMatch: any
  searchUser: any
  searchMarket: any
  searchSport: any
  searchSeries: any
  match_id: any;
  market_id: any;
  typeId: any;
  sport_name: any;
  series_name: any;
  match_name: any;
  market_name: any;
  userId: any;
  userDetails: any;
  constructor(private http: HttpClient, private route: ActivatedRoute, private toastr: ToastrService) {
    this.route.params.subscribe((params) => {
      this.match_id = params.matchId;
      this.market_id = params.marketId;
      this.typeId = params.type;
      this.sport_name = params.sportName;
      this.series_name = params.seriesName;
      this.match_name = params.matchName;
      this.market_name = params.marketName;
      this.userId = params.id;
    });
  }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'))
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      paging: true,
      serverSide: true,
      processing: false,
      ordering: false,
      ajax: (dataTablesParameters1: any, callback) => {
        let dataTablesParameters = {
          "match_id": this.match_id,
          "search": {
          }
        }
        if (this.typeId == 1) {
          dataTablesParameters.search['market_id'] = this.market_id
        } else {
          dataTablesParameters.search['fancy_id'] = this.market_id
        }
        if (dataTablesParameters.search == undefined) {
          delete dataTablesParameters.search
        }
        that.http.post<DataTablesResponse>(this.Base_Url + 'bet/settledBets', dataTablesParameters, { headers: this.reqHeader })
          .subscribe(resp => {
            if (resp["status"]) {
              if ((resp.data[0].data).length != 0) {
                this.betList = resp.data[0].data;
                for (let i = 0; i < this.betList.length; i++) {
                  this.betList[i].index = (dataTablesParameters1.start) + 1 + i;
                  this.betList[i].updateDateTime = moment(this.betList[i].createdAt).utcOffset("+5:30").format('DD-MM-YYYY HH:mm:ss');
                  if (this.betList[i].is_fancy == 0) {
                    this.betList[i].updateSelection = this.betList[i].selection_name;
                    this.betList[i].updateOdds = this.betList[i].odds;
                    this.betList[i].updatePl = this.betList[i].chips
                    if (this.betList[i].is_back == 1) {
                      this.betList[i].updateType = 'Back'
                    } else {
                      this.betList[i].updateType = 'Lay'
                    }
                  } else {
                    this.betList[i].updateSelection = this.betList[i].odds;
                    this.betList[i].updateOdds = this.betList[i].odds + '/' + this.betList[i].size;
                    if (this.betList[i].is_back == 1) {
                      this.betList[i].updateType = 'Yes'
                      if (this.betList[i].odds < this.betList[i].winner_name) {
                        this.betList[i].updatePl = this.betList[i].p_l
                      } else {
                        this.betList[i].updatePl = this.betList[i].liability
                      }
                    } else {
                      this.betList[i].updateType = 'No'
                      if (this.betList[i].odds > this.betList[i].winner_name) {
                        this.betList[i].updatePl = this.betList[i].chips
                      } else {
                        this.betList[i].updatePl = this.betList[i].p_l
                      }
                    }
                  }
                }
                callback({
                  recordsTotal: resp.data[0].metadata == undefined ? 0 : resp.data[0].metadata[0].total,
                  recordsFiltered: resp.data[0].metadata == undefined ? 0 : resp.data[0].metadata[0].total,
                  data: this.betList
                });
              } else {
                this.betList = []
                callback({
                  recordsTotal: 0,
                  recordsFiltered: 0,
                  data: this.betList
                });
              }
            } else {
              this.toastr.error(resp["msg"]);
              this.betList = []
              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: this.betList
              });
            }
          });
      },
      columns: [
        {
          title: 'S. No.',
          data: 'index'
        },
        {
          title: 'Sport Name',
          data: 'sport_name',
        },
        {
          title: 'Event Name',
          data: 'match_name',
        },
        {
          title: 'Market Name',
          data: 'market_name',
        },
        {
          title: 'Selection',
          data: 'updateSelection',
        },
        {
          title: 'Bet Type',
          data: 'updateType',
        },
        {
          title: 'User Price',
          data: 'updateOdds',
        },
        {
          title: 'Amount',
          data: 'stack',
        },
        {
          title: 'Profit/Loss',
          data: 'updatePl',
          "render": function (data, type, row) {
            var content = ''
            if (row.is_fancy == 0) {
              if (row.updatePl != null && row.updatePl < 0) {
                content = content + '<span class="text-center text-danger">' + (row.updatePl).toFixed(2) + '</span>';
              } else if (row.updatePl != null && row.updatePl >= 0) {
                content = content + '<span class="text-center text-success">' + (row.updatePl).toFixed(2) + '</span> ';
              } else {
                content = '';
              }
            } else {
              if (row.updatePl != null && row.updatePl < 0) {
                content = content + '<span class="text-center text-danger">' + (row.updatePl).toFixed(2) + '</span>';
              } else if (row.updatePl != null && row.updatePl >= 0) {
                content = content + '<span class="text-center text-success">' + (row.updatePl).toFixed(2) + '</span>';
              } else {
                content = '';
              }
            }
            return content;
          }
        },
        {
          title: 'Result',
          data: 'winner_name'
        },
        {
          title: 'Placed Time',
          data: 'updateDateTime',
        },
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        $(row).addClass('text-center');
        return row;
      }
    };

    if (this.dateTime) {
      this.time = this.date = this.dateTime;
      return;
    }
    this.date = this.time = new Date(); if (this.statementStartDate) {
      this.time = this.date = this.statementStartDate;
      return;
    }
    if (this.statementEndDate) {
      this.time = this.date = this.statementEndDate;
      return;
    }
  }
  dateSelectionDone() {
    this.isDateVisible = false;
  }
  updateDateStatement() {
    if (this.dateStatement) {
      this.statementStartDate = DateTime.getDateTime(this.dateStatement, this.timeStatement);
    }
    if (!this.timeStatement) {
      this.timeStatement = this.dateStatement;
    }
  }
  updateEndDateStatement() {
    if (this.endDateStatement) {
      this.statementEndDate = DateTime.getDateTime(this.endDateStatement, this.endTimeStatement);
    }
    if (!this.endTimeStatement) {
      this.endTimeStatement = this.endDateStatement;
    }
  }
  updateTimeStatement() {
    if (this.timeStatement) {
      this.statementStartDate = DateTime.getDateTime(this.dateStatement, this.timeStatement);
    }
  }
  updateEndTimeStatement() {
    if (this.endTime) {
      this.statementEndDate = DateTime.getDateTime(this.endDateStatement, this.endTimeStatement);
    }
  }
  updateDate() {
    if (this.date) {
      this.dateTime = DateTime.getDateTime(this.date, this.time);
    }
    if (!this.time) {
      this.time = this.date;
    }
  }

  updateTime() {
    if (this.time) {
      this.dateTime = DateTime.getDateTime(this.date, this.time);
    }
  }

  showDate() {
    this.isDateVisible = true;
  }

  showTime() {
    this.isDateVisible = false;
  }

  close() {
    this._popoverRef.hide();
  }

  now() {
    this.dateTime = DateTime.now(this.date);
    this.time = this.dateTime;
  }

  today() {
    this.statementStartDate = DateTime.now(this.date);
    this.statementEndDate = DateTime.now(this.date);
  }
  clear() {
    this.time = void 0;
    this.date = void 0;
    this.dateTime = void 0;
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next("");
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next("");
    });
  }
}
