<app-open-header></app-open-header>
<div class="content_area">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 left_bar">
        <app-open-left-sidebar></app-open-left-sidebar>
      </div>
      <div class="col-md-9 conetnt_col">
        <div class="slider">
          <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item item " *ngFor="let image of banner;let i = index;"
                [ngClass]="(i === 0) ? 'active' : ''">
                <img src="{{image.bannerUrl}}" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-open-footer></app-open-footer>