<app-open-header></app-open-header>
<!-- New design HTML -->
<div class="content_area">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 left_bar">
        <app-open-left-sidebar></app-open-left-sidebar>
      </div>
      <div class="col-md-9 conetnt_col">
        <div class="slider">
          <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item item " *ngFor="let image of banner;let i = index;"
                [ngClass]="(i === 0) ? 'active' : ''">
                <img src="{{image.bannerUrl}}" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="popular-games">
          <div class="position-relative popular-games-bg">
            <div class="top-rated-title"> Trending Games </div>
            <div class="popular-scroll">
              <div class="populer-slider">
                <div class="item_main" (click)="openModalLoginModal(loginModal)">
                  <a>
                    <img src="assets/img/SPB-aviator.png" class="img-responsive" alt="">
                  </a>
                  <div class="play-btn cursor-pointer">
                    <h4>
                      <div class="sub-title"> Aviator </div>
                    </h4>
                  </div>
                </div>
                <div class="item_main" (click)="openModalLoginModal(loginModal)">
                  <a>
                    <img src="assets/img/casino.png" class="img-responsive" alt="">
                  </a>
                  <div class="play-btn cursor-pointer">
                    <h4>
                      <div class="sub-title"> Casino </div>
                    </h4>
                  </div>
                </div>
                <div class="item_main" (click)="openModalLoginModal(loginModal)">
                  <a>
                    <img src="assets/img/ezugi.png" class="img-responsive" alt="">
                  </a>
                  <div class="play-btn cursor-pointer">
                    <h4>
                      <div class="sub-title"> Ezugi </div>
                    </h4>
                  </div>
                </div>
                <div class="item_main" (click)="openModalLoginModal(loginModal)">
                  <a>
                    <img src="assets/img/evolution.png" class="img-responsive" alt="">
                  </a>
                  <div class="play-btn cursor-pointer">
                    <h4>
                      <div class="sub-title"> Evolution </div>
                    </h4>
                  </div>
                </div>
                <div class="item_main" (click)="openModalLoginModal(loginModal)">
                  <a>
                    <img src="assets/img/sexyCasino.png" class="img-responsive" alt="">
                  </a>
                  <div class="play-btn cursor-pointer">
                    <h4>
                      <div class="sub-title"> SexyCasino </div>
                    </h4>
                  </div>
                </div>
                <div class="item_main" (click)="openModalLoginModal(loginModal)">
                  <a>
                    <img src="assets/img/ebet.png" class="img-responsive" alt="">
                  </a>
                  <div class="play-btn cursor-pointer">
                    <h4>
                      <div class="sub-title"> Ebet </div>
                    </h4>
                  </div>
                </div>
                <div class="item_main" (click)="openModalLoginModal(loginModal)">
                  <a>
                    <img src="assets/img/beterlive.png" class="img-responsive" alt="">
                  </a>
                  <div class="play-btn cursor-pointer">
                    <h4>
                      <div class="sub-title"> Beterlive </div>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-main" *ngIf="sportView == true">
          <ul class="nav nav-pills dashboard_navpills" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation" (click)="inplay()">
              <a class="nav-link" [class.active]="sportName == 'inplay'" id="pills-all-tab" data-bs-toggle="pill"
                data-bs-target="#pills-all" type="button" role="tab" aria-controls="pills-all" aria-selected="true"
                (click)="inplay()">
                <img src="assets/images/inplay.png" style="height: 26px;"> Inplay
              </a>
            </li>
            <li class="nav-item" role="presentation" *ngFor="let sports of allSportsList;let i= index"
              (click)="goToDashboard(sports.name,sports.sport_id,sports)">
              <a class="nav-link" [class.active]='sportName == sports.name' id="pills-cricket-tab" data-bs-toggle="pill"
                data-bs-target="#pills-cricket" type="button" role="tab" aria-controls="pills-cricket"
                aria-selected="false">
                <img src="assets/images/tabs/{{sports.name}}.png" style="height: 26px;width: 26px;"> {{sports.name}}
              </a>
            </li>
          </ul>
          <div class="tab-content dashboard_tabcontent" id="pills-tabContent">
            <ng-container>
              <div class="tab-pane fade show active" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab">
                <div id="accordion">
                  <div class="card" *ngFor="let homeMatch of homeData | keyvalue;let i = index;">
                    <div class="card-header" id="headingOne">
                      <a href="#" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                        aria-controls="collapseOne">
                        {{homeMatch?.key}} ({{(homeMatch?.value).length}})
                        <img src="assets/images/tabs/{{homeMatch?.key}}-bg.png">
                      </a>
                    </div>
                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                      data-bs-parent="#accordion">
                      <div class="card-body">
                        <div class="row justify-content-end header_1x1">
                          <div class="col-md-5">
                            <div class="h1x1">
                              <button>1</button>
                              <button>X</button>
                              <button>2</button>
                            </div>
                          </div>
                        </div>
                        <div class="row dashboard_list" *ngFor="let matchData of homeMatch.value;let j = index;">
                          <div class="col-md-6">
                            <div class="event_lists">
                              <div class=""
                                [ngClass]="{'match_highlight inplay':matchData?.inplay == true ,'match_highlight': matchData?.manualInplay == true }"
                                *ngIf="matchData?.inplay == true || matchData?.manualInplay == true">
                                <div class="animate">
                                  <div class="">
                                    <div class="play">
                                      <span class="in-play-light">
                                        <div class="icon-holder-small">
                                          <div class="sports-icon inplay-light-icon">
                                          </div>
                                        </div>
                                        In-Play
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class=""
                                [ngClass]="{'match_highlight':matchData?.inplay != true ,'match_highlight': matchData?.manualInplay != true }"
                                *ngIf="matchData?.inplay != true || matchData?.manualInplay != true">
                                <div class="animate">
                                  <div>
                                    <div class="time_date">
                                      <span class="time">{{(matchData?.match_date | date :'dd-MMM')}}</span>
                                      <span class="date">{{(matchData?.match_date | date :'HH:mm')}}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div (click)="matchDetail(matchData)">
                                <em>{{matchData?.series_name}}</em>
                                <p>{{matchData?.match_name}}</p>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 px-0 mo_header_1x1">
                          </div>
                          <div class="col-md-1 col-3">
                            <div class="elm_icons" *ngIf="matchData?.sport_id == '4'">
                              <span>mo <em>1</em></span>
                              <span>F <em>{{matchData?.fancy_count > 0 ? matchData?.fancy_count : 0}}</em></span>
                            </div>
                          </div>
                          <div class="col-md-5 col-9" (click)="matchDetail(matchData)">
                            <div class="oods_boxes" [ngClass]="{'suspended':matchData?.status != 'OPEN'}">
                              <div class="ob">
                                <button class="back">{{ matchData?.runners[0] == undefined
                                  ||matchData?.runners[0].ex?.availableToBack[0] == undefined
                                  ||matchData?.runners[0].ex?.availableToBack[0]?.price == undefined
                                  ||matchData?.runners[0].ex?.availableToBack[0]?.price == '--' ? '-' :
                                  matchData?.runners[0]?.ex?.availableToBack[0]?.price}}</button>
                                <button class="lay"> {{matchData?.runners[0]?.ex?.availableToLay[0]?.price
                                  == undefined ||matchData?.runners[0].ex?.availableToLay[0] == undefined ||
                                  matchData?.runners[0]?.ex?.availableToLay[0]?.price == '--'? '-' :
                                  matchData?.runners[0]?.ex?.availableToLay[0]?.price}}</button>
                              </div>
                              <div class="ob">
                                <button class="back"> {{matchData?.runners[2] ==
                                  undefined||matchData?.runners[2].ex?.availableToBack[0] == undefined ||
                                  (matchData?.runners[2]?.ex?.availableToBack).length == 0 ? '-' :
                                  matchData?.runners[2]?.ex?.availableToBack[0]?.price}}</button>
                                <button class="lay">{{matchData?.runners[2] ==
                                  undefined||matchData?.runners[2].ex?.availableToLay[0] == undefined ||
                                  (matchData?.runners[2]?.ex?.availableToLay).length == 0 ? '-' :
                                  matchData?.runners[2]?.ex?.availableToLay[0]?.price}}</button>
                              </div>
                              <div class="ob">
                                <button class="back"> {{matchData?.runners[1] == undefined
                                  ||matchData?.runners[1]?.ex?.availableToBack[0]?.price
                                  == undefined || matchData?.runners[1]?.ex?.availableToBack[0] == undefined ||
                                  matchData?.runners[1]?.ex?.availableToBack[0]?.price == '--'? '-' :
                                  matchData?.runners[1]?.ex?.availableToBack[0]?.price}}</button>
                                <button class="lay"> {{matchData?.runners[1]?.ex?.availableToLay[0]?.price ==undefined
                                  ||
                                  matchData?.runners[1]?.ex?.availableToLay[0] == undefined ||
                                  matchData?.runners[1]?.ex?.availableToLay[0]?.price == '--'? '-' :
                                  matchData?.runners[1]?.ex?.availableToLay[0]?.price}}</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>


        <div class="casino-section" *ngIf="sportView == false">
          <div class="all_casion_game">
            <div class="casion_tabs">
              <ul class="nav nav-pills dashboard_navpills">
                <li class="nav-item" (click)="all()">
                  <a class="nav-link" [ngClass]="{'active': casinoId == 'All'}" id="btn-1" tab-content="#item-1">All</a>
                </li>
                <li class="nav-item" (click)="liveCasino()">
                  <a class="nav-link" [ngClass]="{'active': casinoId == 'Live'}" id="btn-4" tab-content="#item-4">Live</a>
                </li>
                <li class="nav-item" (click)="virtual()">
                  <a class="nav-link" [ngClass]="{'active': casinoId == 'Virtual'}" id="btn-5" tab-content="#item-5">Virtual</a>
                </li>
              </ul>
            </div>
            <div class="game_provider">
            </div>
          </div>
          <div class="portfolio_row" id="item-1">
            <div class="port-inner" data-bs-toggle="modal" data-bs-target="#exampleModal"
              *ngFor="let list of casinoList;let i = index;" (click)="openModalLoginModal(loginModal)">
              <div class="thumb">
                <img src="assets/Casino/{{list?.match_id}}.jpg" alt="">
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<app-open-footer></app-open-footer>

<ng-template #loginModal>
  <div class="cross_btn">
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modalRef.hide();this.createFrom();submitted= false;"></button>
  </div>
  <div class="modal-header">
    <img src="{{logo}}" class="modal_image" alt="">
  </div>
  <div class="modal-body">
    <div class="mobile_bg">
      <img src="assets/images/mobile_bg.png" alt="">
      <div class="widraw">
        100%
        <strong>FAST</strong>
        withdraw
        <div class="group">
          <img src="assets/images/group.png" alt="">
        </div>
      </div>
    </div>

    <div class="form" [formGroup]='Form'>
      <div class="input_field">
        <input type="text" class="form_control" formControlName="user_name" required>
        <label for="" class="input_label">Enter your Username</label>
        <i class="fa fa-user"></i>
      </div>
      <div class="alert_base" *ngIf="submitted && f.user_name.status == 'INVALID'">
        <span>* Please enter username</span>
      </div>
      <div class="input_field pass_field">
        <input formControlName="password" required="" [type]="showPassword ? 'text' : 'password'" class="form_control">
        <label for="" class="input_label">Enter your Password</label>
        <i alt="show" class="fa fa-eye eye-show" (click)="showPassword = !showPassword" [class.hide]="showPassword"></i>
        <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showPassword = !showPassword"
          [class.hide]="!showPassword"></i>

      </div>
      <div class="alert_base" *ngIf="submitted && f.password.status == 'INVALID'">
        <span>* Please enter password</span>
      </div>
      <div class="input_btn mt-10" (click)="onSubmitLogin('login')">
        <button class="sinup_btn">Sign In</button>
      </div>
      <div class="member">
        Don't have an account?
        <a class="ragister_link" (click)="modalRef.hide();openModalSignupModal(signupModal)">Register</a>
      </div>
      <div class="social_media">
        <ul>
          <li>
            <a target="_blank" href="{{insta}}"><img src="assets/images/instagram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{wa}}"><img src="assets/images/whatsapp1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{telegram}}"><img src="assets/images/telegram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{twitter}}"><img src="assets/images/twitter1.png" alt=""></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #signupModal>
  <div class="cross_btn">
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modalRef.hide()"></button>
  </div>
  <div class="modal-header">
    <img src="{{logo}}" class="modal_image" alt="">
  </div>
  <div class="modal-body">
    <div class="mobile_bg">
      <img src="assets/images/mobile_bg.png" alt="">
      <div class="widraw">
        100%
        <strong>FAST</strong>
        widraw
        <div class="group">
          <img src="assets/images/group.png" alt="">
        </div>
      </div>
    </div>

    <div class="form" [formGroup]='addUserFrom'>
      <div class="input_field">
        <input type="text" class="form_control" formControlName="user_name" required>
        <label for="" class="input_label">Enter your Username</label>
      </div>
      <div class="alert_base" *ngIf="isControlIsValid('user_name')">
        <span *ngIf="isControlHasErros('user_name')"> {{ isControlHasErros('user_name')}}</span>

      </div>
      <div class="input_field">
        <input type="text" formControlName="mobile" class="form_control" maxlength="10" required>
        <label for="" class="input_label">Enter your Whatsapp Number</label>
      </div>
      <div class="alert_base" *ngIf="isControlIsValid('mobile')">
        <span *ngIf="isControlHasErros('mobile')"> {{ isControlHasErros('mobile')}}</span>
      </div>
      <div class="input_field">
        <input [type]="showPassword ? 'text' : 'password'" class="form_control" formControlName="password" required>
        <label for="" class="input_label">Enter your Password</label>
        <i alt="show" class="fa fa-eye eye-show" (click)="showPassword = !showPassword" [class.hide]="showPassword"></i>
        <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showPassword = !showPassword"
          [class.hide]="!showPassword"></i>
      </div>
      <div class="alert_base" *ngIf="isControlIsValid('password')">
        <span *ngIf="isControlHasErros('password')"> {{ isControlHasErros('password')}}</span>
      </div>
      <div class="input_field" *ngIf="referCode != undefined">
        <input type="text" class="form_control" formControlName="refer_code" readonly>
        <label for="" class="input_label">Enter your Refer Code</label>
      </div>
      <div class="input_field" *ngIf="referCode == undefined">
        <input type="text" class="form_control" formControlName="refer_code">
        <label for="" class="input_label">Enter your Refer Code</label>
      </div>
      <div class="input_btn" (click)="addUserData()">
        <button class="sinup_btn">Register</button>
      </div>

      <div class="input_btn" (click)="goToRoute()">
        <button class="sinup_btn">Get Your ID</button>
      </div>
      <div class="member">
        Already have an account?
        <a class="ragister_link" (click)="modalRef.hide();openModalLoginModal(loginModal)">SignIn</a>
      </div>
      <div class="social_media">
        <ul>
          <li>
            <a target="_blank" href="{{insta}}"><img src="assets/images/instagram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{wa}}"><img src="assets/images/whatsapp1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{telegram}}"><img src="assets/images/telegram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{twitter}}"><img src="assets/images/twitter1.png" alt=""></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>