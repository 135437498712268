import { Component, OnInit, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { OpenHeaderComponent } from '../open-header/open-header.component';
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { OpenApiService } from '../services/open-api.service';
import { LoginService } from '../services/login.service'
import { environment } from '../../environments/environment';
import { AppValidationService } from '../app-validation/app-validation.service';
import * as exchangeList from '../../data.json';
@Component({
  selector: 'app-open-dashboard',
  templateUrl: './open-dashboard.component.html',
  styleUrls: ['./open-dashboard.component.scss'],
  providers: [OpenHeaderComponent],
})
export class OpenDashboardComponent implements OnInit {
  public Base_Url = environment['adminServerUrl'];
  allSportsList: any;
  userData: {};
  parameter: string;
  sportName: string;
  banner: any;
  firstData: any;
  firstDataLength: any;
  dataLength: any;
  homeData: any;
  totalMatches: any;
  totalInplay: any;
  casinoList: any;
  logo: string;
  sportView: boolean;
  Form: FormGroup;
  loginButtonDisable = false
  submitted = false;
  transactionPassword: string;
  showResponseMessage;
  public showPassword: boolean;
  private clickTimeout = null;
  a: any;
  modalRef: BsModalRef;
  tPassword: string;
  isUserNameExist: boolean;
  addUserFrom: FormGroup;
  isAddUserFromSubmitted: boolean;
  exchangeGameList: any;
  totalMatchLength: any;
  referCode: any;
  facebook: any;
  insta: any;
  telegram: any;
  wa: any;
  mail: any;
  twitter: any;
  casinoId:any = 'All';
  constructor(private open: OpenApiService, private appValidationService: AppValidationService, private cdRef: ChangeDetectorRef, private route: ActivatedRoute, private fb: FormBuilder,
    private router: Router, private toastr: ToastrService, private loginService: LoginService, private modalService: BsModalService) {
    this.getBanner();
    this.route.params.subscribe(params => {
      let a = params.sportName != undefined ? (params.sportName.split('series')) : '';
      if (a[1] != '' || params.sportName == undefined) {
        if (params.sportName == undefined) {
          this.sportView = true;
          this.userData = {};
          this.parameter = 'All';
          this.sportName = 'All';
          this.homematches();
        } else {
          if (params.sportName == 'Casino') {
            this.sportView = false;
            this.homematches();
          } else {
            this.sportView = true;
            this.parameter = params.sportName;
            this.sportName = params.sportName;
            this.homematches();
          }
        }
      } else {
        this.homematchesSeries(a[0])
      }
    })
  }

  ngOnInit(): void {
    this.exchangeGameList = exchangeList['default']?.data;
    let domain = window.location.hostname
    let domain1 = domain.split('.')
    if (domain1[0] == '3rdempire') {
      this.facebook = '';
      this.insta = 'https://www.instagram.com/3rdempiree/';
      this.telegram = 'https://telegram.me/bigdream07';
      this.wa = 'https://api.whatsapp.com/send/?phone=919024696202&text=Hi&type=phone_number&app_absent=0';
      this.mail = '';
      this.twitter = 'https://twitter.com/3rdempiree'
    } else if (domain1[0] == 'motabook365') {
      this.facebook = 'https://www.facebook.com/profile.php?id=100086163486306';
      this.insta = 'https://www.instagram.com/motabook247/';
      this.telegram = 'https://t.me/Motabook247';
      this.wa = 'https://api.whatsapp.com/send?phone=917439876249';
      this.mail = '';
      this.twitter = ''
    }
    this.casinoList = this.exchangeGameList;
    this.logo = localStorage.getItem('logo');
    this.createFrom();
    this.createSignFrom();
    this.referCode = localStorage.getItem('referCode') != undefined ? localStorage.getItem('referCode') : '';
  }

  liveCasino() {
    this.casinoId = 'Live'
    this.casinoList = (this.exchangeGameList).filter(a => a.is_live == 1)
  }
  virtual() {
    this.casinoId = 'Virtual'
    this.casinoList = (this.exchangeGameList).filter(a => a.is_live == 0)
  }
  all() {
    this.casinoId = 'All'
    this.casinoList = this.exchangeGameList;
  }
  getBanner() {
    this.open.getBannerImage().subscribe((res) => {
      if (res.status == true) {
        let a = res.data;
        if (a.length != 0) {
          for (let i = 0; i < a.length; i++) {
            if ((a[i]).hasOwnProperty('self_host')) {
              if ((a[i].self_host == true)) {
                a[i].bannerUrl = this.Base_Url + a[i].description;
              } else {
                a[i].bannerUrl = a[i].description;
              }
            } else {
              a[i].bannerUrl = this.Base_Url + a[i].description;
            }
          }
          this.banner = a;
          localStorage.setItem('Banner', JSON.stringify(this.banner))
        }
      }
    })
  }
  getAllSportsListData() {
    let userData = {};
    this.open.getAllSportsList(userData).subscribe(res => {
      this.allSportsList = res.data;
      for (let i = 0; i < (this.allSportsList).length; i++) {
        let matches = (this.firstData).filter((a) => a.sport_id == this.allSportsList[i].sport_id);
        let inplayData = (matches).filter(a => a.inplay == true)
        this.allSportsList[i].totalMatch = matches.length;
        this.allSportsList[i].totalInplayMatch = inplayData.length;
      }
      this.totalMatches = this.firstDataLength;
      let inplay = (this.firstData).filter(a => a.inplay == true)
      this.totalInplay = inplay.length
      this.allSportsList = (this.allSportsList).sort(function (a, b) { return b.sport_id - a.sport_id });
    })
  }
  goToDashboard(sportName, id, data) {
    if (id == -100) {
      this.router.navigate(['open-dashboard/' + sportName]);
    } else if (id == 'QT') {
      this.router.navigate(['openQtech']);
    } else if (id < -100) {
      this.router.navigate(['snova/' + id + '/' + data.name + '/' + data.providerCode])
    } else if (id > -100) {
      this.router.navigate(['open-dashboard/' + sportName]);
      this.homematches();
    }
  }
  inplay() {
    this.router.navigate(['open-dashboard/inplay']);
  }
  matchDetail(matchData) {
    localStorage.setItem('matchData', JSON.stringify(matchData));
    this.router.navigate(['openDetail']);
  }
  matchDetailDrop(matchData) {
    localStorage.setItem('matchData', JSON.stringify(matchData));
    this.router.navigate(['openDetail']);
  }

  homematches() {
    this.userData = {
    }
    this.open.getHomeMatchesList(this.userData).subscribe(res => {
      if (res.status) {
        this.firstData = res.data.result;
       
        this.firstDataLength = this.firstData.length;
        this.getAllSportsListData();
        for (let i = 0; i < this.firstDataLength; i++) {
          this.firstData[i].manualInplay = this.firstData[i]?.inplay
        }
        
        this.firstData.forEach(matDta => {
          matDta.manualInplay = matDta.inplay;
        });
        let matches;
        if (this.parameter == undefined || this.parameter == 'All') {
          matches = this.firstData;
          this.dataLength = matches.length;
        } else if (this.parameter == 'inplay') {
          matches = this.firstData.filter(t => t.inplay == true || t.manualInplay == true);
          this.dataLength = matches.length;
        } else {
          matches = this.firstData.filter(t => t.sport_name == this.parameter);
          this.dataLength = matches.length;
        }
        matches = matches.sort((a: any, b: any) =>
          new Date(a.match_date).getTime() - new Date(b.match_date).getTime()
        );
        this.totalMatchLength = matches;
        matches = matches.reduce((groupSport, sports) => {
          const { sport_name } = sports;
          groupSport[sport_name] = [...groupSport[sport_name] || [], sports];
          return groupSport;
        }, {});
        this.homeData = matches;
    
      } else {
        if (res.logout == true) {
        }
      }
    }, (err) => {
      console.log(err);
    })
  }
  homematchesSeries(param) {
    let data = {
      series_id: param
    }
    this.open.getHomeMatchesList(data).subscribe(res => {
      if (res.status) {
        this.firstData = res.data;
        this.firstDataLength = this.firstData.length;
        this.getAllSportsListData();
        for (let i = 0; i < this.firstDataLength; i++) {
          this.firstData[i].manualInplay = this.firstData[i].inplay
        }
        this.firstData.forEach(matDta => {
          matDta.manualInplay = matDta.inplay;
        });
        let matches = this.firstData;
        matches = matches.sort((a: any, b: any) =>
          new Date(a.match_date).getTime() - new Date(b.match_date).getTime()
        );
        this.totalMatchLength = matches;
        matches = matches.reduce((groupSport, sports) => {
          const { sport_name } = sports;
          groupSport[sport_name] = [...groupSport[sport_name] || [], sports];
          return groupSport;
        }, {});
        this.homeData = matches;
        this.sportView = true;
        this.cdRef.detectChanges();
      } else {
        if (res.logout == true) {
        }
      }
    }, (err) => {
      console.log(err);
    })
  }
  openModalLoginDesktop(loginDesktop: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginDesktop,
      Object.assign({}, { class: 'loginModalDesktop loginModal modal-dialog-centered modal-fullscreen-lg-down', ignoreBackdropClick: false })
    );
  }

  async onSubmitLogin(from?: string) {
    if (this.Form.valid) {
      this.loginButtonDisable = true
      if (this.clickTimeout) {
        this.setClickTimeout(() => { });
      } else {
        this.setClickTimeout(() => {
          if (from == 'login') {
            this.headerSingleLoginClick()
          }
        })
      }
    } else {
      this.submitted = true;
    }
  }
  public handleSingleLoginClick() {

    this.submitted = true;
    this.a = (this.Form.value.user_name);
    let b = (this.a).toLowerCase()
    this.Form.value.user_name = b;
    if (this.Form.invalid) {
      this.loginButtonDisable = false
      return;
    }
    if (this.tPassword == this.transactionPassword) {
      this.loginService.submitlogin(this.Form.value).subscribe((result) => {
        if (result.status == true) {
          this.modalRef.hide();

          this.toastr.success(result.msg, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          })
          this.setCokkies(result);
          localStorage.setItem('userDetails', JSON.stringify(result.data));
          localStorage.setItem('operator', JSON.stringify(result.operatorId));
          this.loginButtonDisable = false
          this.router.navigate(['dashboard'])
        } else {

          this.showResponseMessage = result.msg;
          this.toastr.error(this.showResponseMessage)
          this.loginButtonDisable = false;
          this.createRandomTransactionPassword()
        }
      }, (err) => {
        console.log(err);

        this.loginButtonDisable = false;
        this.createRandomTransactionPassword()
      });
    } else {
      this.loginButtonDisable = false;
      this.showResponseMessage = 'Invalid Captcha';
      this.createRandomTransactionPassword()
    }
  }
  public headerSingleLoginClick() {
    this.submitted = true;
    this.a = (this.Form.value.user_name);
    let b = (this.a).toLowerCase()
    this.Form.value.user_name = b;
    if (this.Form.invalid) {
      this.loginButtonDisable = false
      return;
    }
    if (this.tPassword == this.transactionPassword) {
      this.loginService.submitlogin(this.Form.value).subscribe((result) => {
        if (result.status == true) {
          this.toastr.success(result.msg, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          })
          this.setCokkies(result);
          localStorage.setItem('userDetails', JSON.stringify(result.data));
          localStorage.setItem('operator', JSON.stringify(result.operatorId));
          this.loginButtonDisable = false
          this.router.navigate(['dashboard'])
        } else {
          this.showResponseMessage = result.msg;
          this.toastr.error(this.showResponseMessage)
          this.loginButtonDisable = false;
          this.createRandomTransactionPassword()
        }
      }, (err) => {
        console.log(err);
        this.loginButtonDisable = false;
        this.createRandomTransactionPassword()
      });
    } else {
      this.loginButtonDisable = false;
      this.showResponseMessage = 'Invalid Captcha';
      this.createRandomTransactionPassword()
    }
  }
  public setClickTimeout(callback) {
    clearTimeout(this.clickTimeout);
    this.clickTimeout = setTimeout(() => {
      this.clickTimeout = null;
      callback();
    }, 400);
  }
  async setCokkies(result) {
    debugger
    localStorage.setItem('userAccessToken', result.token.accessToken);
    localStorage.setItem('userId', result.data._id);
    localStorage.setItem('userRefreshToken', result.token.refreshToken);
    var acctoken = localStorage.getItem('userAccessToken');
    this.router.navigate(['dashboard'])
  }

  createRandomTransactionPassword() {
    //initialize a variable having alpha-numeric characters
    var chars = "0123456789";
    //specify the length for the new string to be generated
    var string_length = 4;
    var randomstring = '';
    //put a loop to select a character randomly in each iteration
    for (var i = 0; i < string_length; i++) {
      var rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    this.transactionPassword = randomstring;
  }

  openModalSignupModal(signupModal: TemplateRef<any>) {
    if (this.referCode != undefined || this.referCode != '') {
      this.addUserFrom.controls['refer_code'].setValue(this.referCode);
    }
    this.modalRef = this.modalService.show(
      signupModal,
      Object.assign({}, { class: 'login_page', ignoreBackdropClick: false }),
    );
  }

  openModalLoginModal(loginModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginModal,
      Object.assign({}, { class: 'login_page', ignoreBackdropClick: false }),
    );
  }
  createFrom() {
    this.Form = this.fb.group({
      user_name: ['', Validators.required],
      password: ['', [Validators.required]],
      grant_type: ['password'],
      isRememberMe: [false]
    })
    if (JSON.parse(localStorage.getItem('RememberMe'))) {
      this.Form.patchValue({
        user_name: localStorage.getItem('user_name'),
        password: localStorage.getItem('password'),
        isRememberMe: localStorage.getItem('RememberMe')
      })
    }
  }
  createSignFrom() {
    this.addUserFrom = this.fb.group({
      name: ['', Validators.required],
      user_name: ['', Validators.required],
      password: ['', [Validators.required]],
      mobile: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]],
      email: [''],
      refer_code: ['']
    });
    this.applyValidationToFormGroup(this.addUserFrom, "AddUser")
  }
  get f() { return this.Form.controls; }
  applyValidationToFormGroup(formGroupName, jsonArrayName) {
    this.appValidationService.applyValidationRulesToFromGroup(formGroupName, jsonArrayName).then((validators) => {
    }).catch(() => { })
  }
  addUserData() {
    this.isAddUserFromSubmitted = true;
    this.addUserFrom.controls['name'].setValue(this.addUserFrom.controls['user_name'].value)
    this.appValidationService.markFormGroupTouched(this.addUserFrom)
    if (this.addUserFrom.controls.email.value == '') {
      delete this.addUserFrom.value['email'];
    }
    if (this.addUserFrom.controls.refer_code.value == '') {
      delete this.addUserFrom.value['refer_code'];
    }
    if (this.addUserFrom.invalid) {
      return;
    }
    this.loginService.register(this.addUserFrom.value).subscribe((result) => {
      this.isAddUserFromSubmitted = false;
      if (result.status == true) {
        this.toastr.success(result.msg, '', {
          timeOut: 1000
        });
        this.Form.controls['user_name'].setValue(this.addUserFrom.controls['user_name'].value)
        this.Form.controls['password'].setValue(this.addUserFrom.controls['password'].value)
        this.headerSingleLoginClick();
        this.modalRef.hide();
      } else {
        this.toastr.error(result.msg, '', {
          timeOut: 10000,
        });
      }
    }, (err) => {
      console.log(err);
    });
  }
  async redirectToDashboard() {
    var acctoken = localStorage.getItem('userAccessToken');
    if (acctoken != null && acctoken != undefined && acctoken != '') {
      this.router.navigate(['dashboard'])
    }
  }
  goToRoute() {
    debugger
    let domain = window.location.hostname
    let domain1 = domain.split('.')
    if (domain1[0] == 'billionexchwallet') {
      this.wa = 'https://api.whatsapp.com/send?phone=13124935676&text=Hi&type=phone_number&app_absent=0';
      window.open(this.wa, "_blank")
    } else if (domain1[0] == 'motabook365') {
      this.wa = 'https://api.whatsapp.com/send?phone=13124935676';
      window.open(this.wa, "_blank")
    } else if (domain1[0] == '3rdempire') {
      this.wa = 'https://api.whatsapp.com/send/?phone=919024696202&text=Hi&type=phone_number&app_absent=0';
      window.open(this.wa, "_blank")
    }
  }
}
