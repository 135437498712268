import { Component, OnInit, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../services/login.service'
import { OpenApiService } from '../services/open-api.service';
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import * as exchangeList from '../../data.json';
import { AppValidationService } from '../app-validation/app-validation.service';
@Component({
  selector: 'app-open-left-sidebar',
  templateUrl: './open-left-sidebar.component.html',
  styleUrls: ['./open-left-sidebar.component.scss']
})
export class OpenLeftSidebarComponent implements OnInit {
  allSportsList: any;
  allSeriesList: any;
  sportList: boolean = true;
  matchList: any[] = [];
  seriesId: any;
  isShowMenu: boolean[] = [];
  sportData: any;
  showProviders: boolean = false;
  casinoList: any = [];
  casino: any = [];
  Form: FormGroup;
  loginButtonDisable = false
  submitted = false;
  transactionPassword: string;
  showResponseMessage;
  public showPassword: boolean;
  private clickTimeout = null;
  a: any;
  modalRef: BsModalRef;
  tPassword: string;
  referCode: any;
  facebook: any;
  insta: any;
  telegram: any;
  wa: any;
  mail: any;
  twitter: any;
  addUserFrom: FormGroup;
  isAddUserFromSubmitted: boolean;
  seriesData: any;
  constructor(private appValidationService: AppValidationService, public toastr: ToastrService, private router: Router, private open: OpenApiService, private fb: FormBuilder,
    private modalService: BsModalService, private loginService: LoginService, private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.casinoList = exchangeList['default']?.data;
    let a = window.screen.width;
    this.getAllSportsListData()
    this.createFrom();
    this.createSignFrom();
  }

  getAllSportsListData() {
    let userData = {};
    this.open.getAllSportsList(userData).subscribe(res => {
      if (res.status == true) {
        this.sportList = true;
        this.allSportsList = res.data
        this.allSportsList = (this.allSportsList).sort(function (a, b) { return b.sport_id - a.sport_id });
        this.cdRef.detectChanges()
      } else {
        this.toastr.error(res.msg);
      }
    })
  }
  getSeriesList(id) {
    this.sportData = id;
    let data = {
      sport_id: id
    };
    this.open.getSeriesList(data).subscribe(res => {
      this.sportList = false;
      this.allSeriesList = res.data
      this.isShowMenu = Array(this.allSeriesList.length).fill(false);
      this.matchList = Array(this.allSeriesList.length).fill([]);
      this.cdRef.detectChanges();
    })
  }
  getMatchList(param, index) {
    this.seriesData = param.series_id;
    let data = {
      series_id: param.series_id
    };
    this.isShowMenu[index] = !this.isShowMenu[index]
    this.open.getHomeMatchesList(data).subscribe(res => {
      this.seriesId = param.series_id
      this.matchList[index] = res.data;
      this.cdRef.detectChanges;
    })
  }

  goToDashboard(series) {
    if (series.sport_id == -100) {
      localStorage.setItem('selectedMatch', JSON.stringify(series));
      this.router.navigate(['exchDetail']);
    } else {
      this.router.navigate(['home/' + series.sport_id + '/' + series.series_id])
    }
  }
  matchDetail(matchData) {
    localStorage.setItem('matchData', JSON.stringify(matchData));
    this.router.navigate(['openDetail/' + matchData.match_id]);
  }
  liveCasino() {
    this.casino = (this.casinoList).filter(a => a.is_live == 1)
    this.showProviders = false;
    this.cdRef.detectChanges();
  }
  virtual() {
    this.casino = (this.casinoList).filter(a => a.is_live == 0)
    this.showProviders = false;
    this.cdRef.detectChanges();
  }
  providers() {
    this.showProviders = true;
    this.cdRef.detectChanges();
  }
  openModalLoginDesktop(loginDesktop: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginDesktop,
      Object.assign({}, { class: 'loginModalDesktop loginModal modal-dialog-centered modal-fullscreen-lg-down', ignoreBackdropClick: false })
    );
  }

  async onSubmitLogin(from?: string) {
    if (this.Form.valid) {
      this.loginButtonDisable = true
      if (this.clickTimeout) {
        this.setClickTimeout(() => { });
      } else {
        this.setClickTimeout(() => {
          if (from == 'login') {
            this.headerSingleLoginClick()
          }
        })
      }
    } else {
      this.submitted = true;
    }
  }
  public handleSingleLoginClick() {
    this.submitted = true;
    this.a = (this.Form.value.user_name);
    let b = (this.a).toLowerCase()
    this.Form.value.user_name = b;
    if (this.Form.invalid) {
      this.loginButtonDisable = false
      return;
    }
    if (this.tPassword == this.transactionPassword) {
      this.loginService.submitlogin(this.Form.value).subscribe((result) => {
        if (result.status == true) {
          this.modalRef.hide();
          this.toastr.success(result.msg, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          })
          this.setCokkies(result);
          localStorage.setItem('userDetails', JSON.stringify(result.data));
          localStorage.setItem('operator', JSON.stringify(result.operatorId));
          this.loginButtonDisable = false
          this.router.navigate(['dashboard'])
        } else {
          this.showResponseMessage = result.msg;
          this.toastr.error(this.showResponseMessage)
          this.loginButtonDisable = false;
          this.createRandomTransactionPassword()
        }
      }, (err) => {
        console.log(err);
        this.loginButtonDisable = false;
        this.createRandomTransactionPassword()
      });
    } else {
      this.loginButtonDisable = false;
      this.showResponseMessage = 'Invalid Captcha';
      this.createRandomTransactionPassword()
    }
  }
  public headerSingleLoginClick() {
    this.submitted = true;
    this.a = (this.Form.value.user_name);
    let b = (this.a).toLowerCase()
    this.Form.value.user_name = b;
    if (this.Form.invalid) {
      this.loginButtonDisable = false
      return;
    }
    if (this.tPassword == this.transactionPassword) {
      this.loginService.submitlogin(this.Form.value).subscribe((result) => {
        if (result.status == true) {
          this.toastr.success(result.msg, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          })
          this.setCokkies(result);
          localStorage.setItem('userDetails', JSON.stringify(result.data));
          localStorage.setItem('operator', JSON.stringify(result.operatorId));
          this.loginButtonDisable = false
          this.router.navigate(['dashboard'])
        } else {
          this.showResponseMessage = result.msg;
          this.toastr.error(this.showResponseMessage)
          this.loginButtonDisable = false;
          this.createRandomTransactionPassword()
        }
      }, (err) => {
        console.log(err);
        this.loginButtonDisable = false;
        this.createRandomTransactionPassword()
      });
    } else {
      this.loginButtonDisable = false;
      this.showResponseMessage = 'Invalid Captcha';
      this.createRandomTransactionPassword()
    }
  }
  public setClickTimeout(callback) {
    clearTimeout(this.clickTimeout);
    this.clickTimeout = setTimeout(() => {
      this.clickTimeout = null;
      callback();
    }, 400);
  }
  async setCokkies(result) {
    localStorage.setItem('userAccessToken', result.token.accessToken);
    localStorage.setItem('userId', result.data._id);
    localStorage.setItem('userRefreshToken', result.token.refreshToken);
    var acctoken = localStorage.getItem('userAccessToken');
    this.router.navigate(['dashboard'])
  }

  createRandomTransactionPassword() {
    //initialize a variable having alpha-numeric characters
    var chars = "0123456789";
    //specify the length for the new string to be generated
    var string_length = 4;
    var randomstring = '';
    //put a loop to select a character randomly in each iteration
    for (var i = 0; i < string_length; i++) {
      var rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    this.transactionPassword = randomstring;
  }

  openModalSignupModal(signupModal: TemplateRef<any>) {
    if (this.referCode != undefined || this.referCode != '') {
      this.addUserFrom.controls['refer_code'].setValue(this.referCode);
    }
    this.modalRef = this.modalService.show(
      signupModal,
      Object.assign({}, { class: 'login_page', ignoreBackdropClick: false }),
    );
  }

  openModalLoginModal(loginModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginModal,
      Object.assign({}, { class: 'login_page', ignoreBackdropClick: false }),
    );
  }
  createFrom() {
    this.Form = this.fb.group({
      user_name: ['', Validators.required],
      password: ['', [Validators.required]],
      grant_type: ['password'],
      isRememberMe: [false]
    })
    if (JSON.parse(localStorage.getItem('RememberMe'))) {
      this.Form.patchValue({
        user_name: localStorage.getItem('user_name'),
        password: localStorage.getItem('password'),
        isRememberMe: localStorage.getItem('RememberMe')
      })
    }
  }
  createSignFrom() {
    this.addUserFrom = this.fb.group({
      name: ['', Validators.required],
      user_name: ['', Validators.required],
      password: ['', [Validators.required]],
      mobile: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]],
      email: [''],
      refer_code: ['']
    });
    this.applyValidationToFormGroup(this.addUserFrom, "AddUser")
  }
  get f() { return this.Form.controls; }
  applyValidationToFormGroup(formGroupName, jsonArrayName) {
    this.appValidationService.applyValidationRulesToFromGroup(formGroupName, jsonArrayName).then((validators) => {
    }).catch(() => { })
  }
  addUserData() {
    this.isAddUserFromSubmitted = true;
    this.addUserFrom.controls['name'].setValue(this.addUserFrom.controls['user_name'].value)
    this.appValidationService.markFormGroupTouched(this.addUserFrom)
    if (this.addUserFrom.controls.email.value == '') {
      delete this.addUserFrom.value['email'];
    }
    if (this.addUserFrom.controls.refer_code.value == '') {
      delete this.addUserFrom.value['refer_code'];
    }
    if (this.addUserFrom.invalid) {
      return;
    }
    this.loginService.register(this.addUserFrom.value).subscribe((result) => {
      this.isAddUserFromSubmitted = false;
      if (result.status == true) {
        this.toastr.success(result.msg, '', {
          timeOut: 1000
        });
        this.Form.controls['user_name'].setValue(this.addUserFrom.controls['user_name'].value)
        this.Form.controls['password'].setValue(this.addUserFrom.controls['password'].value)
        this.headerSingleLoginClick();
        this.modalRef.hide();
      } else {
        this.toastr.error(result.msg, '', {
          timeOut: 10000,
        });
      }
    }, (err) => {
      console.log(err);
    });
  }
  async redirectToDashboard() {
    var acctoken = localStorage.getItem('userAccessToken');
    if (acctoken != null && acctoken != undefined && acctoken != '') {
      this.router.navigate(['dashboard'])
    }
  }
  close() {
    this.seriesData = undefined;
    this.cdRef.detectChanges();
  }
  closeSportList() {
    this.seriesData = undefined;
    this.sportData = undefined;
    this.cdRef.detectChanges();
  }
  openSeries(data) {
    this.router.navigate(['open-dashboard/' + data.series_id + 'series'])
  }
  goToRoute() {
    debugger
    let domain = window.location.hostname
    let domain1 = domain.split('.')
    if (domain1[0] == 'billionexchwallet') {
      this.wa = 'https://api.whatsapp.com/send?phone=13124935676&text=Hi&type=phone_number&app_absent=0';
      window.open(this.wa, "_blank")
    } else if (domain1[0] == 'motabook365') {
      this.wa = 'https://api.whatsapp.com/send?phone=13124935676';
      window.open(this.wa, "_blank")
    } else if (domain1[0] == '3rdempire') {
      this.wa = 'https://api.whatsapp.com/send/?phone=919024696202&text=Hi&type=phone_number&app_absent=0';
      window.open(this.wa, "_blank")
    }
  }
}
