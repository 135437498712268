import { Component, OnInit, ViewChild,TemplateRef, AfterViewInit, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import { DataTableDirective } from 'angular-datatables';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal'
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DateTime } from '../../dateTime';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ToastrService } from 'ngx-toastr';

import * as moment from 'moment';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss']
})
export class TransactionHistoryComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('startpopoverRef') private _startpopoverRef: PopoverDirective;
  @ViewChild('endpopoverRef') private _endpopoverRef: PopoverDirective;
  @ViewChild('detailModal') templateRef: TemplateRef<any>;
  time: Date;
  date: Date;
  endDate: Date;
  endTime: Date;
  dataAll:any;
  isDateVisible: boolean = true;
  isMeridian: boolean = false;
  startdateTime = new Date();
  enddateTime = new Date();
  startAt: Date = new Date()
  openStartDate: Date = new Date()
  openEndDate: Date = new Date()
  todayDate: Date = new Date()
  todayDateTime: Date = new Date()
  searchType: any = 'all';
  modalRef: BsModalRef;
  public Base_Url = environment['adminServerUrl'];
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  public token = localStorage.getItem('userAccessToken');
  reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ` + this.token
  });
  userDetails: any;
  previewData:any;
  moment: any = moment;
  dataSourceLength: any;
  dataSource: any;
  constructor(private modalService: BsModalService,private http: HttpClient, private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.todayDateTime.setHours(23, 59, 59);
    this.startdateTime.setHours(0, 0, 0, 0);
    this.enddateTime.setHours(23, 59, 59);
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      serverSide: true,
      searching: true,
      processing: true,
      ordering: false,
      destroy: true,
      "drawCallback": ()=>{
        //define your listeners here
       
    },
      ajax: (dataTablesParameters1: any, callback) => {
        let dataTablesParameters = {
          //limit: dataTablesParameters1.length,
         // page: (dataTablesParameters1.start / dataTablesParameters1.length) + 1,
          "user_id": this.userDetails._id,
          //"type": this.searchType == 0 ? '' : this.searchType,
          "from_date": this.startdateTime.toISOString(),
          "to_date": this.enddateTime.toISOString()
        }

        //if (dataTablesParameters.type == 0) {
          //delete dataTablesParameters.type
        //}
        let string;
        if(this.searchType=='0'){
          string="wallet/get-all-walletw-statement";
        }
        else if(this.searchType=='1')
        {
          string="wallet/get-all-walletd-statement";
        }
        else{
         string="wallet/get-all-statement";
        }
        that.http.post<DataTablesResponse>(this.Base_Url + string, dataTablesParameters, { headers: this.reqHeader })
          .subscribe(resp => {
            if (resp["status"]) {
              this.dataSourceLength = resp.data.length
              this.dataSource = resp.data.sort((a,b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
              this.dataAll=resp.data;
             for (let i = 0; i < this.dataSourceLength; i++) {
                this.dataSource[i].index = (dataTablesParameters1.start) + 1 + i;
                if(!this.dataSource[i].remark){
                  this.dataSource[i].remark = "-";
                }
                
                this.dataSource[i].createdAt = moment(this.dataSource[i].createdAt).utcOffset("+5:30").format('DD-MM-YYYY HH:mm:ss');
              }
              if (resp.data['total'] != 0) {
                callback({
                  recordsTotal: this.dataSourceLength,
                  recordsFiltered: this.dataSourceLength,
                  data: this.dataSource
                });
              } else {
                this.dataSource = []
                callback({
                  recordsTotal: 0,
                  recordsFiltered: 0,
                  data: this.dataSource
                });
              }
            } else {
              this.toastr.error(resp["msg"]);
              this.dataSource = []
              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: this.dataSource
              });
            }
            
          
          });
      },
      columns: [
        {
          title: 'S. No.',
          data: 'index'
        },
        {
          title: 'Date ',
          data: 'createdAt'
        },
        {
          title: 'Transaction Id',
          data: '_id',
        },
        {
          title: 'Status',
          data: 'approval_status',
          
        },
        {
          title: 'Deposit/Withdraw',
          data: 'type',
          "render": function (data, type, row) {
            var content = ''
            content = content + '<span>' + (row.type == '0' ? 'Withdraw' : 'Deposit') + '</span>';
            return content;
          }
        },
        {
          title: 'Amount',
          data: 'amount',
          "render": function (data, type, row) {
            var content = ''
            content = content + '<span>' + ((row.amount)) + '</span>';
            return content;
          }
        },
        {
          title: 'Remark',
          data: 'remark',
          "render": function (data, type, row) {
            var content = ''
            content = content + '<span class="word-wrap">' + (!row.remark || row.remark != '' || row.remark != 'undefined'  || row.remark != undefined  ? row.remark : '-') + '</span>';
            return content;
          }
        },
        {
          title: 'Action',
          data: 'remark',
         
          "render": function (data, type, row) {
            
            return `View`;
            
          }
        }
      
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        // (see https://github.com/l-lin/angular-datatables/issues/87)
        $('td', row).unbind('click');
        $('td', row).bind('click', () => {
          self.getpaymentDetails(data);
         
        });
        return row;
      }
    };
    if (this.startdateTime) {
      this.time = this.date = this.startdateTime;
      return;
    }
    if (this.enddateTime) {
      this.time = this.date = this.enddateTime;
      return;
    }
    this.date = this.time = new Date();
  }
  getpaymentDetails(row)
  {
    console.log(row)
    this.previewData=row;
    this.openModalStackModal(this.templateRef)
  }

  openModalStackModal(templateRef: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      templateRef,
      Object.assign({}, { class: ' stack-edit', ignoreBackdropClick: false }),
    );
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next("");
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next("");
    });
  }

  dateSelectionDone() {
    this.isDateVisible = true;
  }

  updateDate() {

    if (this.date) {
      this.startdateTime = DateTime.getDateTime(this.date, this.time);
    }
    if (!this.time) {
      this.time = this.date;
    }
  }

  updateEndDate() {
    if (this.endDate) {
      this.enddateTime = DateTime.getDateTime(this.endDate, this.endTime);
      this.enddateTime.setHours(23, 59, 59);
    }
  }
  updateTime() {
    if (this.time) {
      this.startdateTime = DateTime.getDateTime(this.date, this.time);
    }
  }
  updateEndTime() {
    if (this.endTime) {
      this.enddateTime = DateTime.getDateTime(this.endDate, this.endTime);
    }
  }


  showDate() {
    this.isDateVisible = true;
  }

  showTime() {
    this.isDateVisible = false;
  }

  close() {
    this._startpopoverRef.hide();

  }
  closeEndDatepicker() {
    this._endpopoverRef.hide()
  }
  now() {
    this.startdateTime = DateTime.now(this.date);
    this.time = this.startdateTime;
    this.enddateTime = DateTime.now(this.date);
    this.time = this.enddateTime;
  }

  today() {
    this.date = this.time = new Date();
    this.startdateTime = DateTime.now(this.date);
    this.enddateTime = DateTime.now(this.date);
  }
  clear() {
    this.time = void 0;
    this.date = void 0;
    this.startdateTime = void 0;
    this.enddateTime = void 0;
  }
}
