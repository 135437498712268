<app-open-header></app-open-header>
<div class="content_area">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 left_bar">
        <app-open-left-sidebar></app-open-left-sidebar>
      </div>
      <div class="col-md-9 conetnt_col">
        <div class="slider">
          <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item item " *ngFor="let image of banner;let i = index;"
                [ngClass]="(i === 0) ? 'active' : ''">
                <img src="{{image.bannerUrl}}" alt="">
              </div>
            </div>
          </div>
        </div>
        <!-- slider -->

        <div class="portfolio_row" id="item-1">
          <div class="port-inner" *ngFor="let list of casinoList;let i = index;"
            (click)="openModalLoginModal(loginModal)">
            <!-- <div class="port-inner"> -->
            <div class="thumb">
              <img src="{{domain_name}}/api/v1/qtech/{{list?.providerCode}}-qt.png" alt="">
            </div>
            <div class="port_content">
              <h4>
                <a>{{list?.name}}</a>
                <div class="sub-title">
                  <!-- {{list?.name}} -->
                </div>
              </h4>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<app-open-footer></app-open-footer>

<ng-template #loginModal>
  <div class="cross_btn">
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modalRef.hide();this.createFrom();submitted= false;"></button>
  </div>
  <div class="modal-header">
    <img src="{{logo}}" class="modal_image" alt="">
  </div>
  <div class="modal-body">
    <div class="mobile_bg">
      <img src="assets/images/mobile_bg.png" alt="">
      <div class="widraw">
        100%
        <strong>FAST</strong>
        withdraw
        <div class="group">
          <img src="assets/images/group.png" alt="">
        </div>
      </div>
    </div>

    <div class="form" [formGroup]='Form'>
      <div class="input_field">
        <input type="text" class="form_control" formControlName="user_name" required>
        <label for="" class="input_label">Enter your Username</label>
        <i class="fa fa-user"></i>
      </div>
      <div class="alert_base" *ngIf="submitted && f.user_name.status == 'INVALID'">
        <span>* Please enter username</span>
      </div>
      <div class="input_field pass_field">
        <input formControlName="password" required="" [type]="showPassword ? 'text' : 'password'" class="form_control">
        <label for="" class="input_label">Enter your Password</label>
        <!-- <i class="fa-solid fa-eye"></i> -->
        <i alt="show" class="fa fa-eye eye-show" (click)="showPassword = !showPassword" [class.hide]="showPassword"></i>
        <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showPassword = !showPassword"
          [class.hide]="!showPassword"></i>

      </div>
      <div class="alert_base" *ngIf="submitted && f.password.status == 'INVALID'">
        <span>* Please enter password</span>
      </div>
      <!-- <div class=" form_check">
          <div class="check_base">
            <input type="checkbox" class="form-check-input" id="exampleCheck1" formControlName="isRememberMe">
            <label class="form-check-label" for="exampleCheck1">&nbsp;&nbsp; Keep me signed in</label>
          </div>
        </div> -->
      <div class="input_btn mt-10" (click)="onSubmitLogin('login')">
        <button class="sinup_btn">Sign In</button>
      </div>
      <div class="member">
        Don't have an account?
        <a class="ragister_link" (click)="modalRef.hide();openModalSignupModal(signupModal)">Register</a>
      </div>
      <div class="social_media">
        <ul>
          <li>
            <a target="_blank" href="{{insta}}"><img src="assets/images/instagram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{wa}}"><img src="assets/images/whatsapp1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{telegram}}"><img src="assets/images/telegram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{twitter}}"><img src="assets/images/twitter1.png" alt=""></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #signupModal>
  <div class="cross_btn">
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modalRef.hide()"></button>
  </div>
  <div class="modal-header">
    <img src="{{logo}}" class="modal_image" alt="">
  </div>
  <div class="modal-body">
    <div class="mobile_bg">
      <img src="assets/images/mobile_bg.png" alt="">
      <div class="widraw">
        100%
        <strong>FAST</strong>
        widraw
        <div class="group">
          <img src="assets/images/group.png" alt="">
        </div>
      </div>
    </div>

    <div class="form" [formGroup]='addUserFrom'>
      <div class="input_field">
        <input type="text" class="form_control" formControlName="user_name" required>
        <label for="" class="input_label">Enter your Username</label>
      </div>
      <div class="alert_base" *ngIf="isControlIsValid('user_name')">
        <span *ngIf="isControlHasErros('user_name')"> {{ isControlHasErros('user_name')}}</span>

      </div>
      <div class="input_field">
        <input type="text" formControlName="mobile" class="form_control" maxlength="10" required>
        <label for="" class="input_label">Enter your Whatsapp Number</label>
      </div>
      <div class="alert_base" *ngIf="isControlIsValid('mobile')">
        <span *ngIf="isControlHasErros('mobile')"> {{ isControlHasErros('mobile')}}</span>
      </div>
      <div class="input_field">
        <input [type]="showPassword ? 'text' : 'password'" class="form_control" formControlName="password" required>
        <label for="" class="input_label">Enter your Password</label>
        <!-- <i class="fa-solid fa-eye"></i> -->
        <i alt="show" class="fa fa-eye eye-show" (click)="showPassword = !showPassword" [class.hide]="showPassword"></i>
        <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showPassword = !showPassword"
          [class.hide]="!showPassword"></i>
      </div>
      <div class="alert_base" *ngIf="isControlIsValid('password')">
        <span *ngIf="isControlHasErros('password')"> {{ isControlHasErros('password')}}</span>
      </div>
      <div class="input_field" *ngIf="referCode != undefined">
        <input type="text" class="form_control" formControlName="refer_code" readonly>
        <label for="" class="input_label">Enter your Refer Code</label>
      </div>
      <div class="input_field" *ngIf="referCode == undefined">
        <input type="text" class="form_control" formControlName="refer_code">
        <label for="" class="input_label">Enter your Refer Code</label>
      </div>
      <div class="input_btn" (click)="addUserData()">
        <button class="sinup_btn">Register</button>
      </div>

      <div class="input_btn" (click)="goToRoute()">
        <button class="sinup_btn">Get Your ID</button>
      </div>
      <div class="member">
        Already have an account?
        <a class="ragister_link" (click)="modalRef.hide();openModalLoginModal(loginModal)">SignIn</a>
      </div>
      <div class="social_media">
        <ul>
          <li>
            <a target="_blank" href="{{insta}}"><img src="assets/images/instagram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{wa}}"><img src="assets/images/whatsapp1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{telegram}}"><img src="assets/images/telegram1.png" alt=""></a>
          </li>
          <li>
            <a target="_blank" href="{{twitter}}"><img src="assets/images/twitter1.png" alt=""></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>