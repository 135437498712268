import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import * as bank from '../../bank.json';
import { SportService } from '../services/sport.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrls: ['./withdraw.component.scss']
})
export class WithdrawComponent implements OnInit {
  bankList: any;

  searchBank: any;
  userDetails: any;
  payMethodList: any;
  walletBalance: any;
  updatedBalance: number;
  amount: any;
  loading: boolean = false;
  methodDetail: any;
  methodId: any;
  allowPaymentType:any;
  paymentType:any=null;
  method_id:any;
  dateTime = new Date();
  statementStartDate = new Date()
  statementEndDate = new Date();
  count: any = 0;
  Form: FormGroup;
  statement_data:any;
  min_MAXdATA:any;
  submitted: boolean = false;
  todayDate: Date = new Date()
  todayDateTime: Date = new Date()
  depositpayment_Method:any=[];
  startdateTime = new Date();
  enddateTime = new Date();
  constructor(private fb: FormBuilder, private toastr: ToastrService, private cdRef: ChangeDetectorRef, private sport: SportService) { }

  ngOnInit(): void {
    this.todayDateTime.setHours(23, 59, 59);
    this.startdateTime.setHours(0, 0, 0, 0);
    this.enddateTime.setHours(23, 59, 59);
    this.statementStartDate.setHours(0, 0, 0, 0);
    this.statementEndDate.setHours(23, 59, 59);
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.withdrawMethod();
    this.getUserBalance();
    this.getStatement();
    this.withdrawFrom();
    this.getTodayStatement();
    this.bankList = bank['default']?.message;
  }
  hideShowBankStatus: boolean = true;
  hideShowBankdetail() {
    this.showBankFormStatus = false;
    this.hideShowBankStatus = !this.hideShowBankStatus;
  }
  get f() { return this.Form.controls; }
  withdrawFrom() {
    this.Form = this.fb.group({
      user_id: ['', Validators.required],
      bank_holder_name: ['', Validators.required],
      bank_name: ['', Validators.required],
      mobile_no: ['-', Validators.required],
      ifsc_code: ['', Validators.required],
      account_no: ['', Validators.required]
    });
    this.Form.controls['user_id'].setValue(this.userDetails._id);
    this.withdrawAllMethod();
  }
  showBankFormStatus: boolean = false;
  showBankForm() {
    this.hideShowBankStatus = false;
    this.showBankFormStatus = !this.showBankFormStatus;
  }
  selectBank(bank) {
    
    let data_row=this.payMethodList.filter((val:any)=>{return val._id==bank});
   
    if(data_row){
      if(data_row.length>0){
        this.min_MAXdATA=this.allowPaymentType.filter((val:any)=>{return val.type==data_row[0]?.type});
        
      }
    }
    

    this.Form.controls['bank_name'].setValue(bank);
  }

  withdrawMethod() {
    let data = {
      user_id:this.userDetails?._id,
      parent_id:this.userDetails?.parent_id,
    };
    this.sport.paymentwithdrawMethodList(data).subscribe((res) => {
      if (res.status == true) {
        this.allowPaymentType=res.data;

        this.paymentType=res.data[0];
        this.selectMethodType(res.data[0])
        
      } else {
        this.toastr.error('Withdraw Method is coming Soon.')
      }
    })
  }
  withdrawAllMethod() {
    let data = {
      user_id:this.userDetails?._id,
      parent_id:this.userDetails?.parent_id,
    };
    this.sport.withdrawMethod(data).subscribe((res) => {
      if (res.status == true) {
        this.payMethodList = res.data;
        if(this.payMethodList){
         if(this.payMethodList[0]?._id){
         
          this.Form.controls['method_id'].setValue(this.payMethodList[0]?._id);
         }
        }
       
        //this.showDetail(this.payMethodList[0])
      } else {
        this.toastr.error('Withdraw Method is coming Soon.')
      }
    })
  }
  getStatement() {
    let data = {
      "statement_type": '1',
      "user_id": this.userDetails._id,
      "from_date": this.statementStartDate.toISOString(),
      "to_date": this.statementEndDate.toISOString()
    }
    this.sport.getStatement(data).subscribe((res) => {
      if (res.status == true) {
        let response = res.data[0].data;
        for (let i = 0; i < response.length; i++) {
          if (response[i].credit_debit < 0) {
            this.count = this.count + 1;
          }
        }
        this.cdRef.detectChanges();
      } else {
        this.count = 0;
      }
    })
  }

  getTodayStatement()
  {
    let params={
      // page: (dataTablesParameters1.start / dataTablesParameters1.length) + 1,
      "user_id": this.userDetails._id,
      //"type": this.searchType == 0 ? '' : this.searchType,
      "from_date": this.startdateTime.toISOString(),
      "to_date": this.enddateTime.toISOString()
    }
    this.sport.statementWDetail(params).subscribe((res) => {
      if (res.status) {
        this.statement_data=res.data;
        //this.toastr.success(res.msg);
        
      } else {
        //this.toastr.error(res.msg)
      }
    })
  }

  selectMethodType(event:any)
  {
    this.paymentType=event;
    if(event.type=="Bank")
    {
      this.Form.controls['mobile_no'].setValue("-");
    }
    else
    {
      this.Form.controls['ifsc_code'].setValue("-");
      this.Form.controls['bank_holder_name'].setValue("-");
      this.Form.controls['bank_name'].setValue("-");
      
    }
  }
  addPaymentMethod() {
    this.submitted = true;
    console.log(this.Form)
    if (this.Form.invalid) {
      return;
    }

    let payment_details:any=[];
    if(this.paymentType.type=='Bank')
    {
      let obj={
        bank_holder_name:this.Form.controls['bank_holder_name'].value,
        bank_name:this.Form.controls['bank_name'].value,
        ifsc_code:this.Form.controls['ifsc_code'].value,
        account_no:this.Form.controls['account_no'].value,
      }
      payment_details.push(obj);
    }
    else
    {
let obj={
  bank_holder_name:this.Form.controls['bank_holder_name'].value,
  mobile_no:this.Form.controls['mobile_no'].value,
  account_no:this.Form.controls['account_no'].value,
      }
      payment_details.push(obj);
    }

    let parms={
      user_id:this.userDetails?._id,
      user_name:this.userDetails?.user_name,
      type:this.paymentType.type,
      name:this.paymentType.name,
      payment_details:payment_details,

    }
    this.sport.addUserPaymentDetail(parms).subscribe((res) => {
      if (res.status == true) {
        this.toastr.success(res.msg);
        this.hideShowBankdetail();
        this.showDetail(this.payMethodList[0]);
        this.withdrawAllMethod()
      } else {
        this.toastr.error(res.msg)
      }
    })
  }
  withdraw() {
    this.loading = true;
    this.cdRef.detectChanges();
    if(this.min_MAXdATA[0]?.min_amount>this.amount)
    {
      this.toastr.error("min amount is required "+this.min_MAXdATA[0]?.min_amount);
      this.loading = false;
    }
    else if(this.min_MAXdATA[0]?.max_amount<this.amount)
    {
      this.toastr.error("max amount is required "+this.min_MAXdATA[0]?.max_amount)
      this.loading = false;
    }
    else if (this.amount > 0) {
      let data = {
        user_id: this.userDetails._id,
        user_name: this.userDetails.user_name,
        amount: parseInt(this.amount),
        method_id:this.method_id,
      }
      this.sport.paymentWithdraw(data).subscribe((res) => {
        if (res.status == true) {
          this.amount = 0;
          this.getStatement();
          this.toastr.success(res.msg)
        } else {
          this.toastr.error(res.msg)
        }
        this.loading = false;
      })
    } else {
      this.toastr.error('Please Enter Amount ...')
      this.loading = false;
    }
  }
  getUserBalance() {
    let data = {};
    this.sport.getUserBalance(data).subscribe(response => {
      if (response.status == true) {
        this.walletBalance = response.data;
        this.updatedBalance = (this.walletBalance.balance) - 100;
        this.cdRef.detectChanges();
      } else {
        this.toastr.error(response.msg, '', {
          timeOut: 10000,
        });
        this.cdRef.detectChanges();
      }
    })
  }
  validate() {
    if (this.count < 2) {
      if (this.updatedBalance >= (this.amount)) {
        if (this.amount < 100) {
          this.toastr.error('Minimum withdraw of 100/-')
        } else {
          if(!this.method_id){
            this.toastr.error('select atleast one payment deatils')
          }
          else{
            this.withdraw();
          }
          
        }
      } else {
        this.toastr.error('Check your wallet Balance ...')
      }
    } else {
      this.toastr.error('The maximum withdrawal allowed 2  transactions per day.')
    }
  }
  delete(list) {
    if (confirm('Delete this account detail')) {
      let param = {
        _id:list?._id,
        visible:false,
        type:list?.type,
        u_type:true
      }
      this.sport.removewithdrawMethod(param).subscribe((res) => {
        if (res.status == true) {
          this.toastr.success(res.msg);
          this.showDetail(this.methodId);
          this.withdrawAllMethod()
        } else {
          this.toastr.error(res.msg)
        }
      })
    }
  }
  showDetail(param) {
    this.methodId = param;
    this.cdRef.detectChanges();
    let data = {
      user_id: this.userDetails._id,
      
    }
    this.sport.paymentWithdrawDetail(data).subscribe((res) => {
      this.methodDetail = res.data;
    })
  }
}
