import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  public Base_Url = environment['adminServerUrl'];
  public token = localStorage.getItem('userAccessToken');
  constructor(private http: HttpClient, private cookie: CookieService) { }

  reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ` + this.token
  });

  accountStatement(param) {
    return this.http.post<any>(this.Base_Url + 'account/accountSatement', param, { headers: this.reqHeader });
  }
  statements(param) {
    return this.http.post<any>(this.Base_Url + 'account/userAccountStatement', param, { headers: this.reqHeader });
  }
  profitLoss(request) {
    return this.http.post<any>(this.Base_Url + 'report/eventsProfitLoss', request, { headers: this.reqHeader })
  }
  settlement(param) {
    return this.http.post<any>(this.Base_Url + 'report/settlementReport', param, { headers: this.reqHeader });
  }
  plBets(param) {
    return this.http.post<any>(this.Base_Url + 'bet/plBets', param, { headers: this.reqHeader });
  }
  openBets(param) {
    return this.http.post<any>(this.Base_Url + 'bet/openBets', param, { headers: this.reqHeader });
  }
  settleBets(param) {
    return this.http.post<any>(this.Base_Url + 'bet/settledBets', param, { headers: this.reqHeader });
  }
  betHistory(param) {
    return this.http.post<any>(this.Base_Url + 'bet/userSettledBetList', param, { headers: this.reqHeader });
  }
  eventList(param) {
    return this.http.post<any>(this.Base_Url + 'event/lists', param, { headers: this.reqHeader });
  }
  eventpl(param) {
    return this.http.post<any>(this.Base_Url + 'report/sportsP_L', param, { headers: this.reqHeader });
  }
  matchpl(param) {
    return this.http.post<any>(this.Base_Url + 'report/matchWiseP_L', param, { headers: this.reqHeader });
  }
  userpl(param) {
    return this.http.post<any>(this.Base_Url + 'report/usersPLByMarket', param, { headers: this.reqHeader });
  }
  passwordHistory(data) {
    return this.http.post<any>(this.Base_Url + 'user/getPasswordChangedHistory', data, { headers: this.reqHeader });
  }
  getOnlineUsersList(param) {
    return this.http.post<any>(this.Base_Url + 'user/getOnlineUsersList', param, { headers: this.reqHeader });
  }
  rollingCommission(param) {
    return this.http.post<any>(this.Base_Url + 'report/eventsStackAndCommission', param, { headers: this.reqHeader });
  }
  getNews(param) {
    return this.http.post<any>(this.Base_Url + 'news/getNews', param, { headers: this.reqHeader });
  }
  userActivity(param) {
    return this.http.post<any>(this.Base_Url + 'user/userActivityList', param, { headers: this.reqHeader });
  }
  casinoBets(param) {
    return this.http.post<any>(this.Base_Url + 'world-casino/betLists', param, { headers: this.reqHeader });
  }
  totalPl(param) {
    return this.http.post<any>(this.Base_Url + 'analytics/getTotalBetsWithPL', param, { headers: this.reqHeader });
  }
}
