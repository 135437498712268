import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class SportService {

  public Base_Url = environment['adminServerUrl'];
  public token = localStorage.getItem('userAccessToken');

  constructor(private http: HttpClient, private cookie: CookieService) {

   }
  reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ` + this.token
  });
  reqHeader1 = new HttpHeaders({
    'Authorization': `Bearer ` + this.token
  });
  
  getAllSportsList(request) {
    return this.http.post<any>(this.Base_Url + 'sports/getSports', request, { headers: this.reqHeader });
  }
  getSeriesList(request) {
    return this.http.post<any>(this.Base_Url + 'series/getSeries', request, { headers: this.reqHeader });
  }
  getHomeMatchesList(request) {
    return this.http.post<any>(this.Base_Url + 'match/homeMatches', request, { headers: this.reqHeader });
  }
  runnerList(request) {
    return this.http.post<any>(this.Base_Url + 'match/homeMatchesRunners', request, { headers: this.reqHeader });
  }
  getUserMatchStack(id) {
    return this.http.post<any>(this.Base_Url + 'user/getUserMatchStack', { "userid": id }, { headers: this.reqHeader });
  }
  updateUserMatchStack(request) {
    return this.http.post<any>(this.Base_Url + 'user/updateMatchStack', request, { headers: this.reqHeader });
  }
  showFancyList(request) {
    return this.http.post<any>(this.Base_Url + 'fancy/getFancies', request, { headers: this.reqHeader });
  }
  showFancyListRunner(request) {
    return this.http.post<any>(this.Base_Url + 'fancy/getFancyLiveData', request, { headers: this.reqHeader });
  }

  depositMethod(request) {
    return this.http.post<any>(this.Base_Url + 'wallet/get-deposit-method-list', request, { headers: this.reqHeader });
  }
  matchDetails(request) {
    return this.http.post<any>(this.Base_Url + 'match/matchDetails', request, { headers: this.reqHeader });
  }
  matchDetailRunners(request) {
    return this.http.post<any>(this.Base_Url + 'match/matchDetailsRunners', request, { headers: this.reqHeader });
  }
  saveBet(request) {
    return this.http.post<any>(this.Base_Url + 'bet/saveBet', request, { headers: this.reqHeader });
  }
  saveFancyBet(request) {
    return this.http.post<any>(this.Base_Url + 'bet/saveFancyBet', request, { headers: this.reqHeader });
  }
  getBet(request) {
    return this.http.post<any>(this.Base_Url + 'bet/bets', request, { headers: this.reqHeader });
  }
  getFancyPosition(request) {
    return this.http.post<any>(this.Base_Url + 'fancy/getRunTimeFancyPosition', request, { headers: this.reqHeader });
  }
  teamPosition(request) {
    return this.http.post<any>(this.Base_Url + 'bet/getTeamPosition', request, { headers: this.reqHeader });
  }
  getFancyLiability(request) {
    return this.http.post<any>(this.Base_Url + 'bet/getFancyLiability', request, { headers: this.reqHeader });
  }
  profitLoss(request) {
    return this.http.post<any>(this.Base_Url + 'report/eventsProfitLoss', request, { headers: this.reqHeader })
  }
  getExposure(request) {
    return this.http.post<any>(this.Base_Url + 'bet/getExposures', request, { headers: this.reqHeader });
  }
  getExposureRoute(request) {
    return this.http.post<any>(this.Base_Url + 'market/getRawEvents', request, { headers: this.reqHeader });
  }
  getPermotionList(request) {
    return this.http.post<any>(this.Base_Url + 'refer/get-permotion-list', request, { headers: this.reqHeader });
  }
  marketSetting(request) {
    return this.http.post<any>(this.Base_Url + 'event/getEventsLimit', request, { headers: this.reqHeader });
  }
  applyValidation(request) {
    return this.http.post<any>(this.Base_Url + 'event/applyValidation', request, { headers: this.reqHeader });
  }
  supernovaGameList(request) {
    return this.http.post<any>(this.Base_Url + 'world-casino/games', request, { headers: this.reqHeader });
  }
  startSupernovaGame(request) {
    return this.http.post<any>(this.Base_Url + 'world-casino/auth', request, { headers: this.reqHeader });
  }
  getSocketStatus() {
    return this.http.get<any>(this.Base_Url + 'globalsetting/getSocketStatus', { headers: this.reqHeader });
  }
  favourite(request) {
    return this.http.post<any>(this.Base_Url + 'match/make-favourite', request, { headers: this.reqHeader });
  }
  paymentMethodList(request) {
    return this.http.post<any>(this.Base_Url + 'wallet/getPayementMethod', request, { headers: this.reqHeader });
  }
  
  paymentwithdrawMethodList(request) {
    return this.http.post<any>(this.Base_Url + 'wallet/get-withdraw-method-request', request, { headers: this.reqHeader });
  }
  withdrawMethod(request) {
    return this.http.post<any>(this.Base_Url + 'wallet/wallet-get-method', request, { headers: this.reqHeader });
  }
  paymentDetail(request) {
    return this.http.post<any>(this.Base_Url + 'wallet/getParentPayementDetails', request, { headers: this.reqHeader });
  }
  paymentDeposit(request) {
    return this.http.post<any>(this.Base_Url + 'wallet/create-deposit-request', request, { headers: this.reqHeader1 });
  }
  paymentWithdraw(request) {
    return this.http.post<any>(this.Base_Url + 'wallet/create-withdraw-request', request, { headers: this.reqHeader1 });
  }
  paymentWithdrawDetail(request) {
    return this.http.post<any>(this.Base_Url + 'wallet/wallet-get-method', request, { headers: this.reqHeader });
  }
  addUserPaymentDetail(request) {
    return this.http.post<any>(this.Base_Url + 'wallet/wallet-withdraw-request-method', request, { headers: this.reqHeader });
  }
  removewithdrawMethod(request) {
    return this.http.post<any>(this.Base_Url + 'wallet/update-wallet-method', request, { headers: this.reqHeader });
  }
  removeUserPaymentDetail(request) {
    return this.http.post<any>(this.Base_Url + 'wallet/removePaymentDetails', request, { headers: this.reqHeader });
  }
  statementWDetail(request) {
    return this.http.post<any>(this.Base_Url + 'wallet/get-all-walletw-statement', request, { headers: this.reqHeader });
  }
  
  statementDDetail(request) {
    return this.http.post<any>(this.Base_Url + 'wallet/get-all-walletd-statement', request, { headers: this.reqHeader });
  }
  getUserBalance(id) {
    return this.http.post<any>(this.Base_Url + 'user/getBalanceCRef', id, { headers: this.reqHeader });
  }
  
  getGlobalSettings() {
    return this.http.get<any>(this.Base_Url + 'globalsetting/getGlobalSettingDetails', { headers: this.reqHeader });
  }
  getQtechProvider() {
    return this.http.get<any>(this.Base_Url + 'qtech/all/sports', { headers: this.reqHeader });
  }
  getProviderGameList(id) {
    return this.http.get<any>(this.Base_Url + 'qtech/gameList?providers=' + id, { headers: this.reqHeader });
  }
  qtechLaunch(id) {
    return this.http.post<any>(this.Base_Url + 'qtech/launchUrl', id, { headers: this.reqHeader });
  }
  lotusLaunch(id) {
    return this.http.post<any>(this.Base_Url + 'lotus/launchUrl', id, { headers: this.reqHeader });
  }
  lotusLobby(id) {
    return this.http.post<any>(this.Base_Url + 'lotus/lobbyUrl', id, { headers: this.reqHeader });
  }
  getStatement(param) {
    return this.http.post<any>(this.Base_Url + 'account/statements', param, { headers: this.reqHeader });
  }
}
