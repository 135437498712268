<router-outlet></router-outlet>
<div class="socialIcon" [ngClass]="isCloseSocialBtn ? 'closeSocialIcon' : ''">
    <div class="closeIcon" (click)="closeSocialBtn()">
        <i class="fa fa-close"></i>
    </div>
    <div class="contentText" (click)="goToRoute()">
        <img src="assets/images/whatsapp-icon.svg">
    </div>
</div>

<div class="backToTop" id="backToTop" (click)="scrollToTop()">
    <i class="fa fa-angle-up"></i>
</div>
<ng-http-loader [filteredUrlPatterns]="filteredUrlPatterns"></ng-http-loader>