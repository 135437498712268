import { Component, OnInit, TemplateRef, ChangeDetectorRef,Inject, Injectable } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../services/login.service';
import { environment } from '../../environments/environment';
import { AppValidationService } from '../app-validation/app-validation.service';
import { OpenApiService } from '../services/open-api.service';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-open-header',
  templateUrl: './open-header.component.html',
  styleUrls: ['./open-header.component.scss']
})
export class OpenHeaderComponent implements OnInit {
  public Base_Url = environment['adminServerUrl'];
  allSportsList: any;
  allSeriesList: any;
  sportList: boolean = true;
  matchList: any[] = [];
  seriesId: any;
  isShowMenu: boolean[] = [];
  sportData: any;
  Form: FormGroup;
  addUserFrom: FormGroup;
  showResponseMessage;
  public showPassword: boolean = false;
  modalRef: BsModalRef;
  modalRefsign: BsModalRef;
  domainName: string;
  domainData: string;
  isUserNameExist: boolean;
  isAddUserFromSubmitted: boolean;
  logo: string;
  private clickTimeout = null;
  loginButtonDisable = false;
  a: any;
  submitted = false;
  path: string;
  path_url: string;
  sportsName: any;
  userData: any;
  homeData: any;
  searchSport: string;
  referCode: any;
  facebook: any;
  insta: any;
  telegram: any;
  wa: any;
  mail: any;
  twitter: any;
  domain_name: string;
  constructor(@Inject(DOCUMENT) private document,private router: Router, private route: ActivatedRoute, private open: OpenApiService,
    private fb: FormBuilder, private toastr: ToastrService, private modalService: BsModalService, private loginService: LoginService,
    private appValidationService: AppValidationService, private cdRef: ChangeDetectorRef) {
    this.route.params.subscribe(params => {
      this.sportsName = params.sportName;
      let a = this.router.url;
      this.path_url="";
      let b = a.split('/')
      if (b.length <= 2) {
        this.path = b[1];
      } else {
        this.path_url = b[1];
        this.path = b[2]
      }
     
    })
    
  }

  ngOnInit(): void {
    this.referCode = localStorage.getItem('referCode') != undefined ? localStorage.getItem('referCode') : '';
    let domain = window.location.hostname;
    this.domain_name = domain+".png"
    let domain1 = domain.split('.')
    if (domain1[0] == '3rdempire') {
      this.facebook = '';
      this.insta = 'https://www.instagram.com/3rdempiree/';
      this.telegram = 'https://telegram.me/bigdream07';
      this.wa = 'https://api.whatsapp.com/send/?phone=919024696202&text=Hi&type=phone_number&app_absent=0';
      this.mail = '';
      this.twitter = 'https://twitter.com/3rdempiree'
    } else if (domain1[0] == 'motabook365') {
      this.facebook = 'https://www.facebook.com/profile.php?id=100086163486306';
      this.insta = 'https://www.instagram.com/motabook247/';
      this.telegram = 'https://t.me/Motabook247';
      this.wa = 'https://api.whatsapp.com/send?phone=917439876249';
      this.mail = '';
      this.twitter = ''
    }
    this.getAllSportsListData()
    this.domainName = window.location.origin;
    this.domainData = this.getDomainWithoutSubdomain(this.domainName);
    this.getLoginLogo();
    this.createFrom();
    this.createSignFrom();
    this.cdRef.detectChanges();
  }
  openMobileSidebarStatus: boolean = false;
  openMobileSidebar() {
    this.openMobileSidebarStatus = !this.openMobileSidebarStatus;
  }
  getAllSportsListData() {
    let userData = {};
    this.open.getAllSportsList(userData).subscribe(res => {
      if (res.status == true) {
        this.sportList = true;
        this.allSportsList = res.data
        this.allSportsList = (this.allSportsList).sort(function (a, b) { return b.sport_id - a.sport_id });
      } else {
        this.toastr.error(res.msg);
      }
    })
  }
  getSeriesList(id) {
    this.sportData = id;
    let data = {
      sport_id: id
    };
    this.open.getSeriesList(data).subscribe(res => {
      this.sportList = false;
      this.allSeriesList = res.data
      this.isShowMenu = Array(this.allSeriesList.length).fill(false);
      this.matchList = Array(this.allSeriesList.length).fill([]);
      this.cdRef.detectChanges();
    })
  }
  getMatchList(param, index) {
    let data = {
      series_id: param.series_id
    };
    this.isShowMenu[index] = !this.isShowMenu[index]
    this.open.getHomeMatchesList(data).subscribe(res => {
      this.seriesId = param.series_id
      this.matchList[index] = res.data;
      this.cdRef.detectChanges;
    })
  }
  getDomainWithoutSubdomain(url) {
    try {
      const urlParts = new URL(url).hostname.split('.');
      return urlParts
        .slice(0)
        .slice(-(urlParts.length === 4 ? 3 : 2))
        .join('.');
    } catch (error) {
      console.log("55", error);
      return "";
    }
  }

  openModalSignupModal(signupModal: TemplateRef<any>) {
    this.modalRefsign = this.modalService.show(
      signupModal,
      Object.assign({}, { class: 'login_page', ignoreBackdropClick: false }),
    );
    this.createSignFrom();
    if (this.referCode != undefined || this.referCode != '') {
      this.addUserFrom.controls['refer_code'].setValue(this.referCode);
    }

    if (this.path_url != '') {
      this.addUserFrom.controls['refer_code'].setValue(this.path);
    }
    if (this.modalRef != undefined) {
      this.modalRef.hide();
    }
  }

  openModalLoginModal(loginModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginModal,
      Object.assign({}, { class: 'login_page', ignoreBackdropClick: false }),
    );
    this.createFrom();
    if (this.modalRefsign != undefined) {
      this.modalRefsign.hide();
    }
  }

  getLoginLogo() {
    let data = {
      key: btoa(this.domainData)
      // key: 'cHVycGxlMjUuY29t'
    }
    
    this.loginService.getLogo(data).subscribe((res) => {
      if (res.status) {
        if (res.data != null) {
          if ((res.data).hasOwnProperty('self_host')) {
            if ((res.data).self_host == true) {
              this.logo = this.Base_Url + res.data.description;
              localStorage.setItem('logo', this.logo)
            } else {
              this.logo = res.data.description;
              localStorage.setItem('logo', this.logo)
            }
          } else {
            this.logo = this.Base_Url + res.data.description;
            localStorage.setItem('logo', this.logo)
          }
        }
      } else {
        this.toastr.error(res.msg)
      }
    })
  }
  createFrom() {
    this.Form = this.fb.group({
      user_name: ['', Validators.required],
      password: ['', [Validators.required]],
      verify_domain: [''],
      grant_type: ['password'],
      isRememberMe: [false]
    })
    if (JSON.parse(localStorage.getItem('RememberMe'))) {
      this.Form.patchValue({
        user_name: localStorage.getItem('user_name'),
        password: localStorage.getItem('password'),
        verify_domain: this.document.location.hostname,
        isRememberMe: localStorage.getItem('RememberMe')
      })
    }
  }

  createSignFrom() {
    this.addUserFrom = this.fb.group({
      name: ['', Validators.required],
      user_name: ['', Validators.required],
      password: ['', [Validators.required]],
      mobile: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]],
      email: [''],
      refer_code: [''],
      verify_domain: [''],
    });
    this.applyValidationToFormGroup(this.addUserFrom, "AddUser")
  }
  
  get f() { return this.Form.controls; }
  applyValidationToFormGroup(formGroupName, jsonArrayName) {
    this.appValidationService.applyValidationRulesToFromGroup(formGroupName, jsonArrayName).then((validators) => {
    }).catch(() => { })
  }

  isControlIsValid(controlName: string): boolean {
    if (this.addUserFrom.controls[controlName].invalid && (this.isAddUserFromSubmitted || this.addUserFrom.controls[controlName].dirty || this.addUserFrom.controls[controlName].touched)) {
      return true;
    }
    return false;
  }
  isControlHasErros(controlName: string) {
    if (this.addUserFrom.controls[controlName].errors) {
      let value = ''
      let object = this.addUserFrom.controls[controlName].errors
      for (var propName in object) {
        if (object.hasOwnProperty(propName) && propName != "required") {
          value = object[propName];
        }
      }
      return value;
    }
    return null;
  }
  addUserData() {
    this.addUserFrom.controls['verify_domain'].setValue("kudo24.com");
    this.isAddUserFromSubmitted = true;
    this.addUserFrom.controls['name'].setValue(this.addUserFrom.controls['user_name'].value)
    this.appValidationService.markFormGroupTouched(this.addUserFrom)
    if (this.addUserFrom.controls.email.value == '') {
      delete this.addUserFrom.value['email'];
    }
    if (this.addUserFrom.controls.refer_code.value == '') {
      delete this.addUserFrom.value['refer_code'];
    }
    if (this.addUserFrom.invalid) {
      return;
    }
    this.loginService.register(this.addUserFrom.value).subscribe((result) => {
      this.isAddUserFromSubmitted = false;
      if (result.status == true) {
        this.toastr.success(result.msg, '', {
          timeOut: 1000
        });
        this.Form.controls['user_name'].setValue(this.addUserFrom.controls['user_name'].value)
        this.Form.controls['password'].setValue(this.addUserFrom.controls['password'].value)
        this.headerSingleLoginClick();
        this.modalRefsign.hide();
      } else {
        this.toastr.error(result.msg, '', {
          timeOut: 10000,
        });
      }
    }, (err) => {
      console.log(err);
    });
  }
  async onSubmitLogin(from?: string) {
    this.Form.controls['verify_domain'].setValue("kudo24.com");
    if (this.Form.valid) {
      this.loginButtonDisable = true
      if (this.clickTimeout) {
        this.setClickTimeout(() => { });
      } else {
        this.setClickTimeout(() => {
          if (from == 'login') {
            this.headerSingleLoginClick()
          }
        })
      }
    } else {
      this.submitted = true;
    }
  }
  public headerSingleLoginClick() {
    this.submitted = true;
    this.a = (this.Form.value.user_name);
    let b = (this.a).toLowerCase()
    this.Form.value.user_name = b;
    this.Form.controls['verify_domain'].setValue("kudo24.com");
    
    if (this.Form.invalid) {
      this.loginButtonDisable = false
      return;
    }
   
    this.loginService.submitlogin(this.Form.value).subscribe((result) => {
      
      if (result.status == true) {
        localStorage.setItem('userAccessToken', result.token.accessToken);
        this.toastr.success(result.msg, '', {
          timeOut: 1000
        })
        this.setCokkies(result);
        localStorage.setItem('userDetails', JSON.stringify(result.data));
        localStorage.setItem('operator', JSON.stringify(result.operatorId));
        this.loginButtonDisable = false;
        this.modalRef.hide();
      } else {
        this.showResponseMessage = result.msg;
        this.toastr.error(this.showResponseMessage)
        this.loginButtonDisable = false;
      }
    }, (err) => {
      console.log(err);
      this.loginButtonDisable = false;
    });
  }
  public setClickTimeout(callback) {
    clearTimeout(this.clickTimeout);
    this.clickTimeout = setTimeout(() => {
      this.clickTimeout = null;
      callback();
    }, 400);
  }
  async redirectToDashboard() {
    var acctoken = localStorage.getItem('userAccessToken');
    if (acctoken != null && acctoken != undefined && acctoken != '') {
      this.router.navigate(['dashboard'])
    }
  }
  async setCokkies(result) {
    localStorage.setItem('userAccessToken', result.token.accessToken);
    localStorage.setItem('userId', result.data._id);
    localStorage.setItem('userRefreshToken', result.token.refreshToken);
    this.router.navigate(['dashboard'])
  }
  dashboard(data) {
    if (data == 1) {
      this.router.navigate(['open-dashboard'])
    } else if (data == 2) {
      this.router.navigate(['open-dashboard/inplay'])
    } else if (data == 3) {
      this.router.navigate(['open-promo'])
    } else if (data == 4) {
      this.router.navigate(['open-dashboard/Casino'])
    } else if (data == 5) {
      this.toastr.error('Coming Soon ...')
    }
  }
  homematches() {
    this.userData = {}
    this.open.getSearchMatchList(this.userData).subscribe(res => {
      if (res.status) {
        this.homeData = res.data;
      } else {
        this.toastr.error(res.msg, '', {
          timeOut: 10000,
        });
      }
    }, (err) => {
      console.log(err);
    })
  }
  matchDetail(matchData) {
    localStorage.setItem('matchData', JSON.stringify(matchData));
    if (this.router.url.split('?')[0] == '/openDetail') {
      window.location.reload();
    } else {
      this.router.navigate(['openDetail']);
    }
  }
  goToRoute() {
    let domain = window.location.hostname
    let domain1 = domain.split('.')
    if (domain1[0] == 'billionexchwallet') {
      this.wa = 'https://api.whatsapp.com/send?phone=13124935676&text=Hi&type=phone_number&app_absent=0';
      window.open(this.wa, "_blank")
    } else if (domain1[0] == 'motabook365') {
      this.wa = 'https://api.whatsapp.com/send?phone=13124935676';
      window.open(this.wa, "_blank")
    } else if (domain1[0] == '3rdempire') {
      this.wa = 'https://api.whatsapp.com/send/?phone=919024696202&text=Hi&type=phone_number&app_absent=0';
      window.open(this.wa, "_blank")
    }
  }
}
