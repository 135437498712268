<app-header></app-header>
<div class="content_area">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 left_bar">
        <app-left-side-bar></app-left-side-bar>
      </div>

      <div class="col-md-9 conetnt_col">

        <!-- slider -->
        <div class="slider">
          <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item item " *ngFor="let image of banner;let i = index;"
                [ngClass]="(i === 0) ? 'active' : ''">
                <img src="{{image.bannerUrl}}" alt="">
              </div>
            </div>
          </div>
        </div>
        <!-- slider -->

        <div class="casion_game">
          <div class="all_casion_game">
            <div class="casion_tabs">
              <ul>
                <li *ngFor="let provider of casinoList;let i = index;"
                  (click)="getProviderGame(provider?.providerCode)">
                  <a class="active" id="btn-{{i}}" tab-content="#item-1">{{provider?.name}}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="portfolio_row" id="item-1">
            <div class="port-inner" *ngFor="let list of providerGames;let i = index;" (click)="gotoexchangeGame(list)">
              <div class="thumb">
                <img [src]="list?.images[1]?.url" alt="">
              </div>
              <div class="port_content">
                <h4>
                  <!-- <div class="sub-title">
                      {{list?.name}}
                    </div> -->
                </h4>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>