<app-header></app-header>
<div class="content_area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3 left_bar">
                <app-left-side-bar></app-left-side-bar>
            </div>

            <div class="col-md-9 conetnt_col">
                <div>
                    <div class="promoting_tabs reporting_tabs">
                        <app-report-header></app-report-header>
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="user_profile" role="tabpanel"
                                aria-labelledby="pills-home-tab">
                                <div class="user-bg users-details">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="user_col">
                                                <div class="user_btn">
                                                    <img src="assets/images/new-user.png" alt="">
                                                    <input type="file">
                                                </div>
                                                <div class="user-profile-details">
                                                    <h6> {{userDetails?.name}} </h6>
                                                </div>
                                                <div class="bottom_content">
                                                    <div class="user-left">
                                                        <div class="number">
                                                            <span><svg xmlns="http://www.w3.org/2000/svg" width="17"
                                                                    height="17" viewBox="0 0 17 17" fill="none">
                                                                    <path
                                                                        d="M14.4368 16.2188H14.3359C2.66868 15.5478 1.01212 5.70344 0.780558 2.69906C0.761899 2.46547 0.789517 2.23049 0.861827 2.00759C0.934138 1.78469 1.04972 1.57824 1.20195 1.40009C1.35418 1.22193 1.54007 1.07556 1.74897 0.969373C1.95787 0.863182 2.18566 0.799254 2.41931 0.781251H5.69087C5.9287 0.78102 6.16112 0.852209 6.35803 0.985597C6.55493 1.11898 6.70725 1.30842 6.79525 1.52938L7.69775 3.75C7.78464 3.96586 7.80621 4.20248 7.75976 4.43049C7.71331 4.6585 7.6009 4.86784 7.4365 5.0325L6.17181 6.30906C6.36936 7.43169 6.90698 8.46655 7.71193 9.27363C8.51689 10.0807 9.55033 10.6211 10.6724 10.8216L11.9609 9.545C12.128 9.38241 12.3393 9.27262 12.5684 9.22933C12.7976 9.18604 13.0344 9.21116 13.2493 9.30156L15.4877 10.1981C15.7054 10.2889 15.891 10.4424 16.0211 10.6391C16.1512 10.8358 16.2197 11.0667 16.2181 11.3025V14.4375C16.2181 14.9099 16.0304 15.363 15.6963 15.697C15.3623 16.0311 14.9092 16.2188 14.4368 16.2188ZM2.56181 1.96875C2.40434 1.96875 2.25331 2.03131 2.14196 2.14266C2.03061 2.25401 1.96806 2.40503 1.96806 2.5625V2.61C2.24118 6.125 3.99275 14.4375 14.4012 15.0312C14.4792 15.0361 14.5574 15.0254 14.6313 15C14.7051 14.9745 14.7733 14.9347 14.8318 14.8829C14.8902 14.831 14.9379 14.7681 14.972 14.6978C15.0061 14.6275 15.026 14.5511 15.0306 14.4731V11.3025L12.7921 10.4059L11.0881 12.0981L10.8031 12.0625C5.63743 11.4153 4.93681 6.24969 4.93681 6.19625L4.90118 5.91125L6.58743 4.20719L5.69681 1.96875H2.56181Z"
                                                                        fill="black"></path>
                                                                </svg>
                                                                {{(userDetails?.mobile == null) ? 'Not Available' :
                                                                userDetails?.mobile}}
                                                            </span>
                                                            <span class="v-status"><i class="fa fa-check"></i></span>
                                                            <!-- <div class="edit-button">
                                                            <a  class="edit-btn" (click)="openModalEditPhone(editPhone)"><i class="fa fa-pencil-alt"></i></a>
                                                        </div> -->
                                                        </div>
                                                        <div class="number gender">
                                                            <span (click)="openModalChangePass(changePass)"><svg
                                                                    width="12" height="12" viewBox="0 0 12 12"
                                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                                        d="M6 6.92308C5.40523 6.92308 4.92308 7.40523 4.92308 8C4.92308 8.59477 5.40523 9.07692 6 9.07692C6.59477 9.07692 7.07692 8.59477 7.07692 8C7.07692 7.40523 6.59477 6.92308 6 6.92308ZM4 8C4 6.89543 4.89543 6 6 6C7.10457 6 8 6.89543 8 8C8 9.10457 7.10457 10 6 10C4.89543 10 4 9.10457 4 8Z"
                                                                        fill="#012984"></path>
                                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                                        d="M3.45723 2.19523C3.03722 2.84356 2.88889 3.78727 2.88889 4.97368V6.44737C2.88889 6.75258 2.6899 7 2.44444 7C2.19898 7 2 6.75258 2 6.44737V4.97368C2 3.72115 2.14797 2.45433 2.76499 1.50188C3.39874 0.523611 4.44245 0 6 0C7.55755 0 8.60126 0.523611 9.23501 1.50188C9.85203 2.45433 10 3.72115 10 4.97368V6.44737C10 6.75258 9.80102 7 9.55556 7C9.3101 7 9.11111 6.75258 9.11111 6.44737V4.97368C9.11111 3.78727 8.96278 2.84356 8.54277 2.19523C8.13948 1.57271 7.40541 1.10526 6 1.10526C4.59459 1.10526 3.86052 1.57271 3.45723 2.19523Z"
                                                                        fill="#012984"></path>
                                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                                        d="M1.27274 5.35131C0.987257 5.65441 0.837209 6.21905 0.837209 7.40741V8.59259C0.837209 9.78095 0.987257 10.3456 1.27274 10.6487C1.55823 10.9518 2.09003 11.1111 3.2093 11.1111H8.7907C9.90997 11.1111 10.4418 10.9518 10.7273 10.6487C11.0127 10.3456 11.1628 9.78095 11.1628 8.59259V7.40741C11.1628 6.21905 11.0127 5.65441 10.7273 5.35131C10.4418 5.0482 9.90997 4.88889 8.7907 4.88889H3.2093C2.09003 4.88889 1.55823 5.0482 1.27274 5.35131ZM0.680746 4.72277C1.23247 4.13699 2.09602 4 3.2093 4H8.7907C9.90399 4 10.7675 4.13699 11.3193 4.72277C11.871 5.30855 12 6.2254 12 7.40741V8.59259C12 9.7746 11.871 10.6915 11.3193 11.2772C10.7675 11.863 9.90399 12 8.7907 12H3.2093C2.09602 12 1.23247 11.863 0.680746 11.2772C0.129022 10.6915 0 9.7746 0 8.59259V7.40741C0 6.2254 0.129022 5.30855 0.680746 4.72277Z"
                                                                        fill="#012984"></path>
                                                                </svg>
                                                                Change Password
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <!-- <div class="user_col">
                                                <div class="top-content">
                                                    <svg _ngcontent-xvl-c60="" xmlns="http://www.w3.org/2000/svg"
                                                        width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                        <path
                                                            d="M5.82062 14.1784H3.0332V7.21583H5.81954V14.1795L5.82062 14.1784ZM11.3922 14.1784H8.60587V0.25H11.3922V14.1784ZM16.9627 14.1784H14.1775V4.42842H16.9627V14.1784Z"
                                                            fill="#666666"></path>
                                                        <path _ngcontent-xvl-c60="" fill-rule="evenodd"
                                                            clip-rule="evenodd"
                                                            d="M19.75 19.4401H0.25V17.2734H19.75V19.4401Z"
                                                            fill="#666666">
                                                        </path>
                                                    </svg>
                                                    <span> Statistics </span>
                                                </div>
                                                <div class="bottom-content">
                                                    <div class="bottom_discription">
                                                        <div class="title">
                                                            Total Profit
                                                        </div>
                                                        <div class="discription"
                                                            [ngClass]="{'loss':(reportData?.total_pl)<0,'profit':(reportData?.total_pl)>=0}">
                                                            {{reportData?.total_pl}}
                                                        </div>
                                                    </div>
                                                    <div class="bottom_discription">
                                                        <div class="title">
                                                            Profit in Sports
                                                        </div>
                                                        <div class="discription"
                                                            [ngClass]="{'loss':(reportData?.sports_pl)<0,'profit':(reportData?.sports_pl)>=0}">
                                                            {{reportData?.sports_pl}}
                                                        </div>
                                                    </div>
                                                    <div class="bottom_discription">
                                                        <div class="title">
                                                            Profit in Casino
                                                        </div>
                                                        <div class="discription"
                                                            [ngClass]="{'loss':(reportData?.casino_pl)<0,'profit':(reportData?.casino_pl)>=0}">
                                                            {{reportData?.casino_pl}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <div class="user_col">
                                                <div class="bonus-subscription">
                                                    <h4> Bonus subscription</h4>
                                                    <span>Bonus subscription not available.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="user-bg users-details">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="user_col">
                                                <div class="bonus-subscription">
                                                    <h4> Bonus subscription</h4>
                                                    <span>Bonus subscription not available.</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="user_col">
                                                <div class="turn_over_col">
                                                    <div class="turnover-image">
                                                        <img src="assets/images/turnover-img.png" alt="">
                                                    </div>
                                                    <div class="turnover">
                                                        <div class="turnover-date">
                                                            Your total deposits till date is
                                                        </div>
                                                        <div class="total-turnover">
                                                            ₹ 0
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>

<!-- deposit modal -->
<ng-template #editPhone>
    <a class="modal-close right close-icon" data-bs-dismiss="modal" aria-label="Close" (click)="modalRef.hide()"><i
            aria-hidden="true" class="fa fa-times"></i></a>
    <h5><span class="card-title">
            <img alt=""
                src="https://cdn.cloudd.live/theme/3rdempire_theme/3rdempire_Black/assets/images/ac-title-bg.png?v=1.1">
            <span class="title"> Update Mobile</span></span>
    </h5>
    <div class="modal-body change-phone">
        <div class="body-inner">
            <div class="otp-page">
                <div class="otp-details">
                    <form>
                        <div class="row enter-number">
                            <div class="input-field verify-mobcontrol">
                                <label>Whatsapp Number</label>
                                <input type="text" placeholder="Enter number..." class="form-control">
                                <div><span data-error="wrong" data-success="right"
                                        class="helper-text error left-align">Whatsapp number is required</span></div>
                                <div data-error="wrong" data-success="right" class="helper-text error left-align">Enter
                                    valid whatsapp number.</div>
                            </div>
                            <div class="confirm-btn">
                                <a class="v-status verified">Update</a>
                            </div>
                        </div>
                    </form>
                    <div class="wrap">
                        <div class="text">We sent a code on whatsapp to verify your whatsapp number </div>
                    </div>
                    <div class="bottom-border">
                        <svg xmlns="http://www.w3.org/2000/svg" width="740" height="1" viewBox="0 0 740 1" fill="none">
                            <line y1="0.5" x2="740" y2="0.5" stroke="url(#paint0_radial_1123_2933)"></line>
                            <defs>
                                <radialGradient id="paint0_radial_1123_2933" cx="0" cy="0" r="1"
                                    gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(345.501 -3.99881) rotate(58.742) scale(166.697 123356)">
                                    <stop stop-color="#E4E1E1"></stop>
                                    <stop offset="1" stop-color="#F5F4F4" stop-opacity="0"></stop>
                                </radialGradient>
                            </defs>
                        </svg>
                    </div>
                    <div class="otp-verify">
                        <div class="enter-otp"> Enter Your OTP code here </div>
                        <form novalidate="">
                            <div class="mt-10 mb-10">
                                <input type="text" pattern="[0-9]{1}" maxlength="1">
                                <input type="text" pattern="[0-9]{1}" maxlength="1">
                                <input type="text" pattern="[0-9]{1}" maxlength="1">
                                <input type="text" pattern="[0-9]{1}" maxlength="1">
                                <input type="text" pattern="[0-9]{1}" maxlength="1">
                                <input type="text" pattern="[0-9]{1}" maxlength="1">
                            </div>
                            <div class="otp-expire"> Resend OTP in :<span id="timer" class="timer"> </span>
                            </div>
                            <button class="btn btn-success" disabled="">
                                <span class="animate-btn"> Update </span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #changePass>
    <div class="modal-body">
        <a class="modal-close right close-icon" data-bs-dismiss="modal" aria-label="Close" (click)="modalRef.hide()"><i
                aria-hidden="true" class="fa fa-times"></i></a>
        <div class="change-password-content">
            <h5>Change Password</h5>
            <form class="col s12 p-0" [formGroup]='changePasswordForm' (ngSubmit)='onSubmitChangePassword()'>
                <div class="row ml-0 mr-0">
                    <div class="col s12">
                        <div class="input_field pass_field">
                            <input [type]="showOldPassword ? 'text' : 'password'" class="form_control"
                                formControlName='old_password'>
                            <label for="myId" class="input_label">Old Password</label>
                            <i alt="show" class="fa fa-eye eye-show" (click)="showOldPassword = !showOldPassword"
                                [class.hide]="showPassword"></i>
                            <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showOldPassword = !showOldPassword"
                                [class.hide]="!showPassword"></i>
                        </div>
                    </div>
                </div>
                <div class="row ml-0 mr-0">
                    <div class="col s12">
                        <div class="input_field pass_field">
                            <input [type]="showNewPassword ? 'text' : 'password'" class="form_control"
                                formControlName='new_password'>
                            <label for="myId1" class="input_label">New Password</label>
                            <i alt="show" class="fa fa-eye eye-show" (click)="showNewPassword = !showNewPassword"
                                [class.hide]="showPassword"></i>
                            <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showNewPassword = !showNewPassword"
                                [class.hide]="!showPassword"></i>
                        </div>
                    </div>
                </div>
                <div class="row ml-0 mr-0">
                    <div class="col s12">
                        <div class="input_field pass_field">
                            <input [type]="showConfirmPassword ? 'text' : 'password'" class="form_control"
                                formControlName='confirm_password'>
                            <label for="myId2" class="input_label">Confirm Password</label>
                            <i alt="show" class="fa fa-eye eye-show"
                                (click)="showConfirmPassword = !showConfirmPassword" [class.hide]="showPassword"></i>
                            <i alt="hide" class="fa fa-eye-slash eye-hide"
                                (click)="showConfirmPassword = !showConfirmPassword" [class.hide]="!showPassword"></i>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="modal-footer">
        <div class="row submit-btn">
            <a (click)="onSubmitChangePassword()" class="btn-success btn mr-10">
                <span class="animate-btn">Change</span>
            </a>
            <a class="btn-danger btn" (click)="modalRef.hide()">
                <span class="animate-btn">Cancel</span>
            </a>
        </div>
    </div>
</ng-template>