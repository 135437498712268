import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SportService } from '../services/sport.service';
import { ReportService } from '../services/report.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
@Component({
  selector: 'app-right-side-bar',
  templateUrl: './right-side-bar.component.html',
  styleUrls: ['./right-side-bar.component.scss']
})
export class RightSideBarComponent implements OnInit {
  casinoList: any;
  casino: any;
  exchangeGameList: any;
  games: string;
  betDataList: any;
  dataSourceLength: any;
  pageSize: number;
  dataSource: any;
  openBetsLength: number;
  iUrl: any;
  showProviders: boolean = true;
  constructor(private sport: SportService, private router: Router, private reportService: ReportService, private ref: ChangeDetectorRef, private toastr: ToastrService) { }
  ngOnInit(): void {
    this.exchangeGame();
    this.providers();
  }
  liveCasino() {
    this.casino = (this.exchangeGameList).filter(a => a.is_live == 1)
    this.showProviders = false;
    this.ref.detectChanges();
  }
  virtual() {
    this.casino = (this.exchangeGameList).filter(a => a.is_live == 0)
    this.showProviders = false;
    this.ref.detectChanges();
  }
  exchangeGame() {
    let data = {
      sport_id: "-100"
    };
    this.sport.getHomeMatchesList(data).subscribe((res) => {
      if (res.status == true) {
        this.exchangeGameList = res.data;
        for (let i = 0; i < (this.exchangeGameList).length; i++) {
          if ((this.exchangeGameList[i].match_name).indexOf('(Virtual)') > -1) {
            this.exchangeGameList[i].is_live = 0;
          } else {
            this.exchangeGameList[i].is_live = 1;
          }
        }
        this.casino = (this.exchangeGameList).filter(a => a.is_live == 1)
        this.showProviders = false;
      }
    })
  }
  gotoexchangeGame(cardData) {
    let data = {
      game_id: cardData.match_id
    }
    this.sport.lotusLaunch(data).subscribe((res) => {
      if (res.status == true) {
        window.open(res.launchUrl, '_blank')
      }
    })
  }
  openBets() {
    this.pageSize = 30
    let data = {
      page: 1,
      limit: this.pageSize,
      search: {
        "delete_status": 0
      }
    }
    this.reportService.openBets(data).subscribe((res) => {
      if (res.status) {
        if (res.data[0].data) {
          this.dataSource = res.data[0].data;
          this.dataSourceLength = (this.dataSource).length;
          let matches = (this.dataSource).reduce((groupSport, sports) => {
            const { market_name } = sports;
            groupSport[market_name] = [...groupSport[market_name] || [], sports];
            return groupSport;
          }, {});
          this.betDataList = matches;
          this.ref.detectChanges();
        } else {
          this.dataSourceLength = 0;
        }
      } else {
        this.toastr.error(res.msg);
        this.openBetsLength = 0;
      }
    })
  }
  aviator() {
    let data = {
      gameId: 'SPB-aviator',
      providerCode: "SPB"
    }
    this.sport.qtechLaunch(data).subscribe((res) => {
      if (res.status) {
        this.iUrl = res.data.url;
        window.open(this.iUrl, "_blank")
      } else {
        this.toastr.error(res.msg)
      }
    })
  }
  qtech(data) {
    this.router.navigate(['qtech/' + data]);
  }
  dashboardCasino() {
    this.router.navigate(['dashboard/Casino']);
  }
  providers() {
    this.showProviders = true;
    this.ref.detectChanges();
  }
}
