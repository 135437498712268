import { Component, HostListener, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { OpenApiService } from './services/open-api.service';
import { Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // title = 'franksport';
  filteredUrlPatterns: Array<string> = [
    'match/homeMatches', 'fancy/getFancies', 'fancy/getFancyLiveData', 'user/logout', 'series/series',
    'match/matchDetails', 'getLiveScore', 'match/matchDetailsRunners', 'bet/getFancyLiability', 'user/getBalanceCRef',
    'event/getEventsLimit', 'match/homeMatchesOpen', 'sport/sports', 'bet/openBets', 'bet/getTeamPosition'
  ]
  wa: string;
  public constructor(private openApiService: OpenApiService,private router: Router,private titleService: Title, @Inject(DOCUMENT) private document: Document) { }
  // title = 'franks-port-admin';
  logo: string;
  ngOnInit(): void {
    this.logo = window.location.hostname;
    let domain_name = this.getDomainName(this.logo)
    let b = (domain_name).split('.')
    this.setTitle(b[0]);
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  }
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
  getDomainName(hostName) {
    return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20) {
      document.getElementById('backToTop').classList.add('showBackBtn');
    }
    else {
      document.getElementById('backToTop').classList.remove('showBackBtn');
    }
  }
  scrollToTop(): void {
    // scroll to the top of the body
    return this.document.body.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start'
    });
  }
  isCloseSocialBtn: boolean = false;
  closeSocialBtn() {
    this.isCloseSocialBtn = true;
  }
  goToRoute() {
   
    let domain = window.location.hostname
    let params={host_name:domain}
    this.openApiService.getSocialMedia(params).subscribe(response => {
      if (response.status == true) {
        if(!response?.data)return;
        this.wa = 'https://api.whatsapp.com/send?phone='+response?.data?.support_no+'&text=Hi&type=phone_number&app_absent=0';
        window.open(this.wa, "_blank")
      }
    
    })
   
  }

 
}
