import { Component, OnChanges, OnInit, TemplateRef, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from "@angular/router";
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { UsersService } from '../services/users.service';
import { SportService } from '../services/sport.service';
import { LoginService } from '../services/login.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { resolve } from 'q';
import { SocketServiceService } from '../services/socket-service.service';
import { ReportService } from '../services/report.service';
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';
import { ClipboardService } from 'ngx-clipboard';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('changePwd', { static: true }) public privacyPopup: TemplateRef<any>;
  public Base_Url = environment['adminServerUrl'];
  modalRef: BsModalRef;
  userDetails: any;
  imageQr:any;
  moment: any = moment;
  timer: any;
  changePasswordForm: FormGroup;
  submitted = false;
  statement_data:any;
  user_id: string;
  walletBalance: any;
  isSocket;
  userMatchStack: any;
  match_stack: any[] = [];
  activeThem = 'oceanBlueThemProps';
  homeData: any;
  userData: any;
  runnerData: any;
  firstData: any;
  parameter: any;
  a: any = [];
  searchSport: string;
  exposureData: any;
  expoLength: any;
  expo_User_name: any;
  logo: string;
  message: string;
  popData: boolean;
  pass: boolean = false;
  expoRouteData: any;
  allSportsList: any;
  currentExpo: number;
  currentBalance: number;
  betList: boolean;
  MatchList: any;
  dataSource: any;
  openBetsLength: any = 0;
  backData: any;
  layData: any;
  pageSize: number;
  expoCurrentData: any;
  showBet: any = 0;
  public showPassword: boolean;
  isRefSpinerShow: boolean = false;
  public token = localStorage.getItem('userAccessToken');
  payDetails: any;
  paymentMethodId: any;
  selectData: any;
  payMethodList: any;
  amount: any;
  adminHeading: string;
  allData: any;
  adminData: any;
  allSeriesList: any;
  matchList: any[] = [];
  sportData: any;
  iUrl: any;
  path: any;
  public showOldPassword: boolean = false;
  public showNewPassword: boolean = false;
  public showConfirmPassword: boolean = false;
  agentData: any;
  domain:any;
  paymentData:any;
  agentHeading: string;
  agentHead: any = [];
  objectId: any;
  displayTooltip: boolean;
  contenCopied = false;
  reference_no:any="";
  payLength: any;
  openStartDate: Date = new Date()
  openEndDate: Date = new Date()
  todayDate: Date = new Date()
  todayDateTime: Date = new Date()
  depositpayment_Method:any=[];
  startdateTime = new Date();
  enddateTime = new Date();
  constructor(public clipboardService: ClipboardService, private sportService: SportService, private router: Router, private cookie: CookieService, private ref: ChangeDetectorRef, private route: ActivatedRoute,
    private loginService: LoginService, private fb: FormBuilder, private usersService: UsersService, private reportService: ReportService,
    private socketService: SocketServiceService, private toastr: ToastrService, private modalService: BsModalService,) {
      this.todayDateTime.setHours(23, 59, 59);
      this.startdateTime.setHours(0, 0, 0, 0);
      this.enddateTime.setHours(23, 59, 59);
      this.route.params.subscribe(params => {
      let a = this.router.url;
      let b = a.split('/')
      if (b.length <= 2) {
        this.path = b[1]
      } else {
        this.path = b[2]
      }
    })
    this.isRefSpinerShow = false;
  }

  async ngOnInit() {
    this.logo = localStorage.getItem('logo');
    this.domain = window.location.hostname
    this.getNews();
    this.getAllSportsListData()
    this.getUserBalance()
    this.usersService.returnUserBalance().subscribe
      (data => { //message contains the data sent from service
        this.walletBalance = data;
      });
    await this.socketService.setUpSocketConnection();
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
   
    this.createChangePasswordFrom();
    this.logoutListenUser();
    this.isSocket = this.cookie.get('is_socket');
    if (this.isSocket == null || this.isSocket == undefined || this.isSocket == '') {
      this.getGlobalSettings();
    }
    // localStorage.openpages = Date.now();
    // let message = "Close current tab and Go back to Previous tab and refresh ....";
    // var onLocalStorageEvent = function (e) {
    //   if (e.key == "openpages") {
    //     localStorage.page_available = Date.now();
    //   }
    //   if (e.key == "page_available") {
    //     Swal.fire({
    //       title: 'Restricted',
    //       text: message,
    //       icon: 'warning',
    //       showCancelButton: false,
    //       showConfirmButton: false,
    //       allowOutsideClick: false,
    //       allowEscapeKey: false,
    //     }).then((result) => {
    //     })
    //   }
    // };
    // window.addEventListener('storage', onLocalStorageEvent, false);
    this.ref.detectChanges()
  }
  socketOnEvent(eventName, callback) {
    // this.socketService.socket.on(eventName, data => callback(data));
  }

  openMobileSidebarStatus: boolean = false;
  openMobileSidebar() {
    this.openMobileSidebarStatus = !this.openMobileSidebarStatus;
  }
  getNews() {
    let data = {};
    this.reportService.getNews(data).subscribe((res) => {
      if (res.status) {
        if (res.data == null) {
          this.adminHeading = '';
        } else {
          this.allData = res.data;
          this.adminData = this.allData.filter(t => t.user_type_id == 0);
          if (this.adminData.length != 0) {
            this.adminHeading = this.adminData[0].heading;
          }
        }
        this.agentData = this.allData.filter(t => t.user_type_id != 0);
        if (this.agentData.length != 0) {
          this.agentHeading = '';
          for (let i = 0; i < this.agentData.length; i++) {
            this.agentHead.push(this.agentData[i].heading);
          }
          this.agentHeading = this.agentHead.join(' | ');
        }
      }
    })
  }
  getAllSportsListData() {
    let userData = {};
    this.sportService.getAllSportsList(userData).subscribe(res => {
      this.allSportsList = res.data
      this.allSportsList = (this.allSportsList).sort(function (a, b) { return b.sport_id - a.sport_id });
      this.ref.detectChanges();
    })
  }
  getSeriesList(id) {
    this.sportData = id;
    let data = {
      sport_id: id
    };
    this.sportService.getSeriesList(data).subscribe(res => {
      this.allSeriesList = res.data
    })
  }
  getMatchList(data, index) {
    let userData = {
      series_id: data.series_id
    };
    this.sportService.getHomeMatchesList(userData).subscribe(res => {
      if (res.status) {
        this.matchList[index] = res.data;
      } else {
        this.toastr.error(res.msg, '', {
          timeOut: 10000,
        });
      }
    }, (err) => {
      console.log(err);
    })
  }
  socketEmitEvent(eventName, data) {
    // this.socketService.socket.emit(eventName, data);
  }
  goToDashboard(sportName, id, data) {
    if (id == -100) {
      this.router.navigate(['casino']);
    } else if (id < -100) {
      this.router.navigate(['snova/' + id + '/' + data.name + '/' + data.providerCode])
    } else if (id > -100) {
      this.router.navigate(['dashboard/' + sportName]);
    }
  }
  goToAll(sportName) {
    this.router.navigate(['dashboard/' + sportName]);
  }
  stackSetting() {
    this.status = !this.status;
  }

  logoutListenUser() {
    this.logoutUserFromAllDevices()
    this.socketOnEvent(`logoutUserFromAllDevices${this.userDetails._id}`, data => {
      if (data.status == true && data.caller_identifier != this.token) {
        this.cookie.delete('userId');
        this.cookie.delete('is_socket');
        this.loginService.clearLocalStorage()
        this.router.navigate(['open-dashboard'])
        window.location.reload();
        window.location.replace('open-dashboard');
      } else {
        this.toastr.error(data.msg, '', {
          timeOut: 10000,
        });
      }
    });

  }
  logoutUserFromAllDevices() {
    this.socketEmitEvent('logoutUserFromAllDevices', { user_id: this.userDetails._id, caller_identifier: this.cookie.get('accessToken') });
  }
  status: boolean = false;
  sideNavEvent() {
    this.status = !this.status;
  }
  dashboard(data) {
    if (data == 1) {
      this.router.navigate(['dashboard'])
    } else if (data == 2) {
      this.router.navigate(['dashboard/inplay'])
    } else if (data == 3) {
      this.router.navigate(['promo'])
    } else if (data == 4) {
      this.router.navigate(['dashboard/Casino'])
    } else if (data == 5) {
      this.aviator()
    }
  }
  aviator() {
    let data = {
      gameId: 'SPB-aviator',
      providerCode: "SPB"
    }
    this.sportService.qtechLaunch(data).subscribe((res) => {
      if (res.status) {
        this.iUrl = res.data.url;
        window.open(this.iUrl, "_blank")
      } else {
        this.toastr.error(res.msg)
      }
    })
  }
  statement() {
    this.router.navigate(['statement'])
  }
  profile() {
    this.router.navigate(['profile'])
  }
  bet() {
    this.router.navigate(['open-bet'])
  }
  sbet() {
    this.router.navigate(['settle-bet'])
  }
  withdraw() {
    this.router.navigate(['withdraw'])
  }
  transaction() {
    this.router.navigate(['transaction'])
  }
  profitLoss() {
    this.router.navigate(['eventpl'])
  }
  openModalChangePwd(changePwd: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      changePwd,
      Object.assign({}, { class: 'passwordModal', ignoreBackdropClick: false }),
    );
  }
  openModalChangeStack(stackValue: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      stackValue,
      Object.assign({}, { class: 'chipsModal displayPop' })
    );
  }
  openModalopenBets(openBets: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      openBets,
      Object.assign({}, { class: 'openBetsModal modal-dialog modal-dialog-centered modal-fullscreen' })
    );
  }

  createChangePasswordFrom() {
    this.changePasswordForm = this.fb.group({

      old_password: ['', [Validators.required]],
      new_password: ['', [Validators.required]],
      confirm_password: ['', [Validators.required]]

    })
  }

  get f() { return this.changePasswordForm.controls; }

  onSubmitChangePassword() {
    this.submitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }
    let data = {
      "newPassword": this.changePasswordForm.value.new_password
    }
    if (this.changePasswordForm.controls['new_password'].value == this.changePasswordForm.controls['confirm_password'].value) {
      this.usersService.changePassword(data).subscribe((result) => {
        if (result.status == true) {
          localStorage.setItem('userDetails', JSON.stringify(this.userDetails));
          this.submitted = false;
          this.toastr.success(result.msg, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          });
          this.modalRef.hide();
          this.logoutUser();
        } else {
          if (result.logout == true) {
            this.cookie.delete('userId');
            this.cookie.delete('accessToken');
            this.cookie.delete('refreshToken');
            localStorage.clear();
            this.loginService.clearLocalStorage()
            this.router.navigate(['open-dashboard']);
            window.location.reload();
            window.location.replace('open-dashboard');
          } else {
            this.toastr.error(result.msg, '', {
              timeOut: 10000,
            });
          }
        }
      }, (err) => {
        console.log(err);
      });
    }
    else {
      this.toastr.error("Password and confirm password did not match", '', {
        timeOut: 10000,
      });
    }
  }

  logoutUser() {
    let data = {};
    this.loginService.logoutUser(data).subscribe(result => {
      if (result.status == true) {
        this.toastr.success(result.msg, '', {
          positionClass: 'toast-bottom-right',
          timeOut: 1000
        })
        this.cookie.delete('userId');
        this.cookie.delete('accessToken');
        this.cookie.delete('refreshToken');
        localStorage.clear();
        this.loginService.clearLocalStorage()
        this.router.navigate(['open-dashboard']);
        window.location.reload();
        window.location.replace('open-dashboard');
      } else {
        this.cookie.delete('accessToken');
        this.cookie.delete('refreshToken');
        localStorage.clear();
        this.cookie.delete('userId');
        this.loginService.clearLocalStorage()
        this.router.navigate(['open-dashboard']);
        window.location.reload();
        window.location.replace('open-dashboard');
      }
    })


  }

  getUserBalance() {
    // this.isRefSpinerShow = true;
    clearTimeout(this.timer)
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    let data = {};
    this.sportService.getUserBalance(data).subscribe(response => {
      // this.isRefSpinerShow = false;
      if (response.status == true) {
        this.isRefSpinerShow = false;
        this.walletBalance = response.data;
        this.usersService.userBalance = response.data;
        this.currentExpo = (this.walletBalance.liability) * (this.userDetails.point);
        this.currentBalance = (this.walletBalance.balance) * (this.userDetails.point)
        this.ref.detectChanges();
      } else {
        this.toastr.error(response.msg, '', {
          timeOut: 10000,
        });
        if (response.logout == true) {
          this.logoutUser()
        }
        this.isRefSpinerShow = false;
        this.ref.detectChanges();
      }
    }, (err) => {
    },
      () => {
        if (this.router.url.split('?')[0] != '/login') {
          this.timer = setTimeout(() => resolve(this.getUserBalance()), 10000);
        }
      })
  }
  getUserMatchStackDetails() {
    this.sportService.getUserMatchStack(this.userDetails._id).subscribe(response => {
      if (response.status == true) {
        this.userMatchStack = response.data
        this.match_stack = this.userMatchStack.match_stack
      } else {
        this.toastr.error(response.msg, '', {
          timeOut: 10000,
        });
      }

    })
  }

  updateUserMatchStack() {
    let stackRequest = {
      "match_stack": this.match_stack
    }
    this.sportService.updateUserMatchStack(stackRequest).subscribe(result => {
      if (result.status == true) {
        this.submitted = false;
        this.toastr.success(result.msg, '', {
          positionClass: 'toast-bottom-right',
          timeOut: 1000
        });
        this.modalRef.hide();
      } else {
        this.toastr.error(result.msg, '', {
          timeOut: 10000,
        });
      }
    })
  }

  getGlobalSettings() {
    this.sportService.getGlobalSettings().subscribe(response => {
      if (response.status == true) {
        this.cookie.delete('is_socket');
        this.cookie.set('is_socket', response.data.is_socket);
      }
    }, error => {
      console.log(error)
    })

  }
  openModalDeposit(depositModal: TemplateRef<any>) {
    this.depositMethod();
    this.modalRef = this.modalService.show(
      depositModal,
      Object.assign({}, { class: 'manual-deposit', ignoreBackdropClick: false }),
    );
  }

 

  homematches() {
    this.sportService.getHomeMatchesList(this.userData).subscribe(res => {
      if (res.status) {
        this.homeData = res.data;
      } else {
        this.toastr.error(res.msg, '', {
          timeOut: 10000,
        });
      }
    }, (err) => {
      console.log(err);
    })
  }
  goToDetail(data) {
    let param = {
      match_id: data.match_id
    }
    this.sportService.getHomeMatchesList(param).subscribe(res => {
      if (res.status) {
        if ((res.data).length != 0) {
          this.expoCurrentData = res.data[0];
          localStorage.setItem('matchData', JSON.stringify(this.expoCurrentData));
          this.modalRef.hide();
          if (this.router.url.split('?')[0] == '/match-detail') {
            window.location.reload();
          } else {
            this.router.navigate(['match-detail']);
          }
        } else {
          this.toastr.error("Results are not announced yet...")
        }

      } else {
        this.toastr.error(res.msg, '', {
          timeOut: 10000,
        });
      }
    }, (err) => {
      console.log(err);
    })
  }
  searchFilter(matchData) {
    localStorage.setItem('matchData', JSON.stringify(matchData));
    this.router.navigate(['match-detail']);
  }

  matchDetail(matchData) {
    localStorage.setItem('matchData', JSON.stringify(matchData));
    if (this.router.url.split('?')[0] == '/match-detail') {
      window.location.reload();
    } else {
      this.router.navigate(['match-detail']);
    }

  }
  trackByFn(index: any, item: any) {
    return index;
  }


  expoRoute(id) {
    let data = {
      "event": "match",
      "filter": {
        "match_id": id.match_id
      },
      "projection": [
        "enable_fancy",
        "inplay",
        "is_created",
        "is_lock",
        "is_active",
        "match_date",
        "bet_count"
      ]
    }
    this.sportService.getExposureRoute(data).subscribe((res) => {
      this.expoRouteData = res.data;
      this.expoRouteData.manualInplay = res.data.inplay;
      let a3 = { ...id, ...this.expoRouteData };
      localStorage.setItem('matchData', JSON.stringify(a3));
      this.router.navigate(['match-detail']);
      this.modalRef.hide();
    })
  }
  getSportList() {
    let data = {
      type: 'openBets',
      search: {}
    };
    this.reportService.eventList(data).subscribe((res) => {
      if (res.status == true) {
        this.MatchList = res.data[0].matches;
        this.betList = false;
      } else {
        this.toastr.error(res.msg);
      }
    })
  }
  openBet(params) {
    this.pageSize = 30
    let data = {
      page: 1,
      limit: this.pageSize,
      search: {
        "match_id": params.match_id,
        "delete_status": 0
      }
    }
    this.reportService.openBets(data).subscribe((res) => {
      if (res.status) {
        if (res.data[0].data) {
          this.betList = true
          this.dataSource = res.data[0].data;
          this.openBetsLength = res.data[0].data.length;
          this.backData = this.dataSource.filter(t => t.is_back == 1);
          this.layData = this.dataSource.filter(t => t.is_back == 0);
        } else {
          this.openBetsLength = 0;
        }
      } else {
        this.toastr.error(res.msg);
        this.openBetsLength = 0;
      }
    })
  }
  showBetHistory(data) {
    if (data == 1) {
      this.showBet = 1;
      this.ref.detectChanges();
    } else {
      this.showBet = 0;
      this.ref.detectChanges();
    }
  }
  depositMethod() {
    let params={
      parent_id:this.userDetails?.parent_id,
      user_id:this.userDetails?._id,
    }
    this.sportService.depositMethod(params).subscribe(res => {
      if (res.status) {
        this.payMethodList = res.data;
        if(this.payMethodList != undefined){
          this.detail(this.payMethodList[0])
      } else {
        this.toastr.error(res.msg, '', {
          timeOut: 10000,
        });
      }
    }
    }, (err) => {
      console.log(err);
    })
    
  }

  validateMinMax(event:any){
    if(event.target.value<this.paymentData?.min_amount){
      this.message="min amount "+this.paymentData?.min_amount;
    }
    else if(event.target.value>this.paymentData?.max_amount){
      this.message="max amount "+this.paymentData?.max_amount;
    }
    else{
      this.message="";
    }
    
  }
  detail(list) {
    this.selectData = list.name;
    this.paymentMethodId = list._id;
    this.paymentData=list;
    this.imageQr=list.image;
    this.payDetails=list.payment_details;
    
    this.ref.detectChanges();
  }
  file: File = null;
  imageSrc: any;
  processFile(imageInput: any) {
    this.file = imageInput.files[0];
    let a = imageInput.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => this.imageSrc = reader.result;
    reader.readAsDataURL(a);
  }

  getTodayStatement()
  {
    let params={
      // page: (dataTablesParameters1.start / dataTablesParameters1.length) + 1,
      "user_id": this.userDetails._id,
      //"type": this.searchType == 0 ? '' : this.searchType,
      "from_date": this.startdateTime.toISOString(),
      "to_date": this.enddateTime.toISOString()
    }
    this.sportService.statementDDetail(params).subscribe((res) => {
      if (res.status) {
        this.statement_data=res.data;
       // this.toastr.success(res.msg);
        
      } else {
        //this.toastr.error(res.msg)
      }
    })
  }

  paymentDeposit() {
    if ((this.payDetails)?.length != 0 || this.payDetails != undefined) {
      if (this.paymentMethodId != undefined) {
       
        if(this.reference_no.length<9){
          this.toastr.error('Reference No is required min 10 charcter .')
        }
        else if(this.paymentData?.min_amount>this.amount){
          this.toastr.error('min amount is required' +this.paymentData?.min_amount)
        }
        else if(this.paymentData?.max_amount<this.amount){
          this.toastr.error('max amount is required' +this.paymentData?.max_amount)
        }
       else if (this.amount >= 100) {
          const formData: FormData = new FormData();
          
          formData.append('user_id',  this.userDetails?._id);
          formData.append('user_name',  this.userDetails?.user_name);
          formData.append('method_id',  this.paymentMethodId);
          formData.append('amount',  this.amount);
          formData.append('reference_no',  this.reference_no);
          formData.append('promotion_id',  "-1");
          formData.append('image',  this.file);

          this.sportService.paymentDeposit(formData).subscribe((res) => {
            if (res.status) {
              this.toastr.success(res.msg);
              this.file = undefined;
              this.ref.detectChanges();
              this.modalRef.hide();
            } else {
              this.toastr.error(res.msg)
            }
          })
        } else {
          this.toastr.error('Minimum deposit amount is 100 .')
        }
      } else {
        this.toastr.error('Plz select any payment mode...')
      }
    } else {
      this.toastr.error('Plz select any Payment method')
    }
  }
  close() {
    this.file = undefined;
    this.imageSrc = '';
    this.ref.detectChanges()
  }
  openModalexposureModal(exposureModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      exposureModal,
      Object.assign({}, { class: 'modal-large change-password exposure-list' })
    );
    let data = {};
    this.sportService.getExposure(data).subscribe((res) => {
      if (res.status == true) {
        this.popData = true;
        this.exposureData = res.data;
        this.expoLength = this.exposureData.length;
        this.expo_User_name = res.user_name;
      } else {
        this.message = "No Record Found ....";
        this.popData = false;
      }
    })
  }
  openModalStackModal(stackModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      stackModal,
      Object.assign({}, { class: ' stack-edit', ignoreBackdropClick: false }),
    );
  }
  openModalChangePass(changePwd: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      changePwd,
      Object.assign({}, { class: 'modal-fixed-footer change-password', ignoreBackdropClick: false }),
    );
  }
  
  copyContent(text) {
    this.objectId = text;
    this.toastr.success('Content Copied')
    this.clipboardService.copyFromContent(text)
  }
  contentCopied(e) {
    this.displayTooltip = true;
    this.contenCopied = e.isSuccess;
  }
  closetooltipDiv() {
    this.displayTooltip = false;
  }
  isToggleExp: boolean = false;
  toggleExpDropdown() {
    this.isToggleExp = !this.isToggleExp;
  }
  
  changeThemeMode(){
    const bodyElement = document.body;
    if (bodyElement) {
      bodyElement.classList.toggle('light_mode_on');
    }
  }

  
}
