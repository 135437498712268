import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UsersService {
  public Base_Url = environment['adminServerUrl'];
  userBalance: any;
  public token = localStorage.getItem('userAccessToken');
  private userBalanceSubjectName = new Subject<any>();
  constructor(private http: HttpClient) { }

  reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ` + this.token
  });

  changePassword(data) {
    return this.http.post<any>(this.Base_Url + 'user/updatePassword/', data, { headers: this.reqHeader });
  }
  profile() {
    return this.http.get<any>(this.Base_Url + 'user/myProfile', { headers: this.reqHeader });
  }
  returnUserBalance() {
    return this.userBalanceSubjectName.asObservable();
  }
  updateUserBalanceSubject(data) {
    this.userBalanceSubjectName.next(data);
  }
  checkUserName(user_name) {
    return this.http.post<any>(this.Base_Url + 'user/checkUserName', user_name, { headers: this.reqHeader });
  }
  addUser(userForm) {
    return this.http.post<any>(this.Base_Url + 'user/register', userForm, { headers: this.reqHeader });
  }
}
