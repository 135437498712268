import { Component, AfterViewInit, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DateTime } from '../../dateTime';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { ReportService } from '../services/report.service';
import { HeaderComponent } from '../header/header.component';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-my-bets',
  templateUrl: './my-bets.component.html',
  styleUrls: ['./my-bets.component.scss'],
  providers: [HeaderComponent],
})
export class MyBetsComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('popoverRef') private _popoverRef: PopoverDirective;
  @ViewChild('startpopoverRef') private _startpopoverRef: PopoverDirective;
  @ViewChild('endpopoverRef') private _endpopoverRef: PopoverDirective;
  moment: any = moment;
  time: Date;
  date: Date;
  isDateVisible: boolean = true;
  isMeridian: boolean = false;
  dateTime = new Date();
  statementStartDate = new Date()
  statementEndDate = new Date();
  timeStatement: Date;
  dateStatement: Date;
  endDateStatement: Date;
  endTimeStatement: Date;
  endTime: any;
  public Base_Url = environment['adminServerUrl'];
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  public token = localStorage.getItem('userAccessToken');
  reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ` + this.token
  });
  betList: any[];
  type: any;
  filterParam: any = [];
  openBetFilter: boolean = false;
  MarketList: any;
  itemsPerPage: number = 10;
  currentPage: number = 1;
  totalItems: number = 0;
  betType: any = "2";
  param: any;
  SportList: any;
  SeriesList: any;
  MatchList: any;
  initialSportList: any;
  searchMatch: any
  searchUser: any
  searchMarket: any
  searchSport: any
  searchSeries: any
  constructor(private http: HttpClient, private head: HeaderComponent, private reportService: ReportService, private toastr: ToastrService) { }
  ngOnInit(): void {
    this.statementStartDate.setHours(0, 0, 0, 0);
    this.statementEndDate.setHours(23, 59, 59);
    this.getSportList()
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      paging: true,
      serverSide: true,
      processing: false,
      ordering: false,
      destroy: true,
      lengthMenu: [10, 15, 20, 30],
      ajax: (dataTablesParameters1: any, callback) => {
        let dataTablesParameters = {
          limit: dataTablesParameters1.length,
          page: (dataTablesParameters1.start / dataTablesParameters1.length) + 1,
          from_date: this.statementStartDate.toISOString(),
          to_date: this.statementEndDate.toISOString(),
          search: this.param == undefined ? '' : this.param.search
        }
        if (dataTablesParameters.search == undefined) {
          delete dataTablesParameters.search
        }
        that.http.post<DataTablesResponse>(this.Base_Url + 'bet/openBets', dataTablesParameters, { headers: this.reqHeader })
          .subscribe(resp => {
            if (resp["status"]) {
              if ((resp.data[0].data).length != 0) {
                this.betList = resp.data[0].data;
                for (let i = 0; i < this.betList.length; i++) {
                  this.betList[i].index = (dataTablesParameters1.start) + 1 + i;
                  this.betList[i].updateDateTime = moment(this.betList[i].createdAt).utcOffset("+5:30").format('DD-MM-YYYY HH:mm:ss');
                  if (this.betList[i].is_fancy == 0) {
                    this.betList[i].updateSelection = this.betList[i].selection_name;
                    this.betList[i].updateOdds = this.betList[i].odds;
                    this.betList[i].updatePl = this.betList[i].p_l + '/' + this.betList[i].liability
                    if (this.betList[i].is_back == 1) {
                      this.betList[i].updateType = 'Back'
                    } else {
                      this.betList[i].updateType = 'Lay'
                    }
                  } else {
                    this.betList[i].updateSelection = this.betList[i].odds;
                    this.betList[i].updateOdds = this.betList[i].odds + '/' + this.betList[i].size;
                    this.betList[i].updatePl = this.betList[i].chips + '/' + this.betList[i].liability
                    if (this.betList[i].is_back == 1) {
                      this.betList[i].updateType = 'Yes'
                    } else {
                      this.betList[i].updateType = 'No'
                    }
                  }
                }
                callback({
                  recordsTotal: resp.data[0].metadata == undefined ? 0 : resp.data[0].metadata[0].total,
                  recordsFiltered: resp.data[0].metadata == undefined ? 0 : resp.data[0].metadata[0].total,
                  data: this.betList
                });
              } else {
                this.betList = []
                callback({
                  recordsTotal: 0,
                  recordsFiltered: 0,
                  data: this.betList
                });
              }
            } else {
              this.toastr.error(resp["msg"]);
              this.betList = []
              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: this.betList
              });
            }
          });
      },
      columns: [
        {
          title: 'S. No.',
          data: 'index'
        },
        {
          title: 'Sport Name',
          data: 'sport_name',
        },
        {
          title: 'Event Name',
          data: 'match_name',
        },
        {
          title: 'Market Name',
          data: 'market_name',
        },
        {
          title: 'Selection',
          data: 'updateSelection',
        },
        {
          title: 'Bet Type',
          data: 'updateType',
        },
        {
          title: 'User Price',
          data: 'updateOdds',
        },
        {
          title: 'Amount',
          data: 'stack',
        },
        {
          title: 'Profit/Loss',
          data: 'liability',
          "render": function (data, type, row) {
            var content = ''
            if (row.is_fancy == 0) {
              if (row.p_l != null && row.p_l < 0) {
                content = content + '<span class="text-center text-danger">' + (row.p_l).toFixed(2) + '</span>/ <span class="text-center text-danger">' + row.liability + ' </span>';
              } else if (row.p_l != null && row.p_l >= 0) {
                content = content + '<span class="text-center text-success">' + (row.p_l).toFixed(2) + '</span> / <span class="text-center text-danger">' + row.liability + ' </span>';
              } else {
                content = '';
              }
            } else {
              if (row.chips != null && row.chips < 0) {
                content = content + '<span class="text-center text-danger">' + (row.chips).toFixed(2) + '</span>/ <span class="text-center text-danger">' + row.liability + ' </span>';
              } else if (row.chips != null && row.chips >= 0) {
                content = content + '<span class="text-center text-success">' + (row.chips).toFixed(2) + '</span> / <span class="text-center text-danger">' + row.liability + ' </span>';
              } else {
                content = '';
              }
            }
            return content;
          }
        },
        {
          title: 'Placed Time',
          data: 'updateDateTime',
        },
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        $(row).addClass('text-center');
        return row;
      }
    };

    if (this.dateTime) {
      this.time = this.date = this.dateTime;
      return;
    }
    this.date = this.time = new Date(); if (this.statementStartDate) {
      this.time = this.date = this.statementStartDate;
      return;
    }
    if (this.statementEndDate) {
      this.time = this.date = this.statementEndDate;
      return;
    }
  }
  dateSelectionDone() {
    this.isDateVisible = false;
  }
  updateDateStatement() {
    if (this.dateStatement) {
      this.statementStartDate = DateTime.getDateTime(this.dateStatement, this.timeStatement);
    }
    if (!this.timeStatement) {
      this.timeStatement = this.dateStatement;
    }
  }
  updateEndDateStatement() {
    if (this.endDateStatement) {
      this.statementEndDate = DateTime.getDateTime(this.endDateStatement, this.endTimeStatement);
    }
    if (!this.endTimeStatement) {
      this.endTimeStatement = this.endDateStatement;
    }
  }
  updateTimeStatement() {
    if (this.timeStatement) {
      this.statementStartDate = DateTime.getDateTime(this.dateStatement, this.timeStatement);
    }
  }
  updateEndTimeStatement() {
    if (this.endTime) {
      this.statementEndDate = DateTime.getDateTime(this.endDateStatement, this.endTimeStatement);
    }
  }
  updateDate() {
    if (this.date) {
      this.dateTime = DateTime.getDateTime(this.date, this.time);
    }
    if (!this.time) {
      this.time = this.date;
    }
  }

  updateTime() {
    if (this.time) {
      this.dateTime = DateTime.getDateTime(this.date, this.time);
    }
  }

  showDate() {
    this.isDateVisible = true;
  }

  showTime() {
    this.isDateVisible = false;
  }

  close() {
    this._popoverRef.hide();
  }

  now() {
    this.dateTime = DateTime.now(this.date);
    this.time = this.dateTime;
  }

  today() {
    this.statementStartDate = DateTime.now(this.date);
    this.statementEndDate = DateTime.now(this.date);
  }
  clear() {
    this.time = void 0;
    this.date = void 0;
    this.dateTime = void 0;
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next("");
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next("");
    });
  }
  getOpenBetFilterData(type, id?: any, name?: any, from?: string, date?: string) {
    this.filterParam = [type, id, name];
    if (from == '1') {
      if (type == 0) {
        this.param = {
          limit: this.itemsPerPage,
          page: 1,
          "search": {
            "sport_id": id
          }
        }
        this.betType = undefined
        this.searchMatch = undefined
        this.searchMarket = undefined
        this.searchSeries = undefined
      } else if (type == 1) {
        this.param = {
          limit: this.itemsPerPage,
          page: 1,
          "search": {
            "series_id": id,
          }
        }
        this.betType = undefined
        this.searchMatch = undefined
        this.searchMarket = undefined
        if (this.searchSport) {
          this.param.search['sport_id'] = this.searchSport.sport_id
        }
      } else if (type == 2) {
        this.param = {
          limit: this.itemsPerPage,
          page: 1,
          "search": {
            "match_id": id
          }
        }
        this.betType = undefined
        this.searchMarket = undefined
        if (this.searchSport) {
          this.param.search['sport_id'] = this.searchSport.sport_id
        }
        if (this.searchSeries) {
          this.param.search['series_id'] = this.searchSeries.series_id
        }
      } else if (type == 3) {
        this.param = {
          limit: this.itemsPerPage,
          page: 1,
          "search": {
            "market_id": id,
          }
        }
        this.betType = undefined
        if (this.searchSport) {
          this.param.search['sport_id'] = this.searchSport.sport_id
        }
        if (this.searchSeries) {
          this.param.search['series_id'] = this.searchSeries.series_id
        }
        if (this.searchMatch) {
          this.param.search['match_id'] = this.searchMatch.match_id
        }
      } else if (type == 4) {

        this.param = {
          limit: this.itemsPerPage,
          page: 1,
          "search": {
            "fancy_id": id,
          }
        }
        this.betType = undefined
        if (this.searchSport) {
          this.param.search['sport_id'] = this.searchSport.sport_id
        }
        if (this.searchSeries) {
          this.param.search['series_id'] = this.searchSeries.series_id
        }
        if (this.searchMatch) {
          this.param.search['match_id'] = this.searchMatch.match_id
        }
      }
    } else {
      if (type == 0) {
        this.param = {
          limit: this.itemsPerPage,
          page: this.currentPage,
          "search": {
            "sport_id": id
          }
        }
        this.betType = undefined
        this.searchMatch = undefined
        this.searchMarket = undefined
        this.searchSeries = undefined
      } else if (type == 1) {
        this.param = {
          limit: this.itemsPerPage,
          page: this.currentPage,
          "search": {
            "series_id": id,
          }
        }
        this.betType = undefined
        this.searchMatch = undefined
        this.searchMarket = undefined
        if (this.searchSport) {
          this.param.search['sport_id'] = this.searchSport.sport_id
        }
      } else if (type == 2) {
        this.param = {
          limit: this.itemsPerPage,
          page: this.currentPage,
          "search": {
            "match_id": id
          }
        }
        this.betType = undefined
        this.searchMarket = undefined
        if (this.searchSport) {
          this.param.search['sport_id'] = this.searchSport.sport_id
        }
        if (this.searchSeries) {
          this.param.search['series_id'] = this.searchSeries.series_id
        }
      } else if (type == 3) {
        this.param = {
          limit: this.itemsPerPage,
          page: this.currentPage,
          "search": {
            "market_id": id,
          }
        }
        this.betType = undefined
        if (this.searchSport) {
          this.param.search['sport_id'] = this.searchSport.sport_id
        }
        if (this.searchSeries) {
          this.param.search['series_id'] = this.searchSeries.series_id
        }
        if (this.searchMatch) {
          this.param.search['match_id'] = this.searchMatch.match_id
        }
      } else if (type == 4) {

        this.param = {
          limit: this.itemsPerPage,
          page: this.currentPage,
          "search": {
            "fancy_id": id,
          }
        }
        this.betType = undefined
        if (this.searchSport) {
          this.param.search['sport_id'] = this.searchSport.sport_id
        }
        if (this.searchSeries) {
          this.param.search['series_id'] = this.searchSeries.series_id
        }
        if (this.searchMatch) {
          this.param.search['match_id'] = this.searchMatch.match_id
        }
      }
    }
    if (this.betType != 'all') {
      if (type == 'betType') {
        this.param = {
          "search": {
            "is_back": parseInt(this.betType),
          }
        }
        if (name == 'filterBtnClick') {
          if (this.statementStartDate) {
            this.param["from_date"] = this.statementStartDate.toISOString()
          }
          if (this.statementEndDate) {
            this.param["to_date"] = this.statementEndDate.toISOString()
          }
        }
      }
    } else {
      delete this.param.search.is_back
    }
    if (this.param.search.is_back == 2) {
      delete this.param.search.is_back
    }
    if (date == 'filterBtnClick') {
      if (this.statementStartDate) {
        this.param["from_date"] = this.statementStartDate.toISOString()
      }
      if (this.statementEndDate) {
        this.param["to_date"] = this.statementEndDate.toISOString()
      }
    }
    this.filterSearchDropdownValues(this.param.search, type)
    this.rerender();
  }

  filterSearchDropdownValues(search, type) {
    let listParams = {
      type: "openBets",
    }
    this.reportService.eventList(listParams).subscribe((res) => {
      if (res.status == true) {
        if (type == 0) {
          this.SeriesList = res.data[0].series;
          this.MatchList = res.data[0].matches;
          this.MarketList = res.data[0].events_m_f;
        } else if (type == 1) {
          this.MatchList = res.data[0].matches;
          this.MarketList = res.data[0].events_m_f;
        } else if (type == 2) {
          this.MarketList = res.data[0].events_m_f;
        }
      } else {
        if (res.logout == true) {
          this.head.logoutUser();
        } else {
          this.toastr.error(res.msg)
        }
      }
    })
  }
  getSportList() {
    let data = {
      type: "openBets",
      search: {}
    };
    this.reportService.eventList(data).subscribe((res) => {
      if (res.status == true) {
        this.initialSportList = res.data[0]
        this.SportList = res.data[0].sports;
        this.SeriesList = res.data[0].series;
        this.MatchList = res.data[0].matches;
        this.MarketList = res.data[0].events_m_f;
      } else {
        if (res.logout == true) {
          this.head.logoutUser();
        } else {
          this.toastr.error(res.msg)
        }
      }
    })
  }
}
