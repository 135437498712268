import { Component, OnInit, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { OpenApiService } from '../services/open-api.service';
import { Match } from '../model/match';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../services/login.service'
import { ToastrService } from 'ngx-toastr';
import { SafeResourceUrl } from '@angular/platform-browser';
import { interval } from 'rxjs';
import { resolve } from 'q';
import { AppValidationService } from '../app-validation/app-validation.service';
@Component({
  selector: 'app-open-match-detail',
  templateUrl: './open-match-detail.component.html',
  styleUrls: ['./open-match-detail.component.scss']
})
export class OpenMatchDetailComponent implements OnInit {
  Form: FormGroup;
  loginButtonDisable = false
  submitted = false;
  transactionPassword: string;
  showResponseMessage;
  public showPassword: boolean;
  private clickTimeout = null;
  a: any;
  modalRef: BsModalRef;
  matchData: any;
  matchDetail: any;
  activeBetTab: string = 'all';
  betslipinfo: boolean;
  betPrice: any;
  arrayObj: any = [];
  public IsToggle = false;
  private oddBetSlipValArray: any = [];
  public oneClickSelectedBet;
  betslipStatus: boolean = false;
  selection: any;
  market: any;
  betSize: any;
  fancyIdValidation: any;
  stakeIds: any;
  stackval: any;
  isActive: boolean;
  total_liability: any;
  config_max_odd_limit: any;
  setRef: any;
  fancy: any = [];
  showFancyList: boolean;
  userMatchStack: any;
  matchDetailData: any;
  match_id: any;
  loading: boolean;
  popFancyId: any;
  popShow: boolean;
  logo: string;
  selectedBackBet: any
  selectedLayBet: any
  selectedLayBetDetails: any;
  selectedBackBetDetails: any;
  betType: any;
  activeScoreTab: string = 'scoreBoard';
  activeScoreDiv: string = 'scoreBoardDiv';
  fancytimer: any;
  matchDetailLength: number;
  userDetails: any;
  user_id: any;
  betData: any;
  matchedBets: any;
  fancyBets: any;
  liveTv: string;
  graphicTv: string;
  liveUrl: SafeResourceUrl;
  graphicTvUrl: SafeResourceUrl;
  matchName: any;
  matchDate: any;
  tv: boolean = false;
  graph: boolean = false;
  scoreData: any;
  scoreBoard: any;
  scoreLength: any;
  score: boolean;
  perball: any;
  callingFancyType = 1
  matchLength: any;
  teamPositionData: any;
  selectedAllBetFilter: any;
  selectedPopAllBetFilter: any;
  selectedFancyBetFilter: any;
  selectedMatchBetFilter: any
  allBetData: any
  graphicScore: boolean;
  allPopBetData: any;
  selectedMarket: any;
  matchedBetsLength: any;
  fancyBetsLength: any;
  allBetDataLength: any;
  disablePlaceBet: boolean = false;
  disableFancyPlaceBet: boolean = false;
  fancyLoading: boolean;
  fancyPosData: any;
  displayfancyPositionBox: Array<boolean> = []
  selectedFancyMarket: any;
  applyUserValidation: boolean;
  fancyLiability: any;
  twenty: boolean;
  param: any;
  marketIds: any = [];
  marketRunnerData: any;
  matchDetailFirst: any;
  runnerObjectData: any = [];
  objectData: any;
  marketObjectData: any;
  sampleObjectData: any;
  sportsSettingValues: any;
  showSetting: boolean;
  sessionSetting: any;
  fancySetting: boolean;
  check_event_limit: any;
  fancyLength: any;
  betDataPopup: boolean;
  message: string;
  fancyDataArray = [];
  fancyArray: any;
  fancyRunnerData: any;
  primaryFancy: any;
  ring: boolean = false;
  ringOn: boolean = false;
  walletBalance: any;
  currentExpo: number;
  currentBalance: number;
  detailCalling = 1;
  fancyId: any = [];
  fancyDta: any;
  createFancyType: number = 1;
  fancyTimer: any;
  pageSize: number;
  dataSource: any;
  openBetsLength: any = 0;
  MatchList: any;
  searchMatch: any;
  layData: any = [];
  backData: any = [];
  cuurency: string;
  showBet: any = 0;
  tvId: any = 0;
  exposuerLimit: any;
  marketLiability: any;
  fancyExpoLiability: any;
  marketCurrentData: any;
  marketPL: number;
  marketLiabilityCondition: boolean;
  currentFancyLiability: any;
  fancySource: any;
  totalMaxLoss: number;
  volumeLimit: boolean = false;
  minVolLimit: boolean = false;
  toggleInfo: boolean = false;
  sportsList: any;
  b: any;
  homeDataCount: any;
  userData: {};
  firstData: any;
  firstDataLength: any;
  runnerDataLength: any;
  runnerData: any;
  updateRunnerData: any;
  tPassword: string;
  targetDate: Date;
  countdown: {
    days: number,
    hours: number,
    minutes: number,
    seconds: number
  };
  isUserNameExist: boolean;
  addUserFrom: FormGroup;
  isAddUserFromSubmitted: boolean;
  selectedMarket_id: any;
  referCode: any;
  facebook: any;
  insta: any;
  telegram: any;
  wa: any;
  mail: any;
  twitter: any;
  timer: any;
  timerInplayStatus: boolean = false;
  constructor(private ref: ChangeDetectorRef, private route: ActivatedRoute, private open: OpenApiService, public matchModel: Match, private toastr: ToastrService,
    private router: Router, private modalService: BsModalService, private fb: FormBuilder, private loginService: LoginService,
    private appValidationService: AppValidationService) {

    this.matchData = JSON.parse(localStorage.getItem('matchData'));
    this.route.params.subscribe(params => {
      if (params.match_id == undefined) {
        this.match_id = this.matchData.match_id;
        this.getMatchDetail();
        this.showFancy()
      } else {
        this.match_id = params.match_id
        this.getMatchDetail();
        this.showFancy()
      }
    })
  }

  ngOnInit(): void {
    this.targetDate = new Date(this.matchData?.match_date);
    let domain = window.location.hostname
    let domain1 = domain.split('.')
    if (domain1[0] == '3rdempire') {
      this.facebook = '';
      this.insta = 'https://www.instagram.com/3rdempiree/';
      this.telegram = 'https://telegram.me/bigdream07';
      this.wa = 'https://api.whatsapp.com/send/?phone=919024696202&text=Hi&type=phone_number&app_absent=0';
      this.mail = '';
      this.twitter = 'https://twitter.com/3rdempiree'
    } else if (domain1[0] == 'motabook365') {
      this.facebook = 'https://www.facebook.com/profile.php?id=100086163486306';
      this.insta = 'https://www.instagram.com/motabook247/';
      this.telegram = 'https://t.me/Motabook247';
      this.wa = 'https://api.whatsapp.com/send?phone=917439876249';
      this.mail = '';
      this.twitter = ''
    }
    const source = interval(1000);
    const now = new Date().getTime();
    const difference = this.targetDate.getTime() - now;
    this.countdown = this.calculateCountdown(difference);
    const subscription = source.subscribe(val => {
      const now = new Date().getTime();
      const difference = this.targetDate.getTime() - now;
      this.countdown = this.calculateCountdown(difference);
      if (this.countdown.seconds <= 0 && this.countdown.minutes <= 0 && this.countdown.hours <= 0 && this.countdown.days <= 0) {
        // Countdown is complete
        this.timerInplayStatus = true;
        subscription.unsubscribe();
      }
    });
    this.createRandomTransactionPassword()
    this.logo = localStorage.getItem('logo')
    this.userMatchStack = [
      10,
      50,
      100, 250, 500, 1000, 5000, 10000, 25000, 50000
    ]
    this.referCode = localStorage.getItem('referCode') != undefined ? localStorage.getItem('referCode') : '';
  }
  calculateCountdown(difference: number) {
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    return {
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds
    };
  }
  toggleLimitInfo(id) {
    this.selectedMarket_id = id;
    this.toggleInfo = !this.toggleInfo;
  }
  getMatchDetail() {
    this.loading = true;
    let data = {
      "match_id": this.match_id
    }
    this.open.matchDetails(data).subscribe((res) => {
      this.loading = false;
      this.matchDetailData = res.data;
      
      for (let i = 0; i < this.matchDetailData.length; i++) {
        if (this.matchDetailData[i].hasOwnProperty('matched')) {
          if (this.matchDetailData[i].matched > 999 && this.matchDetailData[i].matched < 1000000) {
            this.matchDetailData[i].matched = (this.matchDetailData[i].matched / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
          } else if (this.matchDetailData[i].matched > 1000000) {
            this.matchDetailData[i].matched = (this.matchDetailData[i].matched / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
          } else if (this.matchDetailData[i].matched < 900) {
            this.matchDetailData[i].matched = this.matchDetailData[i].matched; // if value < 1000, nothing to do
          }
        } else {
          this.matchDetailData[i].matched = 0;
        }
        for (let o = 0; o < this.matchDetailData[i].runners.length; o++) {
          if ((this.matchDetailData[i].runners[o].ex.availableToBack).length != 0) {
            for (let m = 0; m < (this.matchDetailData[i].runners[o].ex.availableToBack).length; m++) {
              if (this.matchDetailData[i].runners[o].ex.availableToBack[m] != null) {
                if (this.matchDetailData[i].runners[o].ex.availableToBack[m].size > 999 && this.matchDetailData[i].runners[o].ex.availableToBack[m].size < 1000000) {
                  this.matchDetailData[i].runners[o].ex.availableToBack[m].size = (this.matchDetailData[i].runners[o].ex.availableToBack[m].size / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
                } else if (this.matchDetailData[i].runners[o].ex.availableToBack[m].size > 1000000) {
                  this.matchDetailData[i].runners[o].ex.availableToBack[m].size = (this.matchDetailData[i].runners[o].ex.availableToBack[m].size / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
                } else if (this.matchDetailData[i].runners[o].ex.availableToBack[m].size < 900) {
                  this.matchDetailData[i].runners[o].ex.availableToBack[m].size = this.matchDetailData[i].runners[o].ex.availableToBack[m].size; // if value < 1000, nothing to do
                }
              }
            }
          }
          if ((this.matchDetailData[i].runners[o].ex.availableToLay).length != 0) {
            for (let n = 0; n < (this.matchDetailData[i].runners[o].ex.availableToLay).length; n++) {
              if (this.matchDetailData[i].runners[o].ex.availableToLay[n] != null) {
                if (this.matchDetailData[i].runners[o].ex.availableToLay[n].size > 999 && this.matchDetailData[i].runners[o].ex.availableToLay[n].size < 1000000) {
                  this.matchDetailData[i].runners[o].ex.availableToLay[n].size = (this.matchDetailData[i].runners[o].ex.availableToLay[n].size / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
                } else if (this.matchDetailData[i].runners[o].ex.availableToLay[n].size > 1000000) {
                  this.matchDetailData[i].runners[o].ex.availableToLay[n].size = (this.matchDetailData[i].runners[o].ex.availableToLay[n].size / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
                } else if (this.matchDetailData[i].runners[o].ex.availableToLay[n].size < 900) {
                  this.matchDetailData[i].runners[o].ex.availableToLay[n].size = this.matchDetailData[i].runners[o].ex.availableToLay[n].size; // if value < 1000, nothing to do
                }
              }
            }
          }
        }
      }
      this.matchDetail = this.matchDetailData.result;
     
      this.ref.detectChanges();
      let matchOddds;
      this.matchDetail.forEach((matDta, index) => {
        if (matDta.market_name == 'Match Odds') {
          matchOddds = this.matchDetail.splice(index, 1)
        }
      });
      
      this.matchDetail.unshift(matchOddds[0])
    }, (err) => {
      // this.scoreApi();
    },
      () => {
        if (this.router.url.split('?')[0] == '/openDetail') {
          this.timer = setTimeout(() => resolve(this.getMatchDetail()), 10000);
        }
      })

  }

  clearAll = function () {
    this.betslipinfo = false;
    this.betslipStatus = false;
    this.IsShowBetInfo = false;
    this.total_liability = 0;
    this.isActive = false;
    for (let i = 0; i < this.matchModel.stakeIds.length; i++) {
      this.matchModel.stake2['field_' + this.matchModel.stakeIds[i].UId] = 0;
    }
  };


  getBetSlipDataForOdds() {
    this.matchModel.tempArray = [];
    let oddBetSlipVal = JSON.parse(localStorage.getItem('betList'));
    this.oddBetSlipValArray.push(oddBetSlipVal);
    this.matchModel.tempArray.push(oddBetSlipVal);
    if (this.IsToggle) {
      this.matchModel.tempArray[0].stake = this.oneClickSelectedBet;
      this.matchModel.tempArray[0].p_l = ((this.matchModel.tempArray[0].odds * this.matchModel.tempArray[0].stake) - this.matchModel.tempArray[0].stake);
    }
  }

  calculateP_lOnStackOnInput(stake, stkbtn, isback, back) {
    if (stake <= 0) {
      back.stake = 0;
    } else {
      this.stakeIds = this.matchModel.stakeIds;
      this.stackval = (back.stake == '' || back.stake == null) ? 0 : back.stake;
      back.stake = parseFloat(stkbtn);

      if (parseFloat(back.stake) <= parseFloat(back.max_bet_liability) || true) {
        this.isActive = true;
        back.p_l = ((back.odds * back.stake) - back.stake);
        if (isback == 0) {
          this.total_liability = back.p_l;
        } else {
          this.total_liability = back.stake;
        }
        this.matchModel.calculateProfitLoss(back);
        if (back.priceVal <= this.config_max_odd_limit || back.is_session_fancy == 'Y' || true) {
          if (back.priceVal > 0) {
            const tempback = back;
            this.isActive = true;
            this.total_liability = 0;
            if (back.isManual) {
              const pval = back.pricefinal + 1;
              back.p_l = ((pval * back.stake) - back.stake);
            } else {
              back.p_l = ((back.priceVal * back.stake) - back.stake);
            }
            this.matchModel.ProfitLoss = back.p_l;
          }
        }
      } else {
        let msg = '';
        if (back.is_session_fancy == 'Y') {
          msg = 'Max session bet liability is ' + parseFloat(back.max_bet_liability);
        } else {
          msg = 'Max market bet liability is ' + parseFloat(back.max_bet_liability);
        }
        back.stake = parseFloat(back.max_bet_liability);
        this.isActive = false;
      }
    }

  }
  showFancy() {
    this.matchData = JSON.parse(localStorage.getItem('matchData'));
    let param = {
      "match_id": this.match_id,
    }
    this.open.showFancyList(param).subscribe((res) => {
      if (res.status == true) {
        if (res.data.length == 0) {
          this.fancy = [];
          this.showFancyList = false;
          this.ref.detectChanges();
        } else {
          this.showFancyList = true;
          let fancyData = res.data;
          this.fancy = fancyData;
          this.ref.detectChanges();
        }
      } else {
        if (res.logout == true) {
          this.router.navigate(['openDashboard']);
          window.location.reload();
          window.location.replace('openDashboard');
        } else {
          this.toastr.error(res.msg)
        }
      }
    }, (err) => {
      console.log(err);
    })
  }
  hideClose() {
    this.betslipStatus = false;
  }
  activeBetslip() {
    this.betslipStatus = true;
  }
  trackHero(index, runner) {
    // return hero ? hero.id : undefined;
  }

  setbtn(indx) {
    this.setRef = indx;
  }
  addClassWhenOddChange(newSize, oldSize, newPrice, oldPrice, i, type) {
    if ((newSize != "--" && oldSize != "--") || (newPrice != "--" && oldPrice != "--")) {
      if ((newSize != oldSize || newPrice != oldPrice)) {
        return 'backColorChange' + i;
      }

    }
  }

  openModalLoginPopup(loginPopup: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginPopup,
      Object.assign({}, { class: 'loginModal modal-dialog-centered modal-fullscreen-lg-down', ignoreBackdropClick: false })
    );
  }
  openModalCondition(Terms: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      Terms,
      Object.assign({}, { class: 'modal-dialog modal-dialog-scrollable modal-large' })
    );
  }
  openModalFancybook(fancyBook: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      fancyBook,
      Object.assign({}, { class: 'modal-dialog fancy-book' })
    );
  }
  popOver(data) {
    this.popFancyId = data.fancy_id;
    this.popShow = true;
    setTimeout(() => (this.closePopOver()), 5000)
  }
  closePopOver() {
    this.popShow = false;
  }
  setSessionValue(FancyObject, is_back, value, size) {
    this.betslipinfo = true;
    this.betslipStatus = true;
    this.betPrice = value;
    this.betSize = size;
    this.selection = FancyObject.fancy_id;
    this.fancyIdValidation = FancyObject.fancy_id;
    this.arrayObj = {
      fancy_id: FancyObject.fancy_id,
      market_id: FancyObject.market_id,
      odds: value,
      stake: 0,
      is_fancy: 1,
      is_back: is_back,
      MatchName: FancyObject.name,
      placeName: FancyObject.name,
      isManual: 0,
      size: size,
      is_session_fancy: 'Y'
    };
    this.matchModel.isbetslipshow = true;
    localStorage.setItem('betList', JSON.stringify(this.arrayObj));
    this.matchModel.setUser(this.arrayObj);
    this.getBetSlipDataForOdds();
  }
  trackByFn(index: any, item: any) {
    return index;
  }
  status: boolean = false;
  betLimit() {
    this.status = true;
  }
  betLimitClose() {
    this.status = false;
  }
  infoStatus: boolean = false;
  infoClick() {
    this.infoStatus = true;
  }
  infoClose() {
    this.infoStatus = false;
  }
  fancyInfoStatus: boolean = false;
  fancyInfoClick() {
    this.fancyInfoStatus = true;
  }
  fancyInfoClose() {
    this.fancyInfoStatus = false;
  }

  onClickOfBet(matchData, betType, clickedBetDetails) {
    this.betType = betType
    if (betType === "Back") {
      this.selectedBackBet = matchData
      this.selectedBackBetDetails = clickedBetDetails

    } else if (betType === "Lay") {
      this.selectedLayBet = matchData
      this.selectedLayBetDetails = clickedBetDetails
    }
  }

  cancelAllBets() {
    this.betType = null;
    this.selectedBackBet = null;
    this.selectedBackBetDetails = null;
    this.selectedLayBet = null;
    this.selectedLayBetDetails = null;
  }

  // Calculation on Bet

  getOddsValue(price, size, isback, name, id, marketid, index, marketitem) {
    this.matchData = marketitem;
    this.marketCurrentData = marketitem;
    this.ring = false;
    this.betslipinfo = true;
    this.betslipStatus = true;
    this.market = marketid;
    this.betPrice = price;
    this.selection = id;
    this.market = marketid;
    this.arrayObj = {
      selection_id: id,
      market_id: marketid,
      size: size,
      odds: price,
      stake: 0,
      is_back: isback,
      is_fancy: 0,
      MarketName: marketitem.market_name,
      placeName: name,
      isManual: 0,
      is_session_fancy: 'N',
      inplay: marketitem.inplay,
    };
    localStorage.setItem('betList', JSON.stringify(this.arrayObj));
    this.matchModel.setUser(this.arrayObj);
    this.getBetSlipDataForOdds();
  }

  calculateP_lOnStack(stake, stkbtn, isback, back) {
    this.stakeIds = this.matchModel.stakeIds;
    this.stackval = (back.stake == '' || back.stake == null) ? 0 : back.stake;
    back.stake = parseFloat(stkbtn) + parseFloat(this.stackval);
    if (parseFloat(back.stake) <= parseFloat(back.max_bet_liability) || true) {
      this.isActive = true;
      back.p_l = ((back.odds * back.stake) - back.stake);
      if (isback == 0) {
        this.total_liability = back.p_l;
      } else {
        this.total_liability = back.stake;
      }
      this.matchModel.calculateProfitLoss(back);
      if (back.odds <= this.config_max_odd_limit || back.is_session_fancy == 'Y' || true) {
        if (back.odds > 0) {
          const tempback = back;
          this.isActive = true;
          this.total_liability = 0;
          if (back.isManual) {
            const pval = back.pricefinal + 1;
            back.p_l = ((pval * back.stake) - back.stake);
          } else {
            back.p_l = ((back.odds * back.stake) - back.stake);
          }
          this.matchModel.ProfitLoss = back.p_l;
        }
      }
    } else {
      let msg = '';
      if (back.is_session_fancy == 'Y') {
        msg = 'Max session bet liability is ' + parseFloat(back.max_bet_liability);
      } else {
        msg = 'Max market bet liability is ' + parseFloat(back.max_bet_liability);
      }
      back.stake = parseFloat(back.max_bet_liability);
      this.isActive = false;
    }
  }

  //login popup related code 
  openModalLoginDesktop(loginDesktop: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginDesktop,
      Object.assign({}, { class: 'loginModalDesktop loginModal modal-dialog-centered modal-fullscreen-lg-down', ignoreBackdropClick: false })
    );
  }

  async onSubmitLogin(from?: string) {
    if (this.Form.valid) {
      this.loginButtonDisable = true
      if (this.clickTimeout) {
        this.setClickTimeout(() => { });
      } else {
        this.setClickTimeout(() => {
          if (from == 'login') {
            this.headerSingleLoginClick()
          }
        })
      }
    } else {
      this.submitted = true;
    }
  }
  public handleSingleLoginClick() {
    this.loading = true;
    this.submitted = true;
    this.a = (this.Form.value.user_name);
    let b = (this.a).toLowerCase()
    this.Form.value.user_name = b;
    if (this.Form.invalid) {
      this.loginButtonDisable = false
      return;
    }
    if (this.tPassword == this.transactionPassword) {
      this.loginService.submitlogin(this.Form.value).subscribe((result) => {
        if (result.status == true) {
          this.modalRef.hide();
          this.loading = false;
          this.toastr.success(result.msg, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          })
          this.setCokkies(result);
          localStorage.setItem('userDetails', JSON.stringify(result.data));
          localStorage.setItem('operator', JSON.stringify(result.operatorId));
          this.loginButtonDisable = false
          this.router.navigate(['dashboard'])
        } else {
          this.loading = false;
          this.showResponseMessage = result.msg;
          this.toastr.error(this.showResponseMessage)
          this.loginButtonDisable = false;
          this.createRandomTransactionPassword()
        }
      }, (err) => {
        console.log(err);
        this.loading = false;
        this.loginButtonDisable = false;
        this.createRandomTransactionPassword()
      });
    } else {
      this.loading = false;
      this.loginButtonDisable = false;
      this.showResponseMessage = 'Invalid Captcha';
      this.createRandomTransactionPassword()
    }
  }
  public headerSingleLoginClick() {
    this.loading = true;
    this.submitted = true;
    this.a = (this.Form.value.user_name);
    let b = (this.a).toLowerCase()
    this.Form.value.user_name = b;
    if (this.Form.invalid) {
      this.loginButtonDisable = false
      return;
    }
    if (this.tPassword == this.transactionPassword) {
      this.loginService.submitlogin(this.Form.value).subscribe((result) => {
        if (result.status == true) {
          this.loading = false;
          this.toastr.success(result.msg, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          })
          this.setCokkies(result);
          localStorage.setItem('userDetails', JSON.stringify(result.data));
          localStorage.setItem('operator', JSON.stringify(result.operatorId));
          this.loginButtonDisable = false
          this.router.navigate(['dashboard'])
        } else {
          this.loading = false;
          this.showResponseMessage = result.msg;
          this.toastr.error(this.showResponseMessage)
          this.loginButtonDisable = false;
          this.createRandomTransactionPassword()
        }
      }, (err) => {
        console.log(err);
        this.loading = false;
        this.loginButtonDisable = false;
        this.createRandomTransactionPassword()
      });
    } else {
      this.loading = false;
      this.loginButtonDisable = false;
      this.showResponseMessage = 'Invalid Captcha';
      this.createRandomTransactionPassword()
    }
  }
  public setClickTimeout(callback) {
    clearTimeout(this.clickTimeout);
    this.clickTimeout = setTimeout(() => {
      this.clickTimeout = null;
      callback();
    }, 400);
  }
  async setCokkies(result) {
    debugger
    localStorage.setItem('userAccessToken', result.token.accessToken);
    localStorage.setItem('userId', result.data._id);
    localStorage.setItem('userRefreshToken', result.token.refreshToken);
    var acctoken = localStorage.getItem('userAccessToken');
    this.router.navigate(['dashboard'])
  }

  createRandomTransactionPassword() {
    //initialize a variable having alpha-numeric characters
    var chars = "0123456789";
    //specify the length for the new string to be generated
    var string_length = 4;
    var randomstring = '';
    //put a loop to select a character randomly in each iteration
    for (var i = 0; i < string_length; i++) {
      var rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    this.transactionPassword = randomstring;
  }

  openModalSignupModal(signupModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      signupModal,
      Object.assign({}, { class: 'login_page', ignoreBackdropClick: false }),
    );
    this.createSignFrom();
    if (this.referCode != undefined || this.referCode != '') {
      this.addUserFrom.controls['refer_code'].setValue(this.referCode);
    }
  }

  openModalLoginModal(loginModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginModal,
      Object.assign({}, { class: 'login_page', ignoreBackdropClick: false }),
    );
    this.createFrom();
  }
  createFrom() {
    this.Form = this.fb.group({
      user_name: ['', Validators.required],
      password: ['', [Validators.required]],
      grant_type: ['password'],
      isRememberMe: [false]
    })
    if (JSON.parse(localStorage.getItem('RememberMe'))) {
      this.Form.patchValue({
        user_name: localStorage.getItem('user_name'),
        password: localStorage.getItem('password'),
        isRememberMe: localStorage.getItem('RememberMe')
      })
    }
  }
  createSignFrom() {
    this.addUserFrom = this.fb.group({
      name: ['', Validators.required],
      user_name: ['', Validators.required],
      password: ['', [Validators.required]],
      mobile: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]],
      email: [''],
      refer_code: ['']
    });
    this.applyValidationToFormGroup(this.addUserFrom, "AddUser")
  }
  get f() { return this.Form.controls; }
  applyValidationToFormGroup(formGroupName, jsonArrayName) {
    this.appValidationService.applyValidationRulesToFromGroup(formGroupName, jsonArrayName).then((validators) => {
    }).catch(() => { })
  }
  addUserData() {
    this.isAddUserFromSubmitted = true;
    this.addUserFrom.controls['name'].setValue(this.addUserFrom.controls['user_name'].value)
    this.appValidationService.markFormGroupTouched(this.addUserFrom)
    if (this.addUserFrom.controls.email.value == '') {
      delete this.addUserFrom.value['email'];
    }
    if (this.addUserFrom.controls.refer_code.value == '') {
      delete this.addUserFrom.value['refer_code'];
    }
    if (this.addUserFrom.invalid) {
      return;
    }
    this.loginService.register(this.addUserFrom.value).subscribe((result) => {
      this.isAddUserFromSubmitted = false;
      if (result.status == true) {
        this.toastr.success(result.msg, '', {
          timeOut: 1000
        });
        this.Form.controls['user_name'].setValue(this.addUserFrom.controls['user_name'].value)
        this.Form.controls['password'].setValue(this.addUserFrom.controls['password'].value)
        this.headerSingleLoginClick();
        this.modalRef.hide();
      } else {
        this.toastr.error(result.msg, '', {
          timeOut: 10000,
        });
      }
    }, (err) => {
      console.log(err);
    });
  }

  isControlIsValid(controlName: string): boolean {
    if (this.addUserFrom.controls[controlName].invalid && (this.isAddUserFromSubmitted || this.addUserFrom.controls[controlName].dirty || this.addUserFrom.controls[controlName].touched)) {
      return true;
    }
    return false;
  }
  isControlHasErros(controlName: string) {
    if (this.addUserFrom.controls[controlName].errors) {
      let value = ''
      let object = this.addUserFrom.controls[controlName].errors
      for (var propName in object) {
        if (object.hasOwnProperty(propName) && propName != "required") {
          value = object[propName];
        }
      }
      return value;
    }
    return null;
  }
  async redirectToDashboard() {
    var acctoken = localStorage.getItem('userAccessToken');
    if (acctoken != null && acctoken != undefined && acctoken != '') {
      this.router.navigate(['dashboard'])
    }
  }
  goToRoute() {
    debugger
    let domain = window.location.hostname
    let domain1 = domain.split('.')
    if (domain1[0] == 'billionexchwallet') {
      this.wa = 'https://api.whatsapp.com/send?phone=13124935676&text=Hi&type=phone_number&app_absent=0';
      window.open(this.wa, "_blank")
    } else if (domain1[0] == 'motabook365') {
      this.wa = 'https://api.whatsapp.com/send?phone=13124935676';
      window.open(this.wa, "_blank")
    } else if (domain1[0] == '3rdempire') {
      this.wa = 'https://api.whatsapp.com/send/?phone=919024696202&text=Hi&type=phone_number&app_absent=0';
      window.open(this.wa, "_blank")
    }
  }
}
