<app-header></app-header>
<div class="content_area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3 left_bar">
                <app-left-side-bar></app-left-side-bar>
            </div>

            <div class="col-md-9 conetnt_col">
                <div class="promoting_tabs reporting_tabs">
                    <app-report-header></app-report-header>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="open" role="tabpanel"
                            aria-labelledby="pills-contact-tab">
                            <div class="account_title">
                                <h4 class="card-title">View Bets</h4>
                            </div>

                            <div class="data_table">
                                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>



<ng-template #popTemplateStatementStart>
    <datepicker [hidden]="!isDateVisible" [(ngModel)]="dateStatement" [ngModelOptions]="{standalone: true}"
        [minDate]="minDate" [maxDate]="todayDateTime" [showWeeks]="false" (ngModelChange)="updateDateStatement()"
        (selectionDone)="dateSelectionDone()">
    </datepicker>
    <timepicker [hidden]="!isDateVisible" [(ngModel)]="timeStatement" [ngModelOptions]="{standalone: true}"
        [min]="minTime" [max]="maxTime" (ngModelChange)="updateTimeStatement()" [showMeridian]="isMeridian"
        showSeconds="true">
    </timepicker>
</ng-template>
<ng-template #popTemplateStatementEnd>
    <datepicker [hidden]="!isDateVisible" [(ngModel)]="endDateStatement" [ngModelOptions]="{standalone: true}"
        [minDate]="dateStatement" [maxDate]="todayDateTime" [showWeeks]="false"
        (ngModelChange)="updateEndDateStatement()" (selectionDone)="dateSelectionDone()">
    </datepicker>
    <timepicker [hidden]="!isDateVisible" [(ngModel)]="endTimeStatement" [ngModelOptions]="{standalone: true}"
        [min]="minTime" [max]="maxTime" (ngModelChange)="updateEndTimeStatement()" [showMeridian]="isMeridian"
        showSeconds="true">
    </timepicker>
</ng-template>